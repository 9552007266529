import { Checkbox, LoadingSpinner } from "pepsico-ds";
import "./bannerSegmentsTable.scss";

const BannerSegmentsTable = ({
  segmentsListData,
  selectedSegmentsList,
  loading,
  onSelectSegment,
}) => {
  return (
    <div className="table-list-checkbox p-0">
      {loading ? (
        <LoadingSpinner
          size={{
            height: "30px",
            width: "30px",
          }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table>
          <thead>
            <tr>
              <th className="column-checkbox"></th>
              <th className="column-name">Name</th>
              <th className="column-attributes">Attributes</th>
              <th className="column-population">Population</th>
            </tr>
          </thead>
          <tbody>
            {segmentsListData?.length ? (
              segmentsListData?.map((segment) => (
                <tr key={segment.id}>
                  <td className="td-checkbox">
                    <Checkbox
                      id={`checkbox-${segment.id}`}
                      onUpdate={() => onSelectSegment(segment)}
                      checked={selectedSegmentsList?.some(
                        (row) => row.id === segment.id
                      )}
                    />
                  </td>
                  <td className="td-name">{segment.name}</td>
                  <td className="td-attributes">
                    {segment.attributes.length > 0
                      ? segment.attributes.join(", ")
                      : "None"}
                  </td>
                  <td className="td-population">{segment.population}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BannerSegmentsTable;
