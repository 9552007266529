import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import createPartner from "../../../graphql/mutations/createPartner";

export const useCreatePartnerMutation = () => {
  const [createPartnerMutation, { loading }] = useMutation(createPartner);

  const create = useCallback(
    async (onSuccess, onFailure, partnerData) => {
      const payload = {
        ...partnerData,
        image: "string",
      };

      try {
        const response = await createPartnerMutation({
          variables: {
            Partner: payload,
          },
        });

        if (response?.data?.createPartner?.id) {
          onSuccess();
        }
      } catch (error) {
        onFailure(error);
      }
    },
    [createPartnerMutation]
  );

  return [create, loading];
};
