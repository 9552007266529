import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Button, Icon } from "pepsico-ds";
import { useCallback, useEffect, useRef, useState } from "react";
import "./imageUploader.scss";

const PartnerFileUploadComponent = ({
  handleChange,
  label,
  data,
  path,
  uischema,
  visible,
}) => {
  const isPartnerRequired = uischema?.isPartnerRequired || true;
  const accept = uischema?.accept?.toString() || ".txt";
  const description = uischema?.description || "Select a file";
  const [errorMessage, setErrorMessage] = useState("");
  const [rewardItemFileData, setRewardItemFileData] = useState(data || []);
  const [disabled, setDisabled] = useState(false);
  const fileInputRef = useRef(null);

  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;

  // File validation function
  const isValidFileType = useCallback(
    (file) => ["text/plain", "text/csv"].includes(file.type),
    []
  );

  // Handle file changes (on file input)
  const handleFileChange = useCallback(
    (files) => {
      setErrorMessage("");
      if (files.length < 1) return;

      const fileArray = Array.from(files);
      const validFiles = fileArray.filter((file) => {
        if (!isValidFileType(file)) {
          setErrorMessage("Invalid file type. Please upload a .txt or .csv file.");
          return false;
        }
        if (rewardItemFileData.some((f) => f.name === file.name)) {
          setErrorMessage("File already exists");
          return false;
        }
        return true;
      });

      if (validFiles.length > 0) {
        setRewardItemFileData((prevData) => [
          ...prevData,
          ...validFiles.map((file) => ({ name: file.name, file })),
        ]);
      }
      fileInputRef.current.value = null;
    },
    [isValidFileType, rewardItemFileData]
  );

  const handleOnDelete = useCallback((fileId) => {
    setRewardItemFileData((prevData) =>
      prevData.filter((_, index) => index !== fileId)
    );
  }, []);

  useEffect(() => {
    handleChange(path, rewardItemFileData); // Sync with form
  }, [handleChange, path, rewardItemFileData]);

  useEffect(() => {
    if (jsonformState?.partnerId) {
      localStorage.setItem("partnerId", jsonformState.partnerId);
      setDisabled(false);
      setErrorMessage("");
    } else if (!isPartnerRequired) {
      setDisabled(true);
      setRewardItemFileData([]);
      setErrorMessage("Please select a partner to upload files.");
    }
  }, [jsonformState?.partnerId, isPartnerRequired]);

  if (!visible) return null;

  const isOlderFile = (file) => file?.id?.length > 0; // Check if file is already uploaded

  const renderFileList = () =>
    rewardItemFileData.map((file, index) => (
      <div key={index} className="file-uploader__file">
        <span className="file-uploader__file-name">
          <Icon className="file-icon" icon="file_copy" size="small" /> {file.name}
        </span>
        <button onClick={() => handleOnDelete(index)} disabled={isOlderFile(file)}>
          Delete
        </button>
      </div>
    ));

  return (
    <>
      <p style={{ fontSize: 12, marginBottom: 4 }}>{label}</p>
      <div className="partner-code-group-file-uploader">
        <div className="uploader-view">
          <Icon
            className="file-icon file-icon-large"
            icon="file_copy"
            size="large"
          />
          <span>{description}</span>
          <Button
            size="small"
            text="Upload Files"
            variant="secondary"
            onClick={() => fileInputRef.current.click()}
            disabled={disabled}
          />
        </div>

        <div className="file-list">{renderFileList()}</div>

        <input
          ref={fileInputRef}
          type="file"
          onChange={(e) => handleFileChange(e.target.files)}
          multiple
          accept={accept}
          style={{ display: "none" }} // Hide file input
        />
      </div>

      {errorMessage && <p style={{ color: "red", fontSize: 12 }}>{errorMessage}</p>}
    </>
  );
};

export const PartnerFileUploadControl = withJsonFormsControlProps(
  PartnerFileUploadComponent
);
