import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { Breadcrumb, Button, Card, Tab, TabsNavigation } from "pepsico-ds";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SegmentAttributesAndValues from "../../../../components/segmentation/SegmentAttributesAndValues";
import { listSegments } from "../../../../graphql/queries/listSegments";
import { toCamelCase } from "../../../../utils/functions";
import filterCampaignsBySegment from "../queries/filterCampaignsBySegment";
import filterContentBySegment from "../queries/filterContentBySegment";
import "./segmentSummaryViewPage.scss";

const CONTENT_TYPES = {
  BANNER: "banner-content",
  SURVEY_QUIZ: "survey-quiz-content",
  CONTENT: "page-content",
};

const COLUMNS = {
  CAMPAIGN: [
    { header: "Campaign Name", columnKey: "title", width: "50%" },
    { header: "Campaign Type", columnKey: "type", width: "50%" },
  ],
  BANNER: [
    { header: "Banner Name", columnKey: "title", width: "50%" },
    { header: "Banner Type", columnKey: "contentType", width: "50%" },
  ],
  SURVEY: [
    { header: "Survey/Quiz Name", columnKey: "title", width: "50%" },
    { header: "Survey/Quiz Type", columnKey: "contentType", width: "50%" },
  ],
  CONTENT: [
    { header: "Content Name", columnKey: "title", width: "50%" },
    { header: "Content Type", columnKey: "contentType", width: "50%" },
  ],
};

export default function SegmentSummaryViewPage() {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabData, setTabData] = useState({
    campaignData: [],
    bannerData: [],
    surveyData: [],
    contentData: [],
  });

  const [selectedSegments, setSelectedSegments] = useState({});
  const [contentRefetchLoading, setContentRefetchLoading] = useState(false);

  const { data: segmentsData, loading: segmentsLoading } = useQuery(listSegments);

  const { data: campaignData, loading: campaignLoading } = useQuery(
    filterCampaignsBySegment,
    {
      variables: { filter: { segmentations: [segmentId], hasSegments: true } },
    }
  );

  // We'll use refetch to load content based on the selected tab
  const { refetch } = useQuery(filterContentBySegment, {
    variables: {
      filter: {
        contentDefinitionSlug: CONTENT_TYPES.BANNER,
        segments: [segmentId],
      },
    },
    skip: true,
  });

  useEffect(() => {
    if (segmentsData) {
      const initialSegments = segmentsData.listSegments.find(
        (segment) => segment.id === segmentId
      );
      setSelectedSegments(initialSegments);
    }
  }, [segmentsData, segmentId]);

  useEffect(() => {
    setTabData((prev) => ({
      ...prev,
      campaignData: campaignData?.listCampaigns || [],
    }));
  }, [campaignData]);

  const CustomTable = useCallback(
    ({ rows, columns, isLoading }) => (
      <table className="segment-summary-table">
        <thead>
          <tr>
            {columns.map(({ columnKey, header, width }) => (
              <th key={columnKey} style={{ width }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                <CircularProgress />
              </td>
            </tr>
          ) : rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          ) : (
            rows.map((row) => (
              <tr key={row.id}>
                {columns.map(({ columnKey }) => (
                  <td key={columnKey}>{toCamelCase(row[columnKey])}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    ),
    []
  );

  const buildTabContent = useMemo(() => {
    const tabContents = [
      {
        data: tabData.campaignData,
        columns: COLUMNS.CAMPAIGN,
        loading: segmentsLoading || campaignLoading,
      },
      {
        data: tabData.bannerData,
        columns: COLUMNS.BANNER,
        loading: contentRefetchLoading,
      },
      {
        data: tabData.surveyData,
        columns: COLUMNS.SURVEY,
        loading: contentRefetchLoading,
      },
      {
        data: tabData.contentData,
        columns: COLUMNS.CONTENT,
        loading: contentRefetchLoading,
      },
    ];

    return function WrappedCustomTable(tabIndex) {
      const { data, columns, loading } = tabContents[tabIndex];
      return (
        <div>
          <CustomTable
            key={tabIndex}
            rows={data}
            columns={columns}
            isLoading={loading}
          />
        </div>
      );
    };
  }, [tabData, segmentsLoading, campaignLoading, contentRefetchLoading]);

  useEffect(() => {
    const contentTypes = [
      null,
      CONTENT_TYPES.BANNER,
      CONTENT_TYPES.SURVEY_QUIZ,
      CONTENT_TYPES.CONTENT,
    ];
    const dataKeys = ["campaignData", "bannerData", "surveyData", "contentData"];
    const contentTypeKeys = [null, "banner_type", "type", "content_type"];

    if (selectedTab > 0) {
      setContentRefetchLoading(true);
      refetch({
        filter: {
          contentDefinitionSlug: contentTypes[selectedTab],
          segments: [segmentId],
        },
      })
        .then(({ data }) => {
          const listContent = data?.listContent || [];
          const content = listContent.map((item) => ({
            ...item,
            contentType: item?.contentData?.[contentTypeKeys[selectedTab]] || "",
          }));

          setTabData((prev) => ({
            ...prev,
            [dataKeys[selectedTab]]: content,
          }));
        })
        .finally(() => {
          setContentRefetchLoading(false);
        });
    }
  }, [selectedTab, refetch, segmentId]);

  return (
    <div style={{ padding: "20px" }}>
      <Card>
        <div>
          <Breadcrumb
            activeIndex={1}
            breadcrumbs={[
              {
                label: "Segments",
                href: "/segmentation/segments-list",
              },
              {
                label: selectedSegments?.name || "Segment Summary",
                href: `/segmentation/segment-summary/${segmentId}`,
              },
            ]}
          />
        </div>
        <div className="segment-summary-view-page">
          <div className="segement-detail-card">
            <h3>Basic Setup</h3>
            <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
              <div>
                <p>
                  <strong>Name:</strong> {selectedSegments?.name}
                </p>
              </div>
              <div>
                <p>
                  <strong>Created By: </strong> {selectedSegments?.type}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <strong>Attributes and Values:</strong>
              <SegmentAttributesAndValues rules={selectedSegments?.rules} />
            </div>
          </div>
          <div className="segement-detail-card segment-table-card">
            <h3>Segment is added to:</h3>
            <div>
              <TabsNavigation
                className="tabs-wrap"
                style={{ borderBottom: "2px solid #D7E5F8" }}
                onUpdate={setSelectedTab}
                selectedIndex={0}
              >
                <Tab className="tab" label="Campaigns" />
                <Tab className="tab" label="Banners" />
                <Tab className="tab" label="Surveys/Quizzes" />
                <Tab className="tab" label="Contents" />
              </TabsNavigation>
              <div
                style={{
                  padding: "20px 0px",
                  maxHeight: "400px",
                  overflowX: "scroll",
                }}
              >
                {buildTabContent(selectedTab)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => navigate("/segmentation/segments-list/list")}
            >
              Back to Segment
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
