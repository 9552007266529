import { CircularProgress } from "@mui/material";
import _ from "lodash";
import { Pagination } from "pepsico-ds";
import PropTypes from "prop-types";
import { useContext } from "react";

import ButtonGroupDynamic from "../ButtonGroupDynamic";
import ExportOptionsButton from "../exportOptionsButton/ExportOptionsButton";
import FiltersComponent from "../filtersComponent/FiltersComponent.jsx";
import { ListingComponentContext } from "./ListingComponentContext";

const CONTAINER_STYLE = {
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 3rem)",
};
const HEADER_STYLE = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const PAGINATION_STYLE = {
  padding: "16px 0px 16px 0px",
  display: "flex",
  justifyContent: "center",
  flexShrink: 0,
};

const OPTION_STYLE = {
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
  gap: "20px",
};

const ListingComponentWrapper = ({
  filterConfig = {
    withDebounce: {
      jsonSchema: null,
      uiSchema: null,
    },
    withoutDebounce: {
      jsonSchema: null,
      uiSchema: null,
    },
    allFilters: {
      jsonSchema: null,
      uiSchema: null,
    },
  },
  children,
  filterClasses = "",
  customStyles = {},
  filterComponent = null, // Use this if you want custom filter component.
  toggleViewType = true, // Use this if you want to hide toggle between grid and list view option.
  withAllFilters = false, // Use this if you want to show all filters option.
  exportOptions = false, // Use this if you want to show export options.
}) => {
  const {
    filterData,
    loading,
    error,
    viewableRecords,
    setFilterData,
    resetPagination,
    debouncedFilterData,
    handleDebouncedFilterChange,
    handleAllFiltersChange,
    allFiltersData,
    pagination,
    setPagination,
    totalPageCount,
    viewType,
    setViewType,
    handleClearFilters,
    handleApplyFilters,
    viewAllFilters,
    setViewAllFilters,
  } = useContext(ListingComponentContext);

  const onFilterChange = (data) => {
    const cleanedData = _.omitBy(
      data,
      (value) => _.isArray(value) && _.isEmpty(value)
    );
    resetPagination();
    if (!Array.isArray(data?.isActive)) {
      setFilterData(cleanedData);
      return;
    }
    if (cleanedData?.isActive?.length === 0) {
      setFilterData({ ...cleanedData, isActive: null });
    } else if (cleanedData.isActive?.[0] === "true") {
      setFilterData({ ...cleanedData, isActive: true });
    } else if (cleanedData.isActive?.[0] === "false") {
      setFilterData({ ...cleanedData, isActive: false });
    } else {
      setFilterData(cleanedData);
    }
  };

  return (
    <div style={{ ...CONTAINER_STYLE, ...customStyles }}>
      <div style={HEADER_STYLE}>
        {filterComponent || (
          <FiltersComponent
            filterConfig={filterConfig}
            filterClasses={filterClasses}
            onFilterChange={onFilterChange}
            handleClearFilters={handleClearFilters}
            handleApplyFilters={handleApplyFilters}
            viewAllFilters={viewAllFilters}
            setViewAllFilters={setViewAllFilters}
            withAllFilters={withAllFilters}
            debouncedFilterData={debouncedFilterData}
            handleDebouncedFilterChange={handleDebouncedFilterChange}
            filterData={filterData}
            handleAllFiltersChange={handleAllFiltersChange}
            allFiltersData={allFiltersData}
          />
        )}
        <div style={OPTION_STYLE}>
          {toggleViewType && (
            <ToggleViewType viewType={viewType} setViewType={setViewType} />
          )}
          {exportOptions && <ExportOptionsButton />}
        </div>
      </div>
      {loading && <LoadingState />}
      {!loading && viewableRecords.length === 0 && <NoRecordsFound />}
      {!(loading || error) && children}
      {!loading && !error && totalPageCount > 0 && (
        <PaginationComponent
          pagination={pagination}
          setPagination={setPagination}
          totalPageCount={totalPageCount}
        />
      )}
    </div>
  );
};

const ToggleViewType = ({ viewType, setViewType }) => (
  <ButtonGroupDynamic
    items={[
      { icon: "view_module", onClick: () => setViewType("grid") },
      { icon: "view_list", onClick: () => setViewType("list") },
    ]}
    size="medium"
    spacing="2px"
    variant="toggle"
    selectedIndex={viewType === "grid" ? 0 : 1}
  />
);

const LoadingState = () => (
  <div
    data-testid="loading-state"
    className="display-flex align-items-center justify-content-center"
    style={{ flex: "1" }}
  >
    <CircularProgress />
  </div>
);

const NoRecordsFound = () => (
  <div
    className="display-flex align-items-center justify-content-center"
    style={{ flex: "1", padding: "50px 0px" }}
  >
    <p>No Records found</p>
  </div>
);

const PaginationComponent = ({ pagination, setPagination, totalPageCount }) => (
  <div style={PAGINATION_STYLE}>
    <Pagination
      currentPage={pagination.currentPage}
      onUpdate={(newIndex) =>
        setPagination({ ...pagination, currentPage: newIndex })
      }
      pageCount={totalPageCount}
      size="medium"
      variant="number"
    />
  </div>
);

ListingComponentWrapper.propTypes = {
  filterConfig: PropTypes.shape({
    withDebounce: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
    withoutDebounce: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
    allFilters: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
  }),
  children: PropTypes.node,
  filterClasses: PropTypes.string,
  customStyles: PropTypes.object,
  filterComponent: PropTypes.element,
  toggleViewType: PropTypes.bool,
  withAllFilters: PropTypes.bool,
  exportOptions: PropTypes.bool,
};

export default ListingComponentWrapper;
