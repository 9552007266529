import { Button, File, Link, LoadingSpinner } from "pepsico-ds";
import PropTypes from "prop-types";
import { useState } from "react";
import { useBynder } from "../../hooks/useBynderHook";
import useFakeProgressValue from "../../hooks/useFakeProgressValue";
import { BrandsSelector } from "./BrandsSelector";
import "./ImagePicker.scss";

const VIEW_STATE = {
  GALLERY: "GALLERY",
  UPLOAD: "UPLOAD",
};

export const ImagePicker = ({
  accept = ".jpg, .jpeg, .png",
  className,
  shortDescription,
  description = "Select an image",
  disabled = false,
  maxSize = 1000,
  multiple = true,
  onUpdate,
}) => {
  const bynder = useBynder();
  const { initProgress, finishProgress, resetProgress, progressValue } =
    useFakeProgressValue();
  const [uploadError, setUploadError] = useState();

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState([]);
  const [isSubbrandsRequired, setIsSubbrandsRequired] = useState(true);

  const handleBrandSelection = (selected) => {
    setSelectedBrands(selected);
    setSelectedSubBrands([]);
  };

  const handleSubBrandSelection = (selected) => {
    setSelectedSubBrands(selected);
  };

  const [galleryImages, setGalleryImages] = useState([]);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);
  const [galleryError, setGalleryError] = useState();

  const [selectedImage, setSelectedImage] = useState(null);
  const [viewState, setViewState] = useState(VIEW_STATE.GALLERY);

  const selectedFileUploadDidChange = async (file) => {
    const fileExtension = file?.name?.split(".").pop();
    const hasIncludeExt = accept.includes(fileExtension);
    if (!hasIncludeExt) {
      setUploadError(
        `Invalid file type! Allowed files types are ${accept.toString()}`
      );
      return;
    }

    if (file?.size && Math.ceil(file?.size / 1000) > maxSize) {
      const isMb = maxSize >= 1000;
      const size = isMb ? maxSize / 1000 : maxSize;
      const sizeUnit = isMb ? "MB" : "KB";

      setUploadError(
        `Image is too large! Maximum size allowed is ${size}${sizeUnit}.`
      );
      return;
    }

    resetProgress();
    setUploadError();

    try {
      const abortController = new AbortController();
      const mediaInfoResult = await bynder.upload({
        file,
        title: file.name,
        extraMetaproperties: {
          brand: selectedBrands.map((brand) => brand.id),
          subBrand: selectedSubBrands.map((subBrand) => subBrand.id),
        },
        updatePercentage: initProgress,
        abortController,
      });

      finishProgress();
      setTimeout(() => {
        onConfirmImageSelection(mediaInfoResult);
      }, 100);
    } catch (err) {
      console.error("imagePicker::error", err);
      resetProgress();
      setUploadError("Error saving image! Please try again later.");
    }
  };

  const openImageGallery = () => {
    setGalleryError();
    setIsGalleryOpen(true);
    loadGalleryAssets();
  };

  const closeImageGallery = () => {
    setViewState(VIEW_STATE.GALLERY);
    setIsGalleryOpen(false);
  };

  const loadGalleryAssets = async () => {
    setIsGalleryLoading(true);

    try {
      const assets = await bynder.getAssetsByTag();
      setGalleryImages(assets);
      return assets;
    } catch (err) {
      console.error("imagePicker::error", err);
      setGalleryError("Error loading images! Please try again later.");
    } finally {
      setIsGalleryLoading(false);
    }
  };

  const onThumbnailDidClick = (asset) => {
    setSelectedImage(asset.id === selectedImage?.id ? null : asset);
  };

  const onCancelGalleryImageSelection = () => {
    if (isUploading) {
      return;
    }

    closeImageGallery();
    setSelectedImage(null);
  };

  const onConfirmImageSelection = async (imageAsset) => {
    const asset = imageAsset ?? selectedImage;
    onUpdate(asset.original, asset.name);

    closeImageGallery();
  };

  const onGotoUploadNewImage = () => {
    setViewState(VIEW_STATE.UPLOAD);
    setSelectedImage(null);
  };

  const onGotoGallery = () => {
    setViewState(VIEW_STATE.GALLERY);
    setSelectedImage(null);
  };

  const isUploading = progressValue !== undefined && progressValue > 0;
  const isNoResults = !galleryImages?.media?.length;

  const isSubbrandsSelected = isSubbrandsRequired
    ? !!selectedSubBrands.length
    : !selectedSubBrands.length;

  const isFileUploadDisabled =
    disabled || isUploading || !selectedBrands.length || !isSubbrandsSelected;

  return (
    <>
      <div className="image-uploader" data-testid="image-uploader">
        <div className="file-upload">
          <div className="file-upload-drag-drop file-container gap-2">
            <div className="font-xs">{shortDescription}</div>
            <Button
              data-testid="btn-gallery"
              size="small"
              variant="secondary"
              onClick={openImageGallery}
              iconTrailing="image"
              disabled={disabled || isUploading}
            >
              Select image from gallery
            </Button>
          </div>
        </div>
      </div>

      {isGalleryOpen && (
        <div className="image-picker-wrapper" data-testid="image-picker">
          <h3 className="image-picker-wrapper__title">Image Gallery</h3>

          {viewState === VIEW_STATE.UPLOAD && (
            <div
              className="image-uploader-file-wrapper"
              data-testid="image-uploader-file-wrapper"
            >
              <BrandsSelector
                selectedBrands={selectedBrands}
                handleBrandSelection={handleBrandSelection}
                selectedSubBrands={selectedSubBrands}
                handleSubBrandSelection={handleSubBrandSelection}
                setIsSubbrandsRequired={setIsSubbrandsRequired}
                isUploading={isUploading}
              />

              <File
                data-testid="image-uploader-file"
                accept={accept}
                description={description}
                buttonLabel="Upload image"
                type="image"
                files={selectedImage ? [selectedImage.name] : []}
                variant={isUploading ? "uploading" : "default"}
                progressPerc={progressValue}
                onUpdate={selectedFileUploadDidChange}
                onFileError={selectedFileUploadDidChange}
                disabled={isFileUploadDisabled}
                error={uploadError}
                feedbackType={uploadError ? "error" : undefined}
                className={className}
                multiple={multiple}
                helperText={uploadError}
              />
            </div>
          )}

          {viewState === VIEW_STATE.GALLERY && (
            <div className="image-picker">
              {galleryError && (
                <div
                  className="image-picker__error"
                  data-testid="image-picker-error"
                >
                  {galleryError}
                </div>
              )}

              {isGalleryLoading ? (
                <div
                  className="image-picker__loader"
                  data-testid="image-picker-loading"
                >
                  <LoadingSpinner size={{ width: "2rem", height: "2rem" }} />
                </div>
              ) : (
                <>
                  {isNoResults && (
                    <div
                      className="image-picker__empty"
                      data-testid="image-picker-empty"
                    >
                      No images found
                    </div>
                  )}

                  {!isNoResults && (
                    <div
                      className="image-picker__items"
                      data-testid="image-picker-success"
                    >
                      {galleryImages?.media?.map((asset) => {
                        const isImageSelected = selectedImage?.id === asset.id;

                        return (
                          <div
                            key={asset.id}
                            className={`image-picker__thumbnail ${isImageSelected ? "selected" : ""}`}
                            data-testid="image-picker-thumbnail"
                            onClick={() => onThumbnailDidClick(asset)}
                            role="button"
                            tabIndex="0"
                          >
                            <img
                              className="image-picker__image"
                              src={asset.thumbnails.thul}
                              alt={asset.property_Product_Description ?? asset.name}
                            />
                            <small
                              className="image-picker__label"
                              title={
                                asset.property_Product_Description ?? asset.name
                              }
                            >
                              {asset.property_Product_Description ?? asset.name}
                            </small>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="image-picker__actions">
            {viewState === VIEW_STATE.GALLERY ? (
              <>
                <Button
                  data-testid="btn-upload-new-image"
                  className="btn-upload-new-image"
                  variant="secondary"
                  size="medium"
                  onClick={onGotoUploadNewImage}
                >
                  Upload new image
                </Button>

                <div className="flex justify-content-end">
                  <Link
                    data-testid="btn-close-gallery"
                    className="btn-close-gallery"
                    size="medium"
                    onClick={onCancelGalleryImageSelection}
                    disabled={isUploading}
                  >
                    Cancel
                  </Link>
                  <Button
                    data-testid="btn-confirm-selection"
                    className="btn-confirm-selection"
                    size="medium"
                    onClick={() => onConfirmImageSelection()}
                    disabled={!galleryImages?.media?.length || !selectedImage}
                  >
                    Confirm selected image
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex justify-content-start">
                <Button
                  data-testid="btn-upload-new-image"
                  className="btn-upload-new-image"
                  variant="secondary"
                  size="medium"
                  onClick={onGotoGallery}
                  disabled={isUploading}
                >
                  Go back to Gallery
                </Button>
                <Link
                  data-testid="btn-close-gallery"
                  className="btn-close-gallery"
                  size="medium"
                  onClick={onCancelGalleryImageSelection}
                  disabled={isUploading}
                >
                  Cancel
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ImagePicker.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};
