import { gql } from "@apollo/client";

export const listKeywordGroups = gql`
  query ListKeywordGroups($type: String) {
    listKeywordGroups(type: $type) {
      id
      name
      type
      slug
    }
  }
`;

export const matchKeywordGroupsList = gql`
  query ListKeywordGroups($type: String) {
    listKeywordGroups(type: $type) {
      id
      name
      type
      slug
      keywords
    }
  }
`;

export const LIST_BRANDS_AND_SUBBRANDS = gql`
  query ListBrandsAndSubbrands {
    brands: listKeywordGroups(type: "BRAND") {
      id
      name
      metadata
    }
    subBrands: listKeywordGroups(type: "SUB_BRAND") {
      id
      name
      metadata
    }
  }
`;
