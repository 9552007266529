import { withJsonFormsControlProps } from "@jsonforms/react";
import CustomRichTextEditor from "./CustomRichTextEditor";

const RichTextEditorControlComponent = (props) => {
  const { handleChange, label, data, path, errors, schema, uischema, required } =
    props;

  const updateValue = (newVal) => {
    const updatedValue = newVal === "<br>" ? "" : newVal;
    handleChange(path, updatedValue);
  };

  return (
    <CustomRichTextEditor
      value={data}
      label={label}
      updateValue={updateValue}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export const RichTextEditorControl = withJsonFormsControlProps(
  RichTextEditorControlComponent
);
