export const CODE_GROUP_LIFE_CYCLE_STATUS = {
  DRAFT: "DRAFT",
  QUEUED: "QUEUED",
  IN_PROGRESS: "IN_PROGRESS",
  GENERATED: "GENERATED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  EXPIRED: "EXPIRED",
};

export const CODE_GROUP_ADMINISTRATION_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const PRODUCER_COMPANIES = {
  HAS: "HAS",
  BERICAP: "BERICAP",
  PROPAK: "PROPAK",
  BALL: "BALL",
  CROWN: "CROWN",
};

export const PERFORMANCES_ACTIVITY_TYPES = {
  RECEPT_SCAN: "RECEIPT SCAN",
  PACKAGE_SCAN: "PACKAGE SCAN",
  CODE_SCAN: "CODE SCAN",
  SURVEY: "SURVEY",
};

export const PERFORMANCES_LIFE_CYCLE_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
