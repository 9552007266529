import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { listPartners } from "../graphql/queries/listPartners";

export const useListPartnersQuery = (
  filter = {},
  currentPage = 0,
  pageSize = 20
) => {
  const { data, loading, refetch } = useQuery(listPartners, {
    variables: {
      filter: typeof filter === "string" ? {} : filter,
      page: currentPage,
      size: pageSize,
    },
    fetchPolicy: "cache-first",
  });

  const partnerData = data?.listPartners?.items || [];
  const page = data?.listPartners?.page;
  const size = data?.listPartners?.size;
  const total = data?.listPartners?.total || 0;

  const totalPages = useMemo(() => {
    return Math.ceil(total / size);
  }, [total, size]);

  return {
    data: partnerData,
    loading,
    refetch,
    page: page,
    size: size,
    totalPages: totalPages,
    total: total,
  };
};
