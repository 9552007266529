import moment from "moment-timezone";
import "./app-configurations.scss";

const keyToTitleMapper = {
  showCameraScanButtoniOS:
    "Show the 'Scan Code with Camera' button on Manual reading screen",
  showYearSummary: "Year Summary",
  showChatbot: "Show chatbot on My Profile screen",
  applicationIcon: "Application icon",
  contentTitleArea: "Content Page Title",
  defaultCodeReaderAndroid: "Default code reader screen",
  splashScreenAnimation: "Splash Screen Animation",
  defaultCodeReaderiOS: "Default code reader screen",
  chatbotTutorialExpiration: "Chatbot Tutorial expiry date",
  showCameraScanButtonAndroid:
    "Show the 'Scan Code with Camera' button on Manual reading screen",
};

const valueMapper = {
  manually_type_in: "Manual reading screen",
  camera_scan: "Camera scan",
  no: "No",
  yes: "Yes",
  snow: "Snow",
  default: "Default",
};

export default function AppConfigurationsCardList(props) {
  const { appConfigurations } = props;
  return (
    <div>
      <div className="app-configurations-cards-container">
        <h3>Code Scan Settings</h3>
        <h4>iOS Device</h4>
        <div className="app-config-card">
          <h5>
            {keyToTitleMapper["defaultCodeReaderiOS"]}:
            <span>{valueMapper[appConfigurations["defaultCodeReaderiOS"]]}</span>
          </h5>
          <h5>
            {keyToTitleMapper["showCameraScanButtoniOS"]}:
            <span>{valueMapper[appConfigurations["showCameraScanButtoniOS"]]}</span>
          </h5>
        </div>
        <h4>Android Device</h4>
        <div className="app-config-card">
          <h5>
            {keyToTitleMapper["defaultCodeReaderAndroid"]}:
            <span>{valueMapper[appConfigurations["defaultCodeReaderAndroid"]]}</span>
          </h5>
          <h5>
            {keyToTitleMapper["showCameraScanButtonAndroid"]}:
            <span>
              {valueMapper[appConfigurations["showCameraScanButtonAndroid"]]}
            </span>
          </h5>
        </div>
        <h3>Feature Management Settings</h3>
        <div className="app-config-card">
          <h6>
            {keyToTitleMapper["showChatbot"]}:
            <span>{valueMapper[appConfigurations["showChatbot"]]}</span>
          </h6>
          <h6>
            {keyToTitleMapper["chatbotTutorialExpiration"]}:
            <span>
              {moment
                .utc(appConfigurations["chatbotTutorialExpiration"])
                .tz("Europe/Istanbul")
                .format("MM/DD/YYYY hh:mm A")}
            </span>
          </h6>
          <h6>
            {keyToTitleMapper["showYearSummary"]}:
            <span>{valueMapper[appConfigurations["showYearSummary"]]}</span>
          </h6>
        </div>
        <div className="app-config-card">
          <h5>
            {keyToTitleMapper["contentTitleArea"]}:
            <span>{appConfigurations["contentTitleArea"]}</span>
          </h5>
        </div>
        <h3>Splash Screen and App Icon Settings</h3>
        <div className="app-config-card">
          <h5>
            {keyToTitleMapper["applicationIcon"]}:
            <span>{valueMapper[appConfigurations["applicationIcon"]]}</span>
          </h5>
          <h5>
            {keyToTitleMapper["splashScreenAnimation"]}:
            <span>{valueMapper[appConfigurations["splashScreenAnimation"]]}</span>
          </h5>
        </div>
      </div>
    </div>
  );
}
