import { withJsonFormsControlProps } from "@jsonforms/react";
import { useEffect, useState } from "react";
import TimeRangeInputField from "./TimeRangeInputField";

const TimeRangeComponent = (props) => {
  const { handleChange, data, path, errors, required, enabled, visible } = props;

  const [time, setTime] = useState({
    day: "",
    hour: "",
    minute: "",
  });

  // Convert the time fields to seconds and update the form
  const updateTime = (updatedTime) => {
    const totalSeconds =
      (updatedTime.day ? parseInt(updatedTime.day) * 86400 : 0) +
      (updatedTime.hour ? parseInt(updatedTime.hour) * 3600 : 0) +
      (updatedTime.minute ? parseInt(updatedTime.minute) * 60 : 0);

    handleChange(path, totalSeconds || ""); // Update the form with total seconds or keep it empty
  };

  useEffect(() => {
    if (data) {
      const days = Math.floor(data / 86400);
      const hours = Math.floor((data % 86400) / 3600);
      const minutes = Math.floor((data % 3600) / 60);
      setTime({ day: days, hour: hours, minute: minutes });
    }
  }, [data]);

  return (
    <TimeRangeInputField
      time={time}
      updateTime={(updatedTime) => {
        setTime(updatedTime);
        updateTime(updatedTime);
      }}
      disabled={!enabled}
      errors={errors}
      required={required}
      visible={visible}
    />
  );
};

export const TimeRangeControl = withJsonFormsControlProps(TimeRangeComponent);
