import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { useMemo } from "react";
import { LayoutTester } from "../jsonFormsWrapper/testers";
import { ConditionalSectionLayoutControl } from "../layouts/conditionalSectionLayout/ConditionalSectionLayout";
import { CustomGroupLayoutControl } from "../layouts/groupLayout/CustomGroupLayout";
import { HorizontalLayoutControl } from "../layouts/horizontalLayout/horizontalLayout";
import { InputRowControl } from "../layouts/inputRow/inputRow";
import { LayerLayoutControl } from "../layouts/layerLayout/LayerLayout";
import { SimpleGroupControl } from "../layouts/simpleGroup/simpleGroup";
import { SimpleGroupPreviewCardsControl } from "../layouts/simpleGroupPreviewCards/simpleGroupPreviewCards";
import { VerticalLayoutControl } from "../layouts/verticalLayout/verticalLayout";
import { customerRenderesHelper, getScopesofAllElements } from "./helper";

export function SummaryFormWrapper({
  jsonschema,
  uischema,
  data = {},
  config = {},
}) {
  const scopeAndCompsList = useMemo(
    () => getScopesofAllElements(uischema),
    [uischema]
  );

  const customRenderers = useMemo(
    () => customerRenderesHelper(scopeAndCompsList, data),
    [scopeAndCompsList, data]
  );

  const renderers = useMemo(
    () => [
      ...materialRenderers,
      ...customRenderers,
      {
        tester: LayoutTester("VerticalLayout"),
        renderer: VerticalLayoutControl,
      },
      {
        tester: LayoutTester("HorizontalLayout"),
        renderer: HorizontalLayoutControl,
      },
      {
        tester: LayoutTester("SimpleGroup"),
        renderer: SimpleGroupControl,
      },
      {
        tester: LayoutTester("simpleGroupPreviewCards"),
        renderer: SimpleGroupPreviewCardsControl,
      },
      {
        tester: LayoutTester("CustomGroup"),
        renderer: CustomGroupLayoutControl,
      },
      {
        tester: LayoutTester("InputRow"),
        renderer: InputRowControl,
      },
      {
        tester: LayoutTester("ConditionalSectionLayout"),
        renderer: ConditionalSectionLayoutControl,
      },
      {
        tester: LayoutTester("LayerLayout"),
        renderer: LayerLayoutControl,
      },
    ],
    [customRenderers]
  );

  return (
    <JsonForms
      schema={jsonschema}
      uischema={uischema}
      data={data}
      renderers={renderers}
      cells={materialCells}
      config={{ ...config, readonly: true }}
    />
  );
}
