import { withJsonFormsLayoutProps } from "@jsonforms/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
} from "@mui/material";
import { CustomLayoutRendererControl } from "../CustomLayoutRenderer/CustomLayoutRenderer";

const CustomGroupLayout = (props) => {
  const { uischema, schema, path, visible, renderers } = props;
  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "column",
    visible: visible,
    uischema: uischema,
    renderers: renderers,
    labelStyles: uischema.labelStyles,
  };
  return (
    <Hidden xsUp={!visible}>
      <Accordion
        className="gap rounded-halfrem"
        sx={{ boxShadow: "none", border: "1px solid lightgray" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ border: "1px solid lightgray" }}
        >
          <h4 className="font-md">{uischema.label}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <CustomLayoutRendererControl {...layoutProps} />
        </AccordionDetails>
      </Accordion>
    </Hidden>
  );
};

export const CustomGroupLayoutControl = withJsonFormsLayoutProps(CustomGroupLayout);
