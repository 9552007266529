import { useQuery } from "@apollo/client";
import { Button, Card, Modal } from "pepsico-ds";
import { useMemo, useState } from "react";
import { getDateWithFormat } from "../../../utils/commons";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import getListPartners from "../graphql/queries/listPartners";
import "./partnerCodeGroupSummary.scss";

function PartnerCodeGroupSummaryModal({
  PartnerCodeGroupSummaryModalOpen,
  toggleSummaryModal,
  handleActionButtons,
  partnerCodeGroupData,
  currentProgramTimezone,
}) {
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  // Fetch active partners
  const { data: listOfPartnersData } = useQuery(getListPartners, {
    variables: { filter: {} },
  });

  // Memoize the list of partners to avoid unnecessary re-renders
  const partners = useMemo(
    () => listOfPartnersData?.listPartners?.items || [],
    [listOfPartnersData]
  );

  const getPartnerName = (partnerId) => {
    const partner = partners.find((partner) => partner.id === partnerId);
    return partner?.name || "-";
  };

  return (
    <Modal
      className={`partnerCodeGroup-modal ${["published", "paused", "inactive", "active"].includes(partnerCodeGroupData?.status?.toLowerCase()) ? "" : "hide-btn"}`}
      showModal={PartnerCodeGroupSummaryModalOpen}
      onCloseModal={toggleSummaryModal}
      title={"Partner Code Group Details"}
      primaryButtonProps={{
        size: "medium",
        text: "Edit",
        variant: "primary",
        disabled: partnerCodeGroupData?.processingStatus === "IMPORTING",
        onClick: () => {
          if (
            ["published", "paused", "inactive", "active"].includes(
              partnerCodeGroupData?.status?.toLowerCase()
            )
          ) {
            toggleSummaryModal();
            handleActionButtons("edit", partnerCodeGroupData);
          }
        },
      }}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
    >
      <div className="summary-mod">
        <h3>Partner Code Group Details</h3>
        <Card className="basic-setup">
          <div className="summary-content-wrapper">
            <div className="summary-content">
              <p className="title">Partner Code Group Name</p>
              <p className="content">{partnerCodeGroupData?.name || "-"}</p>
            </div>
            <div className="summary-content">
              <p className="title">Code Group Description</p>
              <p className="content">{partnerCodeGroupData?.description || "-"}</p>
            </div>
            <div className="summary-content">
              <p className="title">Code Group Active Dates</p>
              <p className="content">
                {partnerCodeGroupData?.startDate
                  ? getDateWithFormat(
                      partnerCodeGroupData?.startDate,
                      currentProgramTimezone
                    )
                  : "N/A"}{" "}
                -{" "}
                {partnerCodeGroupData?.endDate
                  ? getDateWithFormat(
                      partnerCodeGroupData?.endDate,
                      currentProgramTimezone
                    )
                  : "N/A"}
              </p>
            </div>
          </div>
        </Card>
        <div className="download-wrapper">
          <h3>Code Group File</h3>
        </div>
        <Card className="basic-setup">
          <div className="summary-content-wrapper">
            <div className="summary-content">
              <p className="title">File Name</p>
              <p className="content">
                {partnerCodeGroupData?.rewardItemFileData?.length > 0
                  ? partnerCodeGroupData?.rewardItemFileData
                      ?.map((x) => x?.name?.split("/")?.pop())
                      .join(", ")
                  : "-"}
              </p>
            </div>
            <div className="summary-content">
              <p className="title">Import Date</p>
              <p className="content">
                {partnerCodeGroupData?.processingStatus === "IMPORTED"
                  ? getDateWithFormat(
                      partnerCodeGroupData?.importedDate,
                      currentProgramTimezone
                    )
                  : "Processing"}
              </p>
            </div>
            <div className="summary-content">
              <p className="title">File Status</p>
              <p className="content">
                {(partnerCodeGroupData?.processingStatus === "IMPORTED" && (
                  <Button
                    size="small"
                    variant="primary"
                    iconTrailing="done"
                    className="code-imported"
                  >
                    Code Imported
                  </Button>
                )) ||
                  "Not Imported"}
              </p>
            </div>
          </div>
        </Card>
        <h3>Code Specifications</h3>
        <Card className="basic-setup">
          <div className="summary-content-wrapper">
            <div className="summary-content">
              <p className="title">Partner</p>
              <p className="content">
                {getPartnerName(partnerCodeGroupData?.partnerId) || "-"}
              </p>
            </div>
            <div className="summary-content">
              <p className="title">Import Code Count</p>
              <p className="content">
                {partnerCodeGroupData?.processingStatus === "IMPORTING"
                  ? "Processing"
                  : partnerCodeGroupData?.codeCount}
              </p>
            </div>
            <div className="summary-content">
              <p className="title">Code Warning Limit Percentage</p>
              <p className="content">
                {partnerCodeGroupData?.codeWarningLimitPercentage || "-"}
              </p>
            </div>
          </div>
        </Card>
        <ToastNotification
          {...toast}
          handleClose={() => setToast({ ...toast, open: false })}
        />
      </div>
    </Modal>
  );
}

export default PartnerCodeGroupSummaryModal;
