import EmailIcon from "@mui/icons-material/EmailOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import PropTypes from "prop-types";
import { Quill } from "react-quill";

const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

export function QuillToolbar({ id }) {
  return (
    <div id={id}>
      <section className="toolbar-text-editor">
        <span className="ql-formats toolbar-text-editor">
          <button type="button" className="ql-link" />
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-list">
            <FormatListBulletedIcon sx={{ fontSize: "17px" }} />
          </button>
          <button type="button" className="ql-tel" value="tel">
            <PhoneIcon sx={{ fontSize: "15px" }} />
          </button>
          <button type="button" className="ql-mail" value="mail">
            <EmailIcon sx={{ fontSize: "17px" }} />
          </button>
        </span>
      </section>
    </div>
  );
}

QuillToolbar.propTypes = {
  id: PropTypes.string.isRequired,
};
