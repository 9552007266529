import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../../context/RewardContext";
import { listRewardUnits } from "../../../../graphql/queries/listRewardUnits";

const RewardUnitSelectComponent = ({
  handleChange,
  label,
  data,
  path,
  uischema,
  required,
}) => {
  const { rewardState, addOptionLoading, removeOptionLoading, setToast } =
    useContext(RewardContext);
  const { refetch } = useQuery(listRewardUnits, {
    skip: true,
  });
  const [unitsList, setUnitsList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([{ displayText: "" }]);

  useEffect(() => {
    if (rewardState?.partnerId) {
      addOptionLoading("units");
      refetch({ filter: { partnerId: rewardState.partnerId }, size: 1000 })
        .then(({ data: rewardUnitsData, loading }) => {
          !loading && removeOptionLoading("units");
          if (rewardUnitsData?.listRewardUnits) {
            const unitsListOptions = rewardUnitsData.listRewardUnits.items?.map(
              (unit) => ({
                id: unit.id,
                displayText: unit.name,
              })
            );
            setUnitsList(unitsListOptions);
          }
        })
        .catch(() => {
          removeOptionLoading("units");
          setUnitsList([]);
          setToast({
            open: true,
            type: "error",
            title: "Something went wrong while loading units.",
            description: "Please try again later.",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardState?.partnerId, refetch]);

  useEffect(() => {
    if (unitsList.length) {
      const selectedCategoryOption = unitsList.find((type) => type.id === data);
      setSelectedUnit([selectedCategoryOption ?? { displayText: "" }]);
    }
  }, [data, unitsList]);

  const updateValue = (val) => handleChange(path, val?.[0]?.id || "");
  const diabledControl = uischema?.readonly ? "disabled-control" : "";

  return (
    <Dropdown
      isRequired={required}
      childList={unitsList}
      className={`zIndexToDropDown ${diabledControl}`}
      label={label}
      selection="single"
      selectedValue={selectedUnit}
      setSelectedValue={updateValue}
      size={uischema?.size || "medium"}
      placeholder={uischema?.placeholder || ""}
      disabled={uischema?.readonly || uischema?.disabled}
    />
  );
};

export const RewardUnitSelectControl = withJsonFormsControlProps(
  RewardUnitSelectComponent
);
