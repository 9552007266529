import { withJsonFormsControlProps } from "@jsonforms/react";
import { Font } from "pepsico-ds";

function SimpleText({ data, label, id }) {
  return (
    <section key={id}>
      <Font size="xsmall">{label}</Font>
      <section>
        <Font size="body" style={{ fontWeight: "bold" }}>
          {data}
        </Font>
      </section>
    </section>
  );
}

export const SimpleTextControl = withJsonFormsControlProps(SimpleText);
