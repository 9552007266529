import { useMutation } from "@apollo/client";
import { createAjv } from "@jsonforms/core";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import moment from "moment-timezone";
import { Button, LoadingSpinner } from "pepsico-ds";
import { useState } from "react";
import saveContent from "../../graphql/mutations/saveContent";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

moment.locale("en");

/**
 * EditWinningRewardsScreenModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @param {boolean} props.selectedContent - selectedContent object
 * @param {function} props.setSelectedContent - Function to set the setSelectedContent state.
 * @param {function} props.setAlertMsg - Function to set the error state.
 * @returns {JSX.Element} The EditWinningRewardsScreenModal component.
 */
export default function EditWinningRewardsScreenModal({
  showModal,
  setShowModal,
  setAlertMsg,
  selectedContent,
  setSelectedContent,
  setListContentDefinitions,
}) {
  const [data, setData] = useState(
    selectedContent?.contentData && typeof selectedContent?.contentData === "object"
      ? selectedContent?.contentData
      : {}
  );
  const [saveContentCall, { loading }] = useMutation(saveContent);
  // useEffect(() => {
  //   if (
  //     selectedContent?.contentData &&
  //     typeof selectedContent?.contentData === "object"
  //   ) {
  //     setData(selectedContent?.contentData);
  //   }

  //   return () => setData({});
  // }, [selectedContent?.contentData]);

  const handleSave = async () => {
    const ajv = createAjv();
    const isValid = ajv.validate(
      selectedContent?.contentDefinition?.inputSchema,
      data
    );
    if (!isValid) {
      setAlertMsg({ msg: "Please fill in all required fields.", severity: "error" });
      return;
    }
    const payload = {
      id: selectedContent.id,
      title: selectedContent.title,
      slug: selectedContent.slug,
      paused: selectedContent.paused,
      segments: [""],
      description: selectedContent.description,
      contentData: data,
      contentDefinitionSlug: selectedContent.contentDefinition.slug,
      tags: [""],
      category: "",
    };
    try {
      const result = await saveContentCall({ variables: { content: payload } });
      if (result.data?.saveContent?.id) {
        setAlertMsg({
          msg: `${selectedContent?.title} saved successfully`,
          severity: "success",
        });
        setListContentDefinitions((prev) => {
          return [
            ...prev.map((content) => {
              if (content.slug === selectedContent.slug) {
                return { ...content, contentData: data };
              }
              return { ...content };
            }),
          ];
        });
        setData({});
        setSelectedContent(null);
        setShowModal(false);
      } else {
        setAlertMsg({
          msg: `Error saving ${selectedContent?.title}`,
          severity: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setAlertMsg({
        msg: `Error saving ${selectedContent?.title}`,
        severity: "error",
      });
    }
  };

  return (
    <Dialog
      className="createProduct-modal"
      open={showModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              Edit Screen for Winning {selectedContent?.title}
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={() => {
                setSelectedContent({});
                setData({});
                setShowModal(false);
              }}
              className="cursor-pointer"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        {selectedContent?.contentDefinition?.inputSchema &&
          selectedContent?.contentDefinition?.uiSchema && (
            <div className="my-5">
              <JsonFormsWrapper
                jsonschema={selectedContent?.contentDefinition?.inputSchema}
                uischema={selectedContent?.contentDefinition?.uiSchema}
                data={data}
                onChange={(val) => setData(val)}
              />
            </div>
          )}

        <div className="mt-5 display-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            className="justify-content-space-around"
            disabled={loading}
            onClick={handleSave}
          >
            Save
            {loading && (
              <LoadingSpinner
                size={{
                  height: "26px",
                  width: "26px",
                }}
                isDark
              />
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
