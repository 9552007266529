import _ from "lodash";
import { Breadcrumb, Button } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserLimitCardList from "../../../components/management/UserLimitSettings/UserLimitCardList";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { paths } from "../../../routes/paths";

export default function UserLimitSettingsPage() {
  const [userSettings, setUserSettings] = useState({});
  const { programConfigurations } = useContext(GlobalConfigContext);
  const navigate = useNavigate();
  const navigateToEditSettings = () => {
    navigate(`/${paths.system_setting}/user-login-limits/edit`);
  };

  const navigateToManagement = () => {
    navigate(`/${paths.system_setting}`);
  };

  useEffect(() => {
    const userLoginLimits = _.find(programConfigurations, {
      key: "user_login_limits",
    });
    if (userLoginLimits !== null && userLoginLimits !== undefined) {
      setUserSettings(userLoginLimits.value?.data);
    }
  }, [programConfigurations, userSettings]);

  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../../${paths.system_setting}`,
            },
            {
              label: "View User Limit Settings",
              href: `../../../${paths.system_setting}/${paths.user_limit_settings_edit}`,
            },
          ]}
          activeIndex={1}
        />
        <div className="user-limit-cards-container">
          <UserLimitCardList userSettings={userSettings} />
        </div>
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={navigateToManagement}
          />
          <Button
            size="medium"
            text="Edit"
            variant="primary"
            iconTrailing="arrow_circle_right"
            onClick={navigateToEditSettings}
          />
        </div>
      </div>
    </div>
  );
}
