import { Breadcrumb } from "pepsico-ds";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SummaryFormWrapper } from "../../../../components/jsonForms/summaryFormsWrapper/SummaryFormsWrapper";
import { SocialMediaLinksForm } from "../../../../components/management/SocialMediaLinks/SocialMediaLinksForm";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import { paths } from "../../../../routes/paths";

const jsonSchema = {
  title: "Social Media Links Configurations",
  type: "object",
  properties: {
    socialLinksColor: {
      type: "object",
      properties: {
        youtube: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        instagram: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        tiktok: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        facebook: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        x: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
      },
      default: {
        youtube: {
          url: "https://www.youtube.com/c/KazandiRio",
          position: 1,
          image:
            "https://pepsicotest.getbynder.com/m/310bbc05606ef24b/thul-Youtube-Logo-png.png",
          title: "YouTube",
          active: false,
        },
        instagram: {
          url: "https://www.instagram.com/kazandirio/",
          position: 2,
          image:
            "https://pepsicotest.getbynder.com/m/4dfd7dafabc6af47/thul-Instagram-Logo-png.png",
          title: "Instagram",
          active: false,
        },
        tiktok: {
          url: "https://www.tiktok.com/kazan.drio",
          position: 3,
          image:
            "https://pepsicotest.getbynder.com/m/3bbf6b6504eb0761/thul-TikTok-Logo-png.png",
          title: "TikTok",
          active: false,
        },
        facebook: {
          url: "https://www.facebook.com/kazandiriotr/",
          position: 4,
          image:
            "https://pepsicotest.getbynder.com/m/4dac0b6e2e8f635e/thul-Facebook-Logo-png.png",
          title: "Facebook",
          active: false,
        },
        x: {
          url: "https://www.twitter.com/kazandirio",
          position: 5,
          image:
            "https://pepsicotest.getbynder.com/m/67e539f99404f8c1/thul-Twitter-Logo-png.png",
          title: "X (Twitter)",
          active: false,
        },
      },
    },
    socialLinksGrayscale: {
      type: "object",
      properties: {
        youtube: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        instagram: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        tiktok: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        facebook: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
        x: {
          type: "object",
          properties: {
            url: {
              type: "string",
              format: "uri",
            },
            position: {
              type: "number",
            },
            image: {
              type: "string",
              format: "uri",
            },
            title: {
              type: "string",
            },
            active: {
              type: "boolean",
            },
          },
        },
      },
      default: {
        youtube: {
          url: "https://www.youtube.com/c/KazandiRio",
          position: 1,
          image:
            "https://pepsicotest.getbynder.com/m/203fe2aec764e7c9/thul-Youtube-Logo-Gray-png.png",
          title: "YouTube",
          active: false,
        },
        instagram: {
          url: "https://www.instagram.com/kazandirio/",
          position: 2,
          image:
            "https://pepsicotest.getbynder.com/m/851c13397316fc2/thul-Instagram-Logo-Gray-png.png",
          title: "Instagram",
          active: false,
        },
        tiktok: {
          url: "https://www.tiktok.com/kazan.drio",
          position: 3,
          image:
            "https://pepsicotest.getbynder.com/m/d6013218f8a68a7/thul-TikTok-Logo-Gray-png.png",
          title: "TikTok",
          active: false,
        },
        facebook: {
          url: "https://www.facebook.com/kazandiriotr/",
          position: 4,
          image:
            "https://pepsicotest.getbynder.com/m/606f0228616fb3a6/thul-Facebook-Logo-Gray-png.png",
          title: "Facebook",
          active: false,
        },
        x: {
          url: "https://www.twitter.com/kazandirio",
          position: 5,
          image:
            "https://pepsicotest.getbynder.com/m/1f49d3c78f6357cf/thul-Twitter-Logo-Gray-png.png",
          title: "X (Twitter)",
          active: false,
        },
      },
    },
    homepageIcons: {
      type: "string",
      enum: ["COLOR", "GRAYSCALE"],
    },
    profileIcons: {
      type: "string",
      enum: ["COLOR", "GRAYSCALE"],
    },
    socialMediaText: {
      type: "string",
    },
  },
  required: [
    "socialLinksColor",
    "socialLinksGrayscale",
    "homepageIcons",
    "profileIcons",
    "socialMediaText",
  ],
};

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              component: "socialMediaSelector",
              label: "Icons in color",
              type: "Control",
              scope: "#/properties/socialLinksColor/",
            },
            {
              component: "socialMediaSelector",
              label: "Icons in grayscale",
              type: "Control",
              scope: "#/properties/socialLinksGrayscale/",
            },
          ],
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Label",
          text: "Icon color options for pages",
          component: "Label",
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  options: {
                    labelMapping: {
                      COLOR: "Color",
                      GRAYSCALE: "Grayscale",
                    },
                  },
                  component: "radioInput",
                  label: "Homepage icons",
                  type: "Control",
                  scope: "#/properties/homepageIcons",
                },
                {
                  options: {
                    labelMapping: {
                      COLOR: "Color",
                      GRAYSCALE: "Grayscale",
                    },
                  },
                  component: "radioInput",
                  label: "Profile icons",
                  type: "Control",
                  scope: "#/properties/profileIcons",
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [],
            },
          ],
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Label",
          text: "Social media text configuration",
          component: "Label",
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/socialMediaText",
              placeholder: "Type the social media text here...",
              size: "small",
              label: "Homepage social media text",
              info: "Character limit: 60",
              minLength: 3,
              maxLength: 60,
            },
            {
              type: "VerticalLayout",
              elements: [],
            },
          ],
        },
      ],
    },
  ],
};

export function SocialMediaLinksBase({ isEdit = false }) {
  const { programConfigurations } = useContext(GlobalConfigContext);
  const syncFlag = useRef(false);
  const socialMediaLinksConfig = useMemo(() => {
    if (programConfigurations?.length === 0) return {};

    const socialMedia = programConfigurations.find(
      (item) => item.key === "social_media_links"
    );

    return socialMedia?.value?.data || {};
  }, [programConfigurations]);

  const [socialMediaData, setSocialMediaData] = useState(
    () => socialMediaLinksConfig
  );

  useEffect(() => {
    const dataLoaded =
      socialMediaLinksConfig?.profileIcons &&
      socialMediaLinksConfig?.homepageIcons &&
      socialMediaLinksConfig?.socialMediaText;
    if (syncFlag.current === false && dataLoaded) {
      setSocialMediaData(socialMediaLinksConfig);
      syncFlag.current = true;
    }
  }, [socialMediaLinksConfig]);

  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `/${paths.system_setting}`,
            },
            {
              label: "Social media",
              href: `../../${paths.system_setting}/${paths.social_media_links}`,
            },
          ]}
          activeIndex={1}
        />
        <div className="heading-wrap">
          <h2>Social media link order & configuration</h2>
        </div>
        <div className="app-configurations-form">
          {isEdit ? (
            <SocialMediaLinksForm
              socialMediaData={socialMediaData}
              setSocialMediaData={setSocialMediaData}
              stateChanged={socialMediaLinksConfig === socialMediaData}
              uiSchema={uiSchema}
              jsonSchema={jsonSchema}
            />
          ) : (
            <SummaryFormWrapper
              data={socialMediaData}
              uischema={uiSchema}
              jsonschema={jsonSchema}
            />
          )}
        </div>
      </div>
    </div>
  );
}
