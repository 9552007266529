import { useQuery } from "@apollo/client";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { removeEmptyValues } from "../../../utils/commons";

const ListingComponentContext = createContext();

const ListingComponentProvider = ({
  children,
  query,
  fetchPolicy = "cache-and-network",
  queryKey,
  initialFilterData = {},
  noPagination,
}) => {
  // State for filters
  const [filterData, setFilterData] = useState(initialFilterData);
  const [debouncedFilterData, setDebouncedFilterData] = useState({});
  const [allFiltersData, setAllFiltersData] = useState({});

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsCount: noPagination ? null : 20,
  });

  // References and view-related states
  const allRecords = useRef([]);
  const [viewableRecords, setViewableRecords] = useState([]);
  const [viewType, setViewType] = useState("grid");
  const [viewAllFilters, setViewAllFilters] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);

  // Fetch data using Apollo Client's useQuery
  const { data, loading, error, refetch } = useQuery(query, {
    variables: {
      filter: { ...filterData, ...debouncedFilterData },
      page: noPagination ? undefined : pagination.currentPage - 1,
    },
    fetchPolicy,
  });

  const filteredRecords = useMemo(() => data?.[queryKey] || [], [data, queryKey]);

  // Store all records when data is first loaded
  useEffect(() => {
    if (!allRecords.current.length && data?.[queryKey]) {
      allRecords.current = data[queryKey];
    }
  }, [data, queryKey]);

  // Reset pagination to first page
  const resetPagination = useCallback(
    () => setPagination((state) => ({ ...state, currentPage: 1 })),
    []
  );

  // Check if the object contains an 'items' array
  const hasItems = (obj) => obj.items && Array.isArray(obj.items);

  // Calculate total records count
  const getTotalRecords = useCallback((filteredRecords, queryTotalRecords) => {
    return (
      queryTotalRecords ||
      (hasItems(filteredRecords)
        ? filteredRecords.items.length
        : filteredRecords.length || 0)
    );
  }, []);

  // Process and set viewable records based on pagination
  const handleData = useCallback(
    (records) => {
      if (noPagination) {
        setViewableRecords(records);
      } else {
        const queryTotalRecords = records.total;
        const start = queryTotalRecords
          ? 0
          : (pagination.currentPage - 1) * pagination.rowsCount;
        const end = start + pagination.rowsCount;
        const paginatedRecords = hasItems(records)
          ? records.items.slice(start, end)
          : records.slice(start, end);

        setViewableRecords(paginatedRecords);
        setTotalPageCount(
          Math.ceil(
            getTotalRecords(records, queryTotalRecords) / pagination.rowsCount
          )
        );
      }
    },
    [noPagination, pagination, getTotalRecords]
  );

  // Update viewable records when data or error state changes
  useEffect(() => {
    if (data?.[queryKey]) {
      handleData(data[queryKey]);
    } else if (error) {
      setViewableRecords([]);
    }
  }, [data, error, noPagination, pagination, queryKey, handleData]);

  // Debounced refetch for filtering
  const delayedRefetch = useMemo(
    () =>
      debounce((value) => {
        setDebouncedFilterData(removeEmptyValues({ ...filterData, ...value }));
        resetPagination();
      }, 1500),
    [filterData, resetPagination]
  );

  // Cleanup debounce on unmount
  useEffect(() => () => delayedRefetch.cancel(), [delayedRefetch]);

  const handleDebouncedFilterChange = useCallback(
    (data) => delayedRefetch(data),
    [delayedRefetch]
  );

  const handleAllFiltersChange = useCallback((data) => {
    setAllFiltersData({ ...data });
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilterData(initialFilterData);
    setDebouncedFilterData({});
    setAllFiltersData({});
    resetPagination();
  }, [initialFilterData, resetPagination]);

  const handleApplyFilters = useCallback(
    () => delayedRefetch(allFiltersData),
    [delayedRefetch, allFiltersData]
  );

  const contextValue = useMemo(
    () => ({
      filterData,
      debouncedFilterData,
      setDebouncedFilterData,
      handleDebouncedFilterChange,
      pagination,
      viewType,
      totalPageCount,
      setTotalPageCount,
      handleClearFilters,
      handleApplyFilters,
      handleAllFiltersChange,
      allFiltersData,
      viewAllFilters,
      setViewAllFilters,
      setFilterData,
      setViewType,
      setPagination,
      resetPagination,
      refetch,
      data,
      allRecords: allRecords.current,
      filteredRecords,
      viewableRecords,
      loading,
      error,
    }),
    [
      filterData,
      debouncedFilterData,
      pagination,
      viewType,
      totalPageCount,
      allFiltersData,
      viewAllFilters,
      data,
      filteredRecords,
      viewableRecords,
      loading,
      error,
      handleDebouncedFilterChange,
      handleAllFiltersChange,
      handleClearFilters,
      handleApplyFilters,
      refetch,
      resetPagination,
    ]
  );

  return (
    <ListingComponentContext.Provider value={contextValue}>
      {children}
    </ListingComponentContext.Provider>
  );
};

ListingComponentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.object.isRequired,
  fetchPolicy: PropTypes.string,
  queryKey: PropTypes.string.isRequired,
  initialFilterData: PropTypes.object,
  noPagination: PropTypes.bool,
};

export { ListingComponentContext, ListingComponentProvider };
