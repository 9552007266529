import { Menu, MenuItem } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment-timezone";
import { Icon, Logo } from "pepsico-ds";
import { useContext, useState } from "react";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import ModalConfirm from "./modalConfirm/ModalConfirm";

export default function Header({ programConfig }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { oktaAuth } = useOktaAuth();
  const { globalState } = useContext(GlobalConfigContext);
  const [programId, setProgramId] = useState(null);
  const [showChangeProgram, setShowChangeProgram] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const logout = async () =>
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/pepsico/signin`,
    });

  moment.locale("en");

  const handleProgram = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const onSwitchProgram = (programId) => {
    setProgramId(programId);
    setShowChangeProgram(true);
  };
  const onConfirmProgramChange = () => {
    setShowChangeProgram(false);
    localStorage.setItem("programId", programId);
    window.location.href = "/";
  };
  return (
    <header className="header">
      <nav className="navbar $bg-black $color-text-white $spacing-p04">
        <Logo
          colorStyle="color"
          isDark
          logoType="pepsicoFullLogo"
          className="pepsicoFullLogo"
        />

        <div className="display-flex align-items-center">
          <img src="/assets/images/projectHeaderLogo.svg" alt="" className="mr-1" />
          <h1 className="$font-body-bold">{programConfig?.name}</h1>
        </div>

        <div className="display-flex">
          {globalState.programs?.length > 1 && (
            <div>
              <div
                className="display-flex align-items-center cursor-pointer"
                onClick={handleProgram}
                role="button"
                tabIndex="0"
              >
                <Icon icon="feed" />
                <Icon icon="arrow_drop_down" />
              </div>
              <Menu
                id="menu-programs"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleClose}
                className="absolute"
              >
                {globalState.programs?.map((program) => (
                  <MenuItem
                    key={program.id}
                    selected={program?.id === programConfig?.id}
                    onClick={() => {
                      onSwitchProgram(program?.id);
                      handleClose();
                    }}
                  >
                    {program.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
          <Icon
            alt="notifications"
            className="$color-icon-white mr-20 ml-20"
            icon="notifications"
            size="medium"
            variant="filled"
          />
          <div
            className="display-flex align-items-center cursor-pointer nav-cursor-pointer"
            onClick={handleMenu}
            role="button"
            tabIndex="2"
          >
            <Icon
              alt="account"
              className="$color-icon-white"
              icon="account_circle"
              size="medium"
              variant="filled"
            />
            <Icon
              alt="arrow-drop-down"
              className="$color-icon-white"
              icon="arrow_drop_down"
              size="medium"
              variant="filled"
            />
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className="$color-link-hover $spacing-m02"
              onClick={() => {
                logout();
                handleClose();
              }}
              sx={{ minWidth: "80px" }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </nav>
      <ModalConfirm
        key={"modal-reward-delete"}
        className="modal-confirm"
        showModal={showChangeProgram}
        onCloseModal={() => {
          setShowChangeProgram(false);
        }}
        title={"Program change confirmation!"}
        description={"All changes will be lost. Are you sure you want to proceed?"}
        primaryButtonProps={{
          text: "Yes, Proceed",
          onClick: () => {
            onConfirmProgramChange();
          },
        }}
        secondaryButtonProps={{
          text: "Cancel",
          onClick: () => {
            setShowChangeProgram(false);
          },
        }}
      />
    </header>
  );
}
