import { InputItem, Tag } from "pepsico-ds";
import { useState } from "react";
import { searchHandler } from "../../../utils/functions";
import SegmentAttributesAndValues from "../../segmentation/SegmentAttributesAndValues";

const SegmentSelect = ({
  segmentsData,
  selected,
  onChange,
  selection = "multiple",
}) => {
  const [segments, setSegments] = useState(segmentsData);
  const [selectedSegments, setSelectedSegments] = useState(selected);

  const handleSearch = (e) => {
    const filteredList = searchHandler(
      e.target.value,
      ["name", "rules"],
      segmentsData || []
    );
    setSegments(filteredList);
  };

  const handleSelectSegment = (id, name) => {
    const additionSegments =
      selection === "multiple"
        ? [...selectedSegments, { id, name }]
        : [{ id, name }];
    const newSelectedSegments = selectedSegments.some((segment) => segment.id === id)
      ? selectedSegments.filter((segment) => segment.id !== id)
      : additionSegments;
    setSelectedSegments(newSelectedSegments);
    onChange(newSelectedSegments);
  };

  const handleRemove = (id) => {
    const newSelectedSegments = selectedSegments.filter(
      (segment) => segment.id !== id
    );
    setSelectedSegments(newSelectedSegments);
    onChange(newSelectedSegments);
  };

  const handleRemoveAll = () => {
    setSelectedSegments([]);
    onChange([]);
  };

  return (
    <div className="segment-modal-content">
      <div className="segment-filters">
        <div className="segment-filters-header">
          <span className="font-md font-bold">Find Segments</span>
          <InputItem
            placeholder="Search by keyword"
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleSearch}
          />
        </div>
      </div>
      {segments.length > 0 ? (
        <div className="grid-container">
          {segments.map((segment) => {
            const { id, name, population, rules } = segment;
            const isSelected = selectedSegments.some((segment) => segment.id === id);
            return (
              <div
                className={`segment-card ${isSelected ? "selected" : ""}`}
                key={id}
                onClick={() => handleSelectSegment(id, name)}
                role="button"
                tabIndex="0"
              >
                <span className="font-sm font-bold">{name}</span>
                <div className="segment-info-row">
                  <div className="display-flex flex-direction-column">
                    <span className="font-xxs font-bold">Population</span>
                    <span className="font-xxs">
                      {population.toLocaleString("en")} users
                    </span>
                  </div>
                </div>
                <div className="display-flex flex-direction-column">
                  <span className="font-xxs font-bold">Attributes and Values</span>
                  <span className="font-xxs">
                    <SegmentAttributesAndValues rules={rules} />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="no-segments">
          <span className="font-md font-bold">No Records Found!</span>
        </div>
      )}
      <div className="selected-segments">
        <div className="selected-segments-header">
          <span className="font-xs font-bold">
            {selectedSegments.length} Selected
          </span>
          <span
            className="remove-selected font-xs"
            onClick={handleRemoveAll}
            role="button"
            tabIndex="0"
          >
            Remove All
          </span>
        </div>
        <div className="selected-segments-list">
          {selectedSegments.map((segment) => (
            <Tag
              color={segment.status === "DISABLED" ? "Red-150" : "Green-150"}
              isRemovable
              text={segment.name}
              type="filled"
              onRemove={() => handleRemove(segment.id)}
              key={segment.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SegmentSelect;
