import { Button } from "pepsico-ds";
import { ValidationErrorType } from "../components/common/ValidationError";

export const GenericErrorPage = ({ error, resetErrorBoundary }) => {
  return (
    <div className="min-w-full min-h-full display-flex flex-direction-column align-items-center gap-2 pt-5">
      <h1 className="mt-2">Oops, something went wrong!</h1>
      <p className="font-lg mb-4" data-testid="msg-error">
        {error?.name === ValidationErrorType && error?.message
          ? error.message
          : "Please go back to the previous page and try again."}
      </p>

      <Button onClick={() => resetErrorBoundary()} data-testid="btn-try-again">
        Try again
      </Button>
      <Button
        variant="primaryInverse"
        onClick={() => (window.location.href = "/")}
        data-testid="btn-back-home"
      >
        Go back to Home page
      </Button>
    </div>
  );
};
