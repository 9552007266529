import { InputItem, Switcher } from "pepsico-ds";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CodeInfomation from "./CodeInfomation";
import "./CodeSearch.scss";
import { RelatedCampaign } from "./RelatedCampaign";

const CodeSearchPage = () => {
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [codeGroupId, setCodeGroupId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputSearchText = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length === 10) {
      setHasSearchResults(true);
      setErrorMessage("");
      setSelectedTabIndex(0);
    } else if (value.length > 0) {
      setHasSearchResults(false);
      setErrorMessage(t("CustomerSupport:SearchTextSizeLabel"));
    } else {
      setHasSearchResults(false);
      setErrorMessage("");
    }
  };

  return (
    <div className="container">
      <div className="code-search-container">
        <h2 className="title">{t("CustomerSupport:CodeLookupTitle")}</h2>
        <div className="search-input">
          <InputItem
            placeholder={t("CustomerSupport:SearchKeywordLabel")}
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleInputSearchText}
            value={searchText}
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="search-result">
          {hasSearchResults ? (
            <div>
              <Switcher
                items={[
                  t("CustomerSupport:CodeInformationLabel"),
                  t("CustomerSupport:RelatedCampaignsLabel"),
                ]}
                onUpdate={(index) => setSelectedTabIndex(index)}
                selectedItemIndex={selectedTabIndex}
              />
              <div style={{ marginTop: 12 }}>
                {selectedTabIndex === 0 && (
                  <CodeInfomation
                    searchText={searchText}
                    setCodeGroupId={setCodeGroupId}
                  />
                )}
                {selectedTabIndex === 1 && (
                  <RelatedCampaign codeGroupId={codeGroupId} />
                )}
              </div>
            </div>
          ) : (
            <>
              {!errorMessage && <p>{t("CustomerSupport:FindTheCodeLabel")}</p>}
              <div
                className="empty-state"
                style={{
                  backgroundImage: "url('/assets/images/member_lookup.png')",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodeSearchPage;
