import "./details-sections.scss";

export const DetailsSection = ({ title, children }) => {
  return (
    <div className="details-section">
      <div className="details-section-title">{title}</div>
      <div className="details-section-content">{children}</div>
    </div>
  );
};
