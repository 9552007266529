import { useMutation } from "@apollo/client";
import _ from "lodash";
import { Button, Modal } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import updateProgramConfiguration from "../../../graphql/mutations/updateProgramConfiguration";
import { paths } from "../../../routes/paths";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

export default function UserLimitSettingsForm({ isEditPage }) {
  const jsonSchema = {
    title: "User Login Limit Settings",
    type: "object",
    properties: {
      labelHelper: {
        type: "string",
      },
      maxRegisteredDevices: {
        type: "integer",
      },
      maxRegisteredDevicesTimeWindow: {
        type: "integer",
      },
      maxLoginsPerDevice: {
        type: "integer",
      },
      maxLoginsPerDeviceTimeWindow: {
        type: "integer",
      },
      maxScanCount: {
        type: "integer",
      },
      maxScanCountTimeWindow: {
        type: "integer",
      },
    },
  };

  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Label",
        text: "User Device Limit Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Label",
            text: "A user can login/register on maximum X devices in last Y days.",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/maxRegisteredDevices",
                label: "Maximum number of devices registration*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
              {
                type: "Control",
                scope: "#/properties/maxRegisteredDevicesTimeWindow",
                label: "Number of days*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
            ],
          },
        ],
      },
      {
        type: "Label",
        text: "User Login Limit Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Label",
            text: "On one device, a maximum of X logins/registrations can happen in the last Y days.",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/maxLoginsPerDevice",
                label: "Maximum logins allowed per device*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
              {
                type: "Control",
                scope: "#/properties/maxLoginsPerDeviceTimeWindow",
                label: "Number of days*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
            ],
          },
        ],
      },
      {
        type: "Label",
        text: "User Successful Code Scan Limit Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Label",
            text: "Maximum number of X code scans are allowed per user account in Y minutes.",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/maxScanCount",
                label: "Total Scan Count*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
              {
                type: "Control",
                scope: "#/properties/maxScanCountTimeWindow",
                label: "Minutes*",
                tooltipText: "TODO",
                component: "numberInput",
                placeholder: "Type number",
              },
            ],
          },
        ],
      },
    ],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [userSettings, setUserSettings] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [hasEmptyFields, setHasEmptyFields] = useState(true);
  const [hasNegativeValues, setHasNegativeValues] = useState(true);

  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  const [updateProgramConfigurationMutation] = useMutation(
    updateProgramConfiguration
  );

  const {
    programConfigurations,
    updateProgramConfigurations,
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);

  useEffect(() => {
    const userLoginLimits = _.find(programConfigurations, {
      key: "user_login_limits",
    });
    if (userLoginLimits !== null && userLoginLimits !== undefined) {
      setUserSettings(userLoginLimits.value?.data);
    }
  }, [programConfigurations]);

  useEffect(() => {
    const hasEmptyFields = _.some(userSettings, (value) => isNaN(value));
    setHasEmptyFields(hasEmptyFields);
    if (hasEmptyFields) {
      openWarningToast("Fill all missing fields");
    }
  }, [userSettings]);

  useEffect(() => {
    const hasNegativeValues = _.some(userSettings, (value) => value < 0);
    setHasNegativeValues(hasNegativeValues);
    if (hasNegativeValues) {
      openWarningToast("Input can't be negative!");
    }
  }, [userSettings]);

  const handleSaveConfirmation = async () => {
    const params = {
      variables: {
        input: {
          slug: "user_login_limits",
          data: JSON.stringify(userSettings),
        },
        id: programConfig?.id,
      },
    };

    try {
      setIsLoading(true);
      const res = await updateProgramConfigurationMutation(params);
      if (res?.data?.updateProgramConfiguration) {
        updateProgramConfigurations(
          res?.data?.updateProgramConfiguration?.configurations
        );
        openSuccessToast();
        setIsLoading(false);
      }
    } catch {
      openErrorToast();
      setIsLoading(false);
    }

    setConfirmationModalOpen(false);
  };

  const openSuccessToast = () => {
    setToast({
      open: true,
      type: "success",
      title: "Settings updated successfully!",
      description: "You have just saved the user limit settings",
      navigateToSettings: true,
    });
  };

  const openWarningToast = (message) => {
    setToast({
      open: true,
      type: "warning",
      title: message,
    });
  };

  const openErrorToast = () => {
    setToast({
      open: true,
      type: "error",
      title: "Something went wrong while saving the user limit settings.",
      description: "Try again later.",
    });
  };

  const navigate = useNavigate();

  const navigateToSystemSettings = () => {
    navigate(`/${paths.system_setting}`);
  };

  return (
    <div className="container">
      <div className="wrapper">
        <JsonFormsWrapper
          data={userSettings}
          onChange={(data) => setUserSettings(data)}
          uischema={uiSchema}
          jsonSchema={jsonSchema}
        />
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={navigateToSystemSettings}
          />
          <Button
            size="medium"
            text="Save"
            variant="primary"
            disabled={!isEditPage || hasEmptyFields || hasNegativeValues}
            onClick={() => setConfirmationModalOpen(true)}
          />
        </div>
        <Modal
          showModal={confirmationModalOpen}
          onCloseModal={() => setConfirmationModalOpen(false)}
          primaryButtonProps={{
            size: "medium",
            text: "Save",
            variant: "primary",
            disabled: isLoading,
            onClick: handleSaveConfirmation,
          }}
          secondaryButtonProps={{
            size: "medium",
            text: "Back to settings",
            variant: "secondary",
            onClick: () => setConfirmationModalOpen(false),
          }}
          showTertiaryButton={false}
          showLink={false}
        >
          <div className="wrap">
            <h2>Save this settings?</h2>
            <p>Are you sure you want to update the user limit settings?</p>
          </div>
        </Modal>
        <ToastNotification
          {...toast}
          handleClose={() => {
            setToast({ ...toast, open: false });
            if (toast.navigateToSettings) {
              navigateToSystemSettings();
            }
          }}
        />
      </div>
    </div>
  );
}
