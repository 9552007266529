import { useCallback } from "react";
import { configMap } from "../utils/configMap";
import { useGetUserClaimsOkta } from "./useGetUserClaimsOkta";

export const useHasAccessPermission = () => {
  const userInfo = useGetUserClaimsOkta();
  const roles = userInfo?.pepappwsproles ?? null;

  const getRolesFromConfig = useCallback((config, key) => {
    const [firstProp, ...restProps] = key.split(".");
    if (config && typeof config === "object" && firstProp in config) {
      return restProps.length === 0
        ? config[firstProp]?.roles
        : getRolesFromConfig(config[firstProp], restProps.join("."));
    }
    return undefined;
  }, []);

  const hasAccess = useCallback(
    (key) => {
      const requiredAccess =
        getRolesFromConfig(configMap[window.REACT_APP_PROGRAM], key) || [];

      if (requiredAccess.includes("all")) return true;

      if (roles === null || !Array.isArray(roles)) return false;

      return requiredAccess.some((access) => roles.includes(access));
    },
    [getRolesFromConfig, roles]
  );

  return hasAccess;
};
