import { InputItem } from "pepsico-ds";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/widget/create_new_widget_empty.svg";
import ButtonGroupDynamic from "../../components/common/ButtonGroupDynamic";
import ToastNotification from "../../components/common/toastNotification/ToastNotification";
import SystemSettingCard from "../../components/management/SystemSettingCard/SystemSettingCard";
import SystemSettingTable from "../../components/management/SystemSettingTable/SystemSettingTable";
import EditWidget from "../../components/management/WidgetPages/EditWidget/EditWidget";
import CreateRewardsModal from "../../components/rewards/CreateRewardsModal/CreateRewardsModal";
import { EditWidgetContext } from "../../context/EditWidgetContext";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { paths } from "../../routes/paths";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

const LIST_VIEW_TYPES = [
  {
    icon: "view_module",
    viewType: "grid",
  },
  {
    icon: "view_list",
    viewType: "list",
  },
];

export default function SystemSettingPage() {
  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const [createRewardModalOpen, setCreateRewardModalOpen] = useState(false);
  const [viewType, setViewType] = useState("grid");
  const [editWidgetModalOpen, setEditWidgetModalOpen] = useState(false);
  const { initialState, setWidgetState, successTitle } =
    useContext(EditWidgetContext);
  const [inputSearchText, setInputSearchText] = useState("");
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const configData = useMemo(() => {
    return (
      programConfig?.configurations?.map((config) => ({
        id: config.key,
        name: config?.value?.title || "",
        description: config?.value?.description || "",
        data: { ...config?.value?.data },
      })) || []
    );
  }, [programConfig]);
  const filteredData = configData.filter((config) => {
    return (
      (config.name.toLowerCase().includes(inputSearchText.toLowerCase()) ||
        config.description
          .toLowerCase()
          .includes(inputSearchText.toLocaleLowerCase())) &&
      config?.name
    );
  });

  const configWidgetData = () => {
    setEditWidgetModalOpen(true);
    let widgetData = [...configData];
    widgetData = widgetData.filter((item) => item?.id === "widgets_configuration");
    let data = { ...widgetData[0]?.data };
    if (Object.keys(data)?.length > 0) {
      setWidgetState(JSON.parse(JSON.stringify(data)));
    }
  };

  const onClickEdit = (id) => {
    if (id === "widgets_configuration") {
      configWidgetData();
    } else {
      navigate(`/${paths.system_setting}/${paths[id]}/edit`);
    }
  };
  const onClickView = (id) => {
    if (id === "widgets_configuration") {
      navigate(`/${paths.system_setting}/${paths[id]}`, {
        state: {
          data: configData.filter((item) => item?.id === "widgets_configuration"),
        },
      });
    } else {
      navigate(`/${paths.system_setting}/${paths[id]}`);
    }
  };

  const toggleModalNewReward = () => {
    setCreateRewardModalOpen(!createRewardModalOpen);
  };

  const toggleEditWidgetModal = (param) => {
    setEditWidgetModalOpen(!editWidgetModalOpen);
    if (param === "cancel") {
      setWidgetState(initialState);
    }
  };

  const handleInputSearchText = (event) => {
    const { value } = event.target;
    setInputSearchText(value);
  };

  useEffect(() => {
    if (location.state?.editWidget) {
      setEditWidgetModalOpen(true);
      let data = { ...location.state?.data?.data };
      if (data["survey-card"]) {
        delete data["survey-card"];
      }
      if (Object.keys(data)?.length > 0) {
        setWidgetState(JSON.parse(JSON.stringify(data)));
      }
    }
  }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    navigate({ state: { editWidget: false, data: [] } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      successTitle &&
      Object.keys(successTitle).length > 0 &&
      successTitle["title"] === "Success"
    ) {
      setToast({
        open: true,
        type: "success",
        title: "Widgets on Home Screen updated!",
        description: "You have just made a new update on Widgets on Home Screen.",
      });
    }
  }, [successTitle]);

  return (
    <div className="container">
      <div className="wrap">
        {createRewardModalOpen && (
          <CreateRewardsModal
            createRewardModalOpen={createRewardModalOpen}
            toggleModalNewReward={toggleModalNewReward}
          />
        )}
        <div className="heading-wrap">
          <h2>System setting</h2>
        </div>

        <div className="container filter-container">
          <div className="filter-container-search">
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleInputSearchText}
            />
          </div>
          <div className="filter-button-group">
            <ButtonGroupDynamic
              items={LIST_VIEW_TYPES.map((item) => ({
                ...item,
                onClick: () => setViewType(item.viewType),
              }))}
              size="medium"
              spacing="2px"
              variant="toggle"
              selectedIndex={viewType === "grid" ? 0 : 1}
            />
          </div>
        </div>
        <div className="card-list">
          {filteredData?.length > 0 ? (
            <>
              {viewType === "grid" ? (
                <div className="grid-container">
                  {filteredData.map((item, index) => (
                    <div className="grid-item grid-item-3 grid-item-4" key={index}>
                      <SystemSettingCard
                        {...item}
                        onClickView={onClickView}
                        onClickEdit={onClickEdit}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <SystemSettingTable
                  rewardData={filteredData}
                  onClickView={onClickView}
                  onClickEdit={onClickEdit}
                />
              )}
            </>
          ) : (
            <section className="empty-state-container">
              <section className="empty-state">
                <img src={EmptyImage} alt="No settings was found" />
                <p>No settings was found</p>
              </section>
            </section>
          )}
        </div>
      </div>
      {editWidgetModalOpen && (
        <EditWidget
          editWidgetModalOpen={editWidgetModalOpen}
          toggleEditWidgetModal={toggleEditWidgetModal}
        />
      )}
      {toast && (
        <ToastNotification
          {...toast}
          handleClose={() => setToast({ ...toast, open: false })}
        />
      )}
    </div>
  );
}
