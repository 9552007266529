import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import "./layer-layout.scss";

const LayerLayout = ({ schema, uischema, path, renderers, cells, enabled }) => {
  return (
    <div className="layer-layout">
      {uischema.elements.map((element, index) => (
        <JsonFormsDispatch
          schema={schema}
          uischema={element}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
          key={index}
        />
      ))}
    </div>
  );
};

export const LayerLayoutControl = withJsonFormsLayoutProps(LayerLayout);
