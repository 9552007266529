import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { TextInput } from "pepsico-ds";
import { useEffect, useState } from "react";
import "./text-input.scss";

const PrefixAndSuffixInputComponent = ({
  handleChange,
  label,
  data,
  path,
  errors,
  schema,
  uischema,
  required,
  enabled,
  visible,
}) => {
  const [errMsg, setErrMsg] = useState(getInitialErrorMessage(schema, errors));

  useEffect(() => {
    if (!enabled && data) {
      handleChange(path, "");
    }
  }, [enabled, data, handleChange, path]);

  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;

  const getDynamicMaxLength = () => {
    if (!jsonformState.codeLength) {
      return uischema?.maxLength;
    }
    return calculateLengthAdjustment(jsonformState, uischema);
  };

  const onUpdate = (val) => {
    setErrMsg("");
    handleChange(path, val);
  };

  const handleKeyDown = (e) => {
    if (!isValidCharacter(e.key, jsonformState.characterSet)) {
      e.preventDefault();
    }
  };

  return visible ? (
    <TextInput
      required={required}
      disabled={!enabled || uischema.readOnly}
      maxLength={getDynamicMaxLength()}
      type={getInputType(schema)}
      error={errMsg || undefined}
      label={label}
      info={uischema?.info || ""}
      onKeyDown={handleKeyDown}
      onUpdate={onUpdate}
      value={data === 0 ? data : data || ""}
      placeholderText={uischema.placeholder ?? ""}
    />
  ) : null;
};

const getInitialErrorMessage = (schema, errors) => {
  return schema.message && errors.indexOf("must match pattern") > -1
    ? schema.message
    : errors;
};

const calculateLengthAdjustment = (jsonformState, uischema) => {
  const lengthAdjustment = uischema?.isPrefixVal
    ? jsonformState.suffixVal?.length
    : jsonformState.prefixVal?.length;
  return jsonformState.codeLength - (lengthAdjustment ?? 0);
};

const isValidCharacter = (key, characterSet) => {
  const validCharacters = characterSet || [];
  return validCharacters.includes(key) || key === "Backspace";
};

const getInputType = (schema) => {
  return schema?.type === "integer" ? "number" : schema?.type || "text";
};

export const PrefixAndSuffixInputControl = withJsonFormsControlProps(
  PrefixAndSuffixInputComponent
);
