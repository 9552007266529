import { Grid } from "pepsico-ds";
import { ActivityOutcomesSummary } from "../../../../components/campaign/ActivitySetup/ActivityOutcomesSummary";
import ViewComponentsRenderer from "../../../../components/campaign/viewComponents/ViewComponentsRenderer";
import { useSweepstakeListQuery } from "../../../../hooks/useSweepstakeListQuery";
import { updateSweepstakesOutcomes } from "../../../../utils/commons";
import { FeatureToggle, isFeatureEnabled } from "../../../../utils/featureToggle";
import SummaryCard from "./SummaryCard";

const Activity = ({ data, length }) => {
  return (
    <>
      {length > 1 && (
        <h3 className="campaign-definition-name">
          {data?.activityDefinition?.name}
        </h3>
      )}
      <Grid
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={data?.activityDefinition?.slug}
          data={data?.activityData}
          inputSchema={data?.activityDefinition?.inputSchema}
        />
      </Grid>
    </>
  );
};
const Rules = ({ data }) => (
  <>
    {/* <h3 className="campaign-name">Rules</h3> */}
    {data?.map((rule) => (
      <Grid
        key={rule?.ruleDefinition?.slug}
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={rule.ruleDefinition?.slug}
          data={rule.ruleData}
          inputSchema={rule.ruleDefinition?.inputSchema}
        />
      </Grid>
    ))}
  </>
);
const Outcomes = ({ data, listSweepstakes }) => {
  // Add the outcome name outcomeRules
  const updatedData = updateSweepstakesOutcomes(data, listSweepstakes);
  return (
    <>
      <h3 className="campaign-name">Outcomes</h3>
      {updatedData
        .reduce(
          (acc, outcome) => [
            ...acc,
            ...outcome.outcomeRules,
            {
              ruleData: outcome.outcomeData || {},
              ruleDefinition: outcome.outcomeDefinition || {},
            },
          ],
          []
        )
        .filter((rule) => rule?.ruleData?.target_id !== null)
        .map((rule) => (
          <>
            {rule?.name && (
              <h3 className="activity-definition-name ">{rule?.name}</h3>
            )}
            <div className="ml-3">
              <Grid
                key={rule?.ruleDefinition?.slug}
                item
                xs={12}
                md={6}
                xl={4}
                mt={1}
                mb={1}
                className="display-flex gap-3 two-column"
              >
                {!!Object.keys(rule.ruleData).length && (
                  <ViewComponentsRenderer
                    customComponentName={rule.ruleDefinition?.slug}
                    data={rule.ruleData}
                    inputSchema={rule.ruleDefinition?.inputSchema}
                  />
                )}
              </Grid>
            </div>
          </>
        ))}
    </>
  );
};

const SummaryActivitySetup = ({ data, isViewScreen, editSectionHandler }) => {
  const { sweepstakesList: list } = useSweepstakeListQuery();

  return (
    <SummaryCard
      title="Activity Setup"
      isViewScreen={isViewScreen}
      handleEditSection={editSectionHandler}
    >
      {data?.name && <h3 className="campaign-name">{data?.name}</h3>}
      {data.activities?.map((activity) => {
        const isAcitivtyEmpty =
          (activity?.activityDefinition?.rules?.length === 0 &&
            activity.activityDefinition?.outcomes?.length === 0 &&
            Object.keys(activity.activityDefinition?.uiSchema || {}).length === 0) ||
          false;
        if (isAcitivtyEmpty) {
          return (
            <h3
              key={activity?.activityDefinition?.name}
              className="campaign-definition-name"
            >
              {activity.activityDefinition.name}
            </h3>
          );
        }
        return (
          <div className="mb-20" key={activity?.activityDefinition?.name}>
            {activity?.activityDefinition?.name && (
              <h3 className="campaign-name fs-14">
                {activity?.activityDefinition?.name}
              </h3>
            )}
            <div className="ml-3">
              {isFeatureEnabled(FeatureToggle.showActivityIdAtSummaryPage) &&
                activity?.id && (
                  <div key={activity?.id} className="additional-fields-container">
                    <div className="field-pair">
                      <span className="field-label">Activity ID:</span>
                    </div>
                    <div className="field-pair-two-column">
                      <span className="field-value">{activity?.id}</span>
                    </div>
                  </div>
                )}
              <Grid container>
                {activity?.activityData &&
                  activity?.activityDefinition?.inputSchema &&
                  activity?.activityDefinition?.uiSchema && (
                    <Activity data={activity} length={data.activities?.length} />
                  )}

                {/* Render activityRules */}
                {activity?.activityRules?.length > 0 && (
                  <Rules data={activity.activityRules} />
                )}
              </Grid>
            </div>
            {/* Render activities outcomes */}
            {activity?.outcomes?.length > 0 &&
              (isFeatureEnabled(
                FeatureToggle.isDetailedOutcomeEnabledInSummaryPage
              ) ? (
                <ActivityOutcomesSummary
                  data={activity.outcomes}
                  listSweepstakes={list || []}
                />
              ) : (
                <Outcomes data={activity.outcomes} listSweepstakes={list || []} />
              ))}
          </div>
        );
      })}
    </SummaryCard>
  );
};

export default SummaryActivitySetup;
