import { Card, Tooltip } from "pepsico-ds";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./SystemSettingCard.scss";

const SystemSettingCard = ({ id, name, description, onClickEdit, onClickView }) => {
  return (
    <Card
      className="card-container"
      // icon=""
      // ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <div className="card-container">
        <div className="details">
          <div className="card-content">
            {name.length > 60 ? (
              <Tooltip direction="right" text={name}>
                <h3 className="name">{name.substring(0, 60)}...</h3>
              </Tooltip>
            ) : (
              <h3 className="name">{name}</h3>
            )}

            <div className="description">
              {description !== null && (
                <div className="meta-item">
                  <span>{description}</span>
                </div>
              )}
            </div>
          </div>

          <div className="button-group-container">
            <ButtonGroupDynamic
              className="icon-button-group"
              items={[
                {
                  icon: "edit",
                  onClick: () => onClickEdit(id),
                },
                {
                  icon: "visibility",
                  onClick: () => onClickView(id),
                },
              ]}
              size="small"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SystemSettingCard;
