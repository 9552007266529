import { Grid } from "@mui/material";
import { debounce } from "lodash";
import { InputItem, LoadingSpinner, Pagination } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import EmptyImage from "../../assets/widget/create_new_widget_empty.svg";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import PartnerCard from "./PartnerCard/PartnerCard";
import PartnerListTable from "./PartnerListViewTable/PartnerListTable";
import "./partners.scss";
import { filterJsonSchema, filterUiSchema } from "./schemas/partnerFilters";

const PartnersTab = ({
  filter,
  setFilter,
  listPartnersQuery,
  numPages,
  handlePageChange,
  currentPage,
}) => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);
  const [searchFilter, setSearchFilter] = useState(null);
  const { data, loading, refetch } = listPartnersQuery;

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  const callAfterChange = useMemo(() => {
    return debounce((searchFilter, filter) => {
      if (searchFilter && searchFilter?.length > 0) {
        const temp = { ...filter };
        const searchObj = { searchText: searchFilter };
        setFilter({ ...temp, ...searchObj });
      } else {
        const temp = { ...filter };
        delete temp.searchText;
        setFilter(temp);
      }
    }, 1500);
  }, [setFilter]);

  useEffect(() => {
    return () => callAfterChange.cancel();
  }, [callAfterChange]);

  useEffect(() => {
    callAfterChange(searchFilter, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="search-filter-container">
          <InputItem
            placeholder="Type a search keyword..."
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={(e) => setSearchFilter(e.target.value)}
            data-testid="partner-search"
          />
          <JsonFormsWrapper
            jsonschema={filterJsonSchema}
            uischema={filterUiSchema}
            data={filter}
            onChange={(data) => setFilter((state) => ({ ...state, ...data }))}
          />
        </div>
        <ButtonGroupDynamic
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {!loading ? (
          <>
            {data?.length > 0 ? (
              <>
                {viewType === "grid" ? (
                  <div>
                    <Grid container spacing={2}>
                      {data.map((partner) => (
                        <Grid key={partner?.id} item xs={6} md={4} lg={3}>
                          <PartnerCard {...partner} refetch={refetch} />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ) : (
                  <PartnerListTable partnerList={data} refetch={refetch} />
                )}
                {numPages > 1 ? (
                  <section className="pagination">
                    <Pagination
                      currentPage={currentPage}
                      onUpdate={handlePageChange}
                      pageCount={numPages}
                      size="medium"
                      variant="number"
                    />
                  </section>
                ) : null}
              </>
            ) : (
              <section className="empty-state-container">
                <section className="empty-state">
                  <img src={EmptyImage} alt="No partners was found" />
                  <p>No partners was found</p>
                </section>
              </section>
            )}
          </>
        ) : (
          <LoadingSpinner
            size={{
              height: "45px",
              width: "45px",
            }}
            style={{ margin: "auto", marginTop: "15rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default PartnersTab;
