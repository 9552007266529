import { withJsonFormsControlProps } from "@jsonforms/react";
import TextInputFieldArray from "./TextInputFieldArray";

const TextInputArrayComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;
  return (
    <TextInputFieldArray
      value={data || [""]}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export const TextInputArrayControl = withJsonFormsControlProps(
  TextInputArrayComponent
);
