import { useTranslation } from "react-i18next";

export const useTranslatedCampaigns = () => {
  const { t } = useTranslation();
  return {
    standard_campaign: t("CustomerSupport:StandardCampaignLabel"),
    po1: t("CustomerSupport:PO1CampaignLabel"),
    limitless_campaign: t("CustomerSupport:LimitlessCampaignLabel"),
    take_a_survey: t("CustomerSupport:TakeASurveyCampaignLabel"),
    share_location: t("CustomerSupport:ShareLocationLabel"),
    open_app: t("CustomerSupport:OpenAppLabel"),
    "action-based": t("CustomerSupport:ActionBasedLabel"),
  };
};
