import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextInput } from "pepsico-ds";
import { useEffect, useState } from "react";

const CustomBannerTextFieldControl = (props) => {
  const { handleChange, label, data, path, errors, schema, required, enabled } =
    props;
  const MAX_LENGTH_IMAGE_TEXT_TITLE_WITH_PARAMETER = 33;
  const MAX_LENGTH_IMAGE_TEXT_TITLE_WITHOUT_PARAMETER = 28;
  const [maxLength, setMaxLength] = useState(0);

  useEffect(() => {
    if (data.includes("{{user.username}}")) {
      setMaxLength(MAX_LENGTH_IMAGE_TEXT_TITLE_WITH_PARAMETER);
    } else {
      setMaxLength(MAX_LENGTH_IMAGE_TEXT_TITLE_WITHOUT_PARAMETER);
    }
  }, [data]);

  const handleUpdate = (newVal) => {
    const newValWithFixedLength = getValWithFixedLength(newVal);
    handleChange(path, newValWithFixedLength);
  };

  const getValWithFixedLength = (value) => {
    return value.length > maxLength
      ? value.slice(0, maxLength - value.length)
      : value;
  };

  return (
    <TextInput
      maxLength={maxLength}
      value={getValWithFixedLength(data)}
      label={label}
      placeholder="{{user.username}} "
      onUpdate={handleUpdate}
      required={required}
      errors={errors}
      schema={schema}
      disabled={!enabled}
    />
  );
};

export const CustomBannerTextField = withJsonFormsControlProps(
  CustomBannerTextFieldControl
);
