import { Box, Slider } from "@mui/material";
import { useEffect } from "react";

const MuiSlider = ({ value, setValue, label, uiSchema, required, enabled }) => {
  useEffect(() => {
    if (value && !enabled) {
      setValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
  const displayValue = (displayType, data = 0) => {
    switch (displayType) {
      case "percentage":
        return `${data}%`;
      case "characters":
        return `${data} characters`;
      default:
        return data;
    }
  };
  return (
    <Box>
      <div className="display-flex justify-content-space-between">
        <p style={{ fontSize: "12px" }}>{label || "" + (required ? "*" : "")}</p>
        <span>{displayValue(uiSchema.displayType, value)}</span>
      </div>
      <Slider
        id="slider"
        disabled={uiSchema?.disabled || !enabled}
        aria-label={label}
        value={value || 0}
        onChange={(event, newValue) => setValue(newValue)}
        getAriaValueText={(value) => displayValue(uiSchema.displayType, value)}
        valueLabelDisplay="auto"
        step={1}
        marks={
          !uiSchema.hideMarks && [
            { value: 0, label: displayValue(uiSchema.displayType, 0) },
            { value: 100, label: displayValue(uiSchema.displayType, 100) },
          ]
        }
        min={uiSchema.min || 0}
        max={uiSchema.max || 100}
      />
    </Box>
  );
};

export default MuiSlider;
