import { Tab, TabsNavigation } from "pepsico-ds";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./segmentListPage.scss";

export default function SegmentListPage() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [containerCardY, setContainerCardY] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Check the pathname to determine the default selected tab
    if (location.pathname.includes("/list")) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [location.pathname]);

  const handleResize = () => {
    setContainerCardY(
      Math.ceil(
        document.getElementsByClassName("container-card")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (index) => {
    setSelectedTab(index);
    navigate(index === 0 ? "" : "list");
  };

  return (
    <div className="px-3 pt-3">
      <div
        className="container-card"
        style={{ height: `calc(100vh - ${containerCardY}px)` }}
      >
        <div className="page-header">
          <h6 className="font-bold font-lg">Segments</h6>
        </div>
        <TabsNavigation
          className="tabs-wrap"
          style={{ borderBottom: "2px solid #D7E5F8", marginBottom: "1rem" }}
          onUpdate={handleTabChange}
          selectedIndex={selectedTab}
        >
          <Tab label="Segment Data" />
          <Tab label="Segment List" />
        </TabsNavigation>
        <Outlet />
      </div>
    </div>
  );
}
