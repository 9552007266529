function customQuillToolbar(that, dataType, startsWith) {
  const range = that.quill.getSelection();
  if (range && range.length > 0) {
    const text = that.quill.getText(range);
    let linkHref = "";
    if (text.indexOf(startsWith) !== 0) {
      linkHref = `${startsWith}${text}`;
    }
    const { tooltip } = that.quill.theme;
    tooltip.preview.textContent = text;
    tooltip.preview.setAttribute("href", linkHref);
    tooltip.edit("link", linkHref);
    tooltip.root.setAttribute("data-link-type", dataType);
  }
}

export function convertTelLink() {
  customQuillToolbar(this, "tel", "tel:");
}

export function convertMailLink() {
  customQuillToolbar(this, "mail", "mailto:");
}

export const formats = ["link", "bold", "list"];
