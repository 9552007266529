import { withJsonFormsControlProps } from "@jsonforms/react";
import SwitchField from "./SwitchField";

const SwitchComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;

  return (
    <SwitchField
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export const SwitchControl = withJsonFormsControlProps(SwitchComponent);
