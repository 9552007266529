import { v4 as uuidv4 } from "uuid";
import { formatSegmentKeys, getJsonIfValid } from "../../utils/commons";

const SegmentAttributesAndValues = ({ rules }) => {
  const rulesArray = getJsonIfValid(rules);
  // Check if the parsed JSON is valid and is an array
  if (!Array.isArray(rulesArray) || rulesArray?.includes(null)) {
    return <div>-</div>;
  }

  return (
    <ul>
      {rulesArray.map((rule) => {
        // Check if the rule is an object
        if (rule && typeof rule === "object") {
          return Object.entries(rule).map(([key, value]) => (
            <li key={`${key}-${uuidv4()}`}>
              <span>
                {formatSegmentKeys(key)}:{" "}
                {Array.isArray(value) ? value.join(", ") : value}
              </span>
            </li>
          ));
        } else {
          return <></>;
        }
      })}
    </ul>
  );
};

export default SegmentAttributesAndValues;
