import { ButtonGroup } from "pepsico-ds";

const ButtonGroupDynamic = ({
  items = [],
  selectedIndex,
  size,
  spacing,
  variant,
}) => {
  return (
    <ButtonGroup
      items={items?.map((button) => ({
        label: button?.label,
        icon: button?.icon,
        onClick: button?.onClick,
        disabled: button?.disabled,
      }))}
      selectedindex={selectedIndex}
      size={size}
      spacing={spacing}
      variant={variant}
    />
  );
};

export default ButtonGroupDynamic;
