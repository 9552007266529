import { gql } from "@apollo/client";

export const createReward = gql`
  mutation CreateReward($input: RewardCreateInput!) {
    createReward(input: $input) {
      id
      name
      partnerId
      programId
      isActive
    }
  }
`;
