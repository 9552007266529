export const baseCreateSurveyUiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "SimpleGroup",
      label: "Basic Setup",
      elements: [
        {
          type: "HorizontalLayout",
          className: "survey-image",
          elements: [
            {
              type: "Control",
              scope: "#/properties/contentData/properties/image_url",
              component: "singleImageUpload",
              label: "Survey Image",
              accept: ["image/png", "image/jpg", "image/jpeg"],
              maxWidth: 180,
              maxHeight: 189,
              requiredResolution: true,
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/contentData/properties/type",
                      component: "muiSelect",
                      label: "Survey/Quiz",
                      // options: {
                      //   readOnly: isActive,
                      // },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/contentData/properties/short_title",
                      component: "textField",
                      maxLength: 40,
                    },
                  ],
                },
                {
                  type: "Control",
                  scope: "#/properties/title",
                  component: "textField",
                  maxLength: 50,
                },
                {
                  type: "Control",
                  scope: "#/properties/description",
                  component: "textarea",
                  maxLength: 500,
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/contentData/properties/source_url",
              component: "textField",
              label: "URL",
              // options: {
              //   readOnly: isActive,
              // },
            },
            {
              type: "Control",
              scope: "#/properties/contentData/properties/position",
              component: "textField",
              maxLength: 5,
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/activeStartDate",
              component: "dateTimePicker",
              minimum: "currentDateTime",
              label: "Active Start Date",
              // options: {
              //   readOnly: isActive,
              // },
            },
            {
              type: "Control",
              scope: "#/properties/activeEndDate",
              component: "dateTimePicker",
              minimum: "currentDateTime",
              label: "Active End Date",
              rule: {
                condition: {
                  schema: {
                    gt: "activeStartDate",
                  },
                },
                effect: "VALIDATE",
              },
            },
          ],
        },
      ],
    },
    {
      type: "SimpleGroup",
      label: "Specifications",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/contentData/properties/widget_name",
              component: "textField",
              maxLength: 36,
              label: "Widget Name",
              rule: {
                effect: "DISABLE",
                condition: {
                  scope: "#/properties/contentData/properties/show_in_widget",
                  schema: {
                    const: false,
                  },
                },
              },
            },
            {
              type: "Control",
              scope: "#/properties/contentData/properties/wallet_button_text",
              component: "textField",
              maxLength: 15,
              label: "Wallet Button Text",
              rule: {
                effect: "DISABLE",
                condition: {
                  scope: "#/properties/contentData/properties/show_in_wallet",
                  schema: {
                    const: false,
                  },
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: "SimpleGroup",
      label: "Enable/Disable",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/contentData/properties/show_in_widget",
                  component: "toggle",
                },
                {
                  type: "Control",
                  scope: "#/properties/contentData/properties/show_in_wallet",
                  component: "toggle",
                },
                {
                  type: "Control",
                  scope: "#/properties/contentData/properties/show_heidyeli_badge",
                  component: "toggle",
                },
              ],
            },
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope:
                    "#/properties/contentData/properties/show_more_than_one_participant",
                  component: "toggle",
                },
              ],
              rule: {
                effect: "SHOW",
                condition: {
                  scope: "#/properties/contentData/properties/type",
                  schema: {
                    const: "QUIZ",
                  },
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: "SimpleGroup",
      label: "Segments",
      labelStyles: { display: "none" },
      elements: [
        {
          type: "Control",
          scope: "#/properties/segments",
          component: "bannersegmentselect", // Ensure this matches the registered component name
          label: "Select Segments*",
        },
      ],
    },
  ],
};

export const createSurveyJsonSchema = {
  type: "object",
  properties: {
    title: {
      type: "string",
    },
    description: {
      type: "string",
    },
    activeStartDate: {
      type: "string",
    },
    activeEndDate: {
      type: "string",
    },
    segments: {
      type: "array",
      items: {
        type: "string",
      },
      title: "Segments",
      description: "Select the segments for this Survey/Quiz",
    },
    contentData: {
      type: "object",
      properties: {
        position: {
          type: "integer",
          title: "Position in Homepage",
        },
        image_url: {
          type: "string",
          items: { type: "string" },
        },
        type: {
          type: "string",
          label: "Survey/Quiz",
          enum: ["SURVEY", "QUIZ"],
        },
        source_url: {
          type: "string",
          format: "data-url",
        },
        show_in_wallet: {
          type: "boolean",
          title: "Show in Wallet",
        },
        show_in_widget: {
          type: "boolean",
          title: "Show in Widget",
        },
        short_title: {
          type: "string",
        },
        show_heidyeli_badge: {
          type: "boolean",
          title: "Show hediyeli badge",
        },
        widget_name: {
          type: "string",
          description: "Widget name if widget is enabled",
          title: "Widget Name",
        },
        wallet_button_text: {
          type: "string",
          title: "Wallet Button Text",
          description: "Text displayed on the wallet button.",
        },
        show_more_than_one_participant: {
          type: "boolean",
          title: "Allow more than one participation",
        },
      },
      dependencies: {
        show_in_wallet: {
          oneOf: [
            {
              properties: {
                show_in_wallet: { const: true },
              },
              required: ["wallet_button_text"],
            },
          ],
        },
        show_in_widget: {
          oneOf: [
            {
              properties: {
                show_in_widget: { const: true },
              },
              required: ["widget_name"],
            },
          ],
        },
        type: {
          oneOf: [
            {
              properties: {
                type: { const: "QUIZ" },
              },
              required: ["show_more_than_one_participant"],
            },
          ],
        },
      },
      required: ["position", "source_url", "image_url", "short_title", "type"],
    },
  },
  required: ["title", "description"],
  // allOf: [
  //   {
  //     if: {
  //       properties: {
  //         contentData: {
  //           properties: {
  //             show_in_widget: {
  //               const: true,
  //             },
  //           },
  //         },
  //       },
  //       required: ["show_in_widget"],
  //     },
  //     then: {
  //       properties: {
  //         contentData: {
  //           properties: {
  //             widget_name: {
  //               required: ["widget_name"],
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     if: {
  //       properties: {
  //         contentData: {
  //           properties: {
  //             show_in_wallet: {
  //               const: true,
  //             },
  //           },
  //         },
  //       },
  //       required: ["show_in_wallet"],
  //     },
  //     then: {
  //       properties: {
  //         contentData: {
  //           properties: {
  //             wallet_button_text: {
  //               required: ["wallet_button_text"],
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     if: {
  //       properties: {
  //         type: {
  //           const: "QUIZ",
  //         },
  //       },
  //       required: ["type"],
  //     },
  //     then: {
  //       properties: {
  //         show_more_than_one_participant: {
  //           type: "boolean",
  //           description: "Show more than one participant",
  //           title: "Show More Than One Participant",
  //           const: true,
  //         },
  //       },
  //       required: ["show_more_than_one_participant"],
  //     },
  //   },
  // ],
};

export const createSurveyUiSchema = (isEditing, isActive, actionStatus) => {
  const uiSchema = JSON.parse(JSON.stringify(baseCreateSurveyUiSchema)); // Deep copy to avoid mutating the base schema

  if (isActive && actionStatus && actionStatus === "edit") {
    const elementsToDisable = [
      "#/properties/contentData/properties/type",
      "#/properties/contentData/properties/source_url",
      "#/properties/activeStartDate",
      // "#/properties/activeEndDate",
    ];

    const disableElements = (elements) => {
      elements.forEach((element) => {
        if (
          element.type === "Control" &&
          elementsToDisable.includes(element.scope)
        ) {
          element.options = { ...element.options, readOnly: true };
        } else if (element.elements) {
          disableElements(element.elements);
        }
      });
    };

    disableElements(uiSchema.elements);
  }
  return uiSchema;
};
