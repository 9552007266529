import { GrowthBook, setPolyfills } from "@growthbook/growthbook-react";
import "eventsource/example/eventsource-polyfill";

const isGrowthBookEnabled = () => {
  return !!(
    window.REACT_APP_GROWTHBOOK_HOST && window.REACT_APP_GROWTHBOOK_CLIENT_KEY
  );
};

export const GrowthBookEventSource = new Proxy(window.EventSourcePolyfill, {
  construct(target, [url, eventSourceInitDict]) {
    if (!isGrowthBookEnabled()) {
      // If GrowthBook is not enabled, return null to use the native EventSource
      return null;
    }

    const accessToken = JSON.parse(window.localStorage.getItem("okta-token-storage"))
      ?.accessToken?.accessToken;
    if (!accessToken) {
      console.warn(
        `Access token was not found. This is required for our self-hosted GrowthBook platform`
      );
    }

    return new target(url, {
      ...eventSourceInitDict,
      headers: {
        ...eventSourceInitDict?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

if (!isGrowthBookEnabled()) {
  setPolyfills({
    EventSource: GrowthBookEventSource,
  });
}

export const growthbook = new GrowthBook({
  apiHost: window.REACT_APP_GROWTHBOOK_HOST,
  clientKey: window.REACT_APP_GROWTHBOOK_CLIENT_KEY,
});

export const updateGrowthBookAttributes = (attributes) => {
  if (!isGrowthBookEnabled()) return;

  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });
};

export function hasFeatures() {
  return Object.keys(growthbook?.getFeatures()).length > 0;
}

export { isGrowthBookEnabled };
