import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { debounce } from "lodash";
import { InputItem } from "pepsico-ds";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getContentList } from "../../../graphql/queries/contentQueries";
import SurveyAndQuizTable from "./SurveyAndQuizTable";
import "./surveyAndQuizSelector.scss";

const SurveyAndQuizSelectorComponent = ({ data, path, handleChange }) => {
  const { programTimezone } = useContext(GlobalConfigContext);
  const [selectedContent, setSelectedContent] = useState({});
  const [filter, setFilter] = useState({ searchTerm: "" });
  const {
    data: apiList,
    loading,
    refetch,
  } = useQuery(getContentList, {
    variables: {
      filter: {
        title: filter.searchTerm,
        status: "ACTIVE",
        contentDefinitionSlug: "survey-quiz-content",
      },
    },
  });

  useEffect(() => {
    if (data && apiList?.listContent) {
      const selectedContent = apiList?.listContent.find(
        (content) => content.id === data[0]
      );
      setSelectedContent(selectedContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiList]);

  const onFilterChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    debounceApiCall();
  };

  const debounceApiCall = useMemo(() => {
    return debounce(() => {
      refetch();
    }, 500);
  }, [refetch]);
  useEffect(() => {
    return () => debounceApiCall.cancel();
  }, [debounceApiCall]);

  const onSelectContent = (content) => {
    setSelectedContent(content);
    handleChange(path, [content.id]);
  };

  return (
    <div>
      <div className="display-flex">
        <div style={{ width: "100%" }}>
          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              marginBottom: "0.85rem",
              flex: 0.2,
            }}
          >
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              value={filter.searchTerm}
              style={{ width: "50%" }}
              className="input-search"
              onChange={(e) => onFilterChange("searchTerm", e.target.value)}
            />
            {selectedContent?.title ? (
              <div className="display-flex gap-2 align-items-center">
                <h6 style={{ fontSize: "14px", fontWeight: "700" }}>
                  {selectedContent.title}
                </h6>
                <span style={{ fontSize: "14px", fontWeight: "400" }}>Selected</span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {!loading ? (
            <div className="grid-container" style={{ justifyContent: "center" }}>
              <SurveyAndQuizTable
                surveyAndQuizData={apiList?.listContent || []}
                selectedContent={selectedContent}
                loading={loading}
                programTimezone={programTimezone}
                onSelectContent={(newContent) => onSelectContent(newContent)}
              />
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SurveyAndQuizSelectorControl = withJsonFormsControlProps(
  SurveyAndQuizSelectorComponent
);
