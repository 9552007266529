import { useState } from "react";
import { useCreatePartnerMutation } from "../../Hooks/useCreatePartnerMutation";
import PartnerFormModal from "../PartnerForm/PartnerForm";

function CopyPartnerModal({ isOpen, closeModal, partner, refetch, setToast }) {
  const [partnerData, setPartnerData] = useState(() => ({
    ...partner,
    name: `${partner?.name} copy`,
  }));
  const [copyPartner, loading] = useCreatePartnerMutation();

  const handleCreatePartner = () => {
    const partnerName = partnerData?.name;

    const onSuccess = () => {
      refetch();
      setToast({
        open: true,
        type: "success",
        title: `${partnerName} Copied!`,
        description: "You have just published a partner!",
      });
      closeModal();
    };
    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error on copying ${partnerName}!`,
        description: error?.message ?? "",
      });
      setPartnerData(partner);
      closeModal();
    };

    copyPartner(onSuccess, onFailure, partnerData);
  };

  return (
    <PartnerFormModal
      isOpen={isOpen}
      closeModal={closeModal}
      partnerData={partnerData}
      setPartnerData={setPartnerData}
      handleMutation={handleCreatePartner}
      loading={loading}
      title="Copy Partner"
    />
  );
}

export default CopyPartnerModal;
