export const getUpdatedCampaignConfiguration = (
  campaignsConfiguration,
  campaignGroups
) => {
  let temporaryCampaignsConfig = JSON.parse(JSON.stringify(campaignsConfiguration));
  const updatedConfigs = [];

  if (campaignGroups?.length && temporaryCampaignsConfig.length) {
    campaignGroups.forEach((group) => {
      const updatedGroup = {
        key: group.key,
        image: group.imageURL,
        name: group.name,
        campaigns: [],
        value: null,
      };

      if (group.campaigns?.length) {
        group.campaigns.forEach((campaignKey) => {
          const campaign = temporaryCampaignsConfig.find(
            (config) => config.key === campaignKey
          );
          if (campaign) {
            updatedGroup.campaigns.push(campaign);
            temporaryCampaignsConfig = temporaryCampaignsConfig.filter(
              (config) => config.key !== campaignKey
            );
          }
        });
      }

      updatedConfigs.push(updatedGroup);
    });
  }

  return temporaryCampaignsConfig.concat(updatedConfigs);
};

export const getCampaignKeyToTypesMap = (campaignConfiguration) => {
  const campaignKeyToTypesMap = {};
  campaignConfiguration.forEach((campaign) => {
    if (campaign.campaigns && campaign.campaigns.length > 0) {
      campaignKeyToTypesMap[campaign.key] = campaign.campaigns.map((c) => c.key);
      return;
    }
    campaignKeyToTypesMap[campaign.key] = [campaign.key];
  });
  return campaignKeyToTypesMap;
};
