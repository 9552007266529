import { gql } from "@apollo/client";

const createPartner = gql`
  mutation ($Partner: PartnerInput!) {
    createPartner(input: $Partner) {
      id
      name
      active
      description
      image
      programId
    }
  }
`;

export default createPartner;
