import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, LoadingSpinner, ToastContainer, Toggle } from "pepsico-ds";
import { useCallback, useEffect, useState } from "react";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { usePartnerCodeGroupForm } from "../partners/Modals/PartnerCodeGroupForm/usePartnerCodeGroupForm";
import {
  getInitialCodeGroupData,
  jsonSchema,
  jsonSchemaForPtr,
  restrictedUISchema,
  restrictedUISchemaForPtr,
  uiSchema,
  uiSchemaForPtr,
} from "./codeGroupUtils";
import "./createCodeGroupModal.scss";

/**
 * CodeGroupCommonSetupModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {function} props.refetchListing - Function to refetch the listing.
 * @params {boolean} props.loadCodeGroupLoader - Flag indicating whether the code group data is loading.
 * @params {Object} props.codeGroupData - The code group data.
 * @params {function} props.setCodeGroupData - Function to set the code group data
 * @returns {JSX.Element} The CodeGroupCommonSetupModal component.
 */
export default function CodeGroupCommonSetupModal({
  showModal,
  setShowModal,
  setToast,
  refetchListing,
  loadCodeGroupLoader,
  codeGroupData,
  setCodeGroupData,
}) {
  const [alreadyCreatedData, setAlreadyCreatedData] = useState({});

  const [modalToast, setModalToast] = useState([]);
  const ptrCodeGroupUpload = isFeatureEnabled(FeatureToggle.ptrCodeGroupUpload);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleUploadFileCall, handleDownloadFile } = usePartnerCodeGroupForm();

  useEffect(() => {
    if (!alreadyCreatedData.codeGroupId && codeGroupData?.codeGroupId) {
      setAlreadyCreatedData(codeGroupData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeGroupData]);
  const onCloseModal = () => {
    if (codeGroupData?.codeGroupId) {
      setShowModal((prev) => ({ ...prev, setupModal: false }));
      setCodeGroupData({});
    } else
      setShowModal({ ...showModal, setupModal: false, closeConfirmModal: true });
  };

  const resetForm = useCallback(() => {
    setCodeGroupData(getInitialCodeGroupData(jsonSchema));
    // closeCreatePartnerCodeGroupModal();
  }, [setCodeGroupData]);

  const handleSubmitButton = useCallback(async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let hasError = false;
    const files =
      codeGroupData?.rewardItemFileData?.map((file) => file?.file).filter(Boolean) ||
      [];

    if (files.length > 0) {
      const uploadResponse = await handleUploadFileCall(files);
      if (!uploadResponse?.status) {
        hasError = true;
        setToast([
          {
            id: 1,
            type: "error",
            text: ` ${uploadResponse.message} `,
          },
        ]);
      } else {
        uploadResponse.data.forEach((file) => {
          const fileIndex = codeGroupData.rewardItemFileData?.findIndex(
            (f) => f.name === file.name
          );
          codeGroupData.rewardItemFileData = codeGroupData.rewardItemFileData.filter(
            (f) => f.name !== file.name
          );
          if (fileIndex === -1) codeGroupData.rewardItemFileData.push(file);
          else codeGroupData.rewardItemFileData[fileIndex] = file;
        });

        setToast([
          {
            id: 2,
            type: "success",
            text: `Code Group ${codeGroupData.codeGroupName} Created!`,
          },
        ]);
      }
    }
    if (!hasError) {
      resetForm();
      refetchListing();
      setShowModal((prev) => ({ ...prev, setupModal: false }));
    }

    setIsSubmitting(false);
  }, [
    codeGroupData,
    isSubmitting,
    handleUploadFileCall,
    resetForm,
    setShowModal,
    refetchListing,
    setToast,
  ]);

  const checkIfAllFieldsAreUnchanged =
    new Date(alreadyCreatedData?.activeDates?.codeActiveStartDate).getTime() &&
    (new Date(codeGroupData?.activeDates?.codeActiveStartDate).getTime() ===
      new Date(codeGroupData?.activeDates.codeActiveEndDate).getTime()) ===
      new Date(alreadyCreatedData?.activeDates.codeActiveEndDate).getTime() &&
    codeGroupData.codeGroupName === alreadyCreatedData.codeGroupName;

  return (
    <Dialog
      className="createProduct-modal"
      open={showModal.setupModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2
              data-testid="create-code-group-modal"
              style={{ marginLeft: "18%", textAlign: "center" }}
            >
              {codeGroupData?.codeGroupId ? "Update" : "Create"} Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={onCloseModal}
              className="cursor-pointer"
              data-testid="closeIcon"
            />
          </Grid>
        </Grid>
        <ToastContainer
          data={modalToast}
          removeToast={() => setModalToast([])}
          showActionIcon
        />
      </DialogTitle>
      {!loadCodeGroupLoader ? (
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <div className="my-5 create-modal-wrapper">
            {ptrCodeGroupUpload ? (
              <JsonFormsWrapper
                jsonschema={jsonSchemaForPtr}
                readonly={
                  alreadyCreatedData.codeGroupId &&
                  ((alreadyCreatedData.compositeStatus === "INACTIVE" &&
                    checkIfAllFieldsAreUnchanged &&
                    codeGroupData.administrationStatus === "ENABLED") ||
                    !["DRAFT", "INACTIVE"].includes(
                      alreadyCreatedData.compositeStatus
                    ))
                }
                uischema={
                  codeGroupData?.administrationStatus ===
                  CODE_GROUP_ADMINISTRATION_STATUS.DISABLED
                    ? restrictedUISchemaForPtr
                    : uiSchemaForPtr
                }
                data={codeGroupData}
                onChange={(val) => {
                  setCodeGroupData(val);
                }}
              />
            ) : (
              <JsonFormsWrapper
                jsonschema={jsonSchema}
                readonly={
                  alreadyCreatedData.codeGroupId &&
                  ((alreadyCreatedData.compositeStatus === "INACTIVE" &&
                    checkIfAllFieldsAreUnchanged &&
                    codeGroupData.administrationStatus === "ENABLED") ||
                    !["DRAFT", "INACTIVE"].includes(
                      alreadyCreatedData.compositeStatus
                    ))
                }
                uischema={
                  codeGroupData?.administrationStatus ===
                  CODE_GROUP_ADMINISTRATION_STATUS.DISABLED
                    ? restrictedUISchema
                    : uiSchema
                }
                data={codeGroupData}
                onChange={(val) => setCodeGroupData(val)}
              />
            )}
          </div>

          <Button
            size="small"
            variant="secondary"
            onClick={() => handleDownloadFile("text/csv", "sampleFile.csv")}
            className="download-sample-file"
          >
            Download sample file <DownloadIcon fontSize="small" />
          </Button>
        </DialogContent>
      ) : (
        <DialogContent className="display-flex align-items-center justify-content-center">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{ height: "50px", width: "50px" }}
          />
        </DialogContent>
      )}
      <DialogActions className="mt-5 display-flex justify-content-end align-items-center">
        {[
          CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE,
          CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE,
        ].includes(codeGroupData?.compositeStatus) && (
          <Toggle
            className="toggleStyles mr-3"
            label={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                ? "Active"
                : "In Active"
            }
            disabled={
              alreadyCreatedData.compositeStatus === "INACTIVE" &&
              !checkIfAllFieldsAreUnchanged
            }
            onUpdate={(val) => {
              setCodeGroupData({
                ...codeGroupData,
                administrationStatus: val
                  ? CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                  : CODE_GROUP_ADMINISTRATION_STATUS.DISABLED,
              });
            }}
            size="medium"
            checked={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
            }
          />
        )}
        {(!codeGroupData.codeGroupId ||
          (codeGroupData.codeGroupId &&
            codeGroupData.lifecycleStatus ===
              CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)) && (
          <Button
            variant="secondary"
            className="justify-content-space-around"
            onClick={() =>
              handleSubmitButton(true, CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)
            }
          >
            Save as Draft
          </Button>
        )}
        <Button
          variant="primary"
          className="jufstify-content-space-around"
          onClick={() => handleSubmitButton(false, codeGroupData.lifecycleStatus)}
        >
          {codeGroupData?.codeGroupId ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
