import { useCallback, useState } from "react";

/**
 * Hook to solve common cases of Modal state, such as opening, closing and toggling.
 *
 * @param {boolean} defaultOpenValue
 * @returns {[boolean, function(): void, function(): void, function(): void]}
 */

const useModalState = (defaultOpenValue = false) => {
  const [internalOpenStatus, setInternalOpenStatus] = useState(
    () => defaultOpenValue
  );
  const open = useCallback(() => setInternalOpenStatus(true), []);
  const close = useCallback(() => setInternalOpenStatus(false), []);
  const toggle = useCallback(
    () => setInternalOpenStatus((oldState) => !oldState),
    []
  );

  return [internalOpenStatus, open, close, toggle];
};

export default useModalState;
