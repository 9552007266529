import { Modal } from "pepsico-ds";
import "./ModalConfirm.scss";

const ModalConfirm = ({
  className,
  showModal,
  onCloseModal,
  title,
  text,
  primaryButtonProps,
  secondaryButtonProps,
  showSecondaryButton,
  description,
  itemId,
}) => {
  const defaultButtonProps = {
    size: "medium",
    variant: "primary",
  };

  const modifiedPrimaryButtonProps = {
    ...defaultButtonProps,
    ...primaryButtonProps,
    onClick: () => {
      primaryButtonProps.onClick(itemId);
    },
  };

  const modifiedSecondaryButtonProps = {
    ...defaultButtonProps,
    ...secondaryButtonProps,
    variant: "secondary",
  };

  return (
    <Modal
      className={className}
      showModal={showModal}
      onCloseModal={onCloseModal}
      title={title}
      text={text}
      primaryButtonProps={modifiedPrimaryButtonProps}
      secondaryButtonProps={modifiedSecondaryButtonProps}
      showSecondaryButton={showSecondaryButton === false ? false : true}
      showTertiaryButton={false}
      showLink={false}
    >
      {description && (
        <p className="detail-text $font-small-regular">{description}</p>
      )}
    </Modal>
  );
};

export default ModalConfirm;
