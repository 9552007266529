import { createContext, useContext, useMemo, useState } from "react";

export const PartnerContext = createContext({
  partnerIds: [],
  setPartnerIds: () => {},
});

export const PartnerContextProvider = ({ children }) => {
  const [partnerIds, setPartnerIds] = useState([]);

  const contextValue = useMemo(
    () => ({
      partnerIds,
      setPartnerIds,
    }),
    [partnerIds]
  );

  return (
    <PartnerContext.Provider value={contextValue}>
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartnerContext = () => {
  const context = useContext(PartnerContext);

  if (!context) {
    throw new Error(
      "usePartnerContext must be used within a PartnerContextProvider"
    );
  }

  return context;
};
