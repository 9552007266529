import { Icon } from "pepsico-ds";
import { useState } from "react";
import PreviewPlaceholderDark from "../../../../assets/widget/dark_campaigns.png";
import PreviewPlaceholder from "../../../../assets/widget/placeholder_campaigns.png";
import "./PreviewCardsCarouselCampaigns.scss";

const PreviewCardsCarouselCampaigns = (props) => {
  const { styles } = props;
  const [isHovered, setIsHovered] = useState(false);
  const dynamicStyle = {
    backgroundColor: isHovered
      ? styles.data.buttonHoverColor
      : styles.data.buttonColor,
    color: isHovered
      ? styles.data.buttonTextHoverColor
      : styles.data.buttonTextColor,
  };

  return (
    <div className="container preview-cards-campaigns-container p-0">
      {styles.data.format === "GRID" && (
        <div className="preview-grid">
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the campaign
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.descriptionColor }}
              >
                Description as configured in the campaign
              </div>
              <div
                className="btn-preview btn-mt"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                role="button"
                tabIndex="0"
              >
                <p style={dynamicStyle}>Earn +5 Entries</p>
              </div>
            </div>
          </div>

          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the campaign
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.descriptionColor }}
              >
                Description as configured in the campaign
              </div>
              <div className="text-input" style={{ color: styles.data.textColor }}>
                Enter your promo code here:
              </div>
              <div className="input-box">
                <p></p>
              </div>
              <div
                className="btn-preview "
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                role="button"
                tabIndex="0"
              >
                <p style={dynamicStyle}>Earn +5 Entries</p>
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the campaign
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.descriptionColor }}
              >
                Description as configured in the campaign
              </div>
              <div className="text-input" style={{ color: styles.data.textColor }}>
                Copy and send to your friends to win 2 Entries!
              </div>
              <div className="input-box" style={{ color: styles.data.textColor }}>
                <p></p>
              </div>
              <div
                className="btn-preview "
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                role="button"
                tabIndex="0"
              >
                <p style={dynamicStyle}>Copy</p>
              </div>
            </div>
          </div>

          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the campaign
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.descriptionColor }}
              >
                Description as configured in the campaign
              </div>
              <div
                className="coming-soon"
                style={{ color: styles.data.comingSoonColor }}
              >
                Coming Soon!
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholderDark} alt="Preview" />
            </div>
            <div className="text">
              <div className="title disabled">
                Title as configured in the campaign
              </div>
              <div className="secondary-text disabled">
                Description as configured in the campaign
              </div>
              <div
                className="completed-text"
                style={{ color: styles.data.completedColor }}
              >
                Completed!
                <Icon icon="check_circle" size="small" variant="filled" />
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholderDark} alt="Preview" />
            </div>
            <div className="text">
              <div className="title disabled">
                Title as configured in the campaign
              </div>
              <div className="secondary-text disabled">
                Description as configured in the campaign
              </div>
              <div className="ended disabled">This campaign has ended!</div>
            </div>
          </div>
        </div>
      )}
      {styles.data.format === "CAROUSEL" && (
        <div className="preview-carousel">
          <div
            className="arrow"
            style={{
              backgroundColor: styles.data.buttonColor,
              color: styles.data.buttonTextColor,
            }}
          >
            <Icon
              disabled
              icon="keyboard_arrow_left"
              variant="filled"
              style={{ color: styles.data.buttonTextColor }}
            />
          </div>
          <div className="preview-carousel-items">
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholder} alt="Preview" />
              </div>
              <div className="text">
                <div className="title" style={{ color: styles.data.titleColor }}>
                  Title as configured in the campaign
                </div>
                <div
                  className="secondary-text"
                  style={{ color: styles.data.descriptionColor }}
                >
                  Description as configured in the campaign
                </div>
                <div className="text-input" style={{ color: styles.data.textColor }}>
                  Enter your promo code here
                </div>
                <div className="input-box" style={{ color: styles.data.textColor }}>
                  <p></p>
                </div>
                <div
                  className="btn-preview "
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  role="button"
                  tabIndex="0"
                >
                  <p style={dynamicStyle}>Copy</p>
                </div>
              </div>
            </div>
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholderDark} alt="Preview" />
              </div>
              <div className="text">
                <div className="title disabled">
                  Title as configured in the campaign
                </div>
                <div className="secondary-text disabled">
                  Description as configured in the campaign
                </div>
                <div
                  className="completed-text"
                  style={{ color: styles.data.completedColor }}
                >
                  Completed!
                  <Icon icon="check_circle" size="small" variant="filled" />
                </div>
              </div>
            </div>
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholder} alt="Preview" />
              </div>
              <div className="text">
                <div className="title" style={{ color: styles.data.titleColor }}>
                  Title as configured in the campaign
                </div>
                <div
                  className="secondary-text"
                  style={{ color: styles.data.descriptionColor }}
                >
                  Description as configured in the campaign
                </div>
                <div
                  className="coming-soon"
                  style={{ color: styles.data.comingSoonColor }}
                >
                  Coming Soon!
                </div>
              </div>
            </div>
          </div>
          <div
            className="arrow"
            style={{
              backgroundColor: styles.data.buttonColor,
              color: styles.data.buttonTextColor,
            }}
          >
            <Icon
              disabled
              icon="keyboard_arrow_right"
              variant="filled"
              style={{ color: styles.data.buttonTextColor }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewCardsCarouselCampaigns;
