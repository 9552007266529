import { Tag } from "pepsico-ds";
import useModalState from "../../../hooks/useModalState";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import PartnerCardModal from "../Modals/PartnerCardModal/PartnerCardModal";
import { partnersActionButtons } from "../partnersActionButton";

const PartnerListCard = ({ partner, refetch }) => {
  const [viewIsOpen, viewOnOpen, viewOnClose] = useModalState();
  const [copyIsOpen, copyOnOpen, copyOnClose] = useModalState();
  const [editIsOpen, editOnOpen, editOnClose] = useModalState();
  const [deleteIsOpen, deleteOnOpen, deleteOnClose] = useModalState();

  const activeStatus = partner.active ? "active" : "inactive";
  return (
    <>
      <tr data-testid="partner-list-card">
        <td className="widget-status-type">
          <div className="partner-card-tags">
            <Tag
              text={partner.active ? "Active" : "Inactive"}
              color={partner.active ? "Green-600" : "Gray-100"}
              type="filled"
              size="small"
              isCopyable={false}
            />
            {partner.type === "INTEGRATED" && (
              <Tag
                text="Third Party"
                color="Cyan-500"
                type="filled"
                size="small"
                isCopyable={false}
              />
            )}
          </div>
        </td>
        <td>{partner.name}</td>
        <td>{partner.description}</td>

        <td>
          <div
            className={`button-group-container ${!partner.active ? "inactive" : ""}`}
          >
            <ButtonGroupDynamic
              items={partnersActionButtons({
                status: partner.type === "INTEGRATED" ? "third-party" : activeStatus,
                viewCallback: viewOnOpen,
                copyCallback: copyOnOpen,
                editCallback: editOnOpen,
                deleteCallback: deleteOnOpen,
              })}
              spacing="0px"
              size="medium"
              variant="control"
            />
          </div>
        </td>
      </tr>
      <PartnerCardModal
        viewIsOpen={viewIsOpen}
        viewOnClose={viewOnClose}
        copyIsOpen={copyIsOpen}
        copyOnClose={copyOnClose}
        editIsOpen={editIsOpen}
        editOnClose={editOnClose}
        deleteIsOpen={deleteIsOpen}
        deleteOnClose={deleteOnClose}
        partner={partner}
        refetch={refetch}
        key={`partner-card-modal-list-${partner.id}`}
      />
    </>
  );
};

export default PartnerListCard;
