import { useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import getListPartners from "../../partners/graphql/queries/listPartners";
import "./partnerSelect.scss";

const PartnerSelectComponent = (props) => {
  const { handleChange, label, path, required, visible, enabled, data, uischema } =
    props;

  const [selected, setSelected] = useState(undefined);

  // Fetch active partners
  const { data: listOfPartnersData } = useQuery(getListPartners, {
    variables: { filter: { active: true } },
  });

  // Memoize the list of partners to avoid unnecessary re-renders
  const partners = useMemo(
    () => listOfPartnersData?.listPartners?.items || [],
    [listOfPartnersData]
  );

  // Format options for dropdown
  const formatOptions = useMemo(
    () => (options) =>
      options.map((option) => ({
        id: option.id,
        displayText: option.name,
      })),
    []
  );

  // Initialize selected option if `data` is provided
  useEffect(() => {
    if (data) {
      const selectedPartner = formatOptions(partners).find(
        (partner) => partner.id === data
      );
      if (selectedPartner) {
        setSelected([selectedPartner]);
      }
    }
  }, [data, partners, formatOptions]);

  // Handle selection change
  const handleSelectValue = (val) => {
    if (val) {
      setSelected(val[0].index);
      handleChange(path, val[0].id);
    } else {
      setSelected(null);
      handleChange(path, null);
    }
  };

  // Return dropdown component if visible
  return visible ? (
    <div className="multiple-select-wrap">
      <label style={{ fontSize: 12, color: "#3a3a3a" }}>
        {label} {required ? "*" : ""}
      </label>
      <Dropdown
        childList={formatOptions(partners)}
        selection="single"
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={handleSelectValue}
        placeholder={uischema.placeholder || ""}
      />
    </div>
  ) : null;
};

export const PartnerSelectControl = withJsonFormsMultiEnumProps(
  PartnerSelectComponent
);
