import { rankWith, scopeEndsWith, uiTypeIs } from "@jsonforms/core";

export const ScopeTester = (scope) =>
  rankWith(
    5, // increase rank as needed
    scopeEndsWith(scope)
  );

export const LayoutTester = (type) => rankWith(1000, uiTypeIs(type));

export const MultiSelectTester = (scope) => rankWith(6, scopeEndsWith(scope));

export const TextAreaTester = (scope) => rankWith(6, scopeEndsWith(scope));
