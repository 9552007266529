import { withJsonFormsControlProps } from "@jsonforms/react";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./_filter-select.scss";

const FilterSelectComponent = (props) => {
  const { handleChange, schema, path, data } = props;
  const items = schema.enum || schema.items.oneOf;
  const buttons = items.map((item) => {
    const label = item.title || item;
    const value = item.const || item;
    return {
      label: label,
      onClick: () => handleChange(path, value),
    };
  });

  return (
    <div className="filter-select-container">
      <span className="font-sm font-bold">{schema.label}</span>
      <ButtonGroupDynamic
        spacing="0px"
        size="medium"
        variant="toggle"
        items={buttons}
        selectedIndex={
          schema?.enum?.includes(data) ? schema?.enum?.indexOf(data) : -1
        }
      />
    </div>
  );
};

export const FilterSelectControl = withJsonFormsControlProps(FilterSelectComponent);
