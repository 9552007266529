import { withJsonFormsControlProps } from "@jsonforms/react";
import NumberInputField from "./NumberInputField";

const NumberInputComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;
  return (
    <NumberInputField
      value={data}
      disabled={!enabled}
      label={uischema.showLabel === false ? "" : label}
      updateValue={(newVal) => handleChange(path, parseInt(newVal))}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export const NumberInputControl = withJsonFormsControlProps(NumberInputComponent);
