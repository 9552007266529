import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Button, Checkbox } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { ListingComponentContext } from "../../common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../common/listingComponents/ListingComponentWrapper";
import JsonFormsWrapper from "../jsonFormsWrapper/JsonFormsWrapper";
import CardView from "./CardView";
import TableView from "./TableView";
import "./promoProductSelector.scss";

const PromoProductSelector = ({
  selectedProductIds,
  setSelectedProductIds,
  filterSchemas,
}) => {
  const {
    debouncedFilterData,
    setDebouncedFilterData,
    filterData,
    setFilterData,
    allRecords,
    filteredRecords,
    viewableRecords,
    viewType,
  } = useContext(ListingComponentContext);

  const [selectedPromoProducts, setSelectedPromoProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const mapRuleDataToState = () => {
      const formattedSelectedPromoProducts = [];
      if (selectedProductIds?.length && allRecords?.length) {
        const formatPromoProductsById = {};
        allRecords?.forEach((product) => {
          formatPromoProductsById[product.id] = product;
        });
        selectedProductIds.forEach((id) => {
          const formattedPromoProduct = formatPromoProductsById[id];
          if (formattedPromoProduct) {
            formattedSelectedPromoProducts.push(formattedPromoProduct);
          }
        });
        setSelectedPromoProducts(formattedSelectedPromoProducts);
      }
    };

    mapRuleDataToState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRecords]);

  useEffect(() => {
    const mapStateToRuleData = () => {
      let updatedProductIds = [];
      if (selectedPromoProducts?.length) {
        updatedProductIds = selectedPromoProducts.map((product) => product.id);
      }
      setSelectedProductIds(updatedProductIds);
    };

    mapStateToRuleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPromoProducts]);

  useEffect(() => {
    if (
      filteredRecords?.length > 0 &&
      filteredRecords.every((record) =>
        selectedPromoProducts.some((item) => item.id === record.id)
      )
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedPromoProducts, filteredRecords]);

  const onRemovePromoProduct = (index) => {
    let selectedRows = selectedPromoProducts
      ? JSON.parse(JSON.stringify(selectedPromoProducts))
      : [];
    selectedRows = selectedRows?.filter((item, i) => i !== index);
    setSelectedPromoProducts(selectedRows);
  };

  const onSelectPromoProduct = (promoProduct) => {
    let selectedRows = selectedPromoProducts
      ? JSON.parse(JSON.stringify(selectedPromoProducts))
      : [];
    const index = selectedRows?.findIndex((item) => item.id === promoProduct.id);
    if (index > -1) {
      selectedRows = selectedRows?.filter((item) => item.id !== promoProduct.id);
    } else {
      // if (selectedRows.length === allRecords.length - 1) {}
      selectedRows = [...selectedRows, promoProduct];
    }
    setSelectedPromoProducts(selectedRows);
  };

  const handleSelectAll = (value) => {
    if (value) {
      setSelectedPromoProducts(filteredRecords);
    } else {
      setSelectedPromoProducts([]);
    }
  };
  return (
    <div>
      <div className="display-flex gap-2">
        {filterSchemas.debouncedFilterSchema || filterSchemas.filterDataSchema ? (
          <div className="product-filter-container">
            <div className="filter-wrap">
              {filterSchemas.debouncedFilterSchema && (
                <JsonFormsWrapper
                  uischema={filterSchemas.debouncedFilterSchema.uiSchema}
                  jsonschema={filterSchemas.debouncedFilterSchema.jsonSchema}
                  data={debouncedFilterData}
                  onChange={(value) => setDebouncedFilterData(value)}
                />
              )}
              {filterSchemas.filterDataSchema &&
              filterData?.contentData?.category ? (
                <div
                  className="display-flex gap-2 mt-16"
                  style={{ flexWrap: "wrap" }}
                >
                  <Chip
                    id={`product-${filterData?.contentData?.category}`}
                    label={filterData.contentData?.category || ""}
                    deleteIcon={
                      <CloseIcon
                        sx={{
                          color: "#3A3A3A !important",
                        }}
                      />
                    }
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#DFDFDF",
                      fontSize: "12px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#DFDFDF",
                      },
                    }}
                    onDelete={() =>
                      setFilterData({
                        ...filterData,
                        contentData: { ...filterData.contentData, category: "" },
                      })
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {filterSchemas.filterDataSchema && (
                <JsonFormsWrapper
                  uischema={filterSchemas.filterDataSchema.uiSchema}
                  jsonschema={filterSchemas.filterDataSchema.jsonSchema}
                  data={filterData}
                  onChange={(value) => setFilterData(value)}
                />
              )}
              {/* <InputItem
              placeholder={"Search product"}
              trailingIcon="search"
              inputSize="medium"
              value={debouncedFilterData?.title || ""}
              className="input-search"
              onChange={(e) => setDebouncedFilterData({ ...debouncedFilterData, title: e.target.value })}
            />
            <ListItemButton onClick={handleClick}>
              <ListItemText
                className="filter-type-setup-label"
                primary="Category"
              />
              {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <span>Snacks</span>
                  </div>
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <span>Baverages</span>
                  </div>
                </ListItemButton>
              </List>
            </Collapse> */}
            </div>
          </div>
        ) : (
          <></>
        )}
        <ListingComponentWrapper
          customStyles={{ width: "54%", position: "relative" }}
          filterComponent={<></>}
        >
          <div style={{ position: "absolute" }}>
            <Checkbox
              onUpdate={handleSelectAll}
              text="Select All"
              checked={selectAll}
            />
          </div>
          <div style={{ marginTop: "8px" }}>
            {viewType === "grid" ? (
              <CardView
                promoProductList={viewableRecords}
                selectedPromoProducts={selectedPromoProducts}
                onSelectPromoProduct={onSelectPromoProduct}
              />
            ) : (
              <TableView
                promoProductList={viewableRecords}
                selectedPromoProducts={selectedPromoProducts}
                onSelectPromoProduct={onSelectPromoProduct}
              />
            )}
          </div>
        </ListingComponentWrapper>
        <div
          style={{
            width: "23%",
            padding: "0.5rem",
            backgroundColor: "#F3F7FD",
            marginLeft: "1%",
          }}
        >
          <div className="display-flex justify-content-space-between align-items-center">
            <span style={{ fontWeight: "700", fontSize: "12px" }}>
              {selectedPromoProducts?.length || 0} selected
            </span>
            <Button
              variant="primaryInverse"
              size="small"
              onClick={() => handleSelectAll(false)}
              style={{ padding: "0px" }}
            >
              Remove all
            </Button>
          </div>
          <div className="display-flex gap-2 mt-16" style={{ flexWrap: "wrap" }}>
            {selectedPromoProducts?.map((item, index) => (
              <Chip
                key={index}
                id={`product-${item?.contentData?.name}`}
                className="font-xs"
                label={item.contentData?.name || ""}
                deleteIcon={
                  <CloseIcon
                    sx={{
                      color: "#3A3A3A !important",
                    }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  fontSize: "12px",
                  backgroundColor: "#B3DACB",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#B3DACB",
                  },
                }}
                onDelete={() => onRemovePromoProduct(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoProductSelector;
