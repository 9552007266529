import { useTranslation } from "react-i18next";

export const useFilterCampaigns = () => {
  const { t } = useTranslation();
  const relatedCampaignFilterInputSchema = {
    title: "Campaign List Filter",
    type: "object",
    properties: {
      title: {
        type: "string",
      },
      types: {
        items: {
          type: "string",
          options: [
            {
              id: "standard_campaign",
              displayText: "Standard Campaign",
            },
            {
              id: "po1",
              displayText: "PO1",
            },
          ],
        },
      },
      activeStartDate: {
        type: "string",
      },
      activeEndDate: {
        type: "string",
      },
    },
  };

  const relatedCampaignFilterUiSchema = {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/title",
        placeholder: t("CustomerSupport:CampaignNameLabel"),
        component: "searchInput",
      },
      {
        type: "Control",
        scope: "#/properties/types",
        component: "multiSelect",
        placeholder: t("CustomerSupport:TypeLabel"),
        selection: "multiple",
      },
      {
        type: "Control",
        scope: "#/properties/activeStartDate",
        component: "dateTimePicker",
        label: t("CustomerSupport:StartDateLabel"),
      },
      {
        type: "Control",
        scope: "#/properties/activeEndDate",
        component: "dateTimePicker",
        label: t("CustomerSupport:EndDateLabel"),
        rule: {
          effect: "VALIDATE",
          condition: {
            schema: {
              gt: "activeStartDate",
            },
          },
        },
      },
    ],
  };

  return {
    relatedCampaignFilterInputSchema,
    relatedCampaignFilterUiSchema,
  };
};
