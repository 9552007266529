/* eslint-disable no-unused-vars */
import { defaultTo, toNumber } from "lodash";
import { Icon, TextInput, Tooltip } from "pepsico-ds";
import "./NumberInputField.scss";

export default function NumberInputField(props) {
  const { value, updateValue, label, errors, schema, uiSchema, required, disabled } =
    props;

  const handleUpdateValue = (...args) => {
    updateValue(...args);
  };

  return (
    <>
      <div className="textinput-wrapper">
        {uiSchema.tooltipText && (
          <div className="tooltip-wrapper">
            <span>{label}</span>
            <Tooltip
              direction={uiSchema.tooltipDirection ?? "right"}
              text={uiSchema.tooltipText}
            >
              <Icon
                alt=""
                className="$color-icon-default"
                icon={uiSchema.tooltipIcon ?? "info"}
                size="medium"
                variant="outlined"
              />
            </Tooltip>
          </div>
        )}

        <TextInput
          /* error={!!errors} */
          required={required}
          disabled={disabled}
          type="number"
          maxLength={uiSchema.maxLength ?? ""}
          label={!uiSchema.tooltipText ? label : ""}
          onUpdate={handleUpdateValue}
          value={defaultTo(toNumber(value), "")} // Prevents NaN error
          placeholderText={uiSchema.placeholder ?? ""}
        />
      </div>
    </>
  );
}
