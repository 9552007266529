import { Popover } from "@mui/material";
import { Button, Divider } from "pepsico-ds";
import { useState } from "react";
import ToastNotification from "../../common/toastNotification/ToastNotification";

const ExportOptionsButton = () => {
  const [toast, setToast] = useState({ open: false, title: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const showToast = (title) => setToast({ open: true, type: "success", title });

  const handleExport = (type) => {
    handleClose();
    if (type === "csv") {
      showToast("Exported to CSV! Download will start soon.");
    } else if (type === "email") {
      showToast(
        "The export will be sent to your email at john.smith@pepsico.com. Please check your email in a few minutes."
      );
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
      <Button
        size="medium"
        text="Export"
        variant="primary"
        iconTrailing="arrow_drop_down"
        iconLeading="file_download"
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ className: "range-filter-popover" }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", padding: "8px 16px" }}
        >
          <ExportOption
            text="Export to CSV"
            icon="exit_to_app"
            onClick={() => handleExport("csv")}
          />
          <Divider style={dividerStyle} />
          <ExportOption
            text="Export to Email"
            icon="attach_email"
            onClick={() => handleExport("email")}
          />
          <Divider style={dividerStyle} />
        </div>
      </Popover>
    </div>
  );
};

const ExportOption = ({ text, icon, onClick }) => (
  <Button
    size="medium"
    text={text}
    variant="tertiary"
    iconLeading={icon}
    onClick={onClick}
    style={{ color: "black", fontWeight: "normal" }}
  />
);

const dividerStyle = { backgroundColor: "rgba(0, 92, 188, 0.2)" };

export default ExportOptionsButton;
