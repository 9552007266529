import PartnerListCard from "../PartnerListCard/PartnerListCard";
import "./PartnerListTable.scss";

const PartnerListTable = ({ partnerList, refetch }) => {
  return (
    <div className="widget-list-container" key="list">
      <table className="widget-list-table">
        <thead>
          <tr>
            <th className="column-status">Status</th>
            <th className="column-name">Partner</th>
            <th className="column-type">Description</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnerList.map((partner) => (
            <PartnerListCard
              partner={partner}
              refetch={refetch}
              key={`list-partner-${partner?.id}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartnerListTable;
