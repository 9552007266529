import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid, Radio } from "@mui/material";
import { InputItem, LoadingSpinner } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { CampaignSetupContext } from "../../../context/CampaignSetupContext";
import { getCampaignListing } from "../../../graphql/queries/getCampaignListing";
import { listSegments } from "../../../graphql/queries/listSegments";
import CampaignSummaryViewPage from "../../../pages/campaigns/CampaignSummaryViewPage";
import { capitalizeFirstLetter } from "../../../utils/commons";
import { searchHandler } from "../../../utils/functions";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import Table from "../../common/table/Table";
import "./campaign-select.scss";

const CampaignSelectComponent = ({ visible, data, label, path, handleChange }) => {
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [viewCampaignDetails, setViewCampaignDetails] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [currentCampaignList, setCurrentCampaignList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { campaignState } = useContext(CampaignSetupContext);

  const applyFilter = ["standard_campaign", "limitless_campaign", "po1"].includes(
    campaignState?.type
  );

  const { data: campaignData, loading: loadingCampaigns } = useQuery(
    getCampaignListing,
    {
      variables: applyFilter
        ? {
            filter: {
              types: [campaignState.type],
              campaignStatus: ["active", "inactive", "published"],
            },
          }
        : {},
    }
  );
  const { data: segmentsData, loading: loadingSegments } = useQuery(listSegments, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (campaignData?.listCampaigns) {
      const filteredCampaignList = filterCampaigns(
        campaignData.listCampaigns,
        campaignState.id
      );
      setCampaignList(filteredCampaignList);
      setCurrentCampaignList(filteredCampaignList);
      if (data) {
        setSelectedCampaign(
          filteredCampaignList.find((campaign) => campaign.id === data[0])
        );
      }
    }
  }, [campaignData?.listCampaigns, campaignState.id, data]);

  const toggleModal = () => setOpenModal(!openModal);

  const handleSearch = (e) => {
    const filteredList = searchHandler(
      e.target.value,
      ["title"],
      campaignList || []
    );
    setCurrentCampaignList(filteredList);
  };

  const handleSelect = (campaign) => {
    setSelectedCampaign(campaign);
    handleChange(path, [campaign.id]);
  };

  const handleRemove = () => {
    setSelectedCampaign(null);
    handleChange(path, null);
  };

  const handleViewDetails = (id) => {
    setViewCampaignDetails(id);
    toggleModal();
  };

  const isProgramPTR = window.REACT_APP_PROGRAM === "ptr";

  const headerData = getHeaderData(isProgramPTR);

  const rewardTypeNames = {
    CHANCE_TO_WIN: "Chance to Win",
    LIST: "List",
  };

  const rowData = currentCampaignList.map((campaign) => ({
    selection: (
      <Radio
        size="small"
        checked={selectedCampaign?.id === campaign.id}
        onChange={() => handleSelect(campaign)}
      />
    ),
    campaignName: campaign?.title || "-",
    segments: getSegments(campaign, segmentsData),
    campaignStatus: (
      <div className="common-status-wrapper">
        <span className={`common-status ${campaign.campaignStatus?.toLowerCase()}`}>
          {campaign.campaignStatus
            ? capitalizeFirstLetter(campaign.campaignStatus)
            : "-"}
        </span>
      </div>
    ),
    rewardTypes: getRewardTypes(campaign, rewardTypeNames),
    numberOfRewards: getTotalRewards(campaign) || "-",
    actions: (
      <ButtonGroupDynamic
        items={[
          {
            label: "View details",
            onClick: () => handleViewDetails(campaign.id),
          },
        ]}
      />
    ),
  }));

  return (
    visible && (
      <>
        <span className="campaign-select-label">{label}</span>
        <div className="campaign-select">
          <div className="campaign-select-header">
            <InputItem
              placeholder="Search by campaign name"
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleSearch}
            />
            {selectedCampaign && (
              <div className="selection-header">
                <span className="selected-campaign">
                  <span className="font-bold">{selectedCampaign.title || ""}</span>{" "}
                  Selected
                </span>
                <span
                  className="remove-selected"
                  onClick={handleRemove}
                  role="button"
                  tabIndex="0"
                >
                  Remove
                </span>
              </div>
            )}
          </div>
          {loadingCampaigns || loadingSegments ? (
            <div className="loading-container">
              <LoadingSpinner size={{ height: "2rem", width: "2rem" }} />
            </div>
          ) : (
            <div className="campaign-list">
              <Table headerData={headerData} rowData={rowData} />

              <Dialog
                open={openModal}
                scroll="paper"
                sx={{ "& .MuiPaper-root": { width: "70%" } }}
                fullWidth
                maxWidth="md"
              >
                <DialogTitle>
                  <Grid container justifyContent="space-between" direction="row">
                    <Grid item xs={10}>
                      <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
                        Details of Campaign
                      </h2>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="flex-end">
                      <CloseIcon
                        onClick={() => {
                          toggleModal();
                        }}
                        className="cursor-pointer"
                      />
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent sx={{ overflowX: "hidden" }}>
                  <div className="campaign-details">
                    <CampaignSummaryViewPage campaignId={viewCampaignDetails} />
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      </>
    )
  );
};

const filterCampaigns = (campaigns, currentCampaignId) => {
  return currentCampaignId
    ? campaigns.filter((campaign) => campaign.id !== currentCampaignId)
    : campaigns;
};

const getHeaderData = (isProgramPTR) => {
  return [
    { columnKey: "selection", header: "" },
    { columnKey: "campaignName", header: "Campaign Name" },
    { columnKey: "segments", header: "Segments" },
    { columnKey: "campaignStatus", header: "Status" },
    { columnKey: "rewardTypes", header: isProgramPTR ? "" : "Reward Types" },
    { columnKey: "numberOfRewards", header: isProgramPTR ? "" : "# of Rewards" },
    { columnKey: "actions", header: "Actions" },
  ];
};

const getSegments = (campaign, segmentsData) => {
  return (
    campaign.segments
      ?.map(
        (item) =>
          segmentsData?.listSegments?.find((segment) => segment.id === item)?.name
      )
      .join(", ") || "-"
  );
};

const getRewardTypes = (campaign, rewardTypeNames) => {
  return (
    [
      ...new Set(campaign.rewardGroups?.map((group) => rewardTypeNames[group.type])),
    ].join(", ") || "-"
  );
};

const getTotalRewards = (campaign) => {
  return campaign.rewardGroups?.reduce((sum, group) => sum + group.amount, 0);
};

export const CampaignSelectControl = withJsonFormsControlProps(
  CampaignSelectComponent
);
