import { composePaths } from "@jsonforms/core";
import { JsonFormsDispatch, withJsonFormsArrayControlProps } from "@jsonforms/react";

const SummaryArrayComponent = (props) => {
  const { data, path, schema, uischema, renderers, label } = props;
  const childUiSchema = uischema.options?.detail;

  return (
    <>
      <div className="details-section-title subtitle mb-2">{label}</div>
      {data?.map((_, index) => {
        const childPath = composePaths(path, `${index}`);
        return (
          <div key={childPath} className="details-section-row">
            <JsonFormsDispatch
              path={childPath}
              renderers={renderers}
              schema={schema}
              uischema={childUiSchema}
            />
          </div>
        );
      })}
    </>
  );
};

export const SummaryArrayControl =
  withJsonFormsArrayControlProps(SummaryArrayComponent);
