import { Pagination } from "pepsico-ds";
import { useState } from "react";
import "./table.scss";

const Table = ({
  headerData,
  rowData,
  className,
  pagination = false,
  rowsPerPage,
  noDataLabel = "No data available",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const hasPagination = pagination && rowsPerPage && rowData.length > rowsPerPage;
  const totalPages = Math.ceil(rowData.length / rowsPerPage) || 0;
  const currentData = hasPagination
    ? rowData.slice(
        (currentPage - 1) * rowsPerPage,
        (currentPage - 1) * rowsPerPage + rowsPerPage
      )
    : rowData;

  return (
    <div className="common-table-wrapper w-100">
      <table className={`common-table ${className || ""}`} data-testid="table">
        <thead>
          <tr>
            {headerData.map((header) => (
              <th key={header.columnKey} className={header.className}>
                {header.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowData.length ? (
            currentData.map((row, index) => (
              <tr key={index}>
                {headerData.map((header) => (
                  <td key={header.columnKey} data-testid={header.columnKey}>
                    {row[header.columnKey]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headerData.length}>{noDataLabel}</td>
            </tr>
          )}
        </tbody>
      </table>
      {hasPagination && (
        <Pagination
          currentPage={currentPage}
          onUpdate={(page) => setCurrentPage(page)}
          pageCount={totalPages}
          size="medium"
          variant="number"
        />
      )}
    </div>
  );
};

export default Table;
