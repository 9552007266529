export const partnersActionButtons = ({
  status,
  editCallback,
  deleteCallback,
  viewCallback,
  copyCallback,
}) => {
  const actionButtons = [
    {
      icon: "visibility",
      onClick: viewCallback,
    },
  ];
  const editableStatuses = ["draft", "scheduled", "active", "inactive"];
  const deletableStatuses = ["inactive"];
  const specialStatuses = ["third-party"];

  !specialStatuses.includes(status) &&
    actionButtons.unshift({
      icon: "content_copy",
      onClick: copyCallback,
    });

  editableStatuses.includes(status) &&
    actionButtons.unshift({
      icon: "edit",
      onClick: editCallback,
    });

  deletableStatuses.includes(status) &&
    actionButtons.push({
      icon: "delete",
      onClick: deleteCallback,
    });

  return actionButtons;
};
