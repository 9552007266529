import { Drawer } from "@mui/material";
import PropTypes from "prop-types";

import { Button, Divider, Icon, Tag } from "pepsico-ds";

import { PartnerContextProvider } from "../../../context/PartnerContext";

import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

import "./filtersComponent.scss";

const FiltersComponent = ({
  filterConfig,
  filterClasses,
  onFilterChange,
  handleClearFilters,
  handleApplyFilters,
  viewAllFilters,
  setViewAllFilters,
  withAllFilters,
  debouncedFilterData,
  handleDebouncedFilterChange,
  filterData,
  allFiltersData,
  handleAllFiltersChange,
}) => {
  function removeKey(obj, key) {
    // eslint-disable-next-line no-unused-vars
    const { [key]: _, ...rest } = obj;
    return rest;
  }

  const displayFilterKeysAsTags = (data) => {
    return (
      <div className="tags-container">
        {Object.keys(data).map((key) => {
          const titleCaseKey = key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
          return (
            <Tag
              className="tag"
              key={key}
              text={titleCaseKey.trim()}
              isRemovable={true}
              isCopyable={false}
              onRemove={() => {
                console.log("deleteKey", key);
                const updatedFilters = { ...data };
                const updatedData = removeKey(updatedFilters, key);
                console.log("updatedData", updatedData);
                handleAllFiltersChange(updatedData);
              }}
            />
          );
        })}
      </div>
    );
  };

  const displayFilterCount = () => {
    return allFiltersData && Object.keys(allFiltersData).length > 0 ? (
      <>
        <Tag
          size="small"
          className="badge-count"
          text={allFiltersData && Object.keys(allFiltersData).length}
        />
      </>
    ) : null;
  };

  return (
    <div className="filter-container">
      <Drawer
        anchor="right"
        open={viewAllFilters}
        className="all-filter-drawer"
        data-testid="all-filters-drawer"
        onClose={() => setViewAllFilters(false)}
      >
        <div className="content-container">
          <div className="header-container">
            <h3 className="all-filter-header">All filters</h3>
            <Icon
              style={{ cursor: "pointer" }}
              alt="close"
              icon="close"
              size="medium"
              onClick={() => setViewAllFilters(false)}
            />
          </div>
          <div className="content">
            <div>{allFiltersData && displayFilterKeysAsTags(allFiltersData)}</div>

            <div className="filter-sub-header">
              Advanced filters
              {displayFilterCount()}
            </div>

            <JsonFormsWrapper
              jsonschema={filterConfig.allFilters?.jsonSchema}
              uischema={filterConfig.allFilters?.uiSchema}
              data={allFiltersData}
              onChange={handleAllFiltersChange}
            />
          </div>
          <div className="footer">
            <Button
              variant="primaryInverse"
              size="medium"
              onClick={handleClearFilters}
            >
              Clear all
            </Button>
            <Button
              style={{ color: "#ffffff" }}
              variant="primary"
              size="medium"
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
          </div>
        </div>
      </Drawer>
      <div style={{ display: "flex", flex: 9 }} className={`${filterClasses}`}>
        {filterConfig.withDebounce?.jsonSchema &&
          filterConfig.withDebounce?.uiSchema && (
            <div className="debounced-filters" data-testid="json-forms-wrapper">
              <JsonFormsWrapper
                jsonschema={filterConfig.withDebounce.jsonSchema}
                uischema={filterConfig.withDebounce.uiSchema}
                data={debouncedFilterData}
                onChange={(data) => handleDebouncedFilterChange(data)}
              />
            </div>
          )}
        {filterConfig.withoutDebounce?.jsonSchema &&
          filterConfig.withoutDebounce?.uiSchema && (
            <div className="nonDebounced-filters" data-testid="json-forms-wrapper">
              <PartnerContextProvider>
                <JsonFormsWrapper
                  jsonschema={filterConfig.withoutDebounce.jsonSchema}
                  uischema={filterConfig.withoutDebounce.uiSchema}
                  data={filterData}
                  onChange={(data) => onFilterChange(data)}
                />
              </PartnerContextProvider>
            </div>
          )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {withAllFilters && (
            <Button
              className={"filter-button"}
              variant="primaryInverse"
              size="small"
              onClick={() => setViewAllFilters(true)}
            >
              All filters {displayFilterCount()}
            </Button>
          )}
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: "30px", backgroundColor: "rgba(0, 92, 188, 0.2)" }}
          />
          <Button
            className={"filter-button"}
            variant="primaryInverse"
            size="small"
            onClick={handleClearFilters}
          >
            Clear all
          </Button>
        </div>
      </div>
    </div>
  );
};

FiltersComponent.propTypes = {
  filterConfig: PropTypes.shape({
    allFilters: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
    withDebounce: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
    withoutDebounce: PropTypes.shape({
      jsonSchema: PropTypes.object,
      uiSchema: PropTypes.object,
    }),
  }).isRequired,
  filterClasses: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  viewAllFilters: PropTypes.bool.isRequired,
  setViewAllFilters: PropTypes.func.isRequired,
  withAllFilters: PropTypes.bool,
  debouncedFilterData: PropTypes.object,
  handleDebouncedFilterChange: PropTypes.func.isRequired,
  filterData: PropTypes.object,
  allFiltersData: PropTypes.object,
  handleAllFiltersChange: PropTypes.func.isRequired,
};

export default FiltersComponent;
