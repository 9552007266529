import { ScopeTester } from "../jsonFormsWrapper/testers";
// controllers
import { SocialMediaSelectorControl } from "../socialMediaSelector/SocialMediaSelector";
import { SummaryArrayControl } from "../summaryArray/SummaryArray";
import { SummaryFieldControl } from "../summaryField/SummaryField";
import { SummaryImageControl } from "../summaryImage/SummaryImage";
import { SummaryTextControl } from "../summaryText/SummaryText";

export const getScopesofAllElements = (schema) => {
  const listOfScopes = [];

  function traverse(obj) {
    if (!obj || typeof obj !== "object") {
      return;
    }

    if (obj.scope) {
      listOfScopes.push({
        scope: obj.scope.split("#")[1],
        component: obj.component ? obj.component : "",
      });
    }
    if (obj.elements && Array.isArray(obj.elements)) {
      obj.elements.forEach((element) => {
        traverse(element);
      });
    }
    if (obj.options?.detail && Array.isArray(obj.options?.detail?.elements)) {
      obj.options.detail.elements.forEach((element) => {
        traverse(element);
      });
    }
  }
  traverse(schema);
  return listOfScopes;
};

export const customerRenderesHelper = (scopeAndCompsList) => {
  return scopeAndCompsList?.map(({ component, scope }) => {
    switch (component) {
      case "socialMediaSelector":
        return {
          tester: ScopeTester(scope),
          renderer: SocialMediaSelectorControl,
        };
      case "summaryText":
        return {
          tester: ScopeTester(scope),
          renderer: SummaryTextControl,
        };
      case "summaryImage":
        return {
          tester: ScopeTester(scope),
          renderer: SummaryImageControl,
        };
      case "summaryArray":
        return {
          tester: ScopeTester(scope),
          renderer: SummaryArrayControl,
        };
      default:
        return { tester: ScopeTester(scope), renderer: SummaryFieldControl };
    }
  });
};
