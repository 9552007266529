import { useMutation } from "@apollo/client";
import _ from "lodash";
import { LoadingSpinner, Modal, Toggle } from "pepsico-ds";
import { useContext, useState } from "react";
import EmptyImage from "../../../assets/widget/create_new_widget_empty.svg";
import { RewardContext } from "../../../context/RewardContext";
import { createReward } from "../../../graphql/mutations/createReward";
import { updateReward } from "../../../graphql/mutations/updateReward";
import Button from "../../common/button/Button";
import { ListingComponentContext } from "../../common/listingComponents/ListingComponentContext";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import PartnerSelect from "../PartnerSelect/PartnerSelect";
import RewardCategorySelect from "../RewardCategorySelect/RewardCategorySelect";
import RewardTypeSelect from "../RewardTypeSelect/RewardTypeSelect";
import "./rewards-modal.scss";
import {
  rewardBasicSetupJsonSchema,
  rewardBasicSetupUiSchema,
  rewardImageSetupJsonSchema,
  rewardImageSetupUiSchema,
} from "./schemas";

const setReadonly = (uischema) => {
  if (
    uischema.type === "Control" &&
    uischema.scope !== "#/properties/wheelImageUrl"
  ) {
    const newSchema = {
      ...uischema,
      disabled: true,
    };
    if (newSchema.options && newSchema.options.detail) {
      newSchema.options = {
        ...newSchema.options,
        detail: setReadonly(newSchema.options.detail),
      };
    }
    return newSchema;
  } else if (uischema.elements && Array.isArray(uischema.elements)) {
    return {
      ...uischema,
      elements: uischema.elements.map(setReadonly),
    };
  }
  return uischema;
};

const RewardSpecifications = ({
  loadingSpecifications,
  isEdittingActive,
  schema,
  uischema,
  data,
  onChange,
}) => {
  return loadingSpecifications ? (
    <div className="specifications-loading">
      <LoadingSpinner
        size={{
          height: "2rem",
          width: "2rem",
        }}
      />
    </div>
  ) : (
    schema && uischema && (
      <JsonFormsWrapper
        jsonschema={schema}
        uischema={isEdittingActive ? setReadonly(uischema) : uischema}
        data={data || {}}
        onChange={onChange}
      />
    )
  );
};

const CreateRewardsModal = ({
  createRewardModalOpen,
  toggleModalNewReward,
  showToast,
  additionalConfig,
}) => {
  const {
    rewardState,
    updateRewardState,
    isLoadingOptions,
    loadingSpecifications,
    errors,
    toast,
    setToast,
    uiSchema,
  } = useContext(RewardContext);
  const { refetch } = useContext(ListingComponentContext);
  const [createRewardMutation, { loading: loadingCreate }] =
    useMutation(createReward);
  const [updateRewardMutation, { loading: loadingUpdate }] =
    useMutation(updateReward);
  const [openCopyConfirmationModal, setOpenCopyConfirmationModal] = useState(false);

  const toggleCopyConfirmationModal = () =>
    setOpenCopyConfirmationModal(!openCopyConfirmationModal);

  const isSetupCompleted = !errors.length;

  const updateSpecifications = (data) =>
    updateRewardState({ ...rewardState, rewardTypeData: data });

  const additionalProps = [
    "id",
    "partnerType",
    "partnerName",
    "schema",
    "programId",
    "rewardTypeName",
    "rewardCategories",
  ];

  const showErrorToast = (error) => {
    const action = additionalConfig?.edit ? "update" : "create";
    setOpenCopyConfirmationModal(false);
    setToast({
      open: true,
      type: "error",
      title: `Could not ${action} the reward.`,
      description: error.message || "Please try again later.",
    });
  };

  const handleUpdateReward = async () => {
    updateRewardMutation({
      variables: {
        id: rewardState.id,
        input: _.omit(rewardState, additionalProps),
      },
    })
      .then(() => {
        refetch();
        showToast(
          "success",
          `'${rewardState.name}' Updated!`,
          "You have just updated a reward!"
        );
        toggleModalNewReward();
      })
      .catch(showErrorToast);
  };

  const handleCreateReward = async () => {
    createRewardMutation({
      variables: {
        input: _.omit(rewardState, additionalProps),
      },
    })
      .then(() => {
        refetch();
        showToast(
          "success",
          `'${rewardState.name}' Created!`,
          "You have just published a reward!"
        );
        toggleModalNewReward();
      })
      .catch(showErrorToast);
  };

  const createRewardCallback = additionalConfig?.copy
    ? toggleCopyConfirmationModal
    : handleCreateReward;

  const isEdittingActive = additionalConfig?.isActive === true;

  return (
    <>
      <Modal
        className="reward-modal"
        showModal={createRewardModalOpen && !openCopyConfirmationModal}
        onCloseModal={toggleModalNewReward}
        title={additionalConfig?.edit ? "Edit Reward" : "Create Reward"}
        primaryButtonProps={{}}
        showSecondaryButton={false}
        showTertiaryButton={false}
        showLink={false}
      >
        <h4 className="required-fields-label text-right">*Required fields</h4>
        {isLoadingOptions && (
          <div className="loading-options">
            <LoadingSpinner
              size={{
                height: "1rem",
                width: "1rem",
              }}
            />
            <span>Loading Options...</span>
          </div>
        )}
        <div className="reward">
          <div className="reward-wrap">
            <div className="reward-type-setup">
              <span className="reward-type-setup-label font-bold">Reward Type</span>
              <div className="reward-type-setup-fields">
                <div className="reward-type-setup-field">
                  <PartnerSelect
                    label={"Select Partner*"}
                    selected={rewardState.partnerId}
                    required={true}
                    readonly={isEdittingActive}
                  />
                </div>
                <div className="reward-type-setup-field">
                  <RewardTypeSelect
                    label={"Reward Type*"}
                    selected={rewardState.rewardTypeId}
                    required={true}
                    readonly={isEdittingActive}
                  />
                </div>
                <div className="reward-type-setup-field">
                  <RewardCategorySelect
                    label={"Reward Category*"}
                    selected={rewardState.rewardCategoryIds}
                    required={true}
                    readonly={isEdittingActive}
                  />
                </div>
              </div>
            </div>
            {rewardState?.partnerId?.length ? (
              <div className="reward-basic-setup">
                <div className="vertical-layout gap-normal reward-creation-form">
                  <div className="vertical-layoutItem-item horizontal-layoutItem-0">
                    <div className="simple-form-group">
                      <div className="title-wrapper">
                        <h6 className="font-lg font-bold">Basic Setup</h6>
                      </div>
                      <div className="simple-form-group-wrapper">
                        <div className="horizontal-layout gap-normal basic-setup-columns">
                          <div className="horizontal-layoutItem-item horizontal-layoutItem-0">
                            <JsonFormsWrapper
                              jsonschema={rewardImageSetupJsonSchema}
                              uischema={
                                isEdittingActive
                                  ? setReadonly(rewardImageSetupUiSchema)
                                  : rewardImageSetupUiSchema
                              }
                              data={rewardState}
                              onChange={updateRewardState}
                            />
                          </div>
                          <div className="horizontal-layoutItem-item horizontal-layoutItem-1">
                            <JsonFormsWrapper
                              jsonschema={rewardBasicSetupJsonSchema}
                              uischema={
                                isEdittingActive
                                  ? setReadonly(rewardBasicSetupUiSchema)
                                  : rewardBasicSetupUiSchema
                              }
                              data={rewardState}
                              onChange={updateRewardState}
                            />
                            {!rewardState.partnerCodeType && (
                              <RewardSpecifications
                                loadingSpecifications={loadingSpecifications}
                                isEdittingActive={isEdittingActive}
                                schema={rewardState.schema}
                                uischema={uiSchema}
                                data={rewardState.rewardTypeData || {}}
                                onChange={updateSpecifications}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {rewardState.partnerCodeType && (
                  <RewardSpecifications
                    loadingSpecifications={loadingSpecifications}
                    isEdittingActive={isEdittingActive}
                    schema={rewardState.schema}
                    uischema={uiSchema}
                    data={rewardState.rewardTypeData || {}}
                    onChange={updateSpecifications}
                  />
                )}
              </div>
            ) : (
              <div className="no-reward-type">
                <img src={EmptyImage} alt="No Completed Sweepstakes" />
                <h5>Select your type of reward to start</h5>
              </div>
            )}
          </div>
        </div>
        <div className="action-buttons">
          <Toggle
            label="Active"
            onUpdate={(checked) =>
              updateRewardState({ ...rewardState, isActive: checked })
            }
            size="medium"
            checked={rewardState.isActive}
            disabled={!isSetupCompleted || additionalConfig?.copy}
          />
          <Button
            isLoading={loadingCreate || loadingUpdate}
            onClick={
              additionalConfig?.edit ? handleUpdateReward : createRewardCallback
            }
            disabled={!isSetupCompleted}
          >
            {additionalConfig?.edit ? "Update" : "Create"}
          </Button>
        </div>
        <ToastNotification
          {...toast}
          handleClose={() => setToast({ ...toast, open: false })}
        />
      </Modal>
      <Modal
        className="copy-confirmation-modal"
        showModal={openCopyConfirmationModal}
        onCloseModal={toggleCopyConfirmationModal}
        title="New reward will be created as inactive."
        primaryButtonProps={{}}
        showSecondaryButton={false}
        showTertiaryButton={false}
        showLink={false}
      >
        <div className="action-buttons">
          <Button variant="secondary" onClick={toggleCopyConfirmationModal}>
            Cancel
          </Button>
          <Button
            isLoading={loadingCreate}
            onClick={handleCreateReward}
            disabled={!isSetupCompleted}
          >
            Ok, create
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateRewardsModal;
