import { useLazyQuery, useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import axios from "axios";
import { Button, Modal, ToastContainer } from "pepsico-ds";
import { useContext, useState } from "react";
import BulkEditEndDateModal from "../../components/codeGroups/BulkEditEndDateModal";
import CodeGroupsCard from "../../components/codeGroups/CodeGroupCard";
import CodeGroupCommonSetupModal from "../../components/codeGroups/CodeGroupCommonSetupModal";
import CodeGroupSetupCloseModal from "../../components/codeGroups/CodeGroupSetupCloseModal";
import CodeGroupSetupModal from "../../components/codeGroups/CodeGroupSetupModal";
import CodeGroupTable from "../../components/codeGroups/CodeGroupTable";
import CodeGroupViewModal from "../../components/codeGroups/CodeGroupViewModal";
import {
  generateCodeGroupData,
  getInitialCodeGroupData,
  jsonSchema,
} from "../../components/codeGroups/codeGroupUtils";
import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import LoaderContext from "../../context/LoaderContext";
import { DELETE_CODE_GROUP } from "../../graphql/mutations/DeleteMutation";
import { getCampaignsTaggedToCodeGroup } from "../../graphql/queries/getCampaignListing";
import { listCodeGroups, loadCodeGroup } from "../../graphql/queries/listCodeGroups";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import "./codeGroups.scss";
import {
  codeGroupFilterJsonSchema,
  codeGroupFilterUiSchema,
  debouceCodeGroupFilterJsonSchema,
  debounceCodeGroupFilterUiSchema,
  ptrCodeGroupFilterJsonSchema,
  ptrCodeGroupFilterUiSchema,
} from "./mocks";
const CodeGroupsListPage = () => {
  return (
    <ListingComponentProvider
      query={listCodeGroups}
      fetchPolicy="cache-and-network"
      queryKey="listCodeGroups"
      initialFilterData={{ limit: 5000 }}
      noPagination={false} // Set to true if you don't want pagination
    >
      <CodeGroupsListContent />
    </ListingComponentProvider>
  );
};

const CodeGroupsListContent = () => {
  const isPtrCodeGroupUpload = isFeatureEnabled(FeatureToggle.ptrCodeGroupUpload);
  const { viewableRecords, viewType, refetch, allRecords } = useContext(
    ListingComponentContext
  );

  const { programTimezone } = useContext(GlobalConfigContext) || {};
  const [codeGroupData, setCodeGroupData] = useState({});
  const [showModal, setShowModal] = useState({
    setupModal: false,
    closeConfirmModal: false,
    viewModal: false,
  });
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [selectedItems, setSelectedItems] = useState([]);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [toastData, setToastData] = useState([]);
  const [deleteCodeGroup] = useMutation(DELETE_CODE_GROUP);
  const { setIsLoading } = useContext(LoaderContext);
  const callLoadCodeGroup = async (codeGroupId) => {
    const response = await loadCodeGroupData({ variables: { id: codeGroupId } });
    return response.data;
  };
  const [loadCodeGroupData, { loading: loadCodeGroupLoader }] =
    useLazyQuery(loadCodeGroup);
  const [listCampaigns] = useLazyQuery(getCampaignsTaggedToCodeGroup);
  const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
    ?.accessToken?.accessToken;
  const hasAccess = useHasAccessPermission();
  const downloadPermission = hasAccess("permissions.export_code_group");

  const exportCode = async (url, filename) => {
    const baseUrl = window.REWARD_PARTNER_GROUP_FILE_MANAGER_BASE_URI;
    const endpoint = `${baseUrl}download?container_name=dtc-code-group-export&file_name=${filename}`;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const blob = new Blob([response.data], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      const errMsg =
        error?.response?.data?.extension?.issues[0]?.detail ||
        error?.response?.data?.extension?.issues?.detail ||
        "Something went wrong";
      setToastData([
        {
          id: 3,
          text: `Download error: ${errMsg}`,
          type: "warning",
        },
      ]);
    }
  };

  const deleteCode = (item) => {
    setDeleteModal({ open: true, id: item?.codeGroupId });
  };
  const confirmDelete = async () => {
    setDeleteModal({ open: false, id: null });

    try {
      await deleteCodeGroup({
        variables: {
          id: deleteModal.id,
        },
      });
      refetch();
      setToastData([
        {
          id: 1,
          text: `Codegroup deleted successfully.`,
          type: "success",
        },
      ]);
    } catch (error) {
      console.error(error);
      setToastData([
        {
          id: 1,
          text: `Something went wong.`,
          type: "error",
        },
      ]);
    }
  };

  const checkIfCodeGroupIsLinkedToCampaign = async (codeGroupId, isDraft) => {
    if (isDraft) {
      return false;
    }
    setIsLoading(true);
    const campaignsByCodeGroup = await listCampaigns({
      variables: { filter: { codeGroups: [codeGroupId] } },
    });
    if (campaignsByCodeGroup?.data?.listCampaigns?.length > 0) {
      const campaignTitles = campaignsByCodeGroup.data.listCampaigns.map(
        (campaign) => campaign.title
      );
      setIsLoading(false);
      setToastData([
        {
          id: 1,
          text: `This Code Group is associated with one or more campaigns (${campaignTitles.join(", ")}). You cannot edit it. \n `,
          type: "error",
        },
      ]);
      return true;
    }
    setIsLoading(false);
    return false;
  };

  const handleEditCodeGroup = async (codeGroupId, isDraft) => {
    try {
      const isLinkedToCampaign = await checkIfCodeGroupIsLinkedToCampaign(
        codeGroupId,
        isDraft
      );
      if (isLinkedToCampaign) {
        return;
      }
      setShowModal({ ...showModal, setupModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      setCodeGroupData(generateCodeGroupData(response.loadCodeGroup));
    } catch {
      setIsLoading(false);
      setShowModal({ ...showModal, setupModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
    }
  };

  const handleCopyCodeGroup = async (codeGroupId) => {
    try {
      setShowModal({ ...showModal, setupModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      let copiedData = { ...response.loadCodeGroup };
      delete copiedData.codeGroupId;
      copiedData.basicSetup = {
        ...copiedData.basicSetup,
        codeGroupName: copiedData.basicSetup.codeGroupName + " (Copy)",
      };
      copiedData.lifecycleStatus = CODE_GROUP_LIFE_CYCLE_STATUS.QUEUED;
      copiedData.administrationStatus = CODE_GROUP_ADMINISTRATION_STATUS.ENABLED;
      setCodeGroupData(generateCodeGroupData(copiedData));
    } catch (error) {
      setShowModal({ ...showModal, setupModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
      console.error(error);
    }
  };

  const handleViewCodeGroup = async (codeGroupId) => {
    try {
      setShowModal({ ...showModal, viewModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      setCodeGroupData(generateCodeGroupData(response.loadCodeGroup));
    } catch {
      setShowModal({ ...showModal, viewModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
    }
  };

  const handleEditFromView = async () => {
    try {
      const isLinkedToCampaign = await checkIfCodeGroupIsLinkedToCampaign(
        codeGroupData.codeGroupId,
        codeGroupData.lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT
      );
      if (isLinkedToCampaign) {
        return;
      }
      setShowModal({ ...showModal, viewModal: false, setupModal: true });
    } catch {
      setIsLoading(false);
    }
  };

  const handleActionButtons = (action, group) => {
    switch (action) {
      case "view":
        handleViewCodeGroup(group.codeGroupId);
        break;
      case "edit":
        handleEditCodeGroup(
          group.codeGroupId,
          group.lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT
        );
        break;
      case "copy":
        handleCopyCodeGroup(group.codeGroupId);
        break;
      case "delete":
        deleteCode(group);
        break;
      case "export":
        exportCode(group?.exportUrl, group?.codeGroupId + ".txt");
        break;
      default:
        console.error("Invalid Button Click", action);
    }
  };

  const handleCreateCodeGroup = () => {
    setShowModal({ ...showModal, setupModal: true });
    if (!isPtrCodeGroupUpload) {
      setCodeGroupData(getInitialCodeGroupData(jsonSchema));
    }
  };

  const handleSelectItem = (codeGroupId, isSelected) => {
    setSelectedItems((prev) => {
      if (isSelected) {
        return [...prev, codeGroupId];
      } else {
        return prev.filter((id) => id !== codeGroupId);
      }
    });
  };

  const handleSelectAll = () => {
    const allowedStatuses = [
      CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT.toLowerCase(),
      CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE.toLowerCase(),
    ];

    const allAllowedIds = allRecords
      .filter((record) =>
        allowedStatuses.includes(record.compositeStatus.toLowerCase())
      )
      .map((record) => record.codeGroupId);

    setSelectedItems((prevSelected) => {
      const combined = [...prevSelected, ...allAllowedIds];

      return Array.from(new Set(combined));
    });
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  return (
    <div className="px-3 pt-3">
      {toastData.length > 0 && (
        <ToastContainer
          className="code-group-listing-toast"
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <div className="container-card">
        <div className="codegroups-page-content">
          <div className="codegroups-header">
            <h2>Code Groups</h2>
            <Button size="medium" iconTrailing="add" onClick={handleCreateCodeGroup}>
              Create Code Group
            </Button>
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="codegroups-filter-styles"
            filterConfig={{
              withDebounce: {
                jsonSchema: debouceCodeGroupFilterJsonSchema,
                uiSchema: debounceCodeGroupFilterUiSchema,
              },
              withoutDebounce: {
                jsonSchema: isPtrCodeGroupUpload
                  ? ptrCodeGroupFilterJsonSchema
                  : codeGroupFilterJsonSchema,
                uiSchema: isPtrCodeGroupUpload
                  ? ptrCodeGroupFilterUiSchema
                  : codeGroupFilterUiSchema,
              },
            }}
          >
            {selectedItems.length > 0 && (
              <div className="bulk-action-bar">
                <div className="codegroup-selected-count">
                  {selectedItems.length} selected
                </div>
                <div className="bulk-action-buttons">
                  <Button
                    size="small"
                    variant="auxiliary"
                    onClick={() => setShowBulkEditModal(true)}
                  >
                    Bulk Update End Date
                  </Button>
                  <Button size="small" variant="tertiary" onClick={handleSelectAll}>
                    Select All
                  </Button>
                  <Button
                    size="small"
                    variant="tertiary"
                    onClick={handleDeselectAll}
                  >
                    Deselect All
                  </Button>
                </div>
              </div>
            )}
            {viewType === "grid" ? (
              <div>
                <Grid container spacing={2}>
                  {viewableRecords.map((group, index) => (
                    <Grid key={index} item xs={6} md={4} lg={3}>
                      <CodeGroupsCard
                        key={index}
                        group={group}
                        currentProgramTimezone={programTimezone}
                        handleActionButtons={handleActionButtons}
                        downloadPermission={downloadPermission}
                        isSelected={selectedItems.includes(group.codeGroupId)}
                        onSelect={handleSelectItem}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : (
              <CodeGroupTable
                currentProgramTimezone={programTimezone}
                codeGroupData={viewableRecords}
                handleActionButtons={handleActionButtons}
                downloadPermission={downloadPermission}
                refetchListing={refetch}
                setParentToastData={setToastData}
                selectedItems={selectedItems}
                onSelect={handleSelectItem}
              />
            )}
          </ListingComponentWrapper>
        </div>
        {showModal.setupModal && isPtrCodeGroupUpload ? (
          <CodeGroupCommonSetupModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setToast={setToastData}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            // setParentToastData={setToastData}
          />
        ) : (
          <CodeGroupSetupModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            setParentToastData={setToastData}
          />
        )}
        {/* {showModal.setupModal && (
          <CodeGroupSetupModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            setParentToastData={setToastData}
          />
        )} */}
        {showModal.closeConfirmModal && (
          <CodeGroupSetupCloseModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            setParentToastData={setToastData}
          />
        )}
        {showModal.viewModal && (
          <CodeGroupViewModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            codeGroupData={codeGroupData}
            showModal={showModal}
            setCodeGroupData={setCodeGroupData}
            setShowModal={setShowModal}
            handleEditFromView={handleEditFromView}
          />
        )}
        {deleteModal.open && (
          <Modal
            data-testid="delete-codegroup-modal"
            showModal={deleteModal.open}
            title="Delete this Codegroup?"
            text="Are you sure you want to delete this code group? This action cannot be undone."
            className="content-delete-modal"
            onCloseModal={() => setDeleteModal({ open: false, id: null })}
            primaryButtonProps={{
              size: "medium",
              text: "Yes, Delete",
              variant: "primary",
              onClick: confirmDelete,
            }}
            secondaryButtonProps={{
              size: "medium",
              text: "Cancel",
              variant: "secondary",
              onClick: () => setDeleteModal({ open: false, id: null }),
            }}
            showTertiaryButton={false}
            showLink={false}
          />
        )}
        {showBulkEditModal && (
          <BulkEditEndDateModal
            showModal={showBulkEditModal}
            setShowModal={setShowBulkEditModal}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            refetchListing={refetch}
            setParentToastData={setToastData}
            currentProgramTimezone={programTimezone}
          />
        )}
      </div>
    </div>
  );
};

export default CodeGroupsListPage;
