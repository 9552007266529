import { gql, useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import "./multiple-id-selector.scss";

const MultipleIdSelector = (props) => {
  const { handleChange, label, data, path, required, visible, enabled, schema } =
    props;

  const { data: listOptions, loading } = useQuery(gql`
    query ListRewardCategories {
      listRewardCategories {
        items {
          id
          name
          description
        }
      }
    }
  `);

  const formattedOptions = useMemo(() => {
    if (!loading && schema.queryObject && listOptions?.[schema.queryObject]) {
      return (
        listOptions[schema.queryObject].items?.map((child) => ({
          id: child?.id,
          displayText: child.name || "",
          description: child.description || "",
        })) || []
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, listOptions]);

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (data?.length && formattedOptions.length) {
      const values = data
        .filter((selectedValueId) =>
          formattedOptions.find((option) => selectedValueId === option?.id)
        )
        .map((selectedValueId) =>
          formattedOptions.find((option) => selectedValueId === option?.id)
        );

      if (values?.length > 0) {
        setSelectedValues(values);
      }
    } else {
      setSelectedValues([]);
    }
  }, [data, formattedOptions]);

  const formatData = (listOptions) =>
    listOptions ? listOptions.map((item) => item.id) : [];

  const handleChangeValues = (newValues) => {
    handleChange(path, formatData(newValues));
  };

  if (!visible) return null;

  if (loading && formattedOptions.length === 0) return <p>Loading...</p>;

  return (
    <div className="multiple-select-wrap peacock-dropdown-show-label">
      <Dropdown
        label={label}
        placeholder="Reward Category"
        childList={formattedOptions}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selectedValues}
        setSelectedValue={handleChangeValues}
      />
    </div>
  );
};

export const MultipleIdSelectorControl =
  withJsonFormsMultiEnumProps(MultipleIdSelector);
