/**
 * Rejects the request.
 * @return {Promise} error - Returns a Promise with the details for the wrong request.
 */
function rejectValidation(module, param) {
  return Promise.reject({
    status: 0,
    message: `The ${module} ${param} is not valid or it was not specified properly`,
  });
}

/**
 * @classdesc Represents an API call.
 * @class
 * @abstract
 */
class APICall {
  /**
   * Create a APICall.
   * @constructor
   * @param {string} baseURL - A string with the base URL for account.
   * @param {Object} token - Optional OAuth2 access token
   */
  constructor(baseURL, token) {
    try {
      new URL(baseURL);
    } catch {
      throw new Error("The base URL provided is not valid");
    }

    this.baseURL = baseURL;
    this.token = token;
  }

  /**
   * Fetch the information from the API.
   * @return {Promise} - Returns a Promise that, when fulfilled, will either return an JSON Object with the requested
   * data or an Error with the problem.
   */
  async send(method, url, controller, data = {}) {
    let callURL = new URL(url, this.baseURL).href;

    if (!this.token && !this.permanentToken) {
      throw new Error("No token found");
    }

    const headers = {
      "User-Agent": "bynder-js-sdk/2.3.9",
    };

    if (this.permanentToken) {
      headers["Authorization"] = "Bearer " + this.permanentToken;
    } else {
      this.token = await (this.token.expired()
        ? this.token.refresh()
        : Promise.resolve(this.token));

      headers["Authorization"] = "Bearer " + this.token.token.access_token;
    }

    let body;

    if (method === "POST") {
      headers["Content-Type"] = "application/x-www-form-urlencoded";

      body = new URLSearchParams(data).toString();
    } else if (Object.keys(data).length && data.constructor === Object) {
      callURL = new URL(
        url + "?" + new URLSearchParams(data).toString(),
        this.baseURL
      ).href;
    }

    try {
      const response = await fetch(callURL, {
        method,
        headers,
        body,
        mode: "cors",
        signal: controller?.signal,
      });
      return response.json();
    } catch (error) {
      console.error("fetch", error);
      return Promise.reject(error);
    }
  }

  async sendNoAuth(method, url, controller, data = {}) {
    let callURL = new URL(url, this.baseURL).href;

    let body;

    const headers = {
      "User-Agent": "bynder-js-sdk/2.3.9",
    };

    if (method === "POST") {
      headers["Content-Type"] = "application/x-www-form-urlencoded";

      body = new URLSearchParams(data).toString();
    } else if (Object.keys(data).length && data.constructor === Object) {
      callURL = new URL(
        url + "?" + new URLSearchParams(data).toString(),
        this.baseURL
      ).href;
    }

    return fetch(callURL, {
      method,
      headers,
      body,
      mode: "cors",
      signal: controller?.signal,
    }).then((x) => x.json());
  }
}

const bodyTypes = {
  BLOB: "BLOB",
  STREAM: "STREAM",
  /**
   * @param {Object} body - The file body whose type we need to determine
   * @return {string} One of bodyTypes.BLOB, bodyTypes.STREAM
   */
  get: (body) => {
    if (
      typeof window !== "undefined" &&
      window.Blob &&
      body instanceof window.Blob
    ) {
      return bodyTypes.BLOB;
    }
    if (typeof body.read === "function") {
      return bodyTypes.STREAM;
    }
    return null;
  },
};

/**
 * @return {number} length - The amount of data that can be read from the file
 */

function getLength(file) {
  const { body, length } = file;
  const bodyType = bodyTypes.get(body);
  if (bodyType === bodyTypes.BLOB) {
    return body.size;
  }
  return length;
}

/**
 * @classdesc Represents the Bynder SDK. It allows the user to make every call to the API with a single function.
 * @class
 */
class Bynder {
  /**
   * Create Bynder SDK.
   * @constructor
   * @param {String} options.baseURL - The URL with the account domain.
   * @param {Object} options.token
   * @param {String} options.permanentToken
   * @param {Object} options - An object containing the consumer keys, access keys and the base URL.
   */
  constructor(options) {
    this.baseURL = options.baseURL;

    this.api = new APICall(options.baseURL, options.token);

    if (typeof options.permanentToken === "string") {
      this.api.permanentToken = options.permanentToken;
    }
  }

  /**
   * Gets the closest Amazon S3 bucket location to upload to.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/1-get-closest-amazons3-upload-endpoint/get-closest-amazons3-upload-endpoint}
   * @return {Promise} Amazon S3 location url string.
   */
  getClosestUploadEndpoint(controller) {
    return this.api.sendNoAuth("GET", "upload/endpoint", controller);
  }

  /**
   * Starts the upload process. Registers a file upload with Bynder and returns authorisation information to allow
   * uploading to the Amazon S3 bucket-endpoint.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/2-initialise-upload/initialise-upload}
   * @param {String} filename - filename
   * @param {Object} controller - controller
   * @return {Promise} Relevant S3 file information, necessary for the file upload.
   */
  initUpload(filename, controller) {
    if (!filename) {
      return rejectValidation("upload", "filename");
    }
    return this.api.sendNoAuth("POST", "upload/init", controller, { filename });
  }

  /**
   * Registers a temporary chunk in Bynder.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/3-upload-file-in-chunks-and-register-every-uploaded-chunk/register-uploaded-chunk}
   * @param {Object} init - result from init upload
   * @param {Number} chunkNumber - chunk number
   * @param {Object} controller - controller
   * @return {Promise}
   */
  registerChunk(init, chunkNumber, controller) {
    const { s3file, s3_filename: filename } = init;
    const { uploadid, targetid } = s3file;
    return this.api.send("POST", "v4/upload/", controller, {
      id: uploadid,
      targetid,
      filename: `${filename}/p${chunkNumber}`,
      chunkNumber,
    });
  }

  /**
   * Finalises the file upload when all chunks finished uploading and registers it in Bynder.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/4-finalise-a-completely-uploaded-file/finalise-a-completely-uploaded-file}
   * @param {Object} init - Result from init upload
   * @param {String} filename - Original file name
   * @param {Number} chunks - Number of chunks
   * @param {String} [mediaId] - Media ID of the asset the file will be added to as additional
   * @return {Promise}
   */
  finaliseUpload(init, filename, chunks, mediaId, controller) {
    const { s3file, s3_filename: s3filename } = init;
    const { uploadid, targetid } = s3file;
    const payload = {
      targetid,
      s3_filename: `${s3filename}/p${chunks}`,
      chunks,
    };
    if (mediaId) {
      const finalizeUrl = `v4/media/${mediaId}/save/additional/${uploadid}`;
      return this.api.send("POST", finalizeUrl, controller, payload);
    } else {
      return this.api.send("POST", `v4/upload/${uploadid}/`, controller, {
        ...payload,
        original_filename: filename,
      });
    }
  }

  /**
   * Checks if the files have finished uploading.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/5-poll-processing-state-of-finalised-files/retrieve-entry-point}
   * @param {String[]} importIds - The import IDs of the files to be checked.
   * @param {Object} controller - controller
   * @return {Promise}
   */
  pollUploadStatus(importIds, controller) {
    return this.api.send("GET", "v4/upload/poll/", controller, {
      items: importIds.join(","),
    });
  }

  /**
   * Resolves once assets are uploaded, or rejects after 60 attempts with 2000ms between them
   * @param {String[]} importIds - The import IDs of the files to be checked.
   * @param {Object} controller - controller
   * @return {Promise}
   */
  waitForUploadDone(importIds, controller) {
    const POLLING_INTERVAL = 2000;
    const MAX_POLLING_ATTEMPTS = 60;
    const pollUploadStatus = this.pollUploadStatus.bind(this);
    return new Promise((resolve, reject) => {
      let attempt = 0;
      (function checkStatus() {
        pollUploadStatus(importIds, controller)
          .then((pollStatus) => {
            if (pollStatus !== null) {
              const { itemsDone, itemsFailed } = pollStatus;
              if (itemsDone.length === importIds.length) {
                // done !
                return resolve({ itemsDone });
              }
              if (itemsFailed.length > 0) {
                // failed
                return reject({ itemsFailed });
              }
            }
            if (++attempt > MAX_POLLING_ATTEMPTS) {
              // timed out
              return reject(new Error(`Stopped polling after ${attempt} attempts`));
            }
            return setTimeout(checkStatus, POLLING_INTERVAL);
          })
          .catch(reject);
      })();
    });
  }

  /**
   * Saves a media asset in Bynder. If media id is specified in the data a new version of the asset will be saved.
   * Otherwise a new asset will be saved.
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets/4-finalise-a-completely-uploaded-file/save-as-a-new-asset}
   * @param {Object} data - Asset data
   * @return {Promise}
   */
  saveAsset(data, controller) {
    const { brandId, mediaId } = data;
    if (!brandId) {
      return rejectValidation("upload", "brandId");
    }
    const saveURL = mediaId ? `v4/media/${mediaId}/save/` : "v4/media/save/";

    return this.api.send("POST", saveURL, controller, {
      ...data,
      // This is required to return the "original" property in the media response
      isPublic: true,
      tags: window.REACT_APP_PROGRAM,
    });
  }

  /**
   * Uploads arbirtrarily sized buffer or stream file to provided S3 endpoint in chunks and registers each chunk to Bynder.
   * Resolves the passed init result and final chunk number.
   * @param {Object} file ={} - An object containing the id of the desired collection.
   * @param {String} file.filename - The file name of the file to be saved
   * @param {Buffer|Readable} file.body - The file to be uploaded. Can be either buffer or a read stream.
   * @param {Number} file.length - The length of the file to be uploaded
   * @param {string} endpoint - S3 endpoint url
   * @param {Object} init - Result from init upload
   * @param {progressCallback} [progressCallback] - Function which is called anytime there is a progress update
   * @return {Promise}
   */
  uploadFileInChunks(file, endpoint, init, progressCallback, controller) {
    const { body } = file;
    const bodyType = bodyTypes.get(body);
    const length = getLength(file);
    const CHUNK_SIZE = 1024 * 1024 * 5;
    const chunks = Math.ceil(length / CHUNK_SIZE);

    const registerChunk = this.registerChunk.bind(this);
    const uploadPath = init.multipart_params.key;

    const uploadChunkToS3 = (chunkData, chunkNumber) => {
      const form = new FormData();
      const params = Object.assign(init.multipart_params, {
        name: `${new URL("protocol:" + uploadPath).pathname
          .split("/")
          .pop()}/p${chunkNumber}`,
        chunk: chunkNumber,
        chunks,
        Filename: `${uploadPath}/p${chunkNumber}`,
        key: `${uploadPath}/p${chunkNumber}`,
      });
      Object.keys(params).forEach((key) => {
        form.append(key, params[key]);
      });
      form.append("file", chunkData);

      const { signal } = controller;
      return fetch(endpoint, {
        method: "POST",
        body: form,
        signal,
      });
    };

    function delay(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }

    progressCallback({
      action: "Uploading file",
      completed: "Initializing",
      chunksUploaded: 0,
      chunks,
    });

    // sequentially upload chunks to AWS, then register them
    function nextChunk(chunkNumber) {
      if (chunkNumber >= chunks) {
        return Promise.resolve({ init, chunkNumber });
      }
      let chunkData;
      if (bodyType === bodyTypes.STREAM) {
        // handle stream data
        chunkData = body.read(CHUNK_SIZE);
        if (chunkData === null) {
          // our read stream is not done yet reading
          // let's wait for a while...
          return delay(50).then(() => {
            progressCallback({
              action: "Uploading file",
              completed: "Initializing",
              chunksUploaded: chunkNumber,
              chunks,
            });
            return nextChunk(chunkNumber);
          });
        }
      } else {
        // handle buffer/blob data
        const start = chunkNumber * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, length);
        chunkData = body.slice(start, end);
      }
      const newChunkNumber = chunkNumber + 1;
      return uploadChunkToS3(chunkData, newChunkNumber)
        .then(() => {
          return registerChunk(init, newChunkNumber, controller);
        })
        .then(() => {
          progressCallback({
            action: "Uploading file",
            completed: "Initializing",
            chunksUploaded: chunkNumber,
            chunks,
          });
          return nextChunk(newChunkNumber);
        });
    }
    return nextChunk(0);
  }

  /**
   * Callback for adding two numbers.
   *
   * @callback progressCallback
   * @param {Object} state={} - An object containing the progress state
   * @param {String} state.action - The next action
   * @param {String} [state.completed] - The last completed action
   * @param {Number} [state.chunks] - Total amount of chunks
   * @param {Number} state.chunksUploaded - Amount of chunks already uploaded
   */
  /**
   * Uploads an arbitrarily sized buffer or stream file and returns the uploaded asset information
   * @see {@link https://bynder.docs.apiary.io/#reference/upload-assets}
   * @param {Object} file={} - An object containing the id of the desired collection.
   * @param {String} file.filename - The file name of the file to be saved
   * @param {Buffer|Readable} file.body - The file to be uploaded. Can be either buffer or a read stream.
   * @param {Number} file.length - The length of the file to be uploaded
   * @param {Object} file.data={} - An object containing the assets' attributes
   * @param {Boolean} file.additional - Boolean that signals if the asset should be added as additional to an existing asset
   * @param {progressCallback} [progressCallback] - Function which is called anytime there is a progress update
   * @param {Object} controller - controller
   * @return {Promise} The information of the uploaded file, including IDs and all final file urls.
   */
  async uploadFile(file, progressCallback = () => {}, controller) {
    const { body, filename, data, additional } = file;
    const { brandId } = data;
    const bodyType = bodyTypes.get(body);
    const length = getLength(file);

    if (!brandId) {
      return rejectValidation("upload", "brandId");
    }
    if (!filename) {
      return rejectValidation("upload", "filename");
    }
    if (!body || !bodyType) {
      return rejectValidation("upload", "body");
    }
    if (!length || typeof length !== "number") {
      return rejectValidation("upload", "length");
    }
    if (additional && !data.id) {
      return rejectValidation("upload", "id");
    }

    const getClosestUploadEndpoint = this.getClosestUploadEndpoint.bind(this);
    const initUpload = this.initUpload.bind(this);
    const uploadFileInChunks = this.uploadFileInChunks.bind(this);
    const finaliseUpload = this.finaliseUpload.bind(this);
    const saveAsset = this.saveAsset.bind(this);
    const waitForUploadDone = this.waitForUploadDone.bind(this);
    let totalChunks;

    progressCallback({
      action: "Initializing",
      chunksUploaded: 0,
    });
    return Promise.all([
      getClosestUploadEndpoint(controller),
      initUpload(filename, controller),
    ])
      .then((res) => {
        const [endpoint, init] = res;
        return uploadFileInChunks(
          file,
          endpoint,
          init,
          progressCallback,
          controller
        );
      })
      .then((uploadResponse) => {
        const { init, chunkNumber } = uploadResponse;
        totalChunks = chunkNumber;
        progressCallback({
          action: "Finalizing upload",
          completed: "Uploading file",
          chunksUploaded: chunkNumber,
          chunks: chunkNumber,
        });
        return finaliseUpload(
          init,
          filename,
          chunkNumber,
          additional ? data.id : null,
          controller
        );
      })
      .then((finalizeResponse) => {
        if (additional) {
          return Promise.resolve(finalizeResponse);
        }
        const { importId } = finalizeResponse;
        return waitForUploadDone([importId], controller);
      })
      .then((doneResponse) => {
        if (additional) {
          return Promise.resolve(doneResponse);
        }
        const { itemsDone } = doneResponse;
        const importId = itemsDone[0];
        progressCallback({
          action: "Saving asset",
          completed: "Finalizing upload",
          chunksUploaded: totalChunks,
          chunks: totalChunks,
        });
        return saveAsset(
          Object.assign(data, { importId, isPublic: true }),
          controller
        );
      });
  }

  /**
   * Get all assets from Bynder given a collection id.
   * @see {@link https://bynder.docs.apiary.io/#reference/assets/asset-collection/get-assets-from-a-collection}
   * @param {String} collectionId - The id of the desired collection.
   * @return {Promise} The assets from the collection.
   * @param {Object} controller - controller
   * @return {Promise}
   * @throws {Error} If the collectionId is not provided.
   */
  getAssetsByCollection(collectionId, controller) {
    if (!collectionId) {
      return rejectValidation("collection", "id");
    }
    return this.api.send(
      "GET",
      `v4/media/?collectionId=${collectionId}&orderBy=name%20asc&limit=250&page=1&count=1&total=1`,
      controller
    );
  }

  /**
   * Get all assets from Bynder given a tag.
   * @see {@link https://bynder.docs.apiary.io/#reference/assets/asset-collection/get-assets-from-a-collection}
   * @param {String} tag - The tag of the desired collection.
   * @return {Promise} The assets from the collection.
   * @param {Object} controller - controller
   * @return {Promise}
   * @throws {Error} If the tag is not provided.
   */
  getAssetsByTag(tag, controller) {
    if (!tag) {
      return rejectValidation("tag", "name");
    }
    return this.api.send(
      "GET",
      `v4/media/?tags=${tag}&orderBy=dateCreated%20desc&limit=250&page=1&count=1&total=1`,
      controller
    );
  }

  /**
   * Get the assets information according to the id provided.
   * @see {@link http://docs.bynder.apiary.io/#reference/assets/specific-asset-operations/retrieve-specific-asset|API Call}
   * @param {Object} params - An object containing the id and the version of the desired asset.
   * @param {String} params.id - The id of the desired asset.
   * @param {Boolean} [params.versions] - Whether to include info about the different asset versions.
   * @return {Promise} Asset - Returns a Promise that, when fulfilled, will either return an Object with the asset or
   * an Error with the problem.
   */
  getMediaInfo({ id, ...options } = {}) {
    if (!id) {
      return rejectValidation("media", "id");
    }

    return this.api.send("GET", `v4/media/${id}/`, options);
  }
}

export default Bynder;
