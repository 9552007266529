import { Icon } from "pepsico-ds";
import "./_sweepstakes-status-badge.scss";

const SweepstakesStatusBadge = ({ status }) => {
  const hasEnded = status.toLowerCase() === "ended";
  return (
    <div
      className={`status ${status.toLowerCase()}`}
      data-testid="sweepstakes-status-badge"
    >
      {!hasEnded
        ? status.charAt(0).toUpperCase() + status.slice(1)
        : "Winners Selected"}
      {hasEnded && <Icon icon="check" size="small" />}
    </div>
  );
};

export default SweepstakesStatusBadge;
