import { gql } from "@apollo/client";

export const updateReward = gql`
  mutation UpdateReward($id: ID!, $input: RewardUpdateInput!) {
    updateReward(id: $id, input: $input) {
      id
      name
      partnerId
      programId
      isActive
    }
  }
`;
