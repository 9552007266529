import WidgetEmbedCode from "../../../../components/common/widgetEmdedCode/WidgetEmbedCode";
import { isLatamCampaign } from "../../../../utils/commons";
import SummaryCard from "./SummaryCard";
const AdditionalField = ({ label, campaignData, widgetType }) => {
  if (!campaignData) {
    return null;
  }
  return (
    <div className="field-pair mr-1">
      <h5 className="mb-2 field-pair">{label}</h5>
      <div className="ml-3 additional-fields-container">
        <div className="field-pair fs-12">
          <span className="field-label fs-12">Embed Code:</span>
        </div>

        <div className="field-pair-two-column">
          <div className="widget-code">
            <WidgetEmbedCode campaign={campaignData} widgetType={widgetType} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryWidgetEmbedCode = (props) => {
  const { data, isViewScreen } = props;
  // Return null if the campaign type is not LATAM
  if (!isLatamCampaign(data)) return null;
  return (
    <SummaryCard title="Widgets" isViewScreen={isViewScreen}>
      <AdditionalField
        label="Campaign Widget"
        campaignData={data}
        widgetType={data?.type}
      />
    </SummaryCard>
  );
};
export default SummaryWidgetEmbedCode;
