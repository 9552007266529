import { gql } from "@apollo/client";

export const listRewardCategories = gql`
  query ListRewardCategories {
    listRewardCategories {
      items {
        id
        name
      }
    }
  }
`;
