import { gql } from "@apollo/client";

export const listRewardUnits = gql`
  query ListRewardUnits($filter: RewardUnitFilter) {
    listRewardUnits(filter: $filter) {
      items {
        id
        name
        unit
      }
    }
  }
`;
