import { gql } from "@apollo/client";
// size: 300 is added to avoid pagination in dropdown
const getListPartners = gql`
  query ListPartners($filter: PartnerFilter!) {
    listPartners(filter: $filter, size: 300) {
      items {
        id
        name
      }
    }
  }
`;

export default getListPartners;
