import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, LoadingSpinner } from "pepsico-ds";
import { useContext } from "react";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { getDateWithFormat, returnPlaceHolderIfEmpty } from "../../utils/commons";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../utils/constants";
import "./codeGroupViewModal.scss";

/**
 * CodeGroupViewModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {boolean} props.loadCodeGroupLoader - Flag indicating whether the code group data is loading.
 * @params {Object} props.codeGroupData - The code group data.
 * @returns {JSX.Element} The CodeGroupViewModal component.
 */

const RenderFieldPair = ({ label, value, children }) => (
  <div className="common-field-pair">
    <span className="common-field-label">{label}</span>
    {children ? (
      children
    ) : (
      <span className="common-field-value">
        {returnPlaceHolderIfEmpty(value, "-")}
      </span>
    )}
  </div>
);
export default function CodeGroupViewModal({
  showModal,
  setShowModal,
  setCodeGroupData,
  loadCodeGroupLoader,
  codeGroupData,
  handleEditFromView,
}) {
  const { programTimezone } = useContext(GlobalConfigContext);

  const onCloseModal = () => {
    setShowModal((prev) => ({ ...prev, viewModal: false }));
    setCodeGroupData({});
  };

  return (
    <Dialog
      className="createProduct-modal"
      open={showModal.viewModal}
      scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          minWidth: "50% !important",
          maxWidth: "60% !important",
        },
      }}
    >
      <DialogTitle sx={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2
              data-testid="code-group-view-modal"
              style={{ marginLeft: "18%", textAlign: "center" }}
            >
              Code Group Details
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={onCloseModal}
              className="cursor-pointer"
              data-testid="closeIcon"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      {!loadCodeGroupLoader ? (
        <DialogContent
          sx={{
            overflowX: "hidden",
            padding: "0px",
          }}
        >
          <div key="basic-details" className="details-container">
            <h4 className="details-container-header">Basic Details</h4>
            <div className="details-container-content-wrapper">
              <div className="common-fields-container">
                <RenderFieldPair
                  label="Code Group Name"
                  value={codeGroupData.codeGroupName}
                />
                <RenderFieldPair
                  label="Active Start Date"
                  value={getDateWithFormat(
                    codeGroupData.codeActiveStartDate,
                    programTimezone
                  )}
                />
                <RenderFieldPair
                  label="Active End Date"
                  value={
                    codeGroupData.codeActiveEndDate && programTimezone
                      ? getDateWithFormat(
                          codeGroupData.codeActiveEndDate,
                          programTimezone
                        )
                      : "N/A"
                  }
                />
              </div>
            </div>
          </div>
          <div key="producer-packaging" className="details-container">
            <h4 className="details-container-header">Producer and Packaging</h4>
            <div className="details-container-content-wrapper">
              <div className="common-fields-container">
                <RenderFieldPair label="Brand" value={codeGroupData.brand} />
                <RenderFieldPair
                  label="Producer Company"
                  value={codeGroupData.producerCompany}
                />
                <RenderFieldPair
                  label="Codes Printed"
                  value={codeGroupData.codesPrintedCount}
                />
                <RenderFieldPair
                  label="Product's Volume"
                  value={codeGroupData.productVolume}
                />
                <RenderFieldPair
                  label="Package Type"
                  value={codeGroupData.packageType}
                />
                <RenderFieldPair
                  label="Product's Cap Color"
                  value={codeGroupData.productCapColor}
                />
              </div>
            </div>
          </div>
          <div key="code-specifications" className="details-container">
            <h4 className="details-container-header">Code Specifications</h4>
            <div className="details-container-content-wrapper">
              <div className="common-fields-container">
                <RenderFieldPair label="Prefix" value={codeGroupData.prefixVal} />
                <RenderFieldPair label="Suffix" value={codeGroupData.suffixVal} />
                <RenderFieldPair
                  label="Case Sensitive"
                  value={codeGroupData.caseSensitive ? "Yes" : "No"}
                />
                <RenderFieldPair
                  label="Exclude characters from code"
                  value={codeGroupData.excludeCharacterValues}
                />
              </div>
            </div>
          </div>
          <div key="dummy-codes" className="details-container">
            <h4 className="details-container-header">Dummy Codes</h4>
            <div className="details-container-content-wrapper">
              <div className="common-fields-container">
                <RenderFieldPair
                  label="Dummy Code"
                  value={codeGroupData.dummyCodeMessage}
                />
                <RenderFieldPair
                  label="Dummy Code Percent"
                  value={codeGroupData.dummyCodePercent}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className="display-flex align-items-center justify-content-center">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{ height: "50px", width: "50px" }}
          />
        </DialogContent>
      )}
      {[
        CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE,
        CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE,
        CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT,
      ].includes(codeGroupData.lifecycleStatus) && (
        <DialogActions className="mt-5 display-flex justify-content-end align-items-center">
          <Button
            className="justify-content-space-around"
            iconTrailing="arrow_forward"
            onClick={handleEditFromView}
          >
            Edit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
