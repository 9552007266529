import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { BrowserRouter } from "react-router-dom";
import LoaderNew from "./components/common/LoderNew";
import { GrowthbookWrapper } from "./components/growthbook/GrowthbookWrapper";
import { GlobalConfigProvider } from "./context/GlobalConfigContext";
import { LoaderProvider } from "./context/LoaderContext";
import Router from "./routes";
import { growthbook } from "./utils/growthbook/growthbook";
import { oktaAuth } from "./utils/oktaAuth";

function App() {
  const triggerLogin = async () => {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin();
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <LoaderProvider>
        <GlobalConfigProvider>
          <LoaderNew />
          <GrowthBookProvider growthbook={growthbook}>
            <GrowthbookWrapper>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </GrowthbookWrapper>
          </GrowthBookProvider>
        </GlobalConfigProvider>
      </LoaderProvider>
    </Security>
  );
}

export default App;
