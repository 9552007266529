import { composePaths, createDefaultValue } from "@jsonforms/core";
import { JsonFormsDispatch, withJsonFormsArrayControlProps } from "@jsonforms/react";
import { useCallback } from "react";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./array-of-fields.scss";

const ArrayOfFieldsComponent = (props) => {
  const { data, addItem, removeItems, path, schema, uischema, renderers, label } =
    props;
  const childUiSchema = uischema.options?.detail;
  const isDeleteDisabled = uischema.readonly || uischema.disabled;
  const addItemCallback = useCallback(
    (path, value) => addItem(path, value),
    [addItem]
  );
  const removeItemsCallback = useCallback(
    (path, toDelete) => removeItems(path, toDelete),
    [removeItems]
  );

  return (
    <div className="array-of-fields-wrapper">
      <div key={composePaths(path, "0")} className="array-of-fields-item">
        <JsonFormsDispatch
          path={composePaths(path, "0")}
          renderers={renderers}
          schema={schema}
          uischema={childUiSchema}
        />
      </div>
      {data?.map((_, index) => {
        if (index === 0) return null;
        const childPath = composePaths(path, `${index}`);
        return (
          <div key={childPath} className="array-of-fields-item">
            <JsonFormsDispatch
              path={childPath}
              renderers={renderers}
              schema={schema}
              uischema={childUiSchema}
            />
            {!isDeleteDisabled && (
              <ButtonGroupDynamic
                className="delete-item-button"
                size="small"
                items={[
                  {
                    icon: "delete",
                    disabled: isDeleteDisabled,
                    onClick: removeItemsCallback(path, [index]),
                  },
                ]}
              />
            )}
          </div>
        );
      })}
      {!isDeleteDisabled && (
        <div className="array-of-fields-item add-section">
          <ButtonGroupDynamic
            size="small"
            items={[
              {
                icon: "add",
                onClick: addItemCallback(path, createDefaultValue(schema)),
              },
            ]}
          />
          <span className="add-item-label">{label}</span>
        </div>
      )}
    </div>
  );
};

export const ArrayOfFieldsControl = withJsonFormsArrayControlProps(
  ArrayOfFieldsComponent
);
