import { useMutation, useQuery } from "@apollo/client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import updateProgramConfiguration from "../graphql/mutations/updateProgramConfiguration";
import { loadUiComponent } from "../graphql/queries/loadUiComponent";
import { GlobalConfigContext } from "./GlobalConfigContext";

// Create the  EditWidgetContext
const EditWidgetContext = createContext();

// Create the EditWidgetProvider component
const EditWidgetProvider = ({ children }) => {
  // Define the initial state
  const mainState = {
    survey_card: {
      display: false,
      position: null,
    },
    profile_card: {
      display: false,
      position: null,
      number_of_days_to_show: null,
    },
    po1_pending_completion_card: {
      display: false,
      position: null,
      number_of_days_to_show: null,
    },
    reward_card: {
      display: false,
      position: null,
      number_of_days_to_show: null,
    },
    email_card: {
      display: false,
      position: null,
      number_of_days_to_show: null,
    },
    collect_and_win_pending_completion_card: {
      display: false,
      position: null,
      number_of_days_to_show: null,
    },
  };

  const initialState = useMemo(() => mainState, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Create the state using useState
  const [widgetState, setWidgetState] = useState(initialState);
  const [load, setLoad] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [successTitle, setSuccessTitle] = useState({});
  const [uiSchema, setUiSchema] = useState(null);
  const [jsonSchema, setJsonSchema] = useState(null);
  const {
    updateProgramConfigurations,
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const { refetch } = useQuery(loadUiComponent, {
    variables: { slug: "widgets-configuration" },
    skip: true,
  });

  const [updateProgramConfigurationMutation] = useMutation(
    updateProgramConfiguration
  );

  const handleSaveEditWidget = useCallback(() => {
    setLoad(true);
    const params = {
      variables: {
        input: {
          slug: "widgets_configuration",
          data: JSON.stringify(widgetState),
        },
        id: programConfig?.id,
      },
    };
    updateProgramConfigurationMutation(params)
      .then((res) => {
        if (res?.data?.updateProgramConfiguration) {
          updateProgramConfigurations(
            res?.data?.updateProgramConfiguration?.configurations
          );
          setSuccessTitle({
            title: "Success",
            id: "",
          });
          setWidgetState(initialState);
          setSuccessMsg("success");
          setLoad(false);
        }
      })
      .catch((err) => {
        const tempErr = [];
        const errorMessage =
          err?.graphQLErrors?.[0]?.message ||
          "Failed to save the Widgets. Please try again later.";
        tempErr.push(errorMessage);
        setErrMsg(tempErr);
        setLoad(false);
      });
  }, [widgetState, programConfig, updateProgramConfigurationMutation]); // eslint-disable-line react-hooks/exhaustive-deps

  const callTogetschemas = async () => {
    const res = await refetch();
    if (res?.data?.loadUiComponent) {
      setUiSchema(res?.data?.loadUiComponent?.uiSchema);
      setJsonSchema(res?.data?.loadUiComponent?.inputSchema);
    }
  };

  useEffect(() => {
    callTogetschemas();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Create the context value
  const contextValue = useMemo(
    () => ({
      initialState,
      widgetState,
      load,
      jsonSchema,
      uiSchema,
      errMsg,
      successMsg,
      successTitle,
      setSuccessTitle,
      setSuccessMsg,
      setErrMsg,
      setLoad,
      setWidgetState,
      handleSaveEditWidget,
    }),
    [
      initialState,
      widgetState,
      load,
      jsonSchema,
      uiSchema,
      errMsg,
      successMsg,
      successTitle,
      setSuccessTitle,
      setSuccessMsg,
      setErrMsg,
      setLoad,
      setWidgetState,
      handleSaveEditWidget,
    ]
  );

  // Render the provider with the context value and children
  return (
    <EditWidgetContext.Provider value={contextValue}>
      {children}
    </EditWidgetContext.Provider>
  );
};

export { EditWidgetContext, EditWidgetProvider };
