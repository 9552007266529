import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../../context/RewardContext";
import getPartnerCodeGroups from "../../../../graphql/queries/listPartnerCodeGroups";

const PartnerCodeGroupSelectComponent = ({
  handleChange,
  label,
  data,
  path,
  uischema,
  required,
  enabled,
}) => {
  const { rewardState, addOptionLoading, removeOptionLoading, setToast } =
    useContext(RewardContext);
  const { refetch } = useQuery(getPartnerCodeGroups, {
    skip: true,
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        active: true,
      },
    },
  });
  const [partnerCodeGroupsList, setPartnerCodeGroupsList] = useState([]);
  const [selectedPartnerCodeGroup, setSelectedPartnerCodeGroup] = useState([
    { displayText: "" },
  ]);

  useEffect(() => {
    if (rewardState?.partnerId && rewardState?.partnerCodeType) {
      addOptionLoading("partnerCodeGroups");
      refetch({
        filter: { partnerId: rewardState.partnerId, active: true },
        size: 1000,
      })
        .then(({ data: parnerCodeGroupsData, loading }) => {
          !loading && removeOptionLoading("partnerCodeGroups");
          if (parnerCodeGroupsData?.listPartnerCodeGroups) {
            const partnerCodeGroupsListOptions =
              parnerCodeGroupsData.listPartnerCodeGroups.items?.map(
                (partnerCodeGroup) => ({
                  id: partnerCodeGroup.id,
                  displayText: partnerCodeGroup.name,
                })
              );
            setPartnerCodeGroupsList(partnerCodeGroupsListOptions);
          }
        })
        .catch(() => {
          removeOptionLoading("partnerCodeGroups");
          setPartnerCodeGroupsList([]);
          setToast({
            open: true,
            type: "error",
            title: "Something went wrong while loading partner code groups.",
            description: "Please try again later.",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardState?.partnerId, rewardState?.partnerCodeType, refetch]);

  useEffect(() => {
    if (partnerCodeGroupsList.length) {
      const selectedPartnerCodeGroupOption = partnerCodeGroupsList.find(
        (type) => type.id === data
      );
      setSelectedPartnerCodeGroup([
        selectedPartnerCodeGroupOption ?? { displayText: "" },
      ]);
    }
  }, [data, partnerCodeGroupsList]);

  const updateValue = (val) => handleChange(path, val?.[0]?.id || "");
  const diabledControl = !enabled || uischema?.readonly ? "disabled-control" : "";

  return (
    <Dropdown
      isRequired={required}
      childList={partnerCodeGroupsList}
      disabled={!enabled || uischema.readonly || uischema.disabled}
      style={{ minWidth: "0px" }}
      className={`zIndexToDropDown ${diabledControl}`}
      label={label}
      selection="single"
      selectedValue={selectedPartnerCodeGroup}
      setSelectedValue={updateValue}
      size={uischema.size || "medium"}
      placeholder={uischema.placeholder || ""}
    />
  );
};

export const PartnerCodeGroupSelectControl = withJsonFormsControlProps(
  PartnerCodeGroupSelectComponent
);
