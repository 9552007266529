import SummaryCard from "./SummaryCard";

let languageMap = new Intl.DisplayNames(["en"], { type: "language" });
const languageShortName = (language) => {
  return languageMap.of(language.split("-")[0]);
};

function mapDefinitionToDataForAllLanguages(definition, data) {
  const languages = Object.keys(data.l10n); // Get all configured languages
  const result = {};

  languages.forEach((lang) => {
    const localeData = data.l10n[lang]; // Extract data for the current language
    result[lang] = Object.keys(definition).map((key) => {
      const title = definition[key].title; // Get the title from the definition
      const value = localeData[key] || "-"; // Get the value from the data or fallback to "-"
      return { title, value, key };
    });

    // if key contains image_url, then keep that record at the last
    result[lang].sort((a, b) => {
      if (a.key.toLowerCase().includes("image_url")) {
        return 1;
      }
      if (b.key.toLowerCase().includes("image_url")) {
        return -1;
      }
      return 0;
    });
  });

  return result;
}

const isImageURL = (record) => {
  return record.key.toLowerCase().includes("image_url") && record.value !== "-";
};

const renderFieldValue = (record) => {
  const isLink = record.key.toLowerCase().includes("link");
  const isImage = isImageURL(record);

  if (isLink) {
    return (
      <span className="field-value">
        <a href={record.value} target="_blank" rel="noopener noreferrer">
          {record.value}
        </a>
      </span>
    );
  }

  if (isImage) {
    return (
      <img
        src={record.value}
        alt={record.title || "image"}
        style={{ width: "120px", borderRadius: "8px" }}
      />
    );
  }

  return <span className="field-value">{record.value}</span>;
};

const LocalizationList = ({
  data,
  editSectionHandler,
  contentSchema,
  isViewScreen,
}) => {
  let result = mapDefinitionToDataForAllLanguages(
    contentSchema?.definitions?.localized_content?.properties,
    data
  );

  return (
    <SummaryCard
      title="Localization Content"
      handleEditSection={editSectionHandler}
      isViewScreen={isViewScreen}
    >
      {Object.entries(result).map(([lang, content]) => {
        const language = languageShortName(lang) || lang; // Fallback to lang if mapping not found

        return (
          <div key={lang} className="language-section">
            <h5 className="mb-3">{language}</h5>
            {Object.values(content).map((record) => {
              return (
                <div key={record.key} className="ml-3 additional-fields-container">
                  {record.title && (
                    <>
                      <div className="field-pair">
                        <span className="field-label">{record.title}:</span>
                      </div>
                      <div className="field-pair-two-column">
                        {renderFieldValue(record)}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </SummaryCard>
  );
};

export default LocalizationList;
