import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TextField } from "@mui/material";
import * as React from "react";
import "./styles.scss";

export default function TextAreaField(props) {
  const {
    value,
    updateValue,
    label,
    errors,
    uiSchema,
    required,
    placeholder,
    disabled,
    maxRows = 6,
    minRows = 3,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const getMaxLength = () => {
    if (uiSchema?.isCustomBannerTextArea) {
      return value.includes("{{user.username}}") ? 60 : 55;
    }
    return uiSchema?.maxLength;
  };

  const getValWithFixedLength = (value) => {
    return value?.length > getMaxLength()
      ? value.slice(0, getMaxLength() - value.length)
      : value;
  };

  return (
    <div>
      <div className="input-header">
        <span className="label-text $font-xsmall-regular">
          <label htmlFor="content-title">{label}</label>
        </span>
        {required && (
          <span className="required-text $font-xxsmall-bold float-right">
            *Required
          </span>
        )}
      </div>
      <TextField
        id="outlined-multiline-flexible"
        multiline
        inputProps={{
          maxLength: getMaxLength(),
          readOnly: uiSchema?.readonly,
          "data-testid": "json-forms-textarea",
        }}
        maxRows={maxRows}
        minRows={minRows}
        value={getValWithFixedLength(value)}
        disabled={disabled || uiSchema?.disabled}
        placeholder={placeholder}
        onChange={(e) => {
          const value = e.target.value;
          updateValue(value === "" ? "" : getValWithFixedLength(value));
        }}
        onBlur={() => {
          const trimmedValue = value?.trim();
          updateValue(trimmedValue);
          setIsFocused(true);
        }}
        error={isFocused && !!errors}
        size="small"
        style={{ width: "100%" }}
        sx={{
          fontSize: "14px",
          " & .MuiInputBase-root": {
            padding: "8px !important",
            fontSize: "14px !important",
          },
        }}
      />
      {isFocused && errors && (
        <div
          className="feedback $font-xsmall-regular error display-flex align-items-center gap-1"
          role="alert"
        >
          <ErrorOutlineIcon sx={{ color: "#d40020", fontSize: "16px" }} />
          <span>{errors}</span>
        </div>
      )}
    </div>
  );
}
