import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import { InputItem, Pagination } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import JsonFormsWrapper from "../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { getSegmentsPopulation } from "../../graphql/queries/getSegmentsPopulation";
import { useSegments } from "../../hooks/useSegments";
import {
  segmentationFilterJsonSchema,
  segmentationFilterUISchema,
} from "../../pages/organizations/Segmentation/mocks";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import SegmentCard from "./SegmentCard/SegmentCard";
import SegmentListTable from "./SegmentListTable/SegmentListTable";
import "./segmentation.scss";

const SegmentsListTab = () => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20; // Default page size

  const [filters, setFilters] = useState({
    status: null,
    population: {
      from: null,
      to: null,
    },
    keyword: null,
  });

  const { segments, loading, error } = useSegments(filters);
  const { data: populationData } = useQuery(getSegmentsPopulation);

  // Dynamic min, max, and step values for population
  const [dynamicMinMax, setDynamicMinMax] = useState({});

  useEffect(() => {
    if (populationData && populationData.listSegmentsWithPopulationRange) {
      const { minPopulationRange, maxPopulationRange } =
        populationData.listSegmentsWithPopulationRange;

      const calculateStep = (min, max) => {
        const range = max - min;
        if (range === 0) return 1; // Handle zero range to avoid errors
        const exponent = Math.floor(Math.log10(range));
        const step = Math.pow(10, exponent - 1);
        return Math.max(step, 1); // Ensure minimum step size of 1
      };

      const step = calculateStep(minPopulationRange, maxPopulationRange);

      setDynamicMinMax({
        population: {
          min: minPopulationRange,
          max: maxPopulationRange,
          step: step,
        },
      });
    } else {
      // No population data or empty object
      setDynamicMinMax({
        population: {
          min: 0,
          max: 10000000,
          step: 1000000,
        },
      });
    }
  }, [populationData]);

  // Calculate the total number of pages
  const numPages = Math.ceil(segments.length / rowsPerPage);

  // Get the data for the current page
  const currentData = segments.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex > 0 && newPageIndex <= numPages) {
      setCurrentPage(newPageIndex);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const debouncedSearchChange = useMemo(() => {
    return debounce((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        keyword: value,
      }));
    }, 1500);
  }, []);
  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel();
    };
  }, [debouncedSearchChange]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedSearchChange(value);
  };

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        ...newFilters,
      };
      if (
        Array.isArray(updatedFilters.status) &&
        updatedFilters.status.length === 1
      ) {
        updatedFilters.status = updatedFilters.status[0];
      } else if (
        Array.isArray(updatedFilters.status) &&
        updatedFilters.status.length === 0
      ) {
        updatedFilters.status = null;
      }
      return updatedFilters;
    });
    setCurrentPage(1);
  };
  const handleClearFilters = () => {
    setFilters({
      status: null,
      population: {
        from: null,
        to: null,
      },
      keyword: null,
    });
    setSearchInput("");
    setCurrentPage(1);
  };

  const renderContent = () => {
    // Handle the loading state
    if (loading) {
      return (
        <div className="segment-loading">
          <CircularProgress />
        </div>
      );
    }

    // Handle the case where there are no segments or there's an error
    if (segments.length === 0 || error) {
      return <div className="no-record-found">No Records Found.</div>;
    }

    // Determine the view type (grid or list) and render the appropriate content
    if (viewType === "grid") {
      return (
        <div className="grid-container">
          {currentData.map((segment) => (
            <div className="grid-item grid-item-3 grid-item-4" key={segment.id}>
              <SegmentCard segment={segment} />
            </div>
          ))}
        </div>
      );
    } else {
      return <SegmentListTable segmentData={currentData} />;
    }
  };

  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="segment-filters-container">
          <InputItem
            placeholder="Search name or description"
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleSearchChange}
            value={searchInput}
          />

          <JsonFormsWrapper
            jsonschema={segmentationFilterJsonSchema}
            uischema={segmentationFilterUISchema}
            data={filters}
            onChange={handleFilterChange}
            config={{ dynamicMinMax }}
          />

          {/* <RangeSliderControl
            data={{ from: filters.populationFrom, to: filters.populationTo }}
            handleChange={handlePopulationChange}
          /> */}
          <p className="clear-all-button" onClick={handleClearFilters}>
            Clear Filter
          </p>
        </div>
        <ButtonGroupDynamic
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {renderContent()}
        <div
          style={{
            padding: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            currentPage={currentPage}
            onUpdate={handlePageChange}
            pageCount={numPages}
            size="medium"
            variant="number"
          />
        </div>
      </div>
    </div>
  );
};

export default SegmentsListTab;
