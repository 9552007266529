import { withJsonFormsControlProps } from "@jsonforms/react";
import ListDropdownFilter from "./ListDropdownFilter";

const ListDropdownFilterComponent = (props) => {
  const { handleChange, label, data, path } = props;
  return (
    <ListDropdownFilter
      selectedValue={data}
      setSelectedValue={(newVal) => handleChange(path, newVal)}
      options={props.schema.options}
      label={label}
    />
  );
};

export const ListDropdownFilterControl = withJsonFormsControlProps(
  ListDropdownFilterComponent
);
