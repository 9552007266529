import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { CustomLayoutRendererControl } from "../CustomLayoutRenderer/CustomLayoutRenderer";

const HorizontalLayout = ({
  schema,
  uischema,
  path,
  renderers,
  cells,
  enabled,
  visible,
  label,
}) => {
  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "row",
    gap: uischema.gap,
    visible: visible,
    uischema: uischema,
    renderers: renderers,
    label: label,
    cells: cells,
    enabled: enabled,
  };
  return (
    <>
      <CustomLayoutRendererControl {...layoutProps} />
    </>
  );
};

export const HorizontalLayoutControl = withJsonFormsLayoutProps(HorizontalLayout);
