import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { RewardGroupCampaignSetup } from "../../campaign/RewardGroupCampaignSetup";

const CampaignAddRewardComponent = (props) => {
  const { handleChange, label, data, path, errors, schema, uischema, required } =
    props;

  const ctx = useJsonForms();
  const jsonformState = ctx?.core?.data;

  return (
    <RewardGroupCampaignSetup
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      otherData={jsonformState}
    />
  );
};

export const CampaignAddRewardControl = withJsonFormsControlProps(
  CampaignAddRewardComponent
);
