import { useState } from "react";
import { useEditPartnerMutation } from "../../Hooks/useEditPartnerMutation";
import PartnerFormModal from "../PartnerForm/PartnerForm";

function EditPartnerModal({ isOpen, closeModal, partner, refetch, id, setToast }) {
  const [partnerData, setPartnerData] = useState(() => partner);
  const [editPartnerMutation, loading] = useEditPartnerMutation();

  const handleCreatePartner = () => {
    const partnerName = partnerData?.name;

    const onSuccess = () => {
      refetch();
      setToast({
        open: true,
        type: "success",
        title: `${partnerName} Edited!`,
        description: "You have just updated a partner!",
      });
      closeModal();
    };
    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error on editing ${partnerName}!`,
        description: error?.message ?? "",
      });
      setPartnerData(partner);
      closeModal();
    };

    editPartnerMutation(onSuccess, onFailure, partnerData, id);
  };

  const onClose = () => {
    setPartnerData(partner);
    closeModal();
  };

  return (
    <PartnerFormModal
      isOpen={isOpen}
      closeModal={onClose}
      partnerData={partnerData}
      setPartnerData={setPartnerData}
      handleMutation={handleCreatePartner}
      loading={loading}
      title="Edit Partner"
      btnTitle="Save"
    />
  );
}

export default EditPartnerModal;
