import { gql } from "@apollo/client";

const createRewardPartnerCodeGroup = gql`
  mutation CreateRewardPartnerCodeGroup($request: PartnerCodeGroupRequest!) {
    createRewardPartnerCodeGroup(request: $request) {
      id
      name
      description
      partnerId
      codeWarningLimitPercentage
      isActive
      startDate
      endDate
      rewardItemFileData {
        id
        name
        size
        type
        url
        metadata {
          description
          tags
          uploadedBy
          uploadedAt
          containerName
        }
      }
    }
  }
`;

export default createRewardPartnerCodeGroup;
