import { withJsonFormsControlProps } from "@jsonforms/react";
import SwitchFieldWithModal from "./SwitchFieldWithModal";

const SwitchWithModalComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;

  return (
    <SwitchFieldWithModal
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export const SwitchWithModalControl = withJsonFormsControlProps(
  SwitchWithModalComponent
);
