import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import createRewardPartnerCodeGroup from "../graphql/mutations/createRewardPartnerCodeGroup";

export const useCreatePartnerCodeGroupMutation = () => {
  const [createPartnerCodeGroupMutation, { loading }] = useMutation(
    createRewardPartnerCodeGroup
  );

  const create = useCallback(
    async (onSuccess, onFailure, partnerCodeGroupData) => {
      const payload = {
        ...partnerCodeGroupData,
      };

      const startDate = new Date(payload.startDate);
      const endDate = new Date(payload.endDate);
      const isValidStartDate = startDate.getTime() >= new Date().getTime();
      const isValidEndDate = endDate.getTime() >= startDate.getTime();
      if (!isValidStartDate) {
        return onFailure({
          message: "Start date must be greater than the current time.",
        });
      }
      if (!isValidEndDate) {
        return onFailure({ message: "End date must be greater than start date." });
      }

      try {
        const response = await createPartnerCodeGroupMutation({
          variables: {
            request: payload,
          },
        });

        if (response?.data?.createRewardPartnerCodeGroup?.id) {
          onSuccess();
        }
      } catch (error) {
        onFailure(error);
      }
    },
    [createPartnerCodeGroupMutation]
  );

  return [create, loading];
};
