import ContentListViewComponent from "./ContentListViewComponent";
import DayPartingViewComponent from "./DayPartingViewComponent";
import MatchKeywordsViewComponent from "./MatchKeywordsViewComponent";
import ReceiptDateComponent from "./ReceiptDateComponent";
import SegmentViewComponent from "./SegmentViewComponent";
import SelectedCodeGroups from "./SelectedCodeGroups";

// Define your components outside of ViewComponentsRenderer
const DayPartingView = (props) => <DayPartingViewComponent {...props} />;
const MatchBrandsKeywordsView = (props) => (
  <MatchKeywordsViewComponent {...props} filterBy="BRAND" />
);
const MatchOutletsKeywordsView = (props) => (
  <MatchKeywordsViewComponent {...props} filterBy="OUTLET" />
);
const MatchProductsKeywordsView = (props) => (
  <MatchKeywordsViewComponent {...props} filterBy="PRODUCT" />
);
const UserInSegmentView = (props) => (
  <SegmentViewComponent {...props} segmentIds={props.data?.segment_ids || []} />
);
const ReceiptInDateRangeView = (props) => (
  <ReceiptDateComponent
    currentProgramTimezone={props.currentProgramTimezone}
    {...props}
    data={props.data || []}
  />
);

const CodeGroupIdsView = (props) => (
  <SelectedCodeGroups {...props} data={props.data} />
);
const PromotionalProductContentView = (props) => (
  <ContentListViewComponent
    {...props}
    data={props.data}
    contentKey="product_ids"
    contentDefinitionSlug="promotional-product-content"
    label="Selected Products"
  />
);

const TakeASurveyContentView = (props) => (
  <ContentListViewComponent
    {...props}
    data={props.data}
    contentKey="ids"
    contentDefinitionSlug="survey-quiz-content"
    label="Selected Survey/Quiz"
  />
);

export const customComponents = {
  "day-parting": DayPartingView,
  "match-brands-keywords": MatchBrandsKeywordsView,
  "match-outlets-keywords": MatchOutletsKeywordsView,
  "match-products-keywords": MatchProductsKeywordsView,
  "user-in-segment": UserInSegmentView,
  "receipt-in-date-range": ReceiptInDateRangeView,
  "code-group-ids": CodeGroupIdsView,
  "scan-a-code": PromotionalProductContentView,
  "scan-a-code-snack": PromotionalProductContentView,
  "scan-a-code-beverage": PromotionalProductContentView,
  "match-ids": TakeASurveyContentView,
};
