import { withJsonFormsControlProps } from "@jsonforms/react";
import MuiSlider from "./MuiSlider";

const MuiSliderComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    enabled,
    required,
  } = props;
  return (
    <MuiSlider
      value={data}
      enabled={enabled}
      label={label}
      setValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export const MuiSliderControl = withJsonFormsControlProps(MuiSliderComponent);
