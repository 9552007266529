import { useState } from "react";
import ToastNotification from "../../../common/toastNotification/ToastNotification";
import { useCreatePartnerMutation } from "../../Hooks/useCreatePartnerMutation";
import PartnerFormModal from "../PartnerForm/PartnerForm";

function CreatePartnerModal({ createPartnerModalOpen, closeCreatePartnerModal }) {
  const [partnerData, setPartnerData] = useState({});
  const [createPartner, loading] = useCreatePartnerMutation();
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  const onClose = () => {
    setPartnerData({});
    closeCreatePartnerModal();
  };

  const handleCreatePartner = () => {
    const partnerName = partnerData?.name;

    const onSuccess = () => {
      setToast({
        open: true,
        type: "success",
        title: `${partnerName} Created!`,
        description: "You have just published a partner!",
      });
      setPartnerData({});
      closeCreatePartnerModal();
    };
    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error on creating ${partnerName}!`,
        description: error?.message ?? "",
      });
      setPartnerData({});
      closeCreatePartnerModal();
    };

    createPartner(onSuccess, onFailure, partnerData);
  };

  return (
    <>
      <PartnerFormModal
        isOpen={createPartnerModalOpen}
        closeModal={onClose}
        partnerData={partnerData}
        setPartnerData={setPartnerData}
        handleMutation={handleCreatePartner}
        loading={loading}
        title="Create Partner"
      />
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
    </>
  );
}

export default CreatePartnerModal;
