// filterJsonSchema.js

import {
  CODE_GROUP_LIFE_CYCLE_STATUS,
  PRODUCER_COMPANIES,
} from "../../utils/constants";
export const codeGroupFilterJsonSchema = {
  type: "object",
  properties: {
    lifecycleStatus: {
      items: {
        type: "string",
        options: Object.values(CODE_GROUP_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    producerCompany: {
      enum: Object.values(PRODUCER_COMPANIES),
    },
    activeStartDateFrom: {
      type: "string",
      format: "date",
      title: "Active Start Date",
    },
    activeEndDateTo: {
      type: "string",
      format: "date",
      title: "Active End Date",
    },
    codeCount: {
      type: "number",
      title: "Code Count",
    },
    usedCode: {
      type: "number",
      title: "Used Count",
    },
  },
};

export const ptrCodeGroupFilterJsonSchema = {
  type: "object",
  properties: {
    lifecycleStatus: {
      items: {
        type: "string",
        options: Object.values(CODE_GROUP_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    activeStartDateFrom: {
      type: "string",
      format: "date",
      title: "Active Start Date",
    },
    activeEndDateTo: {
      type: "string",
      format: "date",
      title: "Active End Date",
    },
    codeCount: {
      type: "number",
      title: "Code Count",
    },
    usedCode: {
      type: "number",
      title: "Used Count",
    },
  },
};

// filterUiSchema.js
export const ptrCodeGroupFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/lifecycleStatus",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Status",
    },
    {
      type: "Control",
      scope: "#/properties/activeStartDateFrom",
      component: "dateTimePicker",
      label: "Active Start Date",
    },
    {
      type: "Control",
      scope: "#/properties/activeEndDateTo",
      component: "dateTimePicker",
      label: "Active End Date",
    },
    {
      type: "Control",
      scope: "#/properties/codeCount",
      component: "rangeFilter",
      label: "Code Count",
    },
    {
      type: "Control",
      scope: "#/properties/usedCode",
      component: "rangeFilter",
      label: "Used Count",
    },
  ],
};

export const codeGroupFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/lifecycleStatus",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Status",
    },
    {
      type: "Control",
      scope: "#/properties/producerCompany",
      component: "muiSelect",
      placeholder: "Producer Company",
    },
    {
      type: "Control",
      scope: "#/properties/activeStartDateFrom",
      component: "dateTimePicker",
      label: "Active Start Date",
    },
    {
      type: "Control",
      scope: "#/properties/activeEndDateTo",
      component: "dateTimePicker",
      label: "Active End Date",
    },
    {
      type: "Control",
      scope: "#/properties/codeCount",
      component: "rangeFilter",
      label: "Code Count",
    },
    {
      type: "Control",
      scope: "#/properties/usedCode",
      component: "rangeFilter",
      label: "Used Count",
    },
  ],
};

export const debouceCodeGroupFilterJsonSchema = {
  type: "object",
  properties: {
    codeGroupName: {
      type: "string",
      title: "Search",
    },
  },
};

export const debounceCodeGroupFilterUiSchema = {
  type: "Control",
  scope: "#/properties/codeGroupName",
  component: "searchInput",
  placeholder: "Search by code group name",
};
