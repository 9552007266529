import { withJsonFormsControlProps } from "@jsonforms/react";
import "./SummaryField.scss";

export function SummaryField(props) {
  const { data, label, id } = props;

  return (
    <section key={id} className="summary-field">
      <p className="summary-title">{label}</p>
      <p className="summary-content">{data}</p>
    </section>
  );
}

export const SummaryFieldControl = withJsonFormsControlProps(SummaryField);
