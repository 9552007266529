import { gql } from "@apollo/client";

export const listRewards = gql`
  query ListRewards($filter: RewardFilterInput, $page: Int, $size: Int) {
    listRewards(filter: $filter, page: $page, size: $size) {
      first
      last
      self
      total
      items {
        id
        name
        description
        shortDescription
        partnerId
        programId
        partnerCodeType
        quantity
        linkType
        inventoryNumber
        createdInPlatform
        exchangeable
        autoClaim
        unlimitedInventory
        rewardItemGroup
        percentReward
        rewardCategoryIds
        imageUrl
        wheelImageUrl
        rewardItemGroupId
        isActive
        partnerName
        coverImageUrl
        buttonText
        webUrl
        appUrl
        sms
        smsNumber
        androidAppId
        iosAppId
        rewardTypeId
        rewardTypeName
        type
        category
        parentRewardGroupRewardId
        rewardUnitId
        rewardTypeData
        rewardCategories {
          id
          name
          description
          programId
          imageUrl
          priority
        }
      }
    }
  }
`;
