import { gql } from "@apollo/client";

export const loadUiComponent = gql`
  query LoadUiComponent($slug: String!) {
    loadUiComponent(slug: $slug) {
      inputSchema
      uiSchema
    }
  }
`;
