// src/hooks/useSweepstakeListQuery.js
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { listSweepstakesActive } from "../graphql/queries/listSweepstakesActive";

/**
 * Hook to fetch and manage the list of active sweepstakes.
 *
 * @returns {Array} Array of active sweepstakes data.
 *
 * This hook utilizes Apollo Client's `useQuery` to execute the
 * `listSweepstakesActive` GraphQL query and retrieves the list of
 * active sweepstakes. The fetched data is stored in a state variable
 * `sweepstakesList`, which is updated whenever the query result changes.
 *
 * The hook will return an empty array while the query is loading or in case of an error.
 */
export function useSweepstakeListQuery() {
  const { data: queryData, isLoading, isError } = useQuery(listSweepstakesActive);
  const [sweepstakesList, setSweepstakesList] = useState([]);

  useEffect(() => {
    if (queryData && queryData.listSweepstakes) {
      setSweepstakesList(queryData.listSweepstakes);
    }
  }, [queryData]);

  if (isLoading) {
    return []; // Return an empty array while loading
  }

  if (isError) {
    return []; // Return an empty array in case of error
  }

  return { sweepstakesList, isLoading, isError };
}
