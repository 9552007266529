import { withJsonFormsControlProps } from "@jsonforms/react";
import MUISelectField from "./MUISelectField";

const MUISelectComponent = (props) => {
  const {
    data,
    handleChange,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
    label,
  } = props;

  return (
    <MUISelectField
      value={data}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      label={label}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export const MUISelectControl = withJsonFormsControlProps(MUISelectComponent);
