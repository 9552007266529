import { useLazyQuery } from "@apollo/client";
import { Alert, Snackbar } from "@mui/material";
import { InputItem } from "pepsico-ds";
import { useEffect, useState } from "react";
import getListContent from "../../components/survey/graphQL/queries/getListContent";
import EditWinningRewardsScreenModal from "../../components/winningRewardsScreen/EditWinningRewardsScreenModal";
import ViewWinningRewardsScreenModal from "../../components/winningRewardsScreen/ViewWinningRewardsScreenModal";
import WinningRewardsListTable from "../../components/winningRewardsScreen/WinningRewardsListTable";
import "./winningRewardsScreen.scss";

const listContentSlug = [
  "partner-reward-sodexo-yemek-content",
  "partner-reward-sodexo-flexogift-content",
  "partner-reward-sodexo-flexogift-tek-marka-content",
  "partner-reward-hopi-content",
  "partner-reward-4-play-content",
  "partner-reward-papara-content",
];

export default function WinningRewardsScreen() {
  const [listContentDefinitions, setListContentDefinitions] = useState([]);
  const [filteredContentDefinitions, setFilteredContentDefinitions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showViewModal, setViewShowModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [selectedContent, setSelectedContent] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [callListContent] = useLazyQuery(getListContent, {
    onCompleted: () => setLoading(false),
    onError: () => setLoading(false),
  });

  useEffect(() => {
    async function getListContents() {
      setLoading(true);
      const resp = await callListContent({
        variables: { filter: { contentDefinitionSlug: listContentSlug.join(",") } },
      });

      if (resp.data?.listContent?.length) {
        let listData = [...resp.data.listContent];
        listData.sort((a, b) => (a.title < b.title ? -1 : 1));
        setListContentDefinitions(listData);
        setFilteredContentDefinitions(listData);
        setLoading(false);
      }
    }
    getListContents();
  }, [callListContent]);

  // Handle search filter
  useEffect(() => {
    const filtered = listContentDefinitions.filter((content) =>
      content.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredContentDefinitions(filtered);
  }, [searchTerm, listContentDefinitions]);

  return (
    <div className="winningRewards-container-wrapper">
      <div className="winningRewards-container">
        <div className="winningRewards-header">
          <h2>Winning Rewards Screen</h2>
        </div>
        <div className="input-wrapper">
          <InputItem
            placeholder="Search by title"
            trailingIcon="search"
            inputSize="medium"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input-search"
          />
        </div>
        <WinningRewardsListTable
          loading={loading}
          listContentDefinitions={filteredContentDefinitions}
          setShowModal={setShowModal}
          setViewShowModal={setViewShowModal}
          setSelectedContent={setSelectedContent}
        />
      </div>
      {showModal && (
        <EditWinningRewardsScreenModal
          setAlertMsg={setAlertMsg}
          setShowModal={setShowModal}
          showModal={showModal}
          selectedContent={selectedContent}
          setSelectedContent={setSelectedContent}
          setListContentDefinitions={setListContentDefinitions}
        />
      )}
      <ViewWinningRewardsScreenModal
        setShowModal={setShowModal}
        setViewShowModal={setViewShowModal}
        showViewModal={showViewModal}
        selectedContent={selectedContent}
        setSelectedContent={setSelectedContent}
      />
      <Snackbar
        open={!!alertMsg.msg}
        autoHideDuration={6000}
        onClose={() => setAlertMsg({ msg: "", severity: "error" })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertMsg({ msg: "", severity: "error" })}
          severity={alertMsg.severity}
          sx={{ width: "100%" }}
        >
          {alertMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
