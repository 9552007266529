import { gql } from "@apollo/client";

const getListContent = gql`
  query ListContent($filter: ContentFilter!) {
    listContent(filter: $filter) {
      id
      title
      slug
      segments
      description
      status
      paused
      activeStartDate
      activeEndDate
      contentData
      tags
      category
      createdAt
      updatedAt
      deletedAt
      contentDefinition {
        id
        name
        slug
        inputSchema
        uiSchema
      }
    }
  }
`;

export default getListContent;
