import _ from "lodash";
import { Breadcrumb, Button } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppConfigurationsCardList from "../../components/management/AppConfigurations/AppConfigurationsCardList";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { paths } from "../../routes/paths";

export default function AppConfigurationsPage() {
  const [appConfigurations, setAppConfigurations] = useState({});
  const { programConfigurations } = useContext(GlobalConfigContext);
  const navigate = useNavigate();
  const navigateToAppConfigEdit = () => {
    navigate(`/${paths.system_setting}/${paths.app_configurations}/edit`);
  };

  const navigateToManagement = () => {
    navigate(`/${paths.system_setting}`);
  };

  useEffect(() => {
    const programConfigData = _.find(programConfigurations, {
      key: "app_configurations",
    });
    if (programConfigData !== null && programConfigData !== undefined) {
      setAppConfigurations(
        programConfigData.value?.data === null ? {} : programConfigData.value?.data
      );
    }
  }, [programConfigurations, appConfigurations]);

  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "Go back to system settings",
              href: `/${paths.system_setting}`,
            },
            {
              label: "View App Configurations",
              href: `/${paths.system_setting}/${paths.app_configurations}`,
            },
          ]}
          activeIndex={1}
        />
        <div className="heading-wrap">
          <h2>App Configurations</h2>
        </div>
        <div>
          <AppConfigurationsCardList appConfigurations={appConfigurations} />
        </div>
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={navigateToManagement}
          />
          <Button
            size="medium"
            text="Edit"
            variant="primary"
            iconTrailing="arrow_circle_right"
            onClick={navigateToAppConfigEdit}
          />
        </div>
      </div>
    </div>
  );
}
