import { Chip } from "@mui/material";
import { Icon } from "pepsico-ds";
import collect_win from "../Images/collect_win.png";
import email from "../Images/email.png";
import email_icon from "../Images/email_icon.png";
import mini_progress from "../Images/mini_progress.png";
import po1 from "../Images/po1.png";
import profile from "../Images/profile.png";
import profile_icon from "../Images/profile_icon.png";
import reward_icon from "../Images/reward_icon.png";
import survey from "../Images/survey.png";
import survey_icon from "../Images/survey_icon.png";
import widget from "../Images/widget.png";
import "./SystemCard.scss";

export const SystemCard = ({ widgetName }) => {
  const widgetImages = {
    survey_card: {
      widget: survey,
      icon: survey_icon,
      title: "Yeni",
      headline: "Survey Widget",
      titleColor: "#5C1AA9",
      titleBg: "#F3E9FE",
    },
    reward_card: {
      widget: widget,
      icon: reward_icon,
      title: "SKT Dolmak Üzere",
      headline: "Expiration Date Widget",
      titleColor: "#F65800",
      titleBg: "#FFEFE1",
    },
    email_card: {
      widget: email,
      icon: email_icon,
      title: "Güvenliğin İçin",
      headline: "Email Verification Widget",
      titleColor: "#0069F4",
      titleBg: "#E6F0FE",
    },
    profile_card: {
      widget: profile,
      icon: profile_icon,
      title: "Sana Özel Hediyeler İçin;",
      headline: "Profile Completion Widget",
      titleColor: "#8DA1AF",
    },
    po1_pending_completion_card: {
      widget: po1,
      icon: mini_progress,
      title: "1 Adet",
      headline: "PO1 Widget",
      titleColor: "#8DA1AF",
    },
    collect_and_win_pending_completion_card: {
      widget: collect_win,
      icon: mini_progress,
      title: "1 Adet",
      headline: "Collect and Win Widget",
      titleColor: "#8DA1AF",
    },
  };

  return (
    <div className="system-card-container" data-testid="system-card">
      <div className="img-wrapper">
        <Icon
          className="drag-icon"
          icon="drag_indicator"
          size="medium"
          variant="filled"
          data-testid="icon"
        />
      </div>
      <div className="img-wrapper">
        <img
          className="widget"
          src={widgetImages[widgetName]?.widget}
          alt="widget"
        />
      </div>
      <div className="content-wrapper">
        <Chip
          sx={{
            backgroundColor: widgetImages[widgetName]?.titleBg ?? "transparent",
            width: "50px",
            color: widgetImages[widgetName]?.titleColor ?? "#04B0E2",
            padding: "0 !important",
            height: "15px",
          }}
          size="small"
          data-testid="chip"
          label={widgetImages[widgetName]?.title}
        />
        <p>{widgetImages[widgetName]?.headline}</p>
      </div>
      <div className="img-wrapper">
        <img
          className="progress"
          src={widgetImages[widgetName]?.icon}
          alt="dragIcon"
        />
      </div>
    </div>
  );
};
