// !DO NOT REMOVE THIS.
// import { Check, Close } from "@mui/icons-material";
// import { Box, Button, IconButton, Popover, TextField } from "@mui/material";
// import React, { useState } from "react";

// const RangeInputControl = ({ data, handleChange }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [values, setValues] = useState({
//     from: data?.from || "",
//     to: data?.to || "",
//   });

//   // Function to handle opening the popover
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   // Function to handle closing the popover
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // Function to handle input changes
//   const onInputChange = (event) => {
//     const { name, value } = event.target;
//     setValues((prevValues) => ({
//       ...prevValues,
//       [name]: value !== "" ? parseInt(value, 10) : "",
//     }));
//   };

//   // Function to apply the selected range
//   const handleApply = () => {
//     handleChange({ from: values.from, to: values.to });
//     handleClose();
//   };

//   // Function to clear the input values
//   const handleClear = () => {
//     setValues({ from: "", to: "" });
//     handleChange({ from: "", to: "" });
//     handleClose();
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "population-input-popover" : undefined;

//   return (
//     <div>
//       {/* Button to trigger the popover */}
//       <Button
//         variant="outlined"
//         onClick={handleClick}
//         size="small"
//         sx={{ borderRadius: "15px", borderColor: "light-blue" }}
//       >
//         Population Range
//       </Button>

//       {/* Popover component to display the input fields */}
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//       >
//         <Box sx={{ width: 250, padding: 2 }}>
//           {/* Box for flex layout to place inputs on the same line */}
//           <Box
//             sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
//           >
//             <TextField
//               label="From"
//               name="from"
//               type="number"
//               value={values.from}
//               onChange={onInputChange}
//               variant="outlined"
//               sx={{
//                 width: "45%",
//                 fontSize: "small",
//                 "& .MuiOutlinedInput-input": {
//                   padding: "12px 13px",
//                 },
//               }}
//               InputProps={{
//                 inputProps: { min: 0 },
//                 sx: {
//                   padding: "0px 0px",
//                   fontSize: "small",
//                 },
//               }}
//             />
//             <TextField
//               label="To"
//               name="to"
//               type="number"
//               value={values.to}
//               onChange={onInputChange}
//               variant="outlined"
//               sx={{
//                 width: "45%",
//                 fontSize: "medium",
//                 "& .MuiOutlinedInput-input": {
//                   padding: "12px 13px",
//                 },
//               }}
//               InputProps={{
//                 inputProps: { min: 0 },
//                 sx: {
//                   padding: "0px 0px", // Reduce padding inside the input
//                   fontSize: "small", // Smaller font size
//                 },
//               }}
//             />
//           </Box>

//           {/* Icon Buttons for Apply (Tick) and Clear (Cross) */}
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               gap: 1,
//               marginTop: 1,
//             }}
//           >
//             <IconButton
//               onClick={handleApply}
//               sx={{ padding: 0, color: "rgba(0, 128, 0)" }}
//             >
//               <Check fontSize="medium" />
//             </IconButton>
//             <IconButton
//               onClick={handleClear}
//               sx={{ padding: 0, color: "rgba(128, 0, 0)" }}
//             >
//               <Close fontSize="medium" />
//             </IconButton>
//           </Box>
//         </Box>
//       </Popover>
//     </div>
//   );
// };

// export default RangeInputControl;

// !DO NOT REMOVE THIS.
// import { Box, Button, Popover, Slider, Typography } from "@mui/material";
// import { useEffect, useState } from "react";

// const RangeSliderControl = ({ data, handleChange }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [value, setValue] = useState([data?.from || 0, data?.to || 300]);

//   useEffect(() => {
//     // Update the slider value when the passed data changes (for example, when clearing filters)
//     setValue([data?.from || 0, data?.to || 300]);
//   }, [data]);

//   // Function to handle opening the popover
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   // Function to handle closing the popover
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // Function to handle slider change
//   const onSliderChange = (event, newValue) => {
//     setValue(newValue);
//     handleChange({ from: newValue[0], to: newValue[1] });
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "population-slider-popover" : undefined;

//   return (
//     <div>
//       {/* Button to trigger the popover */}
//       <Button
//         variant="outlined"
//         onClick={handleClick}
//         size="small"
//         sx={{ borderRadius: "15px", borderColor: "light-blue" }}
//       >
//         {" "}
//         Population Range
//       </Button>

//       {/* Popover component to display the slider */}
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//       >
//         <Box sx={{ width: 500, padding: 2 }}>
//           <Typography gutterBottom>Population Range</Typography>
//           <Slider
//             value={value}
//             onChange={onSliderChange}
//             valueLabelDisplay="auto"
//             min={0}
//             max={300}
//             step={10}
//           />
//           {/* Conditionally render the range text */}
//           {value[0] !== null && value[1] !== null ? (
//             <Typography>
//               From: {value[0]} - To: {value[1]}
//             </Typography>
//           ) : (
//             <Typography>Please select a range</Typography>
//           )}
//         </Box>
//       </Popover>
//     </div>
//   );
// };

// export default RangeSliderControl;

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Slider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const RangeSliderComponent = (props) => {
  const {
    data,
    handleChange,
    path,
    label,
    uischema,
    config, // Add config to access dynamic values
  } = props;

  const options = uischema.options || {};
  const buttonLabel = options.buttonLabel || label || "Set Range";
  const sliderLabel = options.sliderLabel || "Select Range";

  // Fetch dynamic min, max, and step from config or options
  const min = config?.dynamicMinMax?.[path]?.min ?? options.min ?? 0;
  const max = config?.dynamicMinMax?.[path]?.max ?? options.max ?? 100;
  const step = config?.dynamicMinMax?.[path]?.step ?? options.step ?? 1;

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([data?.from ?? min, data?.to ?? max]);

  useEffect(() => {
    // Update the slider value when the data or min/max changes
    setValue([data?.from ?? min, data?.to ?? max]);
  }, [data, min, max]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleApply = () => {
    handleChange(path, { from: value[0], to: value[1] });
    setAnchorEl(null);
  };

  const handleClear = () => {
    setValue([min, max]);
    handleChange(path, { from: null, to: null });
    setAnchorEl(null);
  };

  const onSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        size="small"
        style={{ borderRadius: "15px" }}
      >
        {buttonLabel}
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {sliderLabel}
          </Typography>
          <Slider
            value={value}
            onChange={onSliderChange}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
          />
          <Typography variant="body2" align="center" sx={{ marginTop: 1 }}>
            From: {value[0]} - To: {value[1]}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
            <IconButton onClick={handleClear} size="small" aria-label="Clear">
              <CancelIcon color="error" fontSize="small" />
            </IconButton>
            <IconButton onClick={handleApply} size="small" aria-label="Apply">
              <CheckCircleIcon color="success" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

RangeSliderComponent.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string,
  uischema: PropTypes.object.isRequired,
  config: PropTypes.object, // Add config to prop types
};

export const RangeSliderControl = withJsonFormsControlProps(RangeSliderComponent);
