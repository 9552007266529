import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Icon } from "pepsico-ds";
import { Draggable } from "react-beautiful-dnd";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./SelectedRewards.scss";

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/quantity",
          component: "textField",
          label: "Multiplier",
        },
        {
          type: "Control",
          scope: "#/properties/totalUserWinLimit",
          component: "textField",
          label: "Win limit",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/position",
      component: "textField",
      label: "Position",
      rule: {
        effect: "DISABLE",
        condition: {
          schema: {
            gt: 0,
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/walletWithdrawalDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Wallet Withdrawal Date",
      rule: {
        effect: "VALIDATE",
        condition: {
          schema: {
            gt: "activeStartDate",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      minimum: "currentDateTime",
      component: "dateTimePicker",
      label: "Display start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      minimum: "currentDateTime",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
      component: "dateTimePicker",
      label: "Display end date",
    },
  ],
};

const jsonSchema = {
  title: "Basic & Rules",
  type: "object",
  properties: {
    position: {
      type: "integer",
    },
    quantity: {
      type: "integer",
    },
    totalUserWinLimit: {
      type: "integer",
    },
    walletWithdrawalDate: {
      type: "string",
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
  required: ["quantity", "totalUserWinLimit"],
};

const uiSchemaChanceToWin = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/quantity",
          component: "textField",
          label: "Multiplier",
        },
        {
          type: "Control",
          scope: "#/properties/totalUserWinLimit",
          component: "textField",
          label: "Win limit",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/position",
      component: "textField",
      label: "Position",
      rule: {
        effect: "DISABLE",
        condition: {
          schema: {
            gt: 0,
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/chanceToWinPercentage",
      component: "decimalInput",
      label: "Chance to win (%)",
      acceptDecimal: true,
      maxDecimalPlaces: 2,
      step: 0.01,
    },
    {
      type: "Control",
      scope: "#/properties/walletWithdrawalDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Wallet Withdrawal Date",
      rule: {
        effect: "VALIDATE",
        condition: {
          schema: {
            gt: "activeStartDate",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Display start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      minimum: "currentDateTime",
      component: "dateTimePicker",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
      label: "Display end date",
    },
  ],
};

const jsonSchemaChanceToWin = {
  title: "Basic & Rules",
  type: "object",
  properties: {
    position: {
      type: "integer",
    },
    quantity: {
      type: "integer",
    },
    totalUserWinLimit: {
      type: "integer",
    },
    chanceToWinPercentage: {
      type: "number",
      maxDecimalPlaces: 2,
    },
    walletWithdrawalDate: {
      type: "string",
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
  required: ["quantity", "totalUserWinLimit", "chanceToWinPercentage"],
};

const RewardItem = ({
  reward,
  index,
  onRewardSelect,
  updateRewardData,
  type,
  activeStartDate,
}) => {
  return (
    <Draggable key={reward.rewardId} draggableId={reward.rewardId} index={index}>
      {(provided) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className="selected-reward-accordion"
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <div className="display-flex align-items-center justify-content-space-between w-100">
              <div className="display-flex align-items-center w-100">
                <Icon
                  icon="drag_indicator"
                  alt="Drag"
                  size="small"
                  variant="filled"
                  className="mr-1"
                />
                <div>
                  <h4>{reward?.rewardInfo?.name || reward?.name}</h4>
                  {reward?.rewardInfo?.shortDescription && (
                    <h5 className="font-regular mt-1">
                      {reward?.rewardInfo?.shortDescription}
                    </h5>
                  )}
                </div>
              </div>
              <Icon
                icon="close"
                alt="Close"
                size="small"
                variant="filled"
                className="mx-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onRewardSelect(reward);
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <JsonFormsWrapper
              uischema={type === "LIST" ? uiSchema : uiSchemaChanceToWin}
              jsonschema={type === "LIST" ? jsonSchema : jsonSchemaChanceToWin}
              data={{ ...reward, activeStartDate, position: index + 1 }}
              onChange={updateRewardData}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default RewardItem;
