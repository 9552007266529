import { withJsonFormsControlProps } from "@jsonforms/react";
import { Modal } from "pepsico-ds";
import { useEffect, useState } from "react";
import "../../campaign/CampaignLanguageContent/_campaign-language-content.scss";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

export const CampaignAddContentComponent = (props) => {
  const { schema, uischema, data, onChange } = props;
  const productTypes =
    schema.properties?.product_contents?.propertyNames?.enum || [];
  const [currentUiSchema, setCurrentUiSchema] = useState({});
  const [formData, setFormData] = useState(data || { product_contents: {} });
  const [formContent, setFormContent] = useState("snack_content");
  const [savedData, setSavedData] = useState(data?.product_contents || {});
  const [openModal, setOpenModal] = useState(false);
  const [labelMapping, setLabelMapping] = useState({});
  const [title, setTitle] = useState("");
  const requiredFields = schema?.definitions?.product_content?.required || [];

  useEffect(() => {
    const filteredSchema = uischema?.elements?.filter(
      (item) => item?.component === "campaignAddContent"
    );
    setLabelMapping(filteredSchema?.[0]?.elements[0]?.options?.labelMapping);
    setTitle(filteredSchema?.[0]?.elements[0]?.label);
    if (formContent.length && filteredSchema.length) {
      const replacePlaceholder = (uiSchema) => {
        const stringifiedSchema = JSON.stringify(uiSchema);
        const updatedSchemaString = stringifiedSchema?.replace(
          /{content_placeholder}/g,
          formContent
        );
        return JSON.parse(updatedSchemaString);
      };
      const updatedUiSchema = replacePlaceholder(filteredSchema?.[0]?.elements[0]);
      setCurrentUiSchema(updatedUiSchema);
    }
    if (data?.product_contents && !Object.keys(savedData).length) {
      setSavedData(data.product_contents);
    }
  }, [data, formContent, uischema, savedData]);

  const handleToggleModal = () => {
    setFormData({
      product_contents: savedData,
    });
    setOpenModal(!openModal);
  };
  const handleOpenModal = (item) => {
    setFormContent(item);
    handleToggleModal();
  };

  const handleChange = (data) => {
    setFormData(data);
  };

  const handleSaveContent = () => {
    const currentContentData = formData.product_contents?.[formContent] || {};
    setSavedData((savedData) => ({
      ...savedData,
      [formContent]: currentContentData,
    }));
    setOpenModal(!openModal);
    onChange({ product_contents: formData.product_contents });
  };
  const handleDelete = (item) => {
    const updatedData = { ...savedData };
    delete updatedData[item];
    setSavedData(updatedData);
    setFormContent(item);
    setFormData({
      product_contents: { ...updatedData },
    });
    onChange({ product_contents: { ...updatedData } });
  };

  let hasErrors = false;
  requiredFields.forEach((field) => {
    if (
      formData?.product_contents &&
      !formData?.product_contents[formContent]?.[field]
    ) {
      hasErrors = true;
    }
  });

  return (
    <>
      <div className="font-bold font-lg mb-3">{title}</div>
      <div className="multilanguage-content">
        {labelMapping &&
          productTypes?.map((product) => {
            const isSaved = Object.keys(savedData).includes(product);
            return (
              <div
                className={`language-item display-flex flex-direction-row font-xs font-bold ${isSaved ? "language-added" : ""}`}
                key={product}
              >
                {isSaved ? (
                  <div className="added-language display-flex flex-direction-row align-items-center justify-content-space-between">
                    <div className="language-content display-flex flex-direction-row align-items-center">
                      {savedData[product].congratulation_screen_image ? (
                        <img
                          className="language-image"
                          src={savedData[product].congratulation_screen_image}
                          alt={labelMapping[product]}
                        ></img>
                      ) : null}
                      {labelMapping[product]}
                    </div>
                    <ButtonGroupDynamic
                      size="small"
                      items={[
                        {
                          icon: "edit",
                          onClick: () => handleOpenModal(product),
                        },
                        {
                          icon: "delete",
                          onClick: () => handleDelete(product),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <>
                    <ButtonGroupDynamic
                      size="small"
                      items={[
                        {
                          icon: "add",
                          onClick: () => handleOpenModal(product),
                        },
                      ]}
                    />
                    Add {labelMapping[product]}
                  </>
                )}
              </div>
            );
          })}
        <Modal
          className="content-modal campaign-language-content-modal"
          showModal={openModal}
          title={labelMapping?.[formContent]}
          text=""
          showSecondaryButton={false}
          showTertiaryButton={false}
          showLink={false}
          onCloseModal={handleToggleModal}
          primaryButtonProps={{
            text: "Save",
            onClick: handleSaveContent,
            disabled: hasErrors,
          }}
        >
          <hr
            style={{
              marginBottom: 20,
              borderTop: "1px solid #ccc",
              width: "100%",
            }}
          />
          <div style={{ overflow: "auto" }}>
            <JsonFormsWrapper
              schema={schema}
              uischema={currentUiSchema?.definitions}
              onChange={handleChange}
              data={formData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export const CampaignAddContentControl = withJsonFormsControlProps(
  CampaignAddContentComponent
);
