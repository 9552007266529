import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { getCodeGroupByIds } from "../../../graphql/queries/codeGroupQueries";

const SelectedCodeGroups = ({ data }) => {
  const { data: selectedCodeGroups, loading } = useQuery(getCodeGroupByIds, {
    variables: { ids: data.code_group_ids || [] },
  });

  return (
    <div className="additional-fields-container">
      <div className="field-pair" style={{ flexBasis: "31% !important" }}>
        <span className="field-label">Selected Code Groups: </span>
      </div>
      <div className="field-pair-two-column">
        {loading ? (
          <CircularProgress />
        ) : (
          <span className="field-value">
            {selectedCodeGroups?.loadCodeGroupsByIds?.length
              ? selectedCodeGroups?.loadCodeGroupsByIds
                  ?.map((codeGroup) => codeGroup?.basicSetup?.codeGroupName || "")
                  .join(", ")
              : "-"}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectedCodeGroups;
