import { useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import _ from "lodash";
import { Dropdown } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import { usePartnerContext } from "../../../context/PartnerContext";
import { listRewardTypes } from "../../../graphql/queries/listRewardTypes";
import "./multiple-id-selector.scss";

const PartnerSubtypeDropdownComponent = (props) => {
  const { handleChange, data, label, path, required, visible } = props;

  const { partnerIds } = usePartnerContext();

  const [rewardTypeIds, setRewardTypeIds] = useState([]);

  const { data: dataRewardTypes, loading: loadingRewardTypes } = useQuery(
    listRewardTypes,
    {
      variables: {
        partnerId: undefined,
      },
    }
  );

  const formattedOptions = useMemo(() => {
    const items = dataRewardTypes?.listRewardType?.items;
    if (loadingRewardTypes || !items) {
      return [];
    }

    return items
      .filter((child) => partnerIds.includes(child.partnerId))
      .map((child) => ({
        id: child.id,
        displayText: child.name || "",
      }));
  }, [loadingRewardTypes, dataRewardTypes, partnerIds]);

  useEffect(() => {
    if (!data || !formattedOptions) {
      setRewardTypeIds([]);
      return;
    }

    const values = data
      .filter((selectedValueId) =>
        formattedOptions.find((option) => selectedValueId === option?.id)
      )
      .map((selectedValueId) =>
        formattedOptions.find((option) => selectedValueId === option?.id)
      );

    setRewardTypeIds(values);
  }, [data, formattedOptions]);

  const formatData = (listOptions) =>
    listOptions ? listOptions.map((item) => item.id) : [];

  const handleChangeValues = (newValues) => {
    handleChange(path, formatData(newValues));
  };

  if (!visible) return null;

  if (loadingRewardTypes && formattedOptions.length === 0) return <p>Loading...</p>;

  return (
    <div className="multiple-select-wrap">
      <Dropdown
        style={{ zIndex: 1000 }}
        label={label}
        placeholder="Select Partner Subtype"
        childList={formattedOptions}
        selection="multiple"
        isRequired={required}
        disabled={_.isEmpty(partnerIds)}
        selectedValue={rewardTypeIds}
        setSelectedValue={handleChangeValues}
      />
    </div>
  );
};

export const PartnerSubtypeDropdownControl = withJsonFormsMultiEnumProps(
  PartnerSubtypeDropdownComponent
);
