import { useQuery } from "@apollo/client";
import { Breadcrumb, Button, LoadingSpinner } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailsSection } from "../../../components/common/detailsSection/DetailsSections";
import { ListingComponentContext } from "../../../components/common/listingComponents/ListingComponentContext";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getContentList } from "../../../graphql/queries/contentQueries";
import { listSegments } from "../../../graphql/queries/listSegments";
import { capitalizeFirstLetter, getDateWithFormat } from "../../../utils/commons";
import { linkTypeMap } from "../constants";
import "./content-details.scss";

const ContentDetailsPage = () => {
  const { contentId } = useParams("contentId");
  const { data, loading } = useContext(ListingComponentContext);
  const { programTimezone } = useContext(GlobalConfigContext);
  const [content, setContent] = useState();
  const [quiz, setQuiz] = useState();
  const [segments, setSegments] = useState([]);
  const navigate = useNavigate();
  const { refetch: refetchQuiz } = useQuery(getContentList, {
    variables: {
      filter: {
        contentDefinitionSlug: "survey-quiz-content",
        contentData: { type: "QUIZ" },
      },
    },
    fetchPolicy: "cache-first",
    skip: true,
  });
  const { refetch: refetchSegments } = useQuery(listSegments, {
    variables: { status: "ENABLED" },
    fetchPolicy: "cache-first",
  });

  const getQuiz = (quiz, quizData) =>
    quizData?.find((dataQuiz) => dataQuiz.id === quiz);

  const getSegmentList = (segments, segmentsData) =>
    segments
      ?.map(
        (contentSegment) =>
          segmentsData.find((dataSegment) => contentSegment === dataSegment.id)?.name
      )
      .filter((name) => !!name) || [];

  useEffect(() => {
    const fetchQuiz = (quiz) => {
      if (!quiz) return;
      refetchQuiz().then(({ data }) => {
        const quizInfo = getQuiz(quiz, data?.listContent);
        setQuiz(quizInfo?.title);
      });
    };

    const fetchSegments = (segments) => {
      if (!segments?.length) return;
      refetchSegments().then(({ data }) => {
        const segmentsData = data?.listSegments;
        const segmentsList = getSegmentList(segments, segmentsData);
        setSegments(segmentsList);
      });
    };

    if (!data?.listContent) return;
    const content = data.listContent.find((content) => content.id === contentId);
    if (!content) {
      navigate("../");
      return;
    }
    setContent(content);
    fetchQuiz(content.contentData?.quiz);
    fetchSegments(content.segments);
  }, [contentId, data, navigate, refetchQuiz, refetchSegments]);

  const isLoading =
    loading ||
    !content ||
    !programTimezone ||
    (content.contentData?.quiz && !quiz) ||
    (content.segments?.length && !segments.length);
  const contentType = content?.contentData?.content_type;
  const formattedActiveStartDate = content?.activeStartDate
    ? getDateWithFormat(content.activeStartDate, programTimezone)
    : "N/A";
  const formattedActiveEndDate = content?.activeEndDate
    ? getDateWithFormat(content.activeEndDate, programTimezone)
    : "N/A";
  const editableStatuses = ["published", "paused", "inactive", "active"];
  const isEditable =
    content && editableStatuses.includes(content.status.toLowerCase());

  return (
    <div className="content-details">
      {!isLoading ? (
        <>
          <div className="content-details-header">
            <Breadcrumb
              breadcrumbs={[
                {
                  label: "Content",
                  href: "../",
                },
                { label: `${content?.title || ""} Details` },
              ]}
              activeIndex={1}
            />
          </div>
          <div className="content-details-body">
            <DetailsSection title="Basic Setup">
              <div className="details-section-row">
                {content.contentData?.cover_image && (
                  <div className="details-image-wrapper">
                    <span className="details-section-label">Image Cover</span>
                    <img
                      src={content.contentData?.cover_image}
                      alt="Cover"
                      className="details-image"
                    ></img>
                  </div>
                )}
                <div className="details-section-column">
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">Type</span>
                      <span>
                        {contentType ? capitalizeFirstLetter(contentType) : ""}
                      </span>
                    </div>
                    <div className="details-section-value">
                      <span className="details-section-label">Content Name</span>
                      <span>{content.title}</span>
                    </div>
                  </div>
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">
                        Content Description
                      </span>
                      <span>{content.contentData?.description || ""}</span>
                    </div>
                  </div>
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">Start Date</span>
                      <span>{formattedActiveStartDate}</span>
                    </div>
                    <div className="details-section-value">
                      <span className="details-section-label">End Date</span>
                      <span>{formattedActiveEndDate}</span>
                    </div>
                  </div>
                  {content.contentData?.video_url && (
                    <div className="details-section-row">
                      <div className="details-section-value">
                        <span className="details-section-label">Video URL</span>
                        <span>{content.contentData?.video_url}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DetailsSection>
            <DetailsSection title="Page Setup">
              <div className="details-section-column">
                <div className="details-section-row">
                  <div className="details-section-value">
                    <span className="details-section-label">Title</span>
                    <span>{content.contentData?.content_page_title}</span>
                  </div>
                  <div className="details-section-value">
                    <span className="details-section-label">Subtitle</span>
                    <span>{content.contentData?.content_page_subtitle}</span>
                  </div>
                </div>
                <div className="details-section-row">
                  <div className="details-section-value">
                    <span className="details-section-label">Text</span>
                    <div
                      className="content-page-text-details"
                      dangerouslySetInnerHTML={{
                        __html: content.contentData?.content_page_text,
                      }}
                    />
                  </div>
                </div>
              </div>
            </DetailsSection>
            <DetailsSection title="Specifications">
              <div className="details-section-row">
                <div className="details-section-value">
                  <span className="details-section-label">Link Type</span>
                  <span>{linkTypeMap[content.contentData?.action_type] || ""}</span>
                </div>
                {content?.contentData?.content_url && (
                  <div className="details-section-value">
                    <span className="details-section-label">URL</span>
                    <span>{content.contentData?.content_url}</span>
                  </div>
                )}
                {content?.contentData?.quiz && (
                  <div className="details-section-value">
                    <span className="details-section-label">Quiz</span>
                    <span>{quiz}</span>
                  </div>
                )}
                {content?.contentData?.button_text && (
                  <div className="details-section-value">
                    <span className="details-section-label">Button Text</span>
                    <span>{content?.contentData?.button_text}</span>
                  </div>
                )}
                {!!content?.tags.length && (
                  <div className="details-section-value">
                    <span className="details-section-label">Tags</span>
                    <span>{content.tags.join(", ")}</span>
                  </div>
                )}
                <div className="details-section-value">
                  <span className="details-section-label">
                    Position in Blog Page
                  </span>
                  <span>{content?.contentData?.position}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">
                    Show in KZ World page
                  </span>
                  <span>{content.contentData?.show_in_kz_world ? "On" : "Off"}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">Shareable</span>
                  <span>{content.contentData?.shareable ? "On" : "Off"}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">Exclusive</span>
                  <span>{content.contentData?.exclusive ? "On" : "Off"}</span>
                </div>
              </div>
            </DetailsSection>
            {!!segments.length && (
              <DetailsSection title="Segments">
                <div className="details-section-row">
                  <div className="details-section-value">
                    <span>{segments.join(", ")}</span>
                  </div>
                </div>
              </DetailsSection>
            )}
          </div>
        </>
      ) : (
        <div className="loading-container">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{
              height: "2rem",
              width: "2rem",
            }}
          />
        </div>
      )}
      <div className="content-details-footer">
        <div className="content-actions">
          <Button
            size="medium"
            variant={isEditable ? "secondary" : "primary"}
            text="Close"
            onClick={() => navigate("../")}
          />
          {isEditable && (
            <Button
              size="medium"
              text="Edit"
              onClick={() => navigate(`../edit/${contentId}`)}
              iconTrailing="arrow_forward"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentDetailsPage;
