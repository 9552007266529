import { Card } from "pepsico-ds";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../utils/commons";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../utils/constants";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import { codeGroupActionButton } from "./codeGroupActionButton";
import "./codeGroupsCard.scss";

const CodeGroupsCard = ({
  group,
  currentProgramTimezone,
  downloadPermission,
  handleActionButtons,
  isSelected,
  onSelect,
}) => {
  const ptrCodeGroupUpload = isFeatureEnabled(FeatureToggle.ptrCodeGroupUpload);

  if (!group) {
    return "No Data Found";
  }
  const allowedStatuses = [
    CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT.toLowerCase(),
    CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE.toLowerCase(),
  ];
  // Destructure with optional chaining and default values
  const basicSetup = group.basicSetup || {};
  const producerPackaging = group.producerPackaging || {};
  const codesUsedCount =
    group.codesUsedCount !== undefined && group.codesUsedCount !== null
      ? group.codesUsedCount
      : "-";
  const codesGeneratedCount =
    group.codesGeneratedCount !== undefined && group.codesGeneratedCount !== null
      ? group.codesGeneratedCount
      : "-";

  const compositeStatus = group.compositeStatus || "-";

  // Extract and default values
  const codeGroupName = basicSetup.codeGroupName || "-";
  const codeActiveStartDate = basicSetup.codeActiveStartDate || null;
  const codeActiveEndDate = basicSetup.codeActiveEndDate || null;
  const producerCompany = producerPackaging.producerCompany || "-";
  const creationType = group.creationType || "-";
  const brand = producerPackaging.brand || "-";
  const canSelect = allowedStatuses.includes(group.compositeStatus?.toLowerCase());

  return (
    <Card
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div className="code-group-card-container">
        <div className="code-group-details">
          {/* Status Labels */}
          <div className="code-group-status-type">
            <span
              className={`code-upload-status ${compositeStatus
                .toLowerCase()
                .replace(/\s/g, "-")}`}
            >
              {compositeStatus}
            </span>
            <span>
              {canSelect ? (
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={(e) => onSelect(group.codeGroupId, e.target.checked)}
                />
              ) : null}
            </span>
          </div>
          {/* Code Group Name */}
          <h3 className="code-group-name">{codeGroupName}</h3>
          {/* Additional Fields */}
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Code Count</span>
              <span className="field-value">{codesGeneratedCount}</span>
            </div>

            {!ptrCodeGroupUpload ? (
              <div className="field-pair">
                <span className="field-label">Producer Company</span>
                <span className="field-value">{producerCompany}</span>
              </div>
            ) : (
              <div className="field-pair">
                <span className="field-label">Creation Type</span>
                <span className="field-value">{creationType}</span>
              </div>
            )}

            <div className="field-pair">
              <span className="field-label">Used Code Count</span>
              <span className="field-value">{codesUsedCount}</span>
            </div>
            {!ptrCodeGroupUpload && (
              <div className="field-pair">
                <span className="field-label">Producer Brand</span>
                <span className="field-value">{brand}</span>
              </div>
            )}
          </div>
          <div className="code-group-dates">
            <div className="date-item">
              <span className="field-label">Start Date</span>
              <span>
                {codeActiveStartDate
                  ? getDateWithFormat(codeActiveStartDate, currentProgramTimezone)
                  : "-"}
              </span>
            </div>
            <div className="date-item">
              <span className="field-label">End Date</span>
              <span>
                {codeActiveEndDate
                  ? getDateWithFormat(codeActiveEndDate, currentProgramTimezone)
                  : "-"}
              </span>
            </div>
          </div>
          <div className="button-group-container">
            <ButtonGroupDynamic
              items={codeGroupActionButton(
                group,
                handleActionButtons,
                downloadPermission
              )}
              spacing="1px"
              size="medium"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

CodeGroupsCard.propTypes = {
  group: PropTypes.shape({
    basicSetup: PropTypes.shape({
      codeGroupName: PropTypes.string,
      codeActiveStartDate: PropTypes.string,
      codeActiveEndDate: PropTypes.string,
    }),
    producerPackaging: PropTypes.shape({
      producerCompany: PropTypes.string,
    }),
    codesGeneratedCount: PropTypes.number,
    administrationStatus: PropTypes.string,
    compositeStatus: PropTypes.string,
  }),
  currentProgramTimezone: PropTypes.string,
};

export default CodeGroupsCard;
