import { useQuery } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { Tag } from "pepsico-ds";
import { useMemo } from "react";
import { listRewardGroups } from "../../../graphql/queries/listRewardGroups";
import { SingleBorderCard } from "../../common/SingleBorderCard";

const BoostedRewardGroupSelect = ({
  handleChange,
  path,
  data = [],
  label,
  jsonformState,
  rootSchema,
}) => {
  const onSelectRewardGroup = (rewardGroupId) => {
    const isRewardGroupSelected = data?.indexOf(rewardGroupId) > -1;
    let finalData = [];
    if (isRewardGroupSelected) {
      finalData = data.filter((id) => id !== rewardGroupId);
    } else {
      finalData = [...data, rewardGroupId];
    }
    handleChange(path, finalData);
    if (rootSchema?.properties?.default_reward_group_ids) {
      handleChange(
        "default_reward_group_ids",
        jsonformState.rewardGroupIds.filter((value) => !finalData.includes(value))
      );
    }
  };

  return (
    <div className="w-100">
      <p className="mb-3">{label}</p>
      <Grid container spacing={2}>
        {jsonformState.rewardGroupIds?.map((rewardGroupId) => (
          <SingleRewardGroup
            rewardGroupId={rewardGroupId}
            key={rewardGroupId}
            onSelectRewardGroup={() => onSelectRewardGroup(rewardGroupId)}
            values={data}
          />
        ))}
      </Grid>
    </div>
  );
};

const SingleRewardGroup = ({ rewardGroupId, onSelectRewardGroup, values }) => {
  const { data } = useQuery(listRewardGroups, {
    variables: {
      rewardGroupId: rewardGroupId,
    },
    skip: !rewardGroupId,
    // fetchPolicy: "network-only"
  });

  const rewardGroupData = data?.listRewardGroups?.[0];

  const [rewards = [], extraRewards = []] = useMemo(() => {
    const rewards = [],
      extraRewards = [];
    for (const reward of rewardGroupData?.rewardGroupRewards || []) {
      if (reward.isExtra) {
        extraRewards.push(reward);
      } else {
        rewards.push(reward);
      }
    }
    rewards.sort((a, b) => (a.position > b.position ? 1 : -1));
    extraRewards.sort((a, b) => (a.position > b.position ? 1 : -1));
    return [rewards, extraRewards];
  }, [rewardGroupData]);

  return (
    <Grid item xs={6} md={3}>
      <SingleBorderCard
        style={{ width: "100%", cursor: "pointer", position: "relative" }}
        onClick={onSelectRewardGroup}
      >
        <div className="display-flex">
          <Tag
            color="Cyan-500"
            size="large"
            text={rewardGroupData?.type === "LIST" ? "List" : "Chance to win"}
            type="filled"
            isRemovable={false}
            isCopyable={false}
          />
        </div>
        <p className="font-sm font-bold">
          {rewardGroupData?.name?.indexOf("list-rewards-group-name") !== -1
            ? "-"
            : rewardGroupData?.name || "-"}
        </p>
        <Accordion
          sx={{
            border: "none",
            boxShadow: "none",
            "&.Mui-expanded": { padding: "0px", margin: "0px" },
            "&::before": {
              height: "0px",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            sx={{
              padding: "0px",
              minHeight: "auto",
              "&.Mui-expanded": {
                minHeight: "auto",
              },
              ".MuiAccordionSummary-content": {
                margin: "0px",
              },
            }}
          >
            <p className="font-sm font-bold">{`${rewardGroupData?.rewardGroupRewards?.length} rewards in group`}</p>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "8px 0" }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th style={{ color: "#A0A0A0", textAlign: "left" }}>Name</th>
                  <th style={{ color: "#A0A0A0", textAlign: "center" }}>Qty</th>
                  {rewardGroupData?.type !== "LIST" && (
                    <th style={{ color: "#A0A0A0", textAlign: "center" }}>Chance</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {rewards?.map((reward) => (
                  <tr key={reward.id} style={{ height: "30px" }}>
                    <td style={{ color: "#3A3A3A" }}>
                      <span className="text-ellipsis-1">
                        {reward?.rewardInfo?.name || reward.rewardId}
                      </span>
                    </td>
                    <td
                      style={{
                        color: "#3A3A3A",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          background: "#DFDFDF",
                          borderRadius: "8px",
                          padding: "4px 8px",
                        }}
                      >
                        {reward.quantity}
                      </span>
                    </td>
                    {rewardGroupData?.type !== "LIST" && (
                      <td
                        style={{
                          color: "#3A3A3A",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            background: "#DFDFDF",
                            borderRadius: "8px",
                            padding: "4px 6px",
                          }}
                        >
                          {reward.chanceToWinPercentage}%
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
                <>
                  <tr style={{ height: "50px" }}>
                    <td
                      style={{
                        color: "#3A3A3A",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      colSpan={3}
                    >
                      <span className="text-ellipsis-1">Extra Rewards</span>
                    </td>
                  </tr>
                  {extraRewards.length === 0 && (
                    <tr style={{ height: "30px" }}>
                      <td
                        style={{
                          color: "#3A3A3A",
                          textAlign: "center",
                          // fontWeight: "bold",
                        }}
                        colSpan={3}
                      >
                        <span className="">N/A</span>
                      </td>
                    </tr>
                  )}
                  {extraRewards?.map((reward) => (
                    <tr key={reward.id} style={{ height: "30px" }}>
                      <td style={{ color: "#3A3A3A" }}>
                        <span className="text-ellipsis-1">
                          {reward?.rewardInfo?.name || reward.rewardId}
                        </span>
                      </td>
                      <td
                        style={{
                          color: "#3A3A3A",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            background: "#DFDFDF",
                            borderRadius: "8px",
                            padding: "4px 8px",
                          }}
                        >
                          {reward.quantity}
                        </span>
                      </td>
                      {rewardGroupData?.type !== "LIST" && (
                        <td
                          style={{
                            color: "#3A3A3A",
                            textAlign: "center",
                          }}
                        >
                          -
                        </td>
                      )}
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        {!!values.find((item) => {
          return item === rewardGroupId;
        }) && (
          <img
            src={"/assets/images/checkmark.png"}
            height={24}
            width={24}
            style={{ position: "absolute", right: 10, top: 10 }}
          />
        )}
      </SingleBorderCard>
    </Grid>
  );
};

export default BoostedRewardGroupSelect;
