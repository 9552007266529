import { withJsonFormsControlProps } from "@jsonforms/react";
import _ from "lodash";
import { Font } from "pepsico-ds";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../management/WidgetPages/EditWidget/StrictModeDroppable";
import Card from "./Card";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e9ebf0" : "#fff",
  width: "100%",
  position: "relative",
});

const sortSocialMediaList = (list, data) => {
  return list.sort((a, b) => {
    return data[a]?.position - data[b]?.position;
  });
};

const SocialMediaSelectorComponent = (props) => {
  const {
    data,
    path,
    handleChange,
    label,
    config: { readonly = false },
  } = props;
  const syncFlag = useRef(false);
  const socialMediaList = useMemo(() => {
    const isEmpty = _.isEmpty(data);
    if (isEmpty) return [];
    const list = Object.keys(data);
    const sortedList = sortSocialMediaList(list, data);

    return sortedList;
  }, [data]);
  const [draggableList, setDraggableList] = useState(() => socialMediaList);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(draggableList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let dataCopy = { ...data };

    items.forEach((item, index) => {
      dataCopy[item] = {
        ...dataCopy[item],
        position: index + 1,
      };
    });

    setDraggableList(items);
    handleChange(path, dataCopy);
  };

  const handleSocialMediaUpdate = (prop, newPropObj) => {
    const dataCopy = { ...data };
    dataCopy[prop] = newPropObj;
    handleChange(path, dataCopy);
  };

  useEffect(() => {
    if (syncFlag.current === false && socialMediaList !== draggableList) {
      setDraggableList(socialMediaList);
      syncFlag.current = true;
    }
  }, [socialMediaList, draggableList]);

  return (
    <>
      <Font className="social-media-title">{label}</Font>
      <DragDropContext onDragEnd={onDragEnd} key={path}>
        <StrictModeDroppable droppableId={path} direction="vertical">
          {(provided, snapshot) => (
            <section
              className="p-3"
              style={{
                backgroundColor: "#F3F7FD",
                borderRadius: "8px",
                overflowY: "auto",
                ...getListStyle(snapshot.isDraggingOver),
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {draggableList?.map((socialMedia, index) => (
                <Draggable
                  key={`${path}-${socialMedia}`}
                  draggableId={`${path}-${socialMedia}`}
                  index={index}
                  isDragDisabled={readonly}
                >
                  {(providedItem) => (
                    <section
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                      key={`${path}-${socialMedia}-card`}
                    >
                      <Card
                        socialMedia={data[socialMedia]}
                        id={socialMedia}
                        handleSocialMediaUpdate={handleSocialMediaUpdate}
                        readOnly={readonly}
                      />
                    </section>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </section>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </>
  );
};

export const SocialMediaSelectorControl = withJsonFormsControlProps(
  memo(SocialMediaSelectorComponent)
);
