import PropTypes from "prop-types";
import { getDateWithFormat } from "../../utils/commons";

import "./performancesTable.scss";

const PerformancesTable = ({ performancesData, currentProgramTimezone }) => {
  return (
    <div className="performances-container">
      <div className="performances-list-container">
        <table className="performances-list-table">
          <thead>
            <tr>
              <th className="performances-column-id">ID</th>
              <th className="performances-column-user">User</th>
              <th className="performances-column-activity-type">Start Date</th>

              <th className="performances-column-date">Campaign</th>
              <th className="performances-column-outcome-qty">Outcome Name</th>
              <th className="performances-column-outcome-qty">Outcome Quantity</th>
              <th className="performances-column-outcome-qty">Activity Type</th>
              <th className="performances-column-status">Status</th>
            </tr>
          </thead>
          <tbody>
            {performancesData.map((item, index) => {
              const campaign = item.campaign || {};

              const codeGroupID = item.id || "-";
              const codeGroupName = item.title || "-";
              const codeActiveStartDate = item.activeStartDate || null;

              const producerCompany = campaign.category || "-";
              const codesGeneratedCount =
                item.maxWinsPerUser !== undefined && item.maxWinsPerUser !== null
                  ? item.maxWinsPerUser
                  : "-";
              const codesUsedCount =
                item.codesGeneratedCount !== undefined &&
                item.codesGeneratedCount !== null
                  ? item.codesGeneratedCount
                  : "-";
              let compositeStatus = item.campaignStatus || "-";

              //TODO - temp code. must be remove this once API returns the correct status
              if (compositeStatus === "draft") {
                compositeStatus = "Pending";
              } else if (compositeStatus === "active") {
                compositeStatus = "Approved";
              } else {
                compositeStatus = "Rejected";
              }

              const compositeStatusClass = compositeStatus
                .toLowerCase()
                .replace(/\s/g, "-");

              return (
                <tr key={item.codeGroupId + " " + index}>
                  <td>{codeGroupID}</td>
                  <td>{codeGroupName}</td>
                  <td>
                    {codeActiveStartDate
                      ? getDateWithFormat(
                          codeActiveStartDate,
                          currentProgramTimezone
                        )
                      : "-"}
                  </td>

                  <td>{producerCompany}</td>
                  <td>{codeGroupName}</td>
                  <td>{codesGeneratedCount}</td>

                  <td>{codesUsedCount}</td>

                  <td className="performances-widget-status-type">
                    <span
                      className={`performances-lifecycle-status ${compositeStatusClass}`}
                    >
                      {compositeStatus}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PerformancesTable.propTypes = {
  performancesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      startsAt: PropTypes.string,
      campaign: PropTypes.shape({
        category: PropTypes.string,
      }),
      maxWinsPerUser: PropTypes.number,
      codesGeneratedCount: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  codeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      codeGroupId: PropTypes.string.isRequired,
      codesUsedCount: PropTypes.number,
      codesGeneratedCount: PropTypes.number,
      administrationStatus: PropTypes.string,
      compositeStatus: PropTypes.string,
      lifecycleStatus: PropTypes.string,
      basicSetup: PropTypes.shape({
        codeGroupName: PropTypes.string,
        codeActiveStartDate: PropTypes.string,
        codeActiveEndDate: PropTypes.string,
      }),
      producerPackaging: PropTypes.shape({
        producerCompany: PropTypes.string,
        codesPrintedCount: PropTypes.number,
        brand: PropTypes.string,
      }),
    })
  ).isRequired,
  handleActionButtons: PropTypes.func.isRequired,
};

export default PerformancesTable;
