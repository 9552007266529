import { gql } from "@apollo/client";

const createSurveyContent = gql`
  mutation SaveContent($content: ContentInput!) {
    saveContent(content: $content) {
      id
      title
      slug
      segments
      description
      status
      paused
      activeStartDate
      activeEndDate
      contentDefinition {
        id
      }
      contentData
      createdAt
      updatedAt
      deletedAt
      pinned
    }
  }
`;

export default createSurveyContent;
