import { useMutation } from "@apollo/client";
import { Modal } from "pepsico-ds";
import { useState } from "react";
import { updateCodeGroupsActiveEndDateMutation } from "../../graphql/mutations/codeGroupMutations";
import DateTimePickerWithTimeZone from "../common/DateTime/DateTimePickerWithTimeZone";

const BulkEditEndDateModal = ({
  showModal,
  setShowModal,
  selectedItems,
  setSelectedItems,
  refetchListing,
  setParentToastData,
  currentProgramTimezone,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [updateCodeGroupEndDate, { loading }] = useMutation(
    updateCodeGroupsActiveEndDateMutation
  );
  const [partialFailedItems, setPartialFailedItems] = useState([]);
  const [currentContent, setCurrentContent] = useState("form");
  const [showSecondaryButton, setShowSecondaryButton] = useState(true);

  const handleCloseModal = () => {
    setEndDate(null);
    setPartialFailedItems([]);
    setCurrentContent("form");
    setShowModal(false);
  };

  const handleSubmit = async () => {
    if (!endDate) return;

    try {
      await updateCodeGroupEndDate({
        variables: {
          batchCodeGroupsInput: {
            codeGroupsId: selectedItems,
            codeActiveEndDate: endDate,
          },
        },
      });

      setSelectedItems([]);

      await refetchListing();

      setParentToastData([
        {
          id: Date.now(),
          text: `Successfully updated end date for ${selectedItems.length} code group(s).`,
          type: "success",
        },
      ]);

      handleCloseModal();
    } catch (error) {
      const graphQLErrors = error?.graphQLErrors || [];
      const firstError = graphQLErrors[0];

      if (firstError?.extensions?.partialFailed?.length) {
        const partialFailed = firstError.extensions.partialFailed;
        setSelectedItems([]);
        setPartialFailedItems(partialFailed);
        setCurrentContent("partialFailed");
        setShowSecondaryButton(false);

        setParentToastData([
          {
            id: Date.now(),
            text:
              firstError.message ||
              "Some code groups were updated, but some failed.",
            type: "error",
          },
        ]);
      } else {
        setParentToastData([
          {
            id: Date.now(),
            text: "Something went wrong while updating end date.",
            type: "error",
          },
        ]);
      }
    }
  };

  const renderModalContent = () => {
    if (currentContent === "form") {
      return (
        <div>
          <DateTimePickerWithTimeZone
            uischema={{
              minimum: "currentDateTime",
            }}
            timezone={currentProgramTimezone}
            dateTime={endDate}
            setDateTime={setEndDate}
            label="Active End Date/Time"
            slotProps={{ popper: { disablePortal: true, placement: "auto" } }}
          />
        </div>
      );
    }

    if (currentContent === "partialFailed") {
      return (
        <div style={{ padding: "1rem" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Reason</th>
              </tr>
            </thead>
            <tbody>
              {partialFailedItems.map((item) => (
                <tr key={item.code_group_id}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {item.code_group_name || item.code_group_id}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {item.message}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const modalTitle =
    currentContent === "form"
      ? "Change the selected code groups’ active end date to..."
      : "Failed to update the following code groups";
  const primaryButtonProps =
    currentContent === "form"
      ? {
          size: "medium",
          text: "Save",
          variant: "primary",
          onClick: handleSubmit,
          disabled: loading || !endDate,
        }
      : {
          size: "medium",
          text: "Close",
          variant: "primary",
          onClick: () => handleCloseModal(),
        };
  const secondaryButtonProps =
    currentContent === "form"
      ? {
          size: "medium",
          text: "Cancel",
          variant: "secondary",
          onClick: () => handleCloseModal(),
        }
      : null;

  return (
    <Modal
      showModal={showModal}
      title={modalTitle}
      onCloseModal={handleCloseModal}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      showTertiaryButton={false}
      showSecondaryButton={showSecondaryButton}
      showLink={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default BulkEditEndDateModal;
