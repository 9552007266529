// filterJsonSchema.js

import {
  PERFORMANCES_ACTIVITY_TYPES,
  PERFORMANCES_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
export const performancesFilterJsonSchema = {
  type: "object",
  properties: {
    campaignStatus: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    producerCompany: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_ACTIVITY_TYPES).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
  },
};

// filterUiSchema.js

export const performancesFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/producerCompany",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Activity Type",
      label: "Activity Type",
    },
    {
      type: "Control",
      scope: "#/properties/campaignStatus",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Status",
      label: "Status",
    },
  ],
};

export const performancesFilterAllJsonSchema = {
  type: "object",
  properties: {
    performanceId: {
      type: "string",
      title: "Search",
    },
    user: {
      type: "string",
      title: "Search",
    },
    campaign: {
      type: "string",
      title: "Search",
    },
    lifecycleStatus: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    producerCompany: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_ACTIVITY_TYPES).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    // producerCompany: {
    //   enum: Object.values(PERFORMANCES_ACTIVITY_TYPES),
    // },
    activeStartDateFrom: {
      type: "string",
      format: "date",
      title: "From Date",
    },
    activeEndDateTo: {
      type: "string",
      format: "date",
      title: "To Date",
    },
  },
};

export const performancesFilterAllUiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/performanceId",
      //component: "searchInput",
      placeholder: "Search by ID",
      label: "ID",
    },
    {
      type: "Control",
      scope: "#/properties/user",
      //component: "searchInput",
      placeholder: "Search by User",
      label: "User ID",
    },
    {
      type: "Control",
      scope: "#/properties/campaign",
      //component: "searchInput",
      placeholder: "Search by Campaign",
      label: "Campaign",
    },
    {
      type: "Control",
      scope: "#/properties/activeStartDateFrom",
      component: "datePickerMaterialUI",
      label: "From Date",
    },
    {
      type: "Control",
      scope: "#/properties/activeEndDateTo",
      component: "datePickerMaterialUI",
      label: "To Date",
    },
    {
      type: "Control",
      scope: "#/properties/producerCompany",
      component: "activityTypeSelect",
      selection: "multiple",
      placeholder: "Activity Type",
      label: "Activity Type",
    },
    {
      type: "Control",
      scope: "#/properties/lifecycleStatus",
      component: "activityTypeSelect",
      selection: "multiple",
      placeholder: "Status",
      label: "Status",
    },
  ],
};
