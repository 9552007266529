import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import PreviewCardsCarouselCampaigns from "../../previewWidget/PreviewCardsCarouselCampaigns/PreviewCardsCarouselCampaigns";
import { PreviewCardsCarouselSweepstakesComponent } from "../../previewWidget/PreviewCardsCarouselSweepstakes/PreviewCardsCarouselSweepstakes";
import "./simpleGroupPreviewCards.scss";

const SimpleGroupPreviewCards = (props) => {
  const { uischema, schema, path, enabled, renderers, cells, visible } = props;
  return (
    <>
      {visible && (
        <div className={`simple-form-group ${uischema.className ?? ""}`}>
          {uischema.label && <h6 className="font-lg font-bold">{uischema.label}</h6>}
          <div className="simple-form-group-wrapper">
            {uischema.elements.map((child, index) => (
              <JsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            ))}
          </div>
          {uischema.page === "Sweepstakes" ? (
            <PreviewCardsCarouselSweepstakesComponent styles={props} />
          ) : (
            <PreviewCardsCarouselCampaigns styles={props} />
          )}
        </div>
      )}
    </>
  );
};

export const SimpleGroupPreviewCardsControl = withJsonFormsLayoutProps(
  SimpleGroupPreviewCards
);
