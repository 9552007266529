import { withJsonFormsControlProps } from "@jsonforms/react";

const SummaryImageComponent = ({ data, label, uischema }) => {
  return (
    <div className="details-image-wrapper">
      <span className="details-section-label">{label}</span>
      <img
        src={data}
        alt={label}
        className={`details-image ${uischema.className || ""}`}
      ></img>
    </div>
  );
};

export const SummaryImageControl = withJsonFormsControlProps(SummaryImageComponent);
