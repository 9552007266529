import { withJsonFormsControlProps } from "@jsonforms/react";
import { useEffect } from "react";
import TextInputField from "./IntergerOrNullInput";

const IntergerOrNullInputComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
    visible,
  } = props;

  useEffect(() => {
    if (!enabled && data === null && uischema.ifEmptyThen === "null") {
      return handleChange(path, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  const updateValue = (newVal) => {
    handleChange(path, newVal);
  };

  return (
    <TextInputField
      value={data}
      disabled={!enabled}
      label={uischema.showLabel === false ? "" : label}
      updateValue={updateValue}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      visible={visible}
    />
  );
};

export const IntergerOrNullInputControl = withJsonFormsControlProps(
  IntergerOrNullInputComponent
);
