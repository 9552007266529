export const programConfigDuplicateJson = {
  loadProgram: {
    id: "0190a279-9d66-7088-8138-2fd29decfe86",
    name: "Pepsico Tasty Rewards",
    description: "Program configuration for PTR",
    slug: "ptr-program",
    logo: "/logo.png",
    defaultValues: {},
    configurations: [],
    tags: {
      campaign: [
        "ProfileTag1",
        "test tag",
        "hi",
        "qa_tag_test",
        "doritos",
        "#@$",
        "Fun Sweeps",
        "AutomationTag2",
        "Segment",
        "    ",
        "Test",
        "LinkTag1",
        "e2e",
        "@@@@@@",
        "BAM_PREPROD",
        "@#@#",
        "ReferFriendTag1",
        "Check Tag",
        "Automation Tag 2",
        "Sweepstakes tag",
        "Cheetos Hands-Free ",
        "abc",
        "@@@@@",
        "testts",
        "fgdfgdf",
        "BAM_STAGE",
        "q",
        "1",
        "P10-2024",
        "Automation Tag2",
        "Automation Tag1",
        "QA-BAM-PREPROD",
        "Automation Tag3",
      ],
      content: [],
    },
    localization: {
      programTimezone: "America/Chicago",
      countries: [
        {
          key: "US",
          value: {
            languages: ["en-us", "es-us"],
            currencies: ["USD"],
            addressSchema: {},
          },
        },
      ],
    },
    campaignsGroups: [],
    campaigns: [
      {
        key: "create_a_profile",
        value: {
          enabled: true,
          label: "Create a Profile",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["registration"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "22b689e8-9e1c-47ec-9412-134eba7b947f",
                name: "Registration",
                description:
                  "Awards entries when a user registers for the first time.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "registration",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "146bfcf9-5783-4ff7-bfdc-cf3181e47787",
              name: "Create Profile campaign content definition",
              slug: "create-profile-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Create Profile Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "create-profile-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "refer_a_friend",
        value: {
          enabled: true,
          label: "Refer a Friend",
          image:
            "https://pepsicotest.getbynder.com/m/12bbd722c5681cc6/original/content_4f47850d-e1a2-477b-9878-54607df8d0b0.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["refer-a-friend"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "b75d1a86-bb1f-4bb2-a1b0-86246bb4f8a0",
                name: "Refer a Friend",
                description: "Awards entries when a user refers a friend.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "refer-a-friend",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "e9fe2be9-d622-4dbe-992b-e29bb8fb0af9",
              name: "Refer a Friend campaign content definition",
              slug: "refer-a-friend-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Refer a Friend Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "refer-a-friend-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_code_redemption_batch",
        value: {
          enabled: true,
          label: "Code Redemption - Batch (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-batch-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "1906c096-d2b4-41aa-9994-28079aa26acc",
                name: "Batch Code (LATAM)",
                description: "Rewards user when a valid batch code is submitted.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["latam-batch-code-rule"],
                requiredOutcomes: [],
                slug: "latam-batch-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "eb8b9910-a92d-43fc-a409-a3c692aa2f2e",
                    name: "Batch Code",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code_length: {
                          title: "Batch Code Length",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["code_length"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-batch-code-rule",
                    uiSchema: {
                      type: "VerticalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/code_length",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["latam-number-of-entries"],
                    rules: [
                      {
                        id: "ea3ad6f3-f4a8-4780-b2cc-03f7eeb905db",
                        name: "Entries (LATAM)",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            entries: {
                              title: "Number of Entries to Award",
                              type: "number",
                            },
                          },
                          required: ["entries"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-number-of-entries",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/entries",
                            },
                          ],
                        },
                      },
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "0de10214-aaf4-4e2b-b629-cb478b79e397",
              name: "Code Redemption (LATAM)",
              slug: "latam-code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                      },
                      instructions_link: {
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      title: {
                        title: "Title",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        format: "uri",
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: [
                      "title",
                      "terms_and_conditions_text",
                      "terms_and_conditions_link",
                      "input_label",
                      "input_placeholder",
                      "cta_label",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "click_on_a_link",
        value: {
          enabled: true,
          label: "Click On Link",
          image:
            "https://pepsicotest.getbynder.com/m/7158fd8753d4f38e/original/content_b8e13709-5c34-425b-be9c-1d0fd51a2408.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["click-link"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "dd7e25eb-1771-43c7-80b0-fc0c4ef4b4ec",
                name: "Click Link",
                description: "Awards entries when a user clicks a link.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["match-url"],
                requiredOutcomes: ["award-points"],
                slug: "click-link",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e430-7c6d-a277-0a919e72abf7",
                    name: "Match URL",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        urls: {
                          minItems: 1,
                          format: "uri",
                          title: "URL",
                          type: "array",
                        },
                      },
                      required: ["urls"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-url",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "singleInputArray",
                          scope: "#/properties/urls",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "be986d3d-5925-4e68-9d49-b8fa8eda89d5",
              name: "Click on Link campaign content definition",
              slug: "click-on-link-campaign-content",
              version: 2,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Click on Link Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        format: "uri",
                        title: "Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "link", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "click-on-link-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "bonus",
        value: {
          enabled: true,
          label: "Bonus",
          image:
            "https://pepsicotest.getbynder.com/m/42bf29a30f7678c4/original/content_524313cf-91fc-42c9-b697-7ffa06ce26c9.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["bonus"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "54c338f5-3ff1-4000-bee5-27bfa3685a35",
                name: "Bonus Campaign",
                description: "Awards entries associated to segment of users.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["user-in-segment"],
                requiredOutcomes: ["award-points"],
                slug: "bonus",
                maxAllowed: null,
                rules: [
                  {
                    id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                    name: "Qualified Segments",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        segment_ids: {
                          minItems: 1,
                          title: "ids of the segments",
                          type: "array",
                          items: {
                            type: "string",
                          },
                        },
                      },
                      required: ["segment_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "user-in-segment",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "segmentSelect",
                          scope: "#/properties/segment_ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "c9c1c3cb-2802-440a-8638-0482889f0b4c",
              name: "Bonus campaign based on segment content definition",
              slug: "bonus-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Bonus campaign based on segment content definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        format: "uri",
                        title: "Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                    info: "To redirect member to when clicking on the activity card",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "bonus-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "streak",
        value: {
          enabled: true,
          label: "Streak",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["streak"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "2176304f-3ed0-4d0a-92b9-eed8b019bb88",
                name: "Streak",
                description: "Evaluates a streak.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["user-streak-limit"],
                requiredOutcomes: ["award-points-streak"],
                slug: "streak",
                maxAllowed: null,
                rules: [
                  {
                    id: "8ea30f0a-57bc-42ac-9e8d-816d888e8787",
                    name: "User Streak limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Streak Limit",
                          type: "integer",
                          minimum: 1,
                        },
                        performance_count: {
                          title: "Streak Goal Count",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-streak-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/performance_count",
                        },
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "019091a6-0304-7b31-9e77-48d9dbd585cf",
                    name: "Award Entries",
                    slug: "award-points-streak",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["validate-streak", "calculate-points"],
                    rules: [
                      {
                        id: "3b22b629-81ec-4213-a6be-4fd921acc88e",
                        name: "Validate Streak",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            performance_count: {
                              title: "Re-Enter Streak Goal Count",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                        },
                        ruleFunction: "validate",
                        maxAllowed: null,
                        slug: "validate-streak",
                        uiSchema: {
                          type: "HorizontalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/performance_count",
                            },
                          ],
                        },
                      },
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                name: "User Campaign limit",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    limit: {
                      title: "User Campaign limit",
                      type: "integer",
                      minimum: 1,
                    },
                  },
                  required: ["limit"],
                },
                ruleFunction: "validate",
                maxAllowed: 1,
                slug: "user-campaign-limit",
                uiSchema: {
                  type: "HorizontalLayout",
                  class_name: "custom-width",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 10,
                      scope: "#/properties/limit",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "1c5b0e98-36a5-4a6f-a507-258fab61f992",
              name: "Streak campaign content definition",
              slug: "streak-campaign-content",
              version: 1,
              description: "Definition of streak content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Streak Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "streak-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "generic",
        value: {
          enabled: true,
          label: "Generic Campaign",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["generic"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "10436008-2b4f-42a3-8470-f6ec15b0e854",
                name: "Generic",
                description: "Evaluates a generic campaign.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: [],
                slug: "generic",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "e1b85a7d-fd04-4457-b478-99bd13fa2a56",
                    name: "Empty Rule",
                    hidden: false,
                    inputSchema: {},
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "empty-rule",
                    uiSchema: {},
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "f49d8a08-3e60-4a9f-ba99-34e42a62dad1",
              name: "Generic campaign content definition",
              slug: "generic-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Generic Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "generic-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_code_redemption_unique",
        value: {
          enabled: true,
          label: "Code Redemption - Unique (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-unique-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "4f5208e5-5987-4d77-90cd-f0c9a2c31f6a",
                name: "Unique Code (LATAM)",
                description: "Rewards user when a valid unique code is submitted.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["latam-unique-code-rule"],
                requiredOutcomes: [],
                slug: "latam-unique-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "b2dd2a38-bc1d-4c18-8f91-182bc66d4df9",
                    name: "Unique Code",
                    hidden: true,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code_group_ids: {
                          minItems: 1,
                          type: "array",
                        },
                      },
                      required: ["code_group_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "latam-unique-code-rule",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            initialFilter: {
                              lifecycleStatus: "ACTIVE",
                              status: "ENABLED",
                            },
                          },
                          component: "codeGroupSelector",
                          type: "Control",
                          scope: "#/properties/code_group_ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["latam-number-of-entries"],
                    rules: [
                      {
                        id: "ea3ad6f3-f4a8-4780-b2cc-03f7eeb905db",
                        name: "Entries (LATAM)",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            entries: {
                              title: "Number of Entries to Award",
                              type: "number",
                            },
                          },
                          required: ["entries"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-number-of-entries",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/entries",
                            },
                          ],
                        },
                      },
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "0de10214-aaf4-4e2b-b629-cb478b79e397",
              name: "Code Redemption (LATAM)",
              slug: "latam-code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                      },
                      instructions_link: {
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      title: {
                        title: "Title",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        format: "uri",
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: [
                      "title",
                      "terms_and_conditions_text",
                      "terms_and_conditions_link",
                      "input_label",
                      "input_placeholder",
                      "cta_label",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "opt_in_for_notification",
        value: {
          enabled: true,
          label: "Opt in for Notifications",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["notifications-opt-in"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "d317f309-f98f-4518-8421-3693fad8ecf6",
                name: "Notifications Opt-In",
                description:
                  "Awards entries when a user opts-in to receive notifications.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "notifications-opt-in",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "0d923c02-3055-44a5-8e98-7aa071ae6d3c",
              name: "Opt in for notification campaign content definition",
              slug: "opt-for-notifications-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Opt in for notification Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "opt-for-notifications-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "upload_receipt",
        value: {
          enabled: true,
          label: "Upload a Receipt",
          image:
            "https://pepsicotest.getbynder.com/m/6183cec2bcf98239/original/content_86cd7a15-1212-464b-854f-573ecd02d314.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["upload-receipt"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "545988e0-932b-4420-a0d1-868e4743793e",
                name: "Upload Receipt",
                description:
                  "Awards entries after a user uploads a receipt containing the selected keywords",
                inputSchema: {
                  type: "object",
                  properties: {
                    receipt_data: {
                      type: "object",
                    },
                  },
                  required: ["receipt_data"],
                },
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "upload-receipt",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e42f-75ca-884b-67d512454a6f",
                    name: "Brands",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of brand ids selected by the admin",
                          filter_by: "BRAND",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-brands-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907e31-8c2a-7388-b934-2ef174dc93d2",
                    name: "Retailers",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of outlet ids selected by the admin",
                          filter_by: "OUTLET",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-outlets-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190dcb4-54da-76bc-88a4-dd9f143ced9e",
                    name: "SKU/Products",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description:
                            "Array of SKU/product ids selected by the admin",
                          filter_by: "PRODUCT",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-products-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907ec4-1a19-76ac-8d44-4b3dc2c6e8ec",
                    name: "Receipt recency",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        end_timestamp: {
                          title: "End Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                        start_timestamp: {
                          title: "Start Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                      },
                      required: ["start_timestamp"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "receipt-in-date-range",
                    uiSchema: {
                      label: "Receipt date",
                      type: "HorizontalLayout",
                      label_classname: "receipt-recency-label",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          component: "dateTimePicker",
                          scope: "#/properties/start_timestamp",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                gt: "start_timestamp",
                              },
                            },
                            effect: "VALIDATE",
                          },
                          component: "dateTimePicker",
                          type: "Control",
                          scope: "#/properties/end_timestamp",
                        },
                      ],
                    },
                  },
                  {
                    id: "019073a7-39ff-7e45-b60d-c41c929f5809",
                    name: "Minimum Purchase Value",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        min_purchase_val: {
                          default: 1,
                          title: "Min Purchase Value",
                          type: "number",
                          minimum: 1,
                        },
                      },
                      required: ["min_purchase_val"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "min-purchase-val",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/min_purchase_val",
                          prefexLabel: "Per",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "dea1225e-0b0e-4607-9ab2-c274b600de28",
              name: "Upload receipt campaign content definition",
              slug: "upload-receipt-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      redirect_link: {
                        format: "uri",
                        title: "Link to redirect",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/redirect_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "upload-receipt-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "log_in",
        value: {
          enabled: true,
          label: "Log In",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["login"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "e1d93995-f4df-4799-bc1d-e4b2dae3fac6",
                name: "Login",
                description: "Tracks logs in for use in streaks.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["check-streaks"],
                slug: "login",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "8f94f8fe-2535-41ef-ab3d-24491c155728",
              name: "Log in campaign content definition",
              slug: "log-in-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Log in Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "log-in-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_receipt_upload",
        value: {
          enabled: true,
          label: "Receipt Upload (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/6183cec2bcf98239/original/content_86cd7a15-1212-464b-854f-573ecd02d314.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-upload-receipt"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "09454917-b3c6-41c1-812a-d95487fd2d85",
                name: "Upload Receipt (LATAM)",
                description:
                  "Awards entries after a user uploads a receipt containing the selected keywords",
                inputSchema: {
                  type: "object",
                  properties: {
                    receipt_data: {
                      type: "object",
                    },
                  },
                  required: ["receipt_data"],
                },
                uiSchema: null,
                requiredRules: ["min-purchase-val"],
                requiredOutcomes: [],
                slug: "latam-upload-receipt",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e42f-75ca-884b-67d512454a6f",
                    name: "Brands",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of brand ids selected by the admin",
                          filter_by: "BRAND",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-brands-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907e31-8c2a-7388-b934-2ef174dc93d2",
                    name: "Retailers",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of outlet ids selected by the admin",
                          filter_by: "OUTLET",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-outlets-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190dcb4-54da-76bc-88a4-dd9f143ced9e",
                    name: "SKU/Products",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description:
                            "Array of SKU/product ids selected by the admin",
                          filter_by: "PRODUCT",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-products-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907ec4-1a19-76ac-8d44-4b3dc2c6e8ec",
                    name: "Receipt recency",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        end_timestamp: {
                          title: "End Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                        start_timestamp: {
                          title: "Start Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                      },
                      required: ["start_timestamp"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "receipt-in-date-range",
                    uiSchema: {
                      label: "Receipt date",
                      type: "HorizontalLayout",
                      label_classname: "receipt-recency-label",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          component: "dateTimePicker",
                          scope: "#/properties/start_timestamp",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                gt: "start_timestamp",
                              },
                            },
                            effect: "VALIDATE",
                          },
                          component: "dateTimePicker",
                          type: "Control",
                          scope: "#/properties/end_timestamp",
                        },
                      ],
                    },
                  },
                  {
                    id: "019073a7-39ff-7e45-b60d-c41c929f5809",
                    name: "Minimum Purchase Value",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        min_purchase_val: {
                          default: 1,
                          title: "Min Purchase Value",
                          type: "number",
                          minimum: 1,
                        },
                      },
                      required: ["min_purchase_val"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "min-purchase-val",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/min_purchase_val",
                          prefexLabel: "Per",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["latam-number-of-entries"],
                    rules: [
                      {
                        id: "ea3ad6f3-f4a8-4780-b2cc-03f7eeb905db",
                        name: "Entries (LATAM)",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            entries: {
                              title: "Number of Entries to Award",
                              type: "number",
                            },
                          },
                          required: ["entries"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-number-of-entries",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/entries",
                            },
                          ],
                        },
                      },
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "2e356581-3500-423a-a634-da366610a0ff",
              name: "Upload Receipt (LATAM)",
              slug: "latam-upload-receipt-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                      },
                      instructions_link: {
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      title: {
                        title: "Title",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        format: "uri",
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: [
                      "title",
                      "terms_and_conditions_text",
                      "terms_and_conditions_link",
                      "input_label",
                      "input_placeholder",
                      "cta_label",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-upload-receipt-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "sweepstakes",
        value: {
          enabled: true,
          label: "Sweepstakes",
          image:
            "https://pepsicotest.getbynder.com/m/56244251ed4e41d6/original/content_aa534b6a-837c-4efa-bc0c-59eee1cb4d2c.png",
          categories: [
            "Bundle",
            "Gift Cards",
            "High Value",
            "Low Value",
            "Medium Value",
            "Trips/Experiences",
          ],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["sweepstakes"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "f589279a-12f8-409f-b90c-6d35482cb9e4",
                name: "Enter Sweepstakes",
                description: "Buy sweepstakes entries",
                inputSchema: {},
                uiSchema: {},
                requiredRules: ["require-phone", "exclude-email"],
                requiredOutcomes: ["purchase-entry"],
                slug: "sweepstakes",
                maxAllowed: 1,
                rules: [
                  {
                    id: "0190c76a-8bf4-7775-bca7-93d959bcc1b4",
                    name: "Require user phone number",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        require_phone: {
                          description: "Require user profile to have phone-number",
                          default: true,
                          title: "Phone Number Required",
                          type: "boolean",
                        },
                      },
                      required: ["require_phone"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "require-phone",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/require_phone",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190c89b-22d5-71b8-b100-572dca435352",
                    name: "Exclude emails",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        domains: {
                          description:
                            "Comma separated values of domains that will be denied",
                          title: "Domains to exclude",
                          type: "array",
                          items: {
                            type: "string",
                          },
                        },
                        exclude: {
                          title: "Exclude certain domains from winners",
                          type: "boolean",
                          default: false,
                        },
                      },
                      required: ["exclude"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "exclude-email",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/exclude",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/exclude",
                            },
                            effect: "ENABLE",
                          },
                          component: "emailDomainsComponent",
                          placeholder: "@pepsico.com, @domain.com",
                          type: "Control",
                          scope: "#/properties/domains",
                          info: "Everyone can join sweeps, but the domains will be excluded only from winner selection process",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "5915a658-199f-4d2e-9b20-2e36b9e51029",
                    name: "Sweepstakes Purchase",
                    slug: "purchase-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["calculate-cost"],
                    rules: [
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "categorySelect",
                              label: "Category",
                              type: "Control",
                              scope: "#/properties/category",
                            },
                            {
                              component: "brandSelect",
                              label: "Brand",
                              type: "Control",
                              scope: "#/properties/brands",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "category",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                category: {
                  title: "Category",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "category"],
            },
          },
          contentSteps: [
            {
              id: "52dee9da-24ba-4d23-877f-dde00733f9b8",
              name: "Sweepstakes Campaign Content Definition",
              slug: "sweepstakes-campaign-content",
              version: 2,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Sweepstakes Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      terms_and_conditions: {
                        format: "uri",
                        description:
                          "URL to consumer terms and conditions to participate in the campaign",
                        title: "Terms and Conditions URL",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "terms_and_conditions",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                l10n: {
                  className: "content-language-wrapper",
                  title: "Content*",
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "SimpleGroup",
                      className: "content-language-wrapper__content",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              options: {
                                format: "data-url",
                              },
                              component: "singleImageUpload",
                              type: "Control",
                              scope:
                                "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                            },
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "VerticalLayout",
                                  elements: [
                                    {
                                      type: "Control",
                                      maxLength: 150,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                    },
                                    {
                                      component: "textarea",
                                      type: "Control",
                                      maxLength: 500,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                    },
                                    {
                                      type: "Control",
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            },
            {
              id: "52dee9da-24ba-4d23-877f-dde00733f9b8",
              name: "Sweepstakes Campaign Content Definition",
              slug: "sweepstakes-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Sweepstakes Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  terms_and_conditions: {
                    format: "uri",
                    description:
                      "URL to consumer terms and conditions to participate in the campaign",
                    title: "Terms and Conditions URL",
                    type: "string",
                  },
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n", "terms_and_conditions"],
              },
              uiSchema: {
                terms_and_conditions: {
                  className: "content-language-wrapper",
                  title: "Content*",
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "SimpleGroup",
                      className: "content-language-wrapper__content",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/terms_and_conditions",
                        },
                      ],
                    },
                  ],
                },
                l10n: {
                  className: "content-language-wrapper",
                  title: "Content*",
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "SimpleGroup",
                      className: "content-language-wrapper__content",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              options: {
                                format: "data-url",
                              },
                              component: "singleImageUpload",
                              type: "Control",
                              scope:
                                "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                            },
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "VerticalLayout",
                                  elements: [
                                    {
                                      type: "Control",
                                      maxLength: 150,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                    },
                                    {
                                      component: "textarea",
                                      type: "Control",
                                      maxLength: 500,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "sweepstakes-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "take_a_survey",
        value: {
          enabled: true,
          label: "Take a Survey",
          image:
            "https://pepsicotest.getbynder.com/m/171682b1a76ade55/original/content_2daf6d01-bf02-48f1-ab38-e33c0fda4dbb.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["take-a-survey"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "69d737c1-59f3-4eaa-97a1-ee87f6288956",
                name: "Take a Survey",
                description: "Awards entries after a user takes a survey.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "array",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["match-survey-ids"],
                requiredOutcomes: ["award-points"],
                slug: "take-a-survey",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "eb5cc6c8-7352-48b9-9e2d-6cf040f501e7",
                    name: "Match Survey Ids",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        survey_ids: {
                          title: "Survey IDs",
                          type: "array",
                        },
                      },
                      required: ["survey_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 0,
                    slug: "match-survey-ids",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "singleInputArray",
                          scope: "#/properties/survey_ids",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "9a523e11-e739-4bca-80af-1e3b9dcfb53f",
              name: "Take a Survey campaign content definition",
              slug: "take-a-survey-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Take a Survey Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      survey_link: {
                        format: "uri",
                        title: "Survey Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "survey_link",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/survey_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "take-a-survey-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "code_redemption",
        value: {
          enabled: true,
          label: "Code Redemption",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["unlimited-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "296cc101-c581-4f65-9a6f-d5a92d32fdda",
                name: "Unlimited Code",
                description: "Awards entries after a user enters an unlimited code.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["match-unlimited-code"],
                requiredOutcomes: ["award-points"],
                slug: "unlimited-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e430-7b8f-9093-1e7f197e6401",
                    name: "Match Unlimited Code",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code: {
                          title: "Unlimited Code",
                          type: "string",
                        },
                      },
                      required: ["code"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-unlimited-code",
                    uiSchema: {
                      type: "VerticalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/code",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "eb979194-6843-4608-bcac-c8e20ac89fef",
              name: "Code Redemption campaign content definition",
              slug: "code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Code Redemption (unlimited) Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 50,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    component: "textarea",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "support",
        value: {
          enabled: false,
          label: "Support Campaign (Do not use)",
          image:
            "https://pepsicotest.getbynder.com/m/7158fd8753d4f38e/original/content_b8e13709-5c34-425b-be9c-1d0fd51a2408.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["support-activity"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "aafa08c2-a9a1-46f9-a942-4cad9f04fae2",
                name: "Support Activity",
                description: "Awards entries when a user completes their profile.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["support-rule"],
                requiredOutcomes: ["support-outcome"],
                slug: "support-activity",
                maxAllowed: null,
                rules: [
                  {
                    id: "27917fd1-fa20-4af3-82f8-d2626a360a4b",
                    name: "Support Rule (will always return false)",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {},
                      required: [],
                    },
                    ruleFunction: "calculate",
                    maxAllowed: null,
                    slug: "support-rule",
                    uiSchema: {},
                  },
                ],
                outcomes: [
                  {
                    id: "cbf9da41-6dcb-418b-b8ef-e517f352c836",
                    name: "Award Entries",
                    slug: "support-outcome",
                    description: "Support Campaign Outcome",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: null,
            inputSchema: null,
          },
          contentSteps: [
            {
              id: "cdd08f4d-b16e-4907-8577-d36ff0aac7fb",
              name: "Support Campaign Content Definition",
              slug: "support-campaign-content",
              version: 1,
              description: "Definition of support campaign content",
              active: true,
              inputSchema: {
                additionalProperties: false,
                $schema: "http://json-schema.org/draft-07/schema#",
                type: "object",
                properties: {
                  title: {
                    type: "string",
                  },
                },
                required: [],
              },
              uiSchema: {},
            },
          ],
          steps: [],
        },
      },
      {
        key: "complete_your_profile",
        value: {
          enabled: true,
          label: "Complete your Profile",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["profile-completion"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "504db4db-fe5e-427e-ae82-a7b20fd9904b",
                name: "Profile Completion",
                description: "Awards entries when a user completes their profile.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "profile-completion",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "0266d30c-f58e-4099-8ffc-78fda04d49b4",
              name: "Complete your Profile campaign content definition",
              slug: "complete-your-profile-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Complete your Profile Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "complete-your-profile-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
    ],
    widgets: [
      {
        id: "c6d54ee4-562a-4f9f-8023-5b72896890ea",
        embedCode:
          '<pep-loyalty widget-id="c6d54ee4-562a-4f9f-8023-5b72896890ea" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test",
        isActive: true,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T11:42:06.737498369Z",
        updatedAt: "2024-07-12T11:42:06.737498369Z",
      },
      {
        id: "1fd076a8-66b7-48e1-a29a-574a73ff0849",
        embedCode:
          '<pep-loyalty widget-id="1fd076a8-66b7-48e1-a29a-574a73ff0849" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Widget 1",
        isActive: false,
        widgetData: {
          headerTextColor: "#0057A2",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 100,
          rowTextColor: "#000000",
          customWidget: true,
          headerRowColor: "#F8F8F8",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T12:17:55.153991314Z",
        updatedAt: "2024-07-12T12:17:55.153991314Z",
      },
      {
        id: "767372eb-9e2f-4d48-9f17-136163a83ffc",
        embedCode:
          '<pep-loyalty widget-id="767372eb-9e2f-4d48-9f17-136163a83ffc" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test",
        isActive: false,
        widgetData: {
          headerTextColor: "#758797",
          pointsTextColor: "#010a11",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 1000,
          rowTextColor: "#b80808",
          customWidget: true,
          headerRowColor: "#130707",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T12:22:16.422189541Z",
        updatedAt: "2024-07-12T12:22:16.422189541Z",
      },
      {
        id: "9edaf71e-d6b6-41b8-83fb-2437b8f76759",
        embedCode:
          '<pep-loyalty widget-id="9edaf71e-d6b6-41b8-83fb-2437b8f76759" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "@@#$#@@$$",
        isActive: true,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T12:40:12.845687942Z",
        updatedAt: "2024-07-12T12:40:12.845687942Z",
      },
      {
        id: "4a323ea5-0905-4b53-977e-454bf35e3b44",
        embedCode:
          '<pep-loyalty widget-id="4a323ea5-0905-4b53-977e-454bf35e3b44" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test 1",
        isActive: true,
        widgetData: {
          showIcons: false,
          textMainColor: "#1f6bcd",
          showWelcomeMessage: false,
          showMemberBalance: false,
          customWidget: true,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          textHighlightColor: "#373334",
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T14:01:32.893711486Z",
        updatedAt: "2024-07-24T21:06:57.169103470Z",
      },
      {
        id: "5946a7c5-7b23-4c7a-a59e-fdd2626acd34",
        embedCode:
          '<pep-loyalty widget-id="5946a7c5-7b23-4c7a-a59e-fdd2626acd34" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Widget 1",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 100,
          rowTextColor: "#000000",
          customWidget: true,
          headerRowColor: "#F8F8F8",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-12T12:47:59.599606458Z",
        updatedAt: "2024-07-24T21:16:38.274505982Z",
      },
      {
        id: "876af8ad-d2e3-41c3-ad6e-f4e7fdbbeb56",
        embedCode:
          '<pep-loyalty widget-id="876af8ad-d2e3-41c3-ad6e-f4e7fdbbeb56" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Andreza sweeps History test",
        isActive: true,
        widgetData: {
          headerTextColor: "#5b5f62",
          pointsTextColor: "#c9668e",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 410,
          rowTextColor: "#26bb34",
          customWidget: true,
          headerRowColor: "#981717",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-24T21:21:25.202650270Z",
        updatedAt: "2024-07-24T21:21:25.202650270Z",
      },
      {
        id: "d12bfbb6-529e-452b-a0d6-6b4649c10ff0",
        embedCode:
          '<pep-loyalty widget-id="d12bfbb6-529e-452b-a0d6-6b4649c10ff0" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Andreza Member Information created",
        isActive: false,
        widgetData: {
          showIcons: true,
          textMainColor: "#0e4caa",
          showWelcomeMessage: false,
          showMemberBalance: false,
          iconMainColor: "#0e4caa",
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-24T21:21:52.849967125Z",
        updatedAt: "2024-07-24T21:21:52.849967125Z",
      },
      {
        id: "78aa2e53-0ad3-4c29-810b-fcdf1fbf794d",
        embedCode:
          '<pep-loyalty widget-id="78aa2e53-0ad3-4c29-810b-fcdf1fbf794d" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Andreza Activity History",
        isActive: true,
        widgetData: {
          historyType: "ACTIVITY_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-24T21:23:11.629601392Z",
        updatedAt: "2024-07-24T21:23:11.629601392Z",
      },
      {
        id: "fa944dde-1960-4325-b750-e6058a9dabd7",
        embedCode:
          '<pep-loyalty widget-id="fa944dde-1960-4325-b750-e6058a9dabd7" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "original widget",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "a14006e4-1b2d-49c1-a144-e94cd64881f7",
            "a6953898-8201-45fc-84a9-f260e9605b45",
            "9a5431d9-afa8-4564-bf81-7d25b03b374a",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "17776ecf-62ea-41d4-9228-c8bb9809e231",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-08-02T19:24:07.074179229Z",
        updatedAt: "2024-08-02T19:24:07.074179229Z",
      },
      {
        id: "91431ff7-4af3-4ec8-bf39-d3b38b32db85",
        embedCode:
          '<pep-loyalty widget-id="91431ff7-4af3-4ec8-bf39-d3b38b32db85" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Widget List of Sweeps",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "62cce609-2239-4151-bb62-93114d1331d7",
            "839aae32-165a-4010-aba7-ada07932ee29",
            "1df4c9f5-b0a6-4717-b598-e7846ddfc124",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-08-07T20:46:16.388653851Z",
        updatedAt: "2024-08-07T20:46:16.388653851Z",
      },
      {
        id: "f0059ef8-ce6e-4397-81d0-e661a800e2a4",
        embedCode:
          '<pep-loyalty widget-id="f0059ef8-ce6e-4397-81d0-e661a800e2a4" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "PREPROD - Featured Activities - TEST",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "1df4c9f5-b0a6-4717-b598-e7846ddfc124",
            "85f55fbc-0688-40d9-87ac-c041ac4be539",
            "801d5ec6-a74c-4399-9d75-a38eebe706b6",
            "f88268e4-33bc-4171-b8c6-97fbd770ab32",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-08T15:04:29.170081752Z",
        updatedAt: "2024-08-08T15:05:12.183848640Z",
      },
      {
        id: "63d29af0-ee96-4f3d-9779-5a8c9979a0db",
        embedCode:
          '<pep-loyalty widget-id="63d29af0-ee96-4f3d-9779-5a8c9979a0db" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Andreza QA Sweeps",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["1df4c9f5-b0a6-4717-b598-e7846ddfc124"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-08T16:26:21.840374362Z",
        updatedAt: "2024-08-08T16:26:21.840374362Z",
      },
      {
        id: "b4f173f0-e30f-4a59-a1ce-44a4f7eb7592",
        embedCode:
          '<pep-loyalty widget-id="b4f173f0-e30f-4a59-a1ce-44a4f7eb7592" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "TestRegressionWDS Member Information",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T11:34:34.394867403Z",
        updatedAt: "2024-08-09T11:34:34.394867403Z",
      },
      {
        id: "d595556c-bb43-4951-8597-b883368ce467",
        embedCode:
          '<pep-loyalty widget-id="d595556c-bb43-4951-8597-b883368ce467" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "TestRegressionWDS Member History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T11:36:16.621275561Z",
        updatedAt: "2024-08-09T11:36:16.621275561Z",
      },
      {
        id: "6595c827-d962-487b-b210-1719a91680dc",
        embedCode:
          '<pep-loyalty widget-id="6595c827-d962-487b-b210-1719a91680dc" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "TestRegressionWDS Campaigns",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["dbd9fdad-4036-4f1e-8714-8bc00fc8f426"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "17776ecf-62ea-41d4-9228-c8bb9809e231",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T11:54:41.191746537Z",
        updatedAt: "2024-08-09T11:54:41.191746537Z",
      },
      {
        id: "6e16ff90-263d-4a4f-aefa-8bdf596daf91",
        embedCode:
          '<pep-loyalty widget-id="6e16ff90-263d-4a4f-aefa-8bdf596daf91" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test 007",
        isActive: false,
        widgetData: {
          showIcons: true,
          textMainColor: "#0E4CAA",
          showWelcomeMessage: false,
          showMemberBalance: true,
          iconMainColor: "#0E4CAA",
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: false,
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T07:21:37.129658663Z",
        updatedAt: "2024-08-12T07:22:06.717152224Z",
      },
      {
        id: "36186bc6-2ba2-41d5-a746-59a8f02c4762",
        embedCode:
          '<pep-loyalty widget-id="36186bc6-2ba2-41d5-a746-59a8f02c4762" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test 007 - Copy",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-08-31",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: false,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T07:22:14.583470249Z",
        updatedAt: "2024-08-12T07:23:04.112895378Z",
      },
      {
        id: "fca81bc4-acab-4011-9c21-0a3e85232ffa",
        embedCode:
          '<pep-loyalty widget-id="fca81bc4-acab-4011-9c21-0a3e85232ffa" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "tester",
        isActive: false,
        widgetData: {
          selectedCampaigns: [
            "b1bcc047-0c3c-40b5-bb6e-09fbd928d879",
            "3145f70a-49da-41f6-be9a-a3b8812a8b7e",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "7a5c4aca-c879-4456-9f6b-4fd6b453d330",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-08-12T07:46:39.646045876Z",
        updatedAt: "2024-08-12T07:46:39.646045876Z",
      },
      {
        id: "a92f5165-1954-4a8f-aa4b-05f55473e1de",
        embedCode:
          '<pep-loyalty widget-id="a92f5165-1954-4a8f-aa4b-05f55473e1de" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "QA - Member Information",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-14T13:07:23.262659121Z",
        updatedAt: "2024-08-14T13:07:23.262659121Z",
      },
      {
        id: "14e73dc8-bce3-47b5-87aa-2140b3457ceb",
        embedCode:
          '<pep-loyalty widget-id="14e73dc8-bce3-47b5-87aa-2140b3457ceb" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX - Reward History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T21:06:30.023823876Z",
        updatedAt: "2024-08-16T17:07:09.584046296Z",
      },
      {
        id: "70c97833-94e0-4ee6-be32-8ee806c1b31a",
        embedCode:
          '<pep-loyalty widget-id="70c97833-94e0-4ee6-be32-8ee806c1b31a" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "original widget no Copy",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["a818bb71-8633-4e97-8a30-8c82a9cd60b1"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-02T19:26:25.768732843Z",
        updatedAt: "2024-08-20T12:01:19.135911178Z",
      },
      {
        id: "d88afb06-c5b6-4027-9ec9-b6ec88305f02",
        embedCode:
          '<pep-loyalty widget-id="d88afb06-c5b6-4027-9ec9-b6ec88305f02" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "BAM_SANDBOX_Quaker - Rewards List - Copy",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "f4e1abc0-2655-420c-a9d0-44d3733870cc",
            "45880330-e814-4ab7-94e2-d8dedf19dec0",
            "74d841f0-88d7-47a1-b1c0-2b0603013e83",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "7e7f1cce-4797-475d-b482-8561b7f6b4cb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-08-20T14:30:24.402356528Z",
        updatedAt: "2024-08-20T14:30:24.402356528Z",
      },
      {
        id: "894e5731-2ed3-4282-a483-3864b5a30e52",
        embedCode:
          '<pep-loyalty widget-id="894e5731-2ed3-4282-a483-3864b5a30e52" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX_Quaker - Activity History - Copy",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-20T14:31:49.399761097Z",
        updatedAt: "2024-08-20T14:31:49.399761097Z",
      },
      {
        id: "f14102cc-60c3-419c-8d27-139644de07aa",
        embedCode:
          '<pep-loyalty widget-id="f14102cc-60c3-419c-8d27-139644de07aa" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test Joe - 8-20 _ 1",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "78a6f02b-e4c5-4480-a2fe-8d512f2d939a",
            "c2e15fa4-0d1c-42e6-89dd-556b9651401c",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "83230e69-3f6c-4910-9e7a-1f6d8ded61bb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-08-20T14:44:49.797961761Z",
        updatedAt: "2024-08-20T14:44:49.797961761Z",
      },
      {
        id: "d4a00ec1-82d2-44c9-9ba6-f1ce222da8c9",
        embedCode:
          '<pep-loyalty widget-id="d4a00ec1-82d2-44c9-9ba6-f1ce222da8c9" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Demo123",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "b749b1ff-f412-40ed-ae60-2fd1c58cf81a",
            "b3454f7a-43f3-45c9-88c6-f36b4e21e7a9",
            "104a9281-e878-4efb-bdc6-e86c9b1b9181",
            "27b87727-cb9c-4cfb-a86a-33579221faf8",
            "bf008f81-2abd-477a-8392-4f749a16b991",
            "f4e1abc0-2655-420c-a9d0-44d3733870cc",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 6,
        createdAt: "2024-08-16T11:27:14.007840722Z",
        updatedAt: "2024-08-20T14:51:20.279632980Z",
      },
      {
        id: "3fd3973e-ac1f-4b70-a5f2-d9c2aabdfdb9",
        embedCode:
          '<pep-loyalty widget-id="3fd3973e-ac1f-4b70-a5f2-d9c2aabdfdb9" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Andreza - Test of Campaign Deleted",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["47b32a89-c384-47c7-8dce-2106634647d4"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-14T16:56:31.189093Z",
        updatedAt: "2024-08-20T14:51:31.488898837Z",
      },
      {
        id: "f5090cb6-1f2c-44fd-90b9-a5210eac97ce",
        embedCode:
          '<pep-loyalty widget-id="f5090cb6-1f2c-44fd-90b9-a5210eac97ce" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "test delete",
        isActive: false,
        widgetData: {
          selectedCampaigns: ["c8ce6128-4f74-48e6-88ee-e1f77f67ecd9"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-14T16:42:03.947239849Z",
        updatedAt: "2024-08-20T14:51:41.546999498Z",
      },
      {
        id: "afeb95f3-95bf-4b61-af92-d7d2e0d051d5",
        embedCode:
          '<pep-loyalty widget-id="afeb95f3-95bf-4b61-af92-d7d2e0d051d5" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test 1234",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "a9d152b2-93f7-4a3f-8bda-0ab0e7d78959",
            "2b5bee09-961e-4186-97aa-07f2d402c92c",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "Create account",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "Create account",
              },
            },
          },
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-08-12T21:30:56.367979881Z",
        updatedAt: "2024-08-20T14:52:11.347468766Z",
      },
      {
        id: "192884dc-53d0-48a4-a8f1-661d1c09c3e8",
        embedCode:
          '<pep-loyalty widget-id="192884dc-53d0-48a4-a8f1-661d1c09c3e8" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test 123",
        isActive: false,
        widgetData: {
          selectedCampaigns: [
            "98ba9d38-da8c-49a8-8265-1be05f163fa4",
            "90abda53-6551-46a8-b20b-3ff00ebd84d1",
            "72be48e0-e474-4e65-aa65-5ab35b547c5f",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-08-12T16:50:34.520399656Z",
        updatedAt: "2024-08-20T14:52:41.590363854Z",
      },
      {
        id: "91b26c6f-f4e4-43d6-bf54-e6caa9e6fadc",
        embedCode:
          '<pep-loyalty widget-id="91b26c6f-f4e4-43d6-bf54-e6caa9e6fadc" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test123",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["c6c88070-e7fe-4619-928c-cdb02aea2676"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||open()",
                text: "Test 123",
              },
              "en-us": {
                url: "method||open()",
                text: "Test 123",
              },
            },
          },
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-13T18:01:27.967993691Z",
        updatedAt: "2024-08-20T14:52:48.219802227Z",
      },
      {
        id: "831bb7cb-3386-48ba-80dd-50a805f317ad",
        embedCode:
          '<pep-loyalty widget-id="831bb7cb-3386-48ba-80dd-50a805f317ad" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "PREPROD fav",
        isActive: false,
        widgetData: {
          selectedCampaigns: ["98ba9d38-da8c-49a8-8265-1be05f163fa4"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-12T16:38:55.880861120Z",
        updatedAt: "2024-08-20T14:52:59.319441320Z",
      },
      {
        id: "4ec04594-a691-4133-ab8d-b1f3b5cb66f7",
        embedCode:
          '<pep-loyalty widget-id="4ec04594-a691-4133-ab8d-b1f3b5cb66f7" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "TestRegressionWDSSweepstakes Edit",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["74d841f0-88d7-47a1-b1c0-2b0603013e83"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "aee6d370-4593-4ca9-b52e-314ba6689f85",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T11:32:23.390352063Z",
        updatedAt: "2024-08-20T14:53:16.598591734Z",
      },
      {
        id: "7bd2eaef-50a4-4700-aa18-9eb3c624dad9",
        embedCode:
          '<pep-loyalty widget-id="7bd2eaef-50a4-4700-aa18-9eb3c624dad9" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test Daniel",
        isActive: false,
        widgetData: {
          selectedCampaigns: ["ae31dd58-112d-41dd-b3ff-e4ecd372c8cc"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-08T21:53:01.076783937Z",
        updatedAt: "2024-08-20T14:53:28.381495216Z",
      },
      {
        id: "ebc90ff9-14a3-454c-8f16-f44565c25e40",
        embedCode:
          '<pep-loyalty widget-id="ebc90ff9-14a3-454c-8f16-f44565c25e40" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Sweeps for Andreza",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["45880330-e814-4ab7-94e2-d8dedf19dec0"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "7a5c4aca-c879-4456-9f6b-4fd6b453d330",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-08T12:58:02.850524981Z",
        updatedAt: "2024-08-20T14:54:22.189520632Z",
      },
      {
        id: "d685a639-6bef-4896-9629-9723873a87a6",
        embedCode:
          '<pep-loyalty widget-id="d685a639-6bef-4896-9629-9723873a87a6" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Joe Test - 8-20 _ 1",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["45880330-e814-4ab7-94e2-d8dedf19dec0"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "7a5c4aca-c879-4456-9f6b-4fd6b453d330",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-20T18:56:51.160068166Z",
        updatedAt: "2024-08-20T18:56:51.160068166Z",
      },
      {
        id: "9c2a8bd9-e783-4de3-93a1-67b304e591c3",
        embedCode:
          '<pep-loyalty widget-id="9c2a8bd9-e783-4de3-93a1-67b304e591c3" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Member Information 111 - Copy",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-21T06:53:56.973498259Z",
        updatedAt: "2024-08-21T06:53:56.973498259Z",
      },
      {
        id: "27160d45-8bd2-4bfa-a19d-dfe069667398",
        embedCode:
          '<pep-loyalty widget-id="27160d45-8bd2-4bfa-a19d-dfe069667398" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Member History 111 - Copy",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-21T06:54:34.672813743Z",
        updatedAt: "2024-08-21T06:54:34.672813743Z",
      },
      {
        id: "b34c5222-ebef-40cb-82eb-97dca3a8f7ed",
        embedCode:
          '<pep-loyalty widget-id="b34c5222-ebef-40cb-82eb-97dca3a8f7ed" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX_Quaker - Reward History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-20T14:32:43.111294366Z",
        updatedAt: "2024-08-21T17:16:01.825167191Z",
      },
      {
        id: "b9ed32f7-3bd4-47d0-a82e-6b78a2d2358e",
        embedCode:
          '<pep-loyalty widget-id="b9ed32f7-3bd4-47d0-a82e-6b78a2d2358e" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Recipes_Life Front Page",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "0d7c6daa-5b49-402f-a8df-9d120c5dce04",
            "348a8b0d-ec64-4599-a369-d2603711a243",
            "ab194d5d-6314-4603-abef-e7e6fd70b350",
            "2cd14106-c7d0-45e0-b2c9-2d6c66e89050",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-13T00:01:57.609247697Z",
        updatedAt: "2024-08-22T19:10:29.220428067Z",
      },
      {
        id: "37a5745a-a1de-44b1-b96a-1e018ca73264",
        embedCode:
          '<pep-loyalty widget-id="37a5745a-a1de-44b1-b96a-1e018ca73264" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test333",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "83b24a37-8ce5-43b9-801d-fe86cb493bc0",
            "ed66d018-7bcc-4aa3-973a-bbae63c3b662",
            "78a6f02b-e4c5-4480-a2fe-8d512f2d939a",
            "c2e15fa4-0d1c-42e6-89dd-556b9651401c",
            "d449356f-b613-4cda-9a96-fc254c73a2a3",
            "4bd438f4-091d-4ad9-8a59-fcbcd5b7793c",
            "532a057c-28a4-4398-adc2-515ff4c822be",
            "fcbdf32d-0c01-42d8-b6ee-3ce7ad4a919c",
            "6a6acf2d-40d5-4cad-9885-e7d35f4377be",
            "5c616f74-04ed-4770-b801-b5a731e1b054",
            "6db17c1b-81bf-4755-a0d6-b9da0f95c79c",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "cbad3356-78c1-4450-80b8-d113a74c08dc",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 11,
        createdAt: "2024-08-21T06:55:18.843197840Z",
        updatedAt: "2024-08-23T07:52:58.329909978Z",
      },
      {
        id: "5a5a4119-9b78-4ce4-8651-94dc5144b70a",
        embedCode:
          '<pep-loyalty widget-id="5a5a4119-9b78-4ce4-8651-94dc5144b70a" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX - Activity History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T21:07:13.676280672Z",
        updatedAt: "2024-08-23T19:53:58.285208805Z",
      },
      {
        id: "46f9426a-44ad-4c06-ad58-bf7d9ee85977",
        embedCode:
          '<pep-loyalty widget-id="46f9426a-44ad-4c06-ad58-bf7d9ee85977" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "CC 101 - Campaigns Widgets",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "ab041d9a-e9e2-42d4-b2bc-943228380c1b",
            "860e239b-4b8c-4b24-a272-ea4d85f9e137",
            "583a1cfa-b94c-474e-8843-c35c0dfdbeeb",
            "98ba9d38-da8c-49a8-8265-1be05f163fa4",
            "034a2920-ba7f-43c7-ab4c-a72bd7a1cd89",
            "b807994b-4746-4a5d-b429-48dcb25f5d8b",
            "297c8d29-e54e-42d8-96d3-b93ceecdfb33",
            "e30a787b-643f-47d9-ade5-fb5469e7ee36",
            "1d083d49-1324-4ac8-9577-d8a384e83f67",
            "cdb93930-3b65-4780-8714-27a78a7997a7",
            "06bf390e-a687-45f0-8116-d534cd4e90c3",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 11,
        createdAt: "2024-08-26T19:57:36.077248621Z",
        updatedAt: "2024-09-06T18:11:09.442052150Z",
      },
      {
        id: "46b459a6-1ba0-41b7-9e3e-0169a8732682",
        embedCode:
          '<pep-loyalty widget-id="46b459a6-1ba0-41b7-9e3e-0169a8732682" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Quaker Logged Out Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "1b2de07d-078f-47d2-a79e-8f5c4ce02906",
            "197a8300-dfa5-4e6c-89a1-4670f667c750",
            "c48dc9ac-09bf-4014-ae59-12fd033cc773",
            "1ca6a38e-2755-4f96-9031-75f3ce3c9463",
            "077263bb-f635-41b9-94aa-2eb419489c41",
            "98ba9d38-da8c-49a8-8265-1be05f163fa4",
          ],
          buttonColor: "#032955",
          format: "CAROUSEL",
          descriptionColor: "#032955",
          visibleNonMembers: true,
          buttonTextHoverColor: "#FFFFFF",
          textColor: "#032955",
          buttonHoverColor: "#C5A785",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#032955",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "7e7f1cce-4797-475d-b482-8561b7f6b4cb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#032955",
        },
        selectedCampaignsSize: 6,
        createdAt: "2024-08-29T20:28:39.774675972Z",
        updatedAt: "2024-09-06T18:11:32.829086701Z",
      },
      {
        id: "774a24fa-5423-4641-ad12-c5af55241688",
        embedCode:
          '<pep-loyalty widget-id="774a24fa-5423-4641-ad12-c5af55241688" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "SS 0910 Contest",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["9d1d605b-60b3-4b31-bdc5-56f06d7a6e91"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "0df94caf-493c-47bf-82f6-049512ecc7ad",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-09-10T10:06:24.550257065Z",
        updatedAt: "2024-09-10T10:06:43.913852139Z",
      },
      {
        id: "fb4baa28-a6b4-49e6-b0ba-3508d0b44f9a",
        embedCode:
          '<pep-loyalty widget-id="fb4baa28-a6b4-49e6-b0ba-3508d0b44f9a" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "check",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["9d1d605b-60b3-4b31-bdc5-56f06d7a6e91"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.test.com",
                text: "test",
              },
              "en-us": {
                url: "www.test.com",
                text: "test",
              },
            },
          },
          maxHeight: 256,
          brandId: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-09-11T06:11:53.043722149Z",
        updatedAt: "2024-09-11T06:12:07.465249908Z",
      },
      {
        id: "dee628cc-bf52-47f6-addc-f4c062d00cca",
        embedCode:
          '<pep-loyalty widget-id="dee628cc-bf52-47f6-addc-f4c062d00cca" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Cheetos Hands-Free Sweepstakes",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["5b6478b3-69a6-4260-adc1-b37328f21a0f"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-09-23T15:40:14.405742938Z",
        updatedAt: "2024-09-23T15:40:14.405742938Z",
      },
      {
        id: "c3e5cb64-2b76-4890-9f2e-31e8bc7e3d1e",
        embedCode:
          '<pep-loyalty widget-id="c3e5cb64-2b76-4890-9f2e-31e8bc7e3d1e" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX_FLVP_BTS - Featured Activities",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "7ca845c5-1718-402e-9201-12c730576ffc",
            "eb3e7049-621e-4b32-b4b5-904dc5e6bc09",
            "98ba9d38-da8c-49a8-8265-1be05f163fa4",
            "1d083d49-1324-4ac8-9577-d8a384e83f67",
            "7e3b89e8-89a5-4f4c-80d5-e53ad87658fc",
            "63604790-1600-429b-b4d1-60e369b74154",
            "5981a5b6-7ed1-435b-8b76-0662189b60db",
            "541640f2-9020-4c5f-9bd9-17b2748f5a03",
            "7e706ceb-bf0e-4984-befa-95ffb832356d",
            "84034d44-6c64-4143-b07d-b038373703c2",
            "d1f70e11-47ee-444e-8134-4985b5c61b33",
            "911303cb-70d0-4f4e-bc97-e50680e2e813",
            "795ae7ad-a88f-4f74-a460-a4942a6be466",
            "b431dd00-8c77-48cf-ae59-e2d9757c34fb",
            "f5afae04-0cad-4afe-bbd6-62cc5e84227b",
            "077263bb-f635-41b9-94aa-2eb419489c41",
            "65faa63d-84bc-4052-9846-bc0d1d77233c",
            "d7291432-7f93-4c2f-8e3b-4d4e27c3ba28",
            "21bb5b19-ad56-4435-8131-c863b4582327",
            "8772e8d0-76c7-4ccf-84e2-59ecce7ab2cd",
            "871ad794-cdaa-40d5-900d-9e02098f07b7",
            "4bc2c29f-324d-4c0b-af18-82054b0f7825",
            "2fff1b55-7ded-4c6b-9ab4-bc7bc54fc9ac",
            "6dd998a5-b401-4d0a-9ba1-0d74ad9cb960",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 24,
        createdAt: "2024-08-20T14:34:07.756838708Z",
        updatedAt: "2024-09-30T10:53:06.645758720Z",
      },
      {
        id: "a3e9e8cd-d1d6-4c02-8300-253d01b35f1d",
        embedCode:
          '<pep-loyalty widget-id="a3e9e8cd-d1d6-4c02-8300-253d01b35f1d" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX_Quaker - All Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "1b2de07d-078f-47d2-a79e-8f5c4ce02906",
            "197a8300-dfa5-4e6c-89a1-4670f667c750",
            "5366d0b8-bfab-4e62-b0d2-92e0dc619953",
            "02557df0-cd43-479d-a1f3-6ee528b38ee0",
            "c48dc9ac-09bf-4014-ae59-12fd033cc773",
            "1ca6a38e-2755-4f96-9031-75f3ce3c9463",
            "f9615bbd-14c3-4c3f-8532-9250be79d706",
            "188992fb-71a0-4f91-a3a9-fc2056f494b0",
            "98ba9d38-da8c-49a8-8265-1be05f163fa4",
            "7e3b89e8-89a5-4f4c-80d5-e53ad87658fc",
            "d7291432-7f93-4c2f-8e3b-4d4e27c3ba28",
            "679ba4d1-5bda-46bc-be94-9dcea6241bd7",
            "666f2664-09e2-4faa-ae09-8b30d29a55cc",
            "10fbc8a9-4109-45b3-b361-576d2d379801",
            "6dd998a5-b401-4d0a-9ba1-0d74ad9cb960",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE YOUR ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "7e7f1cce-4797-475d-b482-8561b7f6b4cb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 15,
        createdAt: "2024-08-15T20:14:31.767191656Z",
        updatedAt: "2024-09-30T10:54:11.371931161Z",
      },
      {
        id: "9e8bd2a1-f29d-46da-a27e-becbe7e56a75",
        embedCode:
          '<pep-loyalty widget-id="9e8bd2a1-f29d-46da-a27e-becbe7e56a75" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Activity Completion Pop-up",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["3cfd0e98-cf23-4879-ab03-cb9aec04aba2"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-13T00:05:24.120064358Z",
        updatedAt: "2024-10-31T17:53:12.817926633Z",
      },
      {
        id: "6a4b24df-bcdc-414a-afd3-a93faa6cd495",
        embedCode:
          '<pep-loyalty widget-id="6a4b24df-bcdc-414a-afd3-a93faa6cd495" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "BAM_SANDBOX - Rewards List",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "5b6478b3-69a6-4260-adc1-b37328f21a0f",
            "0e355b63-bd6e-4dc3-baf8-be322e81d5a2",
            "0d1bda61-7e11-4bd3-8588-98b89f536faa",
            "f4e1abc0-2655-420c-a9d0-44d3733870cc",
            "89c221b7-b8fe-4dac-a7d0-4bd2d407658f",
            "02f4c6c9-47c3-4b62-bb7e-a02d360a029e",
            "b1f79791-6bf6-4c45-b930-9c79c3094660",
            "21c56c2b-8f76-4539-8051-8b771709bca3",
            "eca3a642-03f8-4ea6-8638-bced3cd8170e",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 9,
        createdAt: "2024-08-13T00:03:33.687978750Z",
        updatedAt: "2024-11-18T19:23:41.620675692Z",
      },
      {
        id: "8556bfb2-cab8-4aa2-9837-08c153993d0b",
        embedCode:
          '<pep-loyalty widget-id="8556bfb2-cab8-4aa2-9837-08c153993d0b" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "WSP Widget Test - DO NOT DELETE",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["24df3d30-4645-4084-a8e5-e2959a5a3cc2"],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-13T13:01:48.014507872Z",
        updatedAt: "2024-11-21T14:00:57.907495456Z",
      },
      {
        id: "273eb7f9-c8af-46b6-afa0-a7fafee36512",
        embedCode:
          '<pep-loyalty widget-id="273eb7f9-c8af-46b6-afa0-a7fafee36512" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - All Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "6dd998a5-b401-4d0a-9ba1-0d74ad9cb960",
            "5ceed007-c322-4327-8772-a5eea389d857",
            "7a4c80a5-33d2-4f7b-b058-914d14fecf75",
            "2287423f-82ae-4d16-a241-4e455572a6da",
            "82915244-3e74-4f1e-aba9-1822742179b4",
            "69489c55-9e64-4dc7-aa83-b73d9e7c895a",
            "9db02eab-f2f3-42fe-825a-22d942846431",
            "093a7fa3-0f0e-4655-9991-69d6c6f95623",
            "e228c773-1a26-43f7-8bc3-5ef3a79028c9",
            "de39a05b-478b-45e0-806c-fc9ee02903c6",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 10,
        createdAt: "2024-08-13T00:04:30.077422971Z",
        updatedAt: "2024-12-05T18:48:13.524598952Z",
      },
      {
        id: "d2c5e913-ddfa-42f4-8d55-c73a172f2187",
        embedCode:
          '<pep-loyalty widget-id="d2c5e913-ddfa-42f4-8d55-c73a172f2187" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Featured Activities",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "e01b4581-846d-403e-8e6c-e686f23c37f8",
            "1e8a7fa4-e0cd-43ac-a752-3028c8fc65e2",
            "e228c773-1a26-43f7-8bc3-5ef3a79028c9",
            "de39a05b-478b-45e0-806c-fc9ee02903c6",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-12T23:55:25.833935867Z",
        updatedAt: "2024-12-05T19:40:28.864058619Z",
      },
    ],
    widgetDefinitions: [
      {
        id: "38f0a234-6aea-487f-bea3-78b45c291765",
        title: "List of sweepstakes",
        version: 1,
        slug: "sweepstakes",
        inputSchema: {
          title: "List of Sweepstakes",
          type: "object",
          properties: {
            warningColor: {
              type: "string",
            },
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
            },
            {
              className: "custom-preview-group",
              page: "Sweepstakes",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          component: "numberInput",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Sweepstakes Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Warning Message",
                          type: "Control",
                          scope: "#/properties/warningColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "3db550c2-47bd-4f0a-bba6-35b5374867a4",
        title: "List of member information",
        version: 1,
        slug: "member-information",
        inputSchema: {
          title: "Member Information",
          type: "object",
          properties: {
            periodStartDate: {
              format: "date",
              title: "Period start date",
              type: "string",
            },
            textMainColor: {
              title: "Text main color",
              type: "string",
            },
            iconMainColor: {
              title: "Icon main color",
              type: "string",
            },
            showMemberSignupDate: {
              title: "Show member signup date",
              type: "boolean",
            },
            textHighlightColor: {
              title: "Text highlight color",
              type: "string",
            },
            showDateBalanceUpdated: {
              title: "Show date balance updated",
              type: "boolean",
            },
            showIcons: {
              title: "Show icons",
              type: "boolean",
            },
            showWelcomeMessage: {
              title: "Show welcome message",
              type: "boolean",
            },
            showMemberBalance: {
              title: "Show member balance",
              type: "boolean",
            },
            iconHighlightColor: {
              title: "Icon highlight color",
              type: "string",
            },
            customWidget: {
              title: "Custom widget",
              type: "boolean",
            },
            showNumberCompletedActivities: {
              title: "Show number of completed activities",
              type: "boolean",
            },
            activitiesPeriod: {
              title: "Activities period",
              type: "string",
              enum: ["ALL_ACTIVITIES", "FROM_SPECIFIC_DATE"],
            },
            showNumberActivitiesToComplete: {
              title: "Show number of activities to complete",
              type: "boolean",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "Widget Component Specifications",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showWelcomeMessage",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberCompletedActivities",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showWelcomeMessage",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show member signup date",
                      type: "Control",
                      scope: "#/properties/showMemberSignupDate",
                    },
                    {
                      options: {
                        labelMapping: {
                          ALL_ACTIVITIES: "All activities",
                          FROM_SPECIFIC_DATE: "From a specific date",
                        },
                      },
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showNumberCompletedActivities",
                        },
                        effect: "DISABLE",
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/activitiesPeriod",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: "FROM_SPECIFIC_DATE",
                          },
                          scope: "#/properties/activitiesPeriod",
                        },
                        effect: "SHOW",
                      },
                      component: "datePickerMaterialUI",
                      label: false,
                      type: "Control",
                      scope: "#/properties/periodStartDate",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-3",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showMemberBalance",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberActivitiesToComplete",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-4",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showMemberBalance",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show date when balance was last updated",
                      type: "Control",
                      scope: "#/properties/showDateBalanceUpdated",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-5",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/customWidget",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "toggle",
                      type: "Control",
                      scope: "#/properties/showIcons",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Icons",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Image*",
                              type: "Control",
                              scope: "#/properties/iconMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Circle*",
                              type: "Control",
                              scope: "#/properties/iconHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Bar Items",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Fixed content*",
                              type: "Control",
                              scope: "#/properties/textMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Dynamic content*",
                              type: "Control",
                              scope: "#/properties/textHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
          ],
        },
      },
      {
        id: "60e1b03e-9277-4188-807f-6d36ab3d3e6b",
        title: "List of campaigns",
        version: 1,
        slug: "campaigns",
        inputSchema: {
          title: "List of Campaigns",
          type: "object",
          properties: {
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            descriptionColor: {
              type: "string",
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            completedColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
            },
            {
              className: "custom-preview-group",
              page: "Campaign",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          component: "numberInput",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-hexacolor",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Description",
                          type: "Control",
                          scope: "#/properties/descriptionColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Completed",
                          type: "Control",
                          scope: "#/properties/completedColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "de5ae77e-d1c9-4632-b436-b55634f8947e",
        title: "List of member history",
        version: 1,
        slug: "member-history",
        inputSchema: {
          title: "Member History",
          type: "object",
          properties: {
            headerTextColor: {
              type: "string",
            },
            pointsTextColor: {
              type: "string",
            },
            historyType: {
              type: "string",
              enum: ["SWEEPSTAKES_HISTORY", "ACTIVITY_HISTORY"],
            },
            maxHeight: {
              type: "number",
            },
            rowTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            headerRowColor: {
              type: "string",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "History Type",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          ACTIVITY_HISTORY: "Activity history",
                          SWEEPSTAKES_HISTORY: "Sweepstake history",
                        },
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/historyType",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-custom-widget",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
            {
              type: "SimpleGroup",
              rule: {
                condition: {
                  schema: {
                    const: false,
                  },
                  scope: "#/properties/customWidget",
                },
                effect: "HIDE",
              },
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header row*",
                      type: "Control",
                      defaultValue: "#000000",
                      scope: "#/properties/headerRowColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header text*",
                      type: "Control",
                      scope: "#/properties/headerTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Row text*",
                      type: "Control",
                      scope: "#/properties/rowTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Entries text*",
                      type: "Control",
                      scope: "#/properties/pointsTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "numberInput",
                      label: "Max height*",
                      placeholder: "Insert value in pixels",
                      type: "Control",
                      scope: "#/properties/maxHeight",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
