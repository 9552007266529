import { withJsonFormsControlProps } from "@jsonforms/react";
import FormHelperText from "@mui/material/FormHelperText";
import { useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.scss";
import { QuillToolbar } from "./Toolbar";
import { convertMailLink, convertTelLink, formats } from "./utils";

const getToolbarId = (id = "") => {
  const splittedId = id?.split("/");
  const lastIndex = splittedId?.length - 1;
  const pureId = splittedId[lastIndex];

  return `toolbar-text-editor-${pureId}`;
};
export function TextEditor(props) {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    required,
    id,
    enabled,
    uischema,
  } = props;

  const toolbarId = getToolbarId(id);
  const handleOnChange = (newValue) => {
    const handledValue = newValue === "<p><br></p>" ? "" : newValue;
    handleChange(path, handledValue);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {
          tel: convertTelLink,
          mail: convertMailLink,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [toolbarId]
  );

  if (typeof window !== "undefined" && Quill) {
    const Link = Quill.import("formats/link");
    Link.sanitize = (url) => {
      // quill by default creates relative links if scheme is missing.
      if (
        !url.startsWith("http://") &&
        !url.startsWith("https://") &&
        !url.startsWith("tel:") &&
        !url.startsWith("mailto:")
      ) {
        return `https://${url}`;
      }
      return url;
    };
  }

  const fieldProps = {
    id: id,
    placeholder: "...",
    disabled: !enabled,
    formats,
  };

  const hasError = Boolean(errors);
  const classes = `${hasError ? "error" : ""}`;
  return (
    <div
      style={{ position: "relative" }}
      className={uischema?.disabled ? "toolbar-text-editor-disabled" : ""}
    >
      <div className="input-header">
        <span className="label-text $font-xsmall-regular">
          <label htmlFor="content-title">{label}</label>
        </span>
        {required && (
          <span className="required-text $font-xxsmall-bold float-right">
            *Required
          </span>
        )}
      </div>
      <QuillToolbar id={toolbarId} />
      <ReactQuill
        {...fieldProps}
        id={id}
        value={data}
        onChange={(newValue) => handleOnChange(newValue)}
        defaultValue={data}
        className={classes}
        modules={modules}
        preserveWhitespace
        bounds={`[id="${toolbarId}"]`}
        readOnly={uischema?.disabled || false}
      />
      <FormHelperText error={hasError}>{hasError ? errors : null}</FormHelperText>
    </div>
  );
}

export const TextEditorControl = withJsonFormsControlProps(TextEditor);
