import { gql } from "@apollo/client";

export const getSegmentsPopulation = gql`
  query ListSegmentsWithPopulationRange {
    listSegmentsWithPopulationRange {
      maxPopulationRange
      minPopulationRange
    }
  }
`;
