import { withJsonFormsControlProps } from "@jsonforms/react";
import { Divider } from "pepsico-ds";
import MultiplePropertiesField from "./MultiplePropertiesField";

const MultiplePropertiesComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;

  return (
    <>
      {!uischema?.isChild && (
        <>
          <div className="my-2 display-flex justify-content-space-between">
            <span className="label-text $font-xsmall-bold">{label}</span>
            {/* <span class="required-text $font-xxsmall-bold $color-text-secondary">
              *Required
            </span> */}
          </div>
          <Divider orientation="horizontal" />
        </>
      )}
      {uischema?.isChild && (
        <div className="my-2 display-flex justify-content-space-between">
          <span className="label-text $font-xsmall-bold">{label}</span>
          {/* <span class="required-text $font-xxsmall-bold $color-text-secondary">
            *Required
          </span> */}
        </div>
      )}
      <MultiplePropertiesField
        value={data}
        disabled={!enabled}
        label={uischema.showLabel === false ? "" : label}
        updateValue={(newVal) => handleChange(path, newVal)}
        errors={errors}
        schema={schema}
        uiSchema={uischema}
        required={required}
      />
      <Divider orientation="horizontal" className="my-2" />
    </>
  );
};

export const MultiplePropertiesControl = withJsonFormsControlProps(
  MultiplePropertiesComponent
);
