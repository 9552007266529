import { useQuery } from "@apollo/client";
import { Dropdown, LoadingSpinner } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import { LIST_BRANDS_AND_SUBBRANDS } from "../../graphql/queries/listKeywordGroups";

export const BrandsSelector = ({
  selectedBrands,
  handleBrandSelection,
  selectedSubBrands,
  handleSubBrandSelection,
  setIsSubbrandsRequired,
  isUploading,
}) => {
  const { loading, data, error } = useQuery(LIST_BRANDS_AND_SUBBRANDS, {
    fetchPolicy: "cache-and-network",
  });
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [notApplicableSubBrand, setNotApplicableSubBrand] = useState(null);

  const [invalidDataError, setInvalidDataError] = useState(false);

  useEffect(() => {
    if (!data) return;

    const { brands, subBrands } = data;

    const filteredBrands = brands.filter((brand) => !!brand.metadata?.dam_id);
    const filteredSubBrands = subBrands.filter(
      (subBrand) => !!subBrand.metadata?.dam_id
    );

    if (!filteredBrands.length || !filteredSubBrands.length) {
      setBrands([]);
      setSubBrands([]);

      setInvalidDataError(true);
      return;
    }

    if (filteredBrands) {
      setBrands(
        filteredBrands.map((brand) => ({
          id: brand.metadata.dam_id,
          displayText: brand.name,
        }))
      );
    }

    if (filteredSubBrands) {
      // Separate "Not Applicable" subbrand from other subbrands
      const formattedSubBrands = filteredSubBrands.map((subBrand) => ({
        id: subBrand.metadata.dam_id,
        displayText: subBrand.name,
        brandIds: subBrand.metadata.dam_brand_ids,
        notApplicable: subBrand.metadata.not_applicable,
      }));

      const notApplicable = formattedSubBrands.find(
        (subBrand) => subBrand.notApplicable
      );
      const regularSubBrands = formattedSubBrands.filter(
        (subBrand) => !subBrand.notApplicable
      );

      if (!notApplicable) {
        console.error('Missing required "Not Applicable" subbrand! Check DB data.');
        setInvalidDataError(true);
        return;
      }

      setNotApplicableSubBrand(notApplicable);
      setSubBrands(regularSubBrands);
    }
  }, [data]);

  const filteredSubBrands = useMemo(() => {
    if (!selectedBrands.length) {
      return [];
    }

    // Add other subbrands that match selected brands
    const matchingSubBrands = subBrands.filter((subBrand) =>
      selectedBrands.some((selectedBrand) =>
        subBrand.brandIds.includes(selectedBrand.id)
      )
    );

    return [notApplicableSubBrand, ...matchingSubBrands];
  }, [selectedBrands, subBrands, notApplicableSubBrand]);

  const isBrandsSelected = !!selectedBrands.length;
  const isSubBrandDisabled =
    !isBrandsSelected || isUploading || !filteredSubBrands.length;

  const isSubbrandsRequired = useMemo(
    () => !!filteredSubBrands.length,
    [filteredSubBrands.length]
  );

  const subBrandsPlaceholder = useMemo(() => {
    return isSubBrandDisabled ? "Select a brand first" : "Select";
  }, [isSubBrandDisabled]);

  useEffect(
    () => setIsSubbrandsRequired(isSubbrandsRequired),
    [isSubbrandsRequired, setIsSubbrandsRequired]
  );

  if (error || invalidDataError) {
    return (
      <div className="error font-xs" data-testid="brands-error">
        Something went wrong while loading Brands. Please try again later.
      </div>
    );
  }

  if (loading || !brands.length) {
    return (
      <LoadingSpinner
        size={{
          height: "30px",
          width: "30px",
        }}
        style={{ margin: "16px auto" }}
        data-testid="brands-loading"
      />
    );
  }

  return (
    <div className="flex justify-content-space-between mb-4">
      {!error && brands.length && (
        <>
          <Dropdown
            data-testid="brands-selector"
            style={{ position: "relative" }}
            childList={brands}
            isRequired
            className="zIndexToDropDown rule-selector"
            label="Brands"
            selection="multiple"
            selectedValue={selectedBrands}
            setSelectedValue={handleBrandSelection}
            size="medium"
            disabled={isUploading}
          />

          <Dropdown
            data-testid="subbrands-selector"
            // [peacock issue] Workaround to force re-render of sub-brand dropdown
            // to reset it when brand dropdown changes
            key={JSON.stringify(selectedBrands)}
            style={{ position: "relative" }}
            childList={filteredSubBrands}
            disabled={isSubBrandDisabled}
            isRequired
            className="zIndexToDropDown rule-selector"
            label="Sub-brands"
            placeholder={subBrandsPlaceholder}
            selection="multiple"
            selectedValue={selectedSubBrands}
            setSelectedValue={handleSubBrandSelection}
            size="medium"
          />
        </>
      )}
    </div>
  );
};
