import { withJsonFormsControlProps } from "@jsonforms/react";
import DatePickerField from "./DatePickerField";

const DatePickerComponent = (props) => {
  const { handleChange, label, data, path, errors, schema, uischema, required } =
    props;
  return (
    <DatePickerField
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export const DatePickerControl = withJsonFormsControlProps(DatePickerComponent);
