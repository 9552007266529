import { gql } from "@apollo/client";

export const listRewardTypes = gql`
  query ListRewardType($partnerId: ID) {
    listRewardType(page: 0, size: 1000, partnerId: $partnerId) {
      items {
        id
        name
        partnerId
        programId
        schemaData
      }
    }
  }
`;
