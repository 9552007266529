import { createAjv } from "@jsonforms/core";
import { Modal } from "pepsico-ds";
import { useMemo, useState } from "react";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./Modal.scss";

const jsonSchema = {
  title: "Social Media Links Configurations",
  type: "object",
  properties: {
    image: {
      type: "string",
      format: "uri",
    },
    url: {
      type: "string",
      format: "uri",
    },
    active: {
      type: "boolean",
    },
  },
  required: ["image", "url", "active"],
};

const uiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      options: {
        format: "data-url",
      },
      component: "singleImageUpload",
      type: "Control",
      scope: "#/properties/image",
      maxWidth: "25",
      maxHeight: "25",
    },
    {
      type: "Control",
      scope: "#/properties/url",
      placeholder: "Insert link here",
      size: "small",
      label: "Link",
      info: "Character limit: 255",
      minLength: 3,
      maxLength: 255,
    },
    {
      component: "toggle",
      label: "Active",
      type: "Control",
      scope: "#/properties/active",
    },
  ],
};

export default function SocialMediaLinksModal({
  isOpen,
  closeModal,
  socialMedia,
  handleSocialMediaUpdate,
  id,
}) {
  const [data, setData] = useState(() => socialMedia);
  const ajvInstance = useMemo(() => {
    return createAjv();
  }, []);
  const isValidForm = ajvInstance.validate(jsonSchema, data);

  const handleSave = () => {
    handleSocialMediaUpdate(id, data);
    closeModal();
  };

  return (
    <Modal
      showModal={isOpen}
      onCloseModal={closeModal}
      title={`Edit for ${socialMedia?.title}`}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
      type="S"
      primaryButtonProps={{
        size: "medium",
        text: "Save",
        variant: "primary",
        disabled: !isValidForm,
        onClick: handleSave,
      }}
      className="social-media-modal"
    >
      <JsonFormsWrapper
        data={data}
        onChange={(data) => setData(data)}
        uischema={uiSchema}
        jsonschema={jsonSchema}
      />
    </Modal>
  );
}
