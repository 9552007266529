import { gql } from "@apollo/client";

const updateProgramConfiguration = gql`
  mutation UpdateProgramConfiguration($input: ProgramConfigurationInput!, $id: ID!) {
    updateProgramConfiguration(input: $input, id: $id) {
      configurations {
        key
        value {
          title
          description
          data
        }
      }
    }
  }
`;

export default updateProgramConfiguration;
