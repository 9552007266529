import { Tag } from "pepsico-ds";
import { capitalizeFirstLetter } from "../../../utils/commons";

const StatusTag = ({ status }) => {
  const lowerCaseStatus = status.toLowerCase();

  const statusColorMap = {
    inactive: "Gray-100",
    draft: "Gray-500",
    active: "Green-600",
    published: "Yellow-400",
    completed: "Cyan-700",
  };

  return (
    <Tag
      text={capitalizeFirstLetter(lowerCaseStatus)}
      color={statusColorMap[lowerCaseStatus]}
      type="filled"
      size="small"
      isCopyable={false}
    />
  );
};

export default StatusTag;
