import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import editPartner from "../../../graphql/mutations/editPartner";

export const useEditPartnerMutation = () => {
  const [editPartnerMutation, { loading }] = useMutation(editPartner);

  const edit = useCallback(
    async (onSuccess, onFailure, partnerData, id) => {
      const payload = {
        ...partnerData,
        image: "string",
      };

      try {
        const response = await editPartnerMutation({
          variables: {
            Partner: payload,
            Id: id,
          },
        });

        if (response?.data?.updatePartner?.id === id) {
          onSuccess();
        }
      } catch (error) {
        onFailure(error);
      }
    },
    [editPartnerMutation]
  );

  return [edit, loading];
};
