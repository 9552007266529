import PropTypes from "prop-types";
import { useMemo } from "react";
import { ImagePicker } from "../../imagePicker/ImagePicker";

export default function FileUploadField({
  acceptedExtensions,
  className,
  disabled = false,
  label,
  maxSize,
  uiSchema,
  updateValue,
  value,
}) {
  const description = useMemo(() => {
    let value = uiSchema.description ?? "Drag and drop your file here";

    if (uiSchema.maxWidth && uiSchema.maxHeight) {
      value += ` (${uiSchema.maxWidth}px x ${uiSchema.maxHeight}px)`;
    }

    if (acceptedExtensions) {
      value += ` (Only ${acceptedExtensions})`;
    }

    return value.trim();
  }, [uiSchema, acceptedExtensions]);

  const shortDescription = useMemo(() => {
    let value = uiSchema.shortDescription ?? "";

    if (uiSchema.maxWidth && uiSchema.maxHeight) {
      value += ` (Dimensions: ${uiSchema.maxWidth}px x ${uiSchema.maxHeight}px)`;
    }

    return value.trim();
  }, [uiSchema.maxHeight, uiSchema.maxWidth, uiSchema.shortDescription]);

  return (
    <ImagePicker
      accept={acceptedExtensions}
      description={description}
      shortDescription={shortDescription}
      files={value || []}
      onUpdate={updateValue}
      label={label}
      multiple
      classNameProp={className}
      maxSize={maxSize}
      disabled={disabled}
    />
  );
}

FileUploadField.propTypes = {
  acceptedExtensions: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  uiSchema: PropTypes.shape({ description: PropTypes.string }).isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};
