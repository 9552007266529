import { useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { listSegments } from "../../../graphql/queries/listSegments";
import BoostedRewardGroupSegmentSelect from "./BoostedRewardGroupSegmentSelect";
import "./_campaign-segment-select.scss";

const BoostedRewardGroupSegmentSelectComponent = (props) => {
  const { handleChange, path, data, label } = props;

  const { data: segmentsData } = useQuery(listSegments, {
    variables: {
      filter: {
        status: "ENABLED",
      },
    },
  });

  return (
    <div className="boost-reward-group-segment-select">
      <p className="mb-3">{label}</p>
      <BoostedRewardGroupSegmentSelect
        segmentsData={segmentsData?.listSegments || []}
        selected={data}
        onChange={(val) => {
          handleChange(path, val);
        }}
      />
    </div>
  );
};

export const BoostedRewardGroupSegmentSelectController = withJsonFormsMultiEnumProps(
  BoostedRewardGroupSegmentSelectComponent
);
