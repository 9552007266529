import { CircularProgress } from "@mui/material";
import { Tooltip } from "pepsico-ds";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import "./winningRewardsListTable.scss";

const WinningRewardsListTable = ({
  loading,
  listContentDefinitions,
  setShowModal,
  setViewShowModal,
  setSelectedContent,
}) => {
  return (
    <div className="winning-rewards-list-container">
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <table className="winning-rewards-list-table">
          <thead>
            <tr>
              <th className="column-name">PARTNER REWARD NAME</th>
              <th className="column-actions">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {listContentDefinitions && listContentDefinitions.length > 0 ? (
              listContentDefinitions.map((reward) => (
                <tr key={reward.id}>
                  <td className="reward-name">
                    {reward.title.length > 60 ? (
                      <Tooltip direction="right" text={reward.title}>
                        {reward.title.substring(0, 60)}...
                      </Tooltip>
                    ) : (
                      reward.title
                    )}
                  </td>
                  <td>
                    <div className="button-group-wrapper">
                      <ButtonGroupDynamic
                        items={[
                          {
                            icon: "edit",
                            onClick: () => {
                              setSelectedContent(reward);
                              setShowModal(true);
                            },
                          },
                          {
                            icon: "visibility",
                            onClick: () => {
                              setSelectedContent(reward);
                              setViewShowModal(true);
                            },
                          },
                        ]}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No content available</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WinningRewardsListTable;
