import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import BoostedRewardGroupSelect from "./BoostedRewardGroupSelect";

const BoostedRewardGroupSelectComponent = (props) => {
  const ctx = useJsonForms();
  const jsonformState = ctx?.core?.data;

  return <BoostedRewardGroupSelect {...props} jsonformState={jsonformState} />;
};

export const BoostedRewardGroupSelectController = withJsonFormsControlProps(
  BoostedRewardGroupSelectComponent
);
