import { useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Modal } from "pepsico-ds";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { listSegments } from "../../../graphql/queries/listSegments";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import SegmentSelect from "./SegmentSelect";
import "./_campaign-segment-select.scss";

const CampaignSegmentSelectComponent = (props) => {
  const { handleChange, path, data } = props;

  const [openModal, setOpenModal] = useState(false);
  const [segments, setSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [savedSegments, setSavedSegments] = useState([]);
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });
  useQuery(listSegments, {
    variables: {
      filter: {
        status: "ENABLED",
      },
    },
    onCompleted: (segmentsData) => {
      setSegments(segmentsData.listSegments);
      if (data) {
        const newSelectedSegments = data.map((item) => {
          const segment = segmentsData.listSegments.find(
            (segment) => segment.id === item
          );
          return (
            segment || {
              id: item,
              name: "Selected segment is no longer active",
              status: "DISABLED",
            }
          );
        });

        const newSelectedSegmentIds = newSelectedSegments.map((seg) => seg.id);
        const currentSelectedSegmentIds = selectedSegments.map((seg) => seg.id);

        if (
          newSelectedSegmentIds.length !== currentSelectedSegmentIds.length &&
          !newSelectedSegmentIds.every(
            (id, idx) => id === currentSelectedSegmentIds[idx]
          )
        ) {
          setSelectedSegments(newSelectedSegments);
          setSavedSegments(newSelectedSegments);
        }
      }
    },
  });

  // Update saved segments state
  useEffect(() => {
    handleChange(
      path,
      savedSegments.map((segment) => segment.id)
    );
  }, [savedSegments, handleChange, path]);

  const handleToggleModal = () => setOpenModal(!openModal);
  const handleCloseModal = () => {
    setSelectedSegments(savedSegments);
    handleToggleModal();
  };
  const handleSegmentSelection = (selected) => setSelectedSegments(selected);
  const handleSaveSegments = () => {
    const newSegments = selectedSegments.filter(
      (segment) => !savedSegments.includes(segment)
    );
    setSavedSegments(selectedSegments);
    handleToggleModal();
    openSuccessToast(newSegments);
  };
  const handleRemoveSaved = (id) => {
    setSavedSegments(savedSegments.filter((segment) => segment.id !== id));
  };
  const openSuccessToast = (newSegments) => {
    const formatNames = (names) => {
      if (names.length === 0) return "";
      if (names.length === 1) return names[0];

      const lastName = names.pop();
      return `${names.join(", ")} and ${lastName}`;
    };

    const newSelectedSegments = formatNames(
      newSegments.map((segment) => segment.name)
    );
    setToast({
      open: true,
      type: "success",
      title: `${newSelectedSegments} segment added`,
      description: "You have just added segments!",
    });
  };

  return (
    <div className="segment-select">
      {savedSegments.map((segment) => (
        <div
          className={`segment-item display-flex flex-direction-row font-xs font-bold segment-added ${
            segment.status === "DISABLED" ? "segment-disabled" : ""
          }`}
          key={segment.id}
        >
          <div className="added-segment display-flex flex-direction-row align-items-center justify-content-space-between">
            <span>{segment.name}</span>
            <ButtonGroupDynamic
              size="small"
              items={[
                // {
                //   icon: "edit",
                //   onClick: handleToggleModal,
                // },
                {
                  icon: "delete",
                  onClick: () => handleRemoveSaved(segment.id),
                },
              ]}
            />
          </div>
        </div>
      ))}
      <div className="segment-item display-flex flex-direction-row font-xs font-bold">
        <ButtonGroupDynamic
          size="small"
          items={[
            {
              icon: "add",
              onClick: handleToggleModal,
            },
          ]}
        />
        Add New Segment
      </div>
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
      <Modal
        className="campaign-segment-select-modal"
        showModal={openModal}
        title="Choose Segment"
        text=""
        showSecondaryButton={false}
        showTertiaryButton={false}
        showLink={false}
        onCloseModal={handleCloseModal}
        primaryButtonProps={{
          text: "Save",
          disabled: !selectedSegments.length,
          onClick: handleSaveSegments,
        }}
      >
        <SegmentSelect
          segmentsData={segments}
          selected={selectedSegments}
          onChange={handleSegmentSelection}
        />
      </Modal>
    </div>
  );
};

CampaignSegmentSelectComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const CampaignSegmentSelectControl = withJsonFormsMultiEnumProps(
  CampaignSegmentSelectComponent
);
