import { useMutation } from "@apollo/client";
import { createAjv } from "@jsonforms/core";
import { Button, LoadingSpinner } from "pepsico-ds";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import updateProgramConfiguration from "../../../graphql/mutations/updateProgramConfiguration";
import { paths } from "../../../routes/paths";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

const CURRENT_SLUG = "social_media_links";
export function SocialMediaLinksForm({
  socialMediaData,
  setSocialMediaData,
  stateChanged,
  uiSchema,
  jsonSchema,
}) {
  const {
    updateProgramConfigurations,
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);

  const [updateProgramConfigurationMutation, { loading }] = useMutation(
    updateProgramConfiguration
  );

  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });
  const navigate = useNavigate();

  const ajvInstance = useMemo(() => {
    return createAjv();
  }, []);

  const isValidForm = ajvInstance.validate(jsonSchema, socialMediaData);

  const handleSave = async () => {
    const params = {
      variables: {
        input: {
          slug: CURRENT_SLUG,
          data: JSON.stringify(socialMediaData),
        },
        id: programConfig?.id,
      },
    };

    try {
      const res = await updateProgramConfigurationMutation(params);
      if (res?.data?.updateProgramConfiguration) {
        updateProgramConfigurations(
          res?.data?.updateProgramConfiguration?.configurations
        );

        setToast({
          open: true,
          type: "success",
          title: "Settings updated successfully!",
          description: "You have just saved the social media links settings",
        });
      }
    } catch {
      setToast({
        open: true,
        type: "error",
        title: "Something went wrong while saving the social media links settings.",
        description: "Try again later.",
      });
    }
  };

  return (
    <div className="container">
      <div className="wrapper">
        <JsonFormsWrapper
          data={socialMediaData}
          onChange={(data) => setSocialMediaData(data)}
          uischema={uiSchema}
          jsonschema={jsonSchema}
        />
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={() => navigate(`/${paths.system_setting}`)}
          />
          <Button
            size="medium"
            text={
              loading ? (
                <LoadingSpinner
                  size={{ height: "24px", width: "24px" }}
                  style={{ margin: "0 0.5rem" }}
                />
              ) : (
                "Save"
              )
            }
            variant="primary"
            disabled={!isValidForm || stateChanged || loading}
            onClick={handleSave}
          />
        </div>
        <ToastNotification
          {...toast}
          handleClose={() => setToast({ ...toast, open: false })}
        />
      </div>
    </div>
  );
}
