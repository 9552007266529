import { useMutation } from "@apollo/client";
import { LoadingSpinner, Modal } from "pepsico-ds";
import deletePartner from "../../../../graphql/mutations/deletePartner";
import "./DeletePartner.scss";

function DeletePartnerModal({ isOpen, closeModal, id, name, refetch, setToast }) {
  const [deletePartnerMutation, { loading }] = useMutation(deletePartner);

  const handleDelete = async () => {
    try {
      const response = await deletePartnerMutation({
        variables: {
          id: id,
        },
      });

      if (response?.data?.deletePartner) {
        refetch();
        setToast({
          open: true,
          type: "success",
          title: `${name} Deleted!`,
          description: "You have just removed a partner!",
        });
        closeModal();
      }
    } catch (error) {
      setToast({
        open: true,
        type: "error",
        title: `Error on deleting ${name}!`,
        description: error?.message ?? "",
      });
      closeModal();
    }
  };
  return (
    <>
      <Modal
        showModal={isOpen}
        onCloseModal={closeModal}
        title="Are you sure you want to delete this partner?"
        showTertiaryButton={false}
        showLink={false}
        className="custom-modal-delete-partner"
        primaryButtonProps={{
          size: "medium",
          text: loading ? (
            <LoadingSpinner
              size={{ height: "24px", width: "24px" }}
              style={{ margin: "0 2.4rem", color: "white" }}
            />
          ) : (
            "Yes, delete"
          ),
          variant: "primary",
          disabled: loading,
          onClick: handleDelete,
        }}
        secondaryButtonProps={{
          size: "medium",
          text: "Cancel",
          variant: "secondary",
          onClick: closeModal,
        }}
      />
    </>
  );
}

export default DeletePartnerModal;
