import { JsonFormsDispatch, withJsonFormsContext } from "@jsonforms/react";
import { Box, Divider } from "@mui/material";
import _isEqual from "lodash/isEqual";
import { Button } from "pepsico-ds";
import React from "react";

const CardRenderer = (props) => {
  const { uischema, schema, path, renderers, cells, onRemove } = props;
  const elements = uischema.options?.detail?.elements;
  const itemsToRender = elements.map((element, index) => (
    <JsonFormsDispatch
      schema={schema}
      uischema={element}
      path={path}
      enabled
      renderers={renderers}
      cells={cells}
      key={index}
    />
  ));
  return (
    <>
      <Box my={2}>
        {itemsToRender}
        <Button onClick={onRemove} color="danger">
          Remove
        </Button>
      </Box>
      <Divider />
    </>
  );
};

const withContextToCardRenderd = (Component) =>
  function WrappedComponent({ props }) {
    return <Component {...props} />;
  };

const withCustomProps = (Component) =>
  withJsonFormsContext(
    withContextToCardRenderd(
      React.memo(Component, (prevProps, props) => _isEqual(prevProps, props))
    )
  );

export const CardRendererControl = withCustomProps(CardRenderer);
