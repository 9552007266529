import { Box, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { Cell, Legend, Pie, PieChart, Sector, Tooltip } from "recharts";
import { useSegments } from "../../hooks/useSegments";

const COLORS = {
  CDP: "var(--Core-Core-Auxiliary-01, rgba(0, 120, 167, 1))",
  NATIVE: "rgba(176, 87, 136, 1)",
  ACTIVE: "rgba(0, 106, 66, 1)",
  INACTIVE: "rgba(223, 223, 223, 1)",
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 6}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        dx={10}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const SegmentDataTab = () => {
  const { segments, loading, error } = useSegments();
  const [activeIndex, setActiveIndex] = useState(0);

  // If data is loading, show a centered spinner
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If an error occurs, show a centered error message
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h6" color="error">
          Error: Unable to load data
        </Typography>
      </Box>
    );
  }

  // Data processing
  const typeCounts = segments.reduce((acc, segment) => {
    const type = segment.type;
    if (!acc[type]) acc[type] = 0;
    acc[type]++;
    return acc;
  }, {});

  const statusCounts = segments.reduce((acc, segment) => {
    const status =
      segment.status === "ENABLED"
        ? "ACTIVE"
        : segment.status === "DISABLED"
          ? "INACTIVE"
          : segment.status;
    if (!acc[status]) acc[status] = 0;
    acc[status]++;
    return acc;
  }, {});

  const typeData = Object.keys(typeCounts).map((key) => ({
    name: key,
    value: typeCounts[key],
  }));

  const statusData = Object.keys(statusCounts).map((key) => ({
    name: key,
    value: statusCounts[key],
  }));

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      <Box sx={{ width: "45%" }}>
        <h3 style={{ marginTop: "20px" }}>Segment&apos;s Creator</h3>
        <PieChart width={450} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={typeData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {typeData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.name] || COLORS.Disabled}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Box>
      <Box sx={{ width: "45%" }}>
        <h3 style={{ marginTop: "20px" }}>Segment Status</h3>
        <PieChart width={400} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={statusData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#82ca9d"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {statusData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.name] || COLORS.Disabled}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Box>
    </Box>
  );
};

export default SegmentDataTab;
