import { toCamelCase } from "../../../../utils/functions";
import SummaryCard from "./SummaryCard";

const ContentItem = ({ content, schema }) => {
  const getTitles = (name) => {
    return schema[name]?.title || toCamelCase(name);
  };
  const contentItem = Object.entries(content)?.map((content, index) => {
    return (
      <div key={index} className="additional-fields-container">
        <div className="field-pair">
          <span className="field-label">{getTitles(content[0])}</span>
        </div>
        <div className="field-pair-two-column">
          {content[0]?.indexOf("image") !== -1 &&
          content[1]?.indexOf("http") === 0 ? (
            <img
              src={content[1]}
              alt={content[1]}
              style={{ width: "120px", borderRadius: "8px" }}
            />
          ) : (
            <span className="field-value">{content[1]?.toString()}</span>
          )}
        </div>
      </div>
    );
  });
  return contentItem;
};

const ProductContentList = ({ data, contentSchema, editSectionHandler }) => {
  return (
    <SummaryCard title="Product Content" handleEditSection={editSectionHandler}>
      {Object.entries(data).map((item) => (
        <ContentItem
          key={item[0]}
          content={item[1]}
          schema={contentSchema?.properties}
        />
      ))}
    </SummaryCard>
  );
};

export default ProductContentList;
