import { gql } from "@apollo/client";

export const listSweepstakes = gql`
  query ListSweepstakes {
    listSweepstakes(status: [COMPLETED, ENDED]) {
      title
      winnersRequired
      status
      id
      campaign {
        id
        activeStartDate
        activeEndDate
        campaignStatus
        createdAt
      }
      totalEntries
      totalUsers
      updatedAt
    }
  }
`;
