import { Button, Tab, TabsNavigation } from "pepsico-ds";
import { useEffect, useState } from "react";
import CreatePartnerModel from "../../components/partners/Modals/CreatePartner/CreatePartner";
import PartnersCodeGroupTab from "../../components/partners/PartnersCodeGroupTab";
import PartnersTab from "../../components/partners/PartnersTab";
import { useListPartnersQuery } from "../../hooks/useListPartnersQuery";
import "./partners.scss";

export default function PartnersPage() {
  const [createPartnerCodeModalOpen, setCreatePartnerCodeModalOpen] =
    useState(false);
  const [createPartnerModalOpen, setCreatePartnerModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [containerCardY, setContainerCardY] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numPages, setNumPages] = useState(100);
  const [filter, setFilter] = useState({
    currentPage: 0,
    searchText: "",
  });
  const listPartnersQuery = useListPartnersQuery(
    {
      searchText: filter?.searchText,
      active:
        filter?.active?.length > 0
          ? JSON.parse(filter?.active?.[0])
          : filter?.active?.[0],
      types: filter?.type,
    },
    parseInt(currentPage - 1),
    parseInt(rowsPerPage)
  );
  const { total, refetch } = listPartnersQuery;

  const handleResize = () => {
    setContainerCardY(
      Math.ceil(
        document.getElementsByClassName("container-card")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCreatePartnerCodeModal = () => {
    setCreatePartnerCodeModalOpen(!createPartnerCodeModalOpen);
  };

  const closeCreatePartnerModal = () => {
    refetch();
    setCreatePartnerModalOpen(false);
  };

  const resetPagination = () => {
    setCurrentPage(1);
    setRowsPerPage(20);
    setNumPages(Math.ceil(total / rowsPerPage));
  };

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex > 0 && newPageIndex <= numPages) {
      setCurrentPage(newPageIndex);
    }
  };

  useEffect(() => {
    setNumPages(Math.ceil(total / rowsPerPage));
  }, [total, rowsPerPage]);

  useEffect(() => {
    resetPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className="px-3 pt-3">
      <div
        className="container-card"
        style={{ height: `calc(100vh - ${containerCardY}px)` }}
      >
        <div className="page-header">
          <h6 className="font-bold font-lg">List of Partners/Partner Code Groups</h6>
          <div className="page-actions">
            <Button
              icontrailingvariant="outlined"
              size="medium"
              iconTrailing="add_circle"
              disabled={selectedTab === 1}
              variant={selectedTab === 0 ? "primary" : "secondary"}
              onClick={() => setCreatePartnerModalOpen(true)}
            >
              Create Partner
            </Button>
            <Button
              size="medium"
              iconTrailing="add_circle"
              disabled={selectedTab === 0}
              variant={selectedTab === 1 ? "primary" : "secondary"}
              onClick={() =>
                setCreatePartnerCodeModalOpen(!createPartnerCodeModalOpen)
              }
            >
              Create Partner Code Groups
            </Button>
          </div>
        </div>
        <TabsNavigation
          className="tabs-wrap"
          style={{ borderBottom: "2px solid #D7E5F8" }}
          onUpdate={function Ya(index) {
            setSelectedTab(index);
          }}
          selectedIndex={0}
        >
          <Tab label="Partner" />
          <Tab label="Partners Code Groups" />
        </TabsNavigation>
        {selectedTab === 0 && (
          <PartnersTab
            filter={filter}
            setFilter={setFilter}
            listPartnersQuery={listPartnersQuery}
            numPages={numPages}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        )}
        {selectedTab === 1 && (
          <PartnersCodeGroupTab
            createPartnerCodeGroupModalOpen={createPartnerCodeModalOpen}
            toggleCreatePartnerCodeGroupModal={toggleCreatePartnerCodeModal}
          />
        )}
      </div>
      <CreatePartnerModel
        createPartnerModalOpen={createPartnerModalOpen}
        closeCreatePartnerModal={closeCreatePartnerModal}
      />
    </div>
  );
}
