import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { capitalizeFirstLetter } from "../../../utils/commons";

export default function MUISelectField(props) {
  const { value, updateValue, label, schema, uiSchema, required, disabled } = props;

  const placeholderText = uiSchema.placeholder;

  return (
    <FormControl
      fullWidth
      required={required}
      size="small"
      className="display-flex flex-direction-column"
      style={{ gap: "2px" }}
    >
      <div className="label-element">
        <span className="label-text $font-xsmall-regular">
          <label htmlFor={label}>{schema.label}</label>
        </span>
        {required && (
          <span className="required-text $font-xxsmall-bold float-right">
            *Required
          </span>
        )}
      </div>
      <Select
        style={{ fontSize: "13px", maxHeight: "32px" }}
        value={value ?? ""}
        input={<OutlinedInput notched={false} />}
        onChange={(event) => updateValue(event.target.value)}
        disabled={disabled || uiSchema.readOnly}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <span style={{ color: "#aaa" }}>{placeholderText}</span>;
          }

          if (schema.items?.options) {
            const selectedOption = schema.items.options.find(
              (option) => option.id === selected
            );
            return selectedOption
              ? selectedOption.displayText
              : capitalizeFirstLetter(selected.replace(/_/g, " "));
          }

          return capitalizeFirstLetter(selected.replace(/_/g, " "));
        }}
      >
        {schema.items?.options
          ? schema.items.options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.displayText}
              </MenuItem>
            ))
          : /* Fallback to enum array */
            schema.enum.map((option, index) => (
              <MenuItem key={index} value={option}>
                {capitalizeFirstLetter(option?.replace(/_/g, " "))}
              </MenuItem>
            ))}
      </Select>
      {/* {errors && <FormHelperText>{errors}</FormHelperText>} */}
    </FormControl>
  );
}
