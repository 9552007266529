import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../../utils/commons";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import Table from "../../common/table/Table";
import { partnersCodeGroupActionButtons } from "../partnersCodeGroupActionButton";
import "./PartnerCodeGroupTable.scss";

const PartnerCodeGroupTable = ({
  partnerCodeGroupData,
  handleActionButtons,
  currentProgramTimezone,
}) => {
  const headerData = [
    { header: "Status", columnKey: "status", className: "column-status" },
    {
      header: "Code Imported",
      columnKey: "processingStatus",
      className: "column-code-status",
    },
    { header: "Code Group Name", columnKey: "name", className: "column-title" },
    {
      header: "Description",
      columnKey: "description",
      className: "column-description",
    },
    {
      header: "Active Date",
      columnKey: "activeDate",
      className: "column-active-date",
    },
    {
      header: "Import Date",
      columnKey: "importedDate",
      className: "column-inactive-date",
    },
    { header: "Actions", columnKey: "actions", className: "column-actions" },
  ];

  const rowData = partnerCodeGroupData.map((item) => ({
    status: (
      <span
        className={`status ${item?.status === "ACTIVE" ? "active" : "inactive"}`}
      >
        {item?.status}
      </span>
    ),
    processingStatus: item?.status ? (
      <span
        className={
          item?.processingStatus === "IMPORTED" ? "status active" : "status inactive"
        }
      >
        {item?.processingStatus === "IMPORTED" ? (
          <>
            {item?.processingStatus} <CheckIcon fontSize="small" />
          </>
        ) : (
          item?.processingStatus
        )}
      </span>
    ) : (
      "Not Imported"
    ),
    name: item.name || "-",
    description: item?.description || "-",
    activeDate: `${item?.startDate ? getDateWithFormat(item?.startDate, currentProgramTimezone) : "-"} - ${
      item?.endDate ? getDateWithFormat(item?.endDate, currentProgramTimezone) : "-"
    }`,
    importedDate:
      item?.processingStatus === "IMPORTED"
        ? new Date(item?.importedDate).toLocaleDateString()
        : "Processing",
    actions: (
      <ButtonGroupDynamic
        items={partnersCodeGroupActionButtons(
          item?.status,
          handleActionButtons,
          item
        )}
        spacing="0px"
        size="medium"
        variant="control"
      />
    ),
  }));

  return (
    <div className="pcg-list-container">
      <Table headerData={headerData} rowData={rowData} className="pcg-list-table" />
    </div>
  );
};

PartnerCodeGroupTable.propTypes = {
  partnerCodeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      partnerId: PropTypes.string.isRequired,
      programId: PropTypes.string.isRequired,
      codeWarningLimitPercentage: PropTypes.number,
      isActive: PropTypes.bool,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      codeCount: PropTypes.number,
      exhaustedCode: PropTypes.number,
      isImported: PropTypes.bool,
      importedDate: PropTypes.string,
      processingStatus: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ).isRequired,
};

export default PartnerCodeGroupTable;
