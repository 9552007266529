import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: window.REACT_APP_OKTA_ISSUER,
  clientId: window.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + window.REACT_APP_OKTA_REDIRECT_URI,
  pkce: true,
  postLogoutRedirectUri: `${window.location.origin}`,
  scopes: ["openid", "profile", "email", "wsp-scope", "offline_access"],
});

const oktaEvents = {
  /**
   * Only listen to this event in contexts where useOkta() cannot be used.
   */
  tokenChange: "authTokenChange",
};

export { oktaAuth, oktaEvents };
