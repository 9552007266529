import { gql } from "@apollo/client";

export const getCodeGroups = gql`
  query ListCodeGroups($filter: CodeGroupFilter) {
    listCodeGroups(filter: $filter) {
      codeGroupId
      codesGeneratedCount
      administrationStatus
      codesUsedCount
      lifecycleStatus
      basicSetup {
        codeActiveStartDate
        codeActiveEndDate
        codeGroupName
      }
      producerPackaging {
        codesPrintedCount
      }
    }
  }
`;

export const getCodeGroupByIds = gql`
  query loadCodeGroupsByIds($ids: [ID!]!) {
    loadCodeGroupsByIds(ids: $ids) {
      exportUrl
      codeGroupId
      codesGeneratedCount
      administrationStatus
      lifecycleStatus
      basicSetup {
        codeGroupName
        codeActiveStartDate
        codeActiveEndDate
      }
      producerPackaging {
        brand
        producerCompany
        codesPrintedCount
        productVolume
        packageType
        productCapColor
      }
      codeSpecifications {
        codeLength
        usePrefix
        prefixVal
        useSuffix
        suffixVal
        caseSensitive
        excludeCharacters
        excludeCharacterValues
      }
      compositeStatus
    }
  }
`;
