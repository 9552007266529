import { gql } from "@apollo/client";

const updatePartner = gql`
  mutation ($Partner: PartnerInput!, $Id: ID!) {
    updatePartner(input: $Partner, id: $Id) {
      id
      name
      active
      description
      image
      programId
      type
    }
  }
`;

export default updatePartner;
