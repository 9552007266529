import { ToastContainer } from "pepsico-ds";
import { useContext, useState } from "react";

import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import PerformancesTable from "../../components/performances/PerformancesTable";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { listPerformances } from "../../graphql/queries/listPerformances";
import {
  performancesFilterAllJsonSchema,
  performancesFilterAllUiSchema,
  performancesFilterJsonSchema,
  performancesFilterUiSchema,
} from "./mocks";
import "./performances.scss";
const PerformancesListPage = () => {
  return (
    <ListingComponentProvider
      query={listPerformances}
      fetchPolicy="cache-and-network"
      queryKey="listCampaigns"
      //initialFilterData={{ limit: 5000 }}
      initialFilterData={{}}
      noPagination={false}
    >
      <PerformancesListContent />
    </ListingComponentProvider>
  );
};

const PerformancesListContent = () => {
  const { viewableRecords } = useContext(ListingComponentContext);

  const { programTimezone } = useContext(GlobalConfigContext) || {};

  const [toastData, setToastData] = useState([]);

  return (
    <div className="px-3 pt-3">
      {toastData && (
        <ToastContainer
          className="performances-listing-toast"
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <div className="container-card">
        <div className="performances-page-content">
          <div className="performances-header">
            <h2>Performances</h2>
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="performances-filter-styles"
            filterConfig={{
              withoutDebounce: {
                jsonSchema: performancesFilterJsonSchema,
                uiSchema: performancesFilterUiSchema,
              },
              allFilters: {
                jsonSchema: performancesFilterAllJsonSchema,
                uiSchema: performancesFilterAllUiSchema,
              },
            }}
            toggleViewType={false}
            withAllFilters={true}
            exportOptions={true}
          >
            <PerformancesTable
              currentProgramTimezone={programTimezone}
              performancesData={viewableRecords}
            />
          </ListingComponentWrapper>
        </div>
      </div>
    </div>
  );
};

export default PerformancesListPage;
