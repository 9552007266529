import { gql } from "@apollo/client";

export const listPerformances = gql`
  query ListCampaigns($filter: CampaignFilter) {
    listCampaigns(filter: $filter) {
      id
      title
      description
      category
      type
      activityTypes
      activeStartDate
      activeEndDate
      campaignStatus
    }
  }
`;
