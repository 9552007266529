export const codeGroupActionButton = (group, clickHandler, downloadPermission) => {
  const compositeStatus = group?.compositeStatus?.toLowerCase();
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => clickHandler("view", group),
    },
    {
      icon: "content_copy",
      onClick: () => clickHandler("copy", group),
    },
  ];
  const editableStatuses = ["draft", "active", "inactive"];
  const deletableStatuses = ["draft"];
  const exportableStatuses = ["active", "inactive", "generated", "expired"];

  editableStatuses.includes(compositeStatus) &&
    actionButtons.push({
      icon: "edit",
      onClick: () => clickHandler("edit", group),
    });
  deletableStatuses.includes(compositeStatus) &&
    actionButtons.push({
      icon: "delete",
      onClick: () => clickHandler("delete", group),
    });
  exportableStatuses.includes(compositeStatus) &&
    downloadPermission &&
    actionButtons.unshift({
      icon: "download",
      onClick: () => clickHandler("export", group),
    });
  return actionButtons;
};
