import { Icon, SideBar } from "pepsico-ds";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import "./sidebar.scss";

import { configMap } from "../../utils/configMap";

const CustomSideBar = () => {
  const { t } = useTranslation();
  const {
    globalState: { isPanelOpen },
    updateSidebarPanelState,
  } = useContext(GlobalConfigContext);

  const hasAccess = useHasAccessPermission();

  let navigationConfig = configMap[window.REACT_APP_PROGRAM].navigation;

  // Hide performances from side menu
  if (isFeatureEnabled(FeatureToggle.showPerformancesSideMenu) == false) {
    navigationConfig = Object.entries(navigationConfig).reduce(
      (acc, [key, value]) => {
        if (key !== "performances") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  }
  return (
    <div
      className="display-flex flex-direction-column mr-1 hideScrollBar"
      style={{
        boxShadow: "4px 0px 24px -2px rgba(0, 92, 188, 0.08)",
        maxWidth: "300px",
        overflowY: "auto",
      }}
    >
      {!isPanelOpen && (
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => updateSidebarPanelState(true)}
          alt="menu"
          className="$spacing-pl05 $spacing-pr04 $spacing-py03"
          icon="menu"
        />
      )}
      <SideBar
        className={`sidebarStyles ${isPanelOpen ? "sidebarStyles-open" : "sidebarStyles-close"}`}
        items={Object.entries(navigationConfig)
          .filter(
            ([key, value]) => hasAccess(`navigation.${key}`) && !value.hideFromNav
          )
          .map(([, value]) => {
            const sublinks = value.children?.map((child) => {
              return {
                label: (
                  <NavItem
                    key={`${JSON.stringify(child)}`}
                    title={child.i18nKey ? t(child.i18nKey) : child.title}
                    navigateTo={child.navigateTo}
                  />
                ),
              };
            });

            const title = value.i18nKey ? t(value.i18nKey) : value.title;

            return {
              label: sublinks?.length ? (
                title
              ) : (
                <NavItem
                  key={`${JSON.stringify(value)}`}
                  title={title}
                  navigateTo={value.navigateTo}
                />
              ),
              icon: value.icon,
              url: value.navigateTo,
              sublinks: sublinks?.length ? sublinks : undefined,
            };
          })}
      />
    </div>
  );
};

const NavItem = ({ title, navigateTo }) => {
  return (
    <Link
      to={navigateTo}
      style={{
        height: "100%",
        textDecoration: "none",
        whiteSpace: "nowrap",
        width: "100%",
      }}
    >
      {title}
    </Link>
  );
};
export default CustomSideBar;
